import React, { useEffect, useState } from 'react'
import Headbar from '../../components/Headbar'
import Menu from '../../components/Menu'
import './AreaDeMonitores.css'
import { Link } from 'react-router-dom'
import SharedTablePagination from '../../components/SharedTablePagination'
import { listAllTeachers } from '../../apis/teacherApi/teacherApi'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import Page from '../Page'

function AreaDeMonitores() {
  const [monitores, setMonitores] = useState([])
  const [monitoresData, setMonitoresData] = useState([])
  const { loggedUser } = useLoggedUserStore()

  useEffect(() => {
    const fetchData = async () => {
      const data = await listAllTeachers()
      const monitores = data.filter(item => item.id !== loggedUser.user_id)
      // TODO: criar logica para remover professores e não somente o usuario atual
      setMonitoresData(monitores)
      handleRender()
    }
    fetchData()
  }, [])

  useEffect(() => {
    setMonitores(monitoresData)
  }, [monitoresData])

  function handleRender() {

  }

  function handleMonitor(monitores) {
    return (
      <div className="atividade-txt-area-de-monitores">
        <div className="">
          <div
            className="x1-ano-area-de-monitores valign-text-middle-area-de-monitores publicsans-normal-black-14px">{monitores}</div>
        </div>
      </div>
    )
  }

  function handleTurmas(monitor) {
    return monitor.groups.map((item, key) => {
      return (
        <div className="frame-2580-area-de-monitores" key={key}>
          <div className="noite-area-de-monitores valign-text-middle-area-de-monitores publicsans-normal-black-12px">
            {item.name}
          </div>
        </div>
      )
    })
  }

  function geradorStatus() {
    return (
      <div className="atividade-txt-2-area-de-monitores">
        <div className="frame-2581-1-area-de-monitores">
          <div
            className="ativa-area-de-monitores valign-text-middle-area-de-monitores publicsans-normal-black-12px">Ativo
          </div>
        </div>
      </div>
    )
  }

  function geradorSetas(monitorId) {
    return (
      <Link to={`/detalhamento-monitor/${monitorId}`}>
        <div className="atividade-txt-4-area-de-monitores">
          <img className="ic-arrowright-area-de-monitores" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright"/>
        </div>
      </Link>
    )
  }

  function handleSearchInputChange(e) {
    const inputText = e.target.value

    setMonitores(
      monitoresData.filter((item) => {
        const nameMatch = item.name.toLowerCase().includes(inputText.toLowerCase())

        return nameMatch
      })
    )
  }

  return (
    <div className="container-center-horizontal-area-de-monitores">
      <Page authPage={true} allowedUserType={'teacher independentteacher'}>
        <div className="turmas-1-area-de-monitores screen-area-de-monitores">
          <Menu/>
          <Headbar/>
          <div className={'area-de-monitores-body'}>
            <div className="search-filter-1-area-de-monitores">
              <div className="title-cta-area-de-monitores">
                <h1 className="title-area-de-monitores publicsans-normal-absolute-zero-40px">Monitores</h1>
                <Link to={'/adicionar-monitor'}>
                  <div className="cta-criar-atividade-area-de-monitores">
                    <div className="button-area-de-monitores">
                      <div className="mainbutton-area-de-monitores">
                        <div className="frame-29-area-de-monitores">
                          <img className="ic-add-area-de-monitores" src="/img/ic-add@2x.png" alt="ic-add"/>
                          <div
                            className="criar-turma-area-de-monitores valign-text-middle-area-de-monitores inter-bold-zircon-14px">
                            Adicionar monitor
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="search-filter-area-de-monitores">
              <div className="search-filtro-area-de-monitores">
                <div className="search-area-de-monitores">
                  <img className="search-2851823-area-de-monitores" src="/img/search-2851823@2x.png"
                       alt="search-2851823"/>
                  <input
                    className="text-area-de-monitores"
                    placeholder={'Buscar monitores...'}
                    type={'search'}
                    onChange={handleSearchInputChange}
                  />
                </div>
              </div>
            </div>
            <SharedTablePagination
              data={monitores}
              itemsPerPage={5}
              className={'area-de-monitores-table-pagination'}
              columns={[
                {
                  title: 'Monitor',
                  render: (itemData) => <div>{handleMonitor(itemData.name)}</div>
                },
                {
                  title: 'Status',
                  render: (itemData) => <div>{geradorStatus(itemData.blocked)}</div>
                },
                {
                  title: 'Turmas vinculadas',
                  render: (itemData) => <div
                    className="atividade-txt-2-area-de-monitores">{handleTurmas(itemData)}</div>
                },
                {
                  title: 'Detalhes',
                  render: (itemData) => <div>{geradorSetas(itemData.id)}</div>
                }
              ]}
            />
          </div>
        </div>
      </Page>
    </div>
  )
}

export default AreaDeMonitores
