import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import './SharedCardPagination.css'

export default function SharedCardPagination({ data, RenderComponent, itemsPerPage, props }) {
  const [currentPage, setCurrentPage] = useState(0)

  const handlePageClick = ({ selected: selectedPage }) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setCurrentPage(selectedPage)
  }

  const offset = currentPage * itemsPerPage
  const currentPageData = data ? data.slice(offset, offset + itemsPerPage).map((item) => <RenderComponent key={item.id} itemData={item} parentProps={props} />) : null
  const pageCount = Math.ceil(data.length / itemsPerPage)

  return (
    <div className={'shared-pagination'}>
      <div className={'paginated-items'}>
        {currentPageData}
      </div>
      <div className={'pagination-bar'}>
        <ReactPaginate
          previousLabel={<img className="ic-arrow-pagination"
            src="/img/ic-arrowleft@2x.png" alt="ic-arrowleft" />}
          nextLabel={<img className="ic-arrow-pagination" src="/img/ic-arrowright@2x.png"
            alt="ic-arrowright" />}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'current-page-pagination'}
          pageLinkClassName={'number-pagination'}
        />
      </div>
    </div>
  )
}
