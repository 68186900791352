import { focusOn } from './commands';
import { getTabbableNodes, contains } from './utils/DOMutils';
/**
 * for a given `element` in a given `scope` returns focusable siblings
 * @param element - base element
 * @param scope - common parent. Can be document, but better to narrow it down for performance reasons
 * @returns {prev,next} - references to a focusable element before and after
 * @returns undefined - if operation is not applicable
 */
export var getRelativeFocusable = function (element, scope) {
    if (!element || !scope || !contains(scope, element)) {
        return {};
    }
    var focusables = getTabbableNodes([scope], new Map());
    var current = focusables.findIndex(function (_a) {
        var node = _a.node;
        return node === element;
    });
    if (current === -1) {
        // an edge case, when anchor element is not found
        return undefined;
    }
    return {
        prev: focusables[current - 1],
        next: focusables[current + 1],
        first: focusables[0],
        last: focusables[focusables.length - 1],
    };
};
var defaultOptions = function (options) {
    return Object.assign({
        scope: document.body,
        cycle: true,
    }, options);
};
/**
 * focuses next element in the tab-order
 * @param fromElement - common parent to scope active element search or tab cycle order
 * @param {FocusNextOptions} [options] - focus options
 */
export var focusNextElement = function (fromElement, options) {
    if (options === void 0) { options = {}; }
    var _a = defaultOptions(options), scope = _a.scope, cycle = _a.cycle;
    var solution = getRelativeFocusable(fromElement, scope);
    if (!solution) {
        return;
    }
    var next = solution.next, first = solution.first;
    var newTarget = next || (cycle && first);
    if (newTarget) {
        focusOn(newTarget.node, options.focusOptions);
    }
};
/**
 * focuses prev element in the tab order
 * @param fromElement - common parent to scope active element search or tab cycle order
 * @param {FocusNextOptions} [options] - focus options
 */
export var focusPrevElement = function (fromElement, options) {
    if (options === void 0) { options = {}; }
    var _a = defaultOptions(options), scope = _a.scope, cycle = _a.cycle;
    var solution = getRelativeFocusable(fromElement, scope);
    if (!solution) {
        return;
    }
    var prev = solution.prev, last = solution.last;
    var newTarget = prev || (cycle && last);
    if (newTarget) {
        focusOn(newTarget.node, options.focusOptions);
    }
};
