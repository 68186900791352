import React, { useState } from 'react'
import './CadastroStep1.css'
import IlustracaoLogin from '../IlustracaoLogin'
import { Box, HStack, Icon, Text, Tooltip, VStack } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'

function CadastroStep1(props) {
  const { handleReturn, handlingNextStep } = props

  const [userType, setUserType] = useState('Professor')

  const onOptionChange = e => {
    setUserType(e.target.value)
  }

  return (
    <div className="container-center-horizontal-cadastro">
      <div className="cadastro-step01-cadastro screen-cadastro">
        <div className={'cadastro-columns'}>

          <IlustracaoLogin/>

          <div className={'cadastro-body-step-1'}>
            <div className="title-cadastro">
              <h1 className="cadastre-se-no-correo-online-cadastro valign-text-bottom-cadastro">
                            <span>
                              <span className="title-cadastre-se publicsans-bold-absolute-zero-40px">Cadastre-se</span>
                              <span className="span1-cadastro">&nbsp;</span>
                              <span className="span2-cadastro">no Correção Online</span>
                            </span>
              </h1>
            </div>
            <div className="content-3-cadastro">
              <img
                className="line-cadastro"
                src="/img/line.svg"
                alt="Line"
              />
              <div className="step-01-cadastro">
                <Frame2482/>
                <p className="em-qual-perfil-voc-se-encaixa-cadastro publicsans-normal-nevada-24px">
                  Em qual perfil você se encaixa?
                </p>
              </div>
              <div className="steps-cadastro">
                <div className="cards-cadastro">
                  <div
                    className={`card-user-type-cadastro ${userType === 'Professor' ? 'selected-card-user-type' : ''}`}>
                    <input
                      type="radio"
                      name="userType"
                      value="Professor"
                      id="Professor"
                      checked={userType === 'Professor'}
                      onChange={onOptionChange}
                      className={'dot-1-cadastro '}
                    />
                    <div className="frame-2548-cadastro">
                      <img
                        className="avatar-photo-02-cadastro"
                        src="/img/avatar---photo-02.svg"
                        alt="avatar - photo 02"
                      />
                      <div className="frame-2551-cadastro">
                        <div className="frame-2547-cadastro">
                          <HStack
                            className={`${userType === 'Professor' ? 'publicsans-normal-absolute-zero-20px' : 'publicsans-normal-nevada-20px'}`}>
                            <div
                              className={'professor-cadastro'}>
                              Professor Independente
                            </div>
                            <Box display="flex" alignItems="center">
                              <Tooltip label={
                                <VStack align="start" spacing={1}>
                                  <Text>Este perfil é ideal para os professores de cursinhos ou professores autônomos.</Text>
                                </VStack>
                              } aria-label="Uma dica">
                                <Icon as={InfoIcon} cursor="pointer"/>
                              </Tooltip>
                            </Box>
                          </HStack>
                        </div>

                        <div
                          className="gesto-da-plataforma-cadastro publicsans-normal-shady-lady-16px">
                          Perfil exclusivo para professores autônomos.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`card-user-type-cadastro ${userType === 'GestorEscolar' ? 'selected-card-user-type' : ''}`}>
                    <input
                      type="radio"
                      name="userType"
                      value="GestorEscolar"
                      id="GestorEscolar"
                      checked={userType === 'GestorEscolar'}
                      onChange={onOptionChange}
                      className={'dot-1-cadastro '}
                    />
                    <div className="content-4-cadastro">
                      <img
                        className="avatar-photo-02-cadastro"
                        src="/img/avatar---photo-02-2.svg"
                        alt="avatar - photo 02"
                      />
                      <div className="txt-cadastro">
                        <HStack
                          className={`${userType === 'GestorEscolar' ? 'publicsans-normal-absolute-zero-20px' : 'publicsans-normal-nevada-20px'}`}>

                          <div
                            className={`aluno-cadastro ${userType === 'GestorEscolar' ? 'publicsans-normal-absolute-zero-20px' : 'publicsans-normal-nevada-20px'}`}>
                            Gestor Escolar
                          </div>
                          <Box display="flex" alignItems="center">
                            <Tooltip label="É um perfil que lhe permite adicionar quantos professores você precisar." aria-label="Uma dica">
                              <Icon as={InfoIcon} cursor="pointer"/>
                            </Tooltip>
                          </Box>

                        </HStack>
                        <div className="perfil-exclusivo-para-estudantes-cadastro publicsans-normal-shady-lady-16px">
                          Ideal para gestores de instituições escolares.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Frame2550 number="2" criarConta="Criar conta"/>
                <Frame2550 number="3" criarConta="Informações adicionais"/>
                <Frame2550 number="4" criarConta="Finalizar cadastro"/>
              </div>

            </div>
            <div className="buttons-cadastro">
              <Button mainbutton6Props={buttonData.mainbutton6Props} handleReturn={handleReturn}/>
              <Button2 mainbutton2Props={button2Data.mainbutton2Props} handlingNextStep={handlingNextStep}
                       userType={userType}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CadastroStep1

function Button(props) {
  const { handleReturn } = props

  return (
    <div onClick={handleReturn} className="button-cadastro">
      <Mainbutton6/>
    </div>
  )
}

function Mainbutton6(props) {
  const { handlingNextStep } = props

  return (
    <div onClick={handlingNextStep} className="mainbutton-cadastro">
      <div className="content-cadastro">
        <div className="main-button-cadastro publicsans-bold-resolution-blue-14px">
          {'Voltar'}
        </div>
      </div>
    </div>
  )
}

function Button2(props) {
  const { mainbutton2Props, handlingNextStep, userType } = props

  return (
    <div onClick={() => handlingNextStep({ userType })} className="button-1-cadastro">
      <Mainbutton2>{mainbutton2Props.children}</Mainbutton2>
    </div>
  )
}

function Mainbutton2(props) {
  const { children } = props

  return (
    <div className="mainbutton-1-cadastro">
      <div className="content-1-cadastro">
        <div className="main-button-1-cadastro publicsans-bold-white-14px">
          {children}
        </div>
      </div>
    </div>
  )
}

function Frame2482() {
  return (
    <div className="frame-2482-cadastro">
      <div className="number-cadastro publicsans-normal-aqua-deep-24px">
        1
      </div>
    </div>
  )
}

function Frame2550(props) {
  const { number, criarConta } = props

  return (
    <div className="frame-255-cadastro">
      <div className="frame-2483-cadastro">
        <div className="number-1-cadastro publicsans-normal-shady-lady-20px">
          {number}
        </div>
      </div>
      <div className="criar-conta-cadastro publicsans-normal-shady-lady-20px">
        {criarConta}
      </div>
    </div>
  )
}

const mainbutton6Data = {
  children: 'Voltar para home'
}

const buttonData = {
  mainbutton6Props: mainbutton6Data
}

const mainbutton2Data = {
  children: 'Continuar'
}

const button2Data = {
  mainbutton2Props: mainbutton2Data
}
