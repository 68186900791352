import { Button } from '@chakra-ui/react'

export const PaginationButton = ({ direction, onClick, ...props }) => {
  return (
        <Button
            size='xs'
            backgroundColor='primary.200'
            _hover={{ backgroundColor: 'primary.100' }}
            color='white'
            borderRadius='2px'
            onClick={onClick}
            {...props}
        >
            {
                direction === 'next'
                  ? '>'
                  : '<'

            }
        </Button>
  )
}
