import './CriarAtividade.css'
import React, { useEffect } from 'react'
import CriarAtividadeStep1 from '../../components/CriarAtividadeStep1'
import CriarAtividadeStep3 from '../../components/CriarAtividadeStep3'
import { useNavigate } from 'react-router-dom'
import CriarAtividadeStep2 from '../../components/CriarAtividadeStep2'
import { Flex } from '@chakra-ui/react'
import useCriarAtividadeStore from '../../store/useCriarAtividadeStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import { listAllInstitutionsFromSchool } from '../../apis/institutionApi/institutionApi'
import { createActivityApi } from '../../apis/activitityApi/activityApi'
import Page from '../Page'

const CriarAtividade = () => {
  const navigate = useNavigate()

  // Fetch states and actions from zustand store
  const {
    currentStep,
    criarAtividadeData,
    setCurrentStep,
    setCriarAtividadeData,
    cleanCriarAtividadeData
  } = useCriarAtividadeStore()
  const { loggedUser } = useLoggedUserStore()

  const fetchInstitution = () => {
    return new Promise((resolve) => {
      const result = listAllInstitutionsFromSchool()

      resolve(result)
    })
  }

  useEffect(() => {
    fetchInstitution().then((data) => {
      setCriarAtividadeData({ institutionId: data[0].id })
      setCriarAtividadeData({ schoolId: loggedUser.user_id })
    })
  }, [])

  const createActivity = () => {
    return new Promise((resolve) => {
      const result = createActivityApi(criarAtividadeData)
      resolve(result)
    })
  }

  const handleNextStep = () => {
    if (currentStep === 2) {
      createActivity().then(() => {
        cleanCriarAtividadeData()
        setCurrentStep(currentStep + 1)
      }
      )
    } else if (currentStep === 3) {
      navigate('/area-de-atividades')
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handlePreviousStep = () => {
    if (currentStep === 3 || currentStep === 1) {
      navigate('/area-de-atividades')
      setCurrentStep(1)
      cleanCriarAtividadeData()
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CriarAtividadeStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      case 2:
        return <CriarAtividadeStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      case 3:
        return <CriarAtividadeStep3 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      // Add more cases for additional steps
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <Flex alignItems={'center'} justifyContent={'center'} h={'100%'} w={'100%'}>
        {renderStepContent()}
        {}
      </Flex>
    </Page>
  )
}

export default CriarAtividade
