import React from 'react'
import './UserProfileHeadbarComponent.css'
import useLoggedUserStore from '../../store/useLoggedUserStore'

function UserProfileHeadbarComponent(props) {
  const { loggedUser } = useLoggedUserStore()
  const LOGGED_USER_ROLE = {
    student: 'Aluno',
    teacher: 'Professor',
    independentteacher: 'Professor',
    monitor: 'Monitor',
    adm: 'Administrador',
    owner: 'Owner'
  }

  return (
    <div className="frame-2553">
      <img className="avatar-photo-02" src="/img/avatar---photo-02-20@2x.png" alt="avatar - photo 02" />
      <div className="frame-2581">
        <div className="gestor valign-text-middle publicsans-bold-nevada-10px">{LOGGED_USER_ROLE[loggedUser.user_role]}</div>
        <div className="joo-pedro-silva valign-text-middle publicsans-normal-black-14px">{loggedUser.user_name}</div>
      </div>
    </div>
  )
}

export default UserProfileHeadbarComponent
