import React from 'react'
import { Link } from 'react-router-dom'
import Mainbutton22 from '../Mainbutton22'
import './Button22.css'

function Button22(props) {
  const { className, mainbutton2Props, linkTo } = props

  return (
        <Link to={linkTo}>
            <div className={`button-2-1 ${className || ''}`}>
                <Mainbutton22 className={mainbutton2Props.className} contentProps={mainbutton2Props.contentProps}/>
            </div>
        </Link>
  )
}

export default Button22
