import {BACKEND_API} from "./Constants";
import {httpRequestHelper} from "./ApiMiddleware";

export const confirmEmailApi = async (data) => {

  const response = await httpRequestHelper.post(BACKEND_API + `/v1/account/confirm-email`, data)

  return response.data.message
}

export const createIndependentTeacherApi = async (data, url) => {

  const requestBody = {
    name: data.nomeInstituicao,
    email: data.email,
    phoneNumber: data.telefone,
    documentNumber: data.cnpj,
    language: 'pt-br',
    password: data.senha,
    UrlConfirmEmailFront: url
  }

  console.log(requestBody)

  const response = await httpRequestHelper.post(BACKEND_API + `/v1/teacher/independentTeacher`, requestBody)

  return response.data.message
}

