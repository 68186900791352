import React, { useEffect, useState } from 'react'
import './DetalhamentoMonitor.css'
import Headbar from '../../components/Headbar'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getActivityByTeacherIdApi } from '../../apis/activitityApi/activityApi'
import SharedTablePagination from '../../components/SharedTablePagination'
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'
import { listAllGroups } from '../../apis/groupApi/GroupApi'
import { deleteTeacherApi, getTeacherByIdApi, updateTeacherApi } from '../../apis/teacherApi/teacherApi'
import { phoneNumberFormatter } from '../../utils/phoneNumberFormatter'
import { buildMessage } from '../../utils/toastMiddleware'
import Page from '../Page'

function DetalhamentoMonitor(props) {
  const { callToast } = props
  const navigate = useNavigate()
  const { monitorID } = useParams()
  const [monitorData, setMonitorData] = useState({})
  const [activitiesData, setActivitiesData] = useState([])
  const [activities, setActivities] = useState([])
  const [turmaOptions, setTurmaOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [filterText, setFilterText] = useState('')
  const [selectedTab, setSelectedTab] = useState('atividades')
  const modalDisclosure = useDisclosure()
  const popoverDisclosure = useDisclosure()

  const fetchOptions = () => {
    return new Promise((resolve) => {
      Promise.all([getTeacherByIdApi(monitorID), getActivityByTeacherIdApi(monitorID), listAllGroups()])
        .then(([monitorResult, activitiesResult, groupsResult]) => {
          resolve({ monitorResult, activitiesResult, groupsResult })
        })
    })
  }

  useEffect(() => {
    fetchOptions().then(({ monitorResult, activitiesResult, groupsResult }) => {
      setMonitorData(monitorResult)
      setActivitiesData(activitiesResult)
      setTurmaOptions(groupsResult.map((turma) => {
        return { value: turma.name, label: turma.name, id: turma.id }
      }))
      setLoading(false)
    })
  }, [])

  function fetchMonitor() {
    Promise.all([getTeacherByIdApi(monitorID)])
      .then(([monitorResult]) => {
        setMonitorData(monitorResult)
      })
  }

  useEffect(() => {
    if (monitorData) {
      setActivities(
        activitiesData ? activitiesData.filter((item) => {
          return (item.subject.toLowerCase().includes(filterText.toLowerCase()) ||
            item.theme.name.toLowerCase().includes(filterText.toLowerCase()))
        }) : []
      )
    }
  }, [filterText, activitiesData])

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  function HandleDetails({ activityId }) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>{
        <Link to={'/detalhamento-atividade/' + activityId}>
          <div className="atividade-txt-4-area-de-turmas">
            <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'}
                 alt="ic-arrowright"/>
          </div>
        </Link>}
      </div>
    )
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function GetModalEditMonitor() {
    const [editMonitorData, setEditMonitorData] = useState({
      name: monitorData.name,
      phoneNumber: monitorData.phoneNumber,
      groups: monitorData.groups
    })

    const [turmaValue, setTurmaValue] = useState(monitorData?.schools[0].groups.map(item => item.name))

    useEffect(() => {
      if (turmaValue) {
        setEditMonitorData({
          ...editMonitorData,
          groups: turmaValue.map(turma => turmaOptions.find(item => turma === item.value).id)
        })
      }
    }, [turmaValue])

    return (
      <Modal isOpen={modalDisclosure.isOpen} size={'2xl'} onClose={modalDisclosure.onClose} isCentered={true}
             closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-monitor'}>Editar
            Monitor</ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={'publicsans-bold-nevada-16px'}>Nome do monitor*</div>
              <Input className={'input-default-style'}
                     onChange={(e) => setEditMonitorData({ ...editMonitorData, name: e.target.value })}
                     defaultValue={editMonitorData.name}
              />
              <div className={'publicsans-bold-nevada-16px'}>Número do monitor</div>
              <Input className={'input-default-style'}
                     onChange={(e) => {
                       const formattedValue = phoneNumberFormatter(e.target.value)
                       setEditMonitorData({ ...editMonitorData, phoneNumber: formattedValue })
                     }}
                     value={editMonitorData.phoneNumber}
              />
              <div className={'publicsans-bold-nevada-16px'}>Turmas vinculadas ao monitor</div>
              <Box w={'100%'} h={'48px'}>
                <MultiSelect
                  placeholder={loading ? 'Aguarde um momento...' : 'Selecionar turma'}
                  onChange={(e) => {
                    setTurmaValue(e.map(turma => {
                      if (turma?.value) { return turma.value }
                      return turma
                    }))
                  }}
                  borderColor={'#d7e3fb'}
                  backgroundColor={'white'}
                  isDisabled={loading}
                  options={turmaOptions}
                  value={turmaValue}
                  w={'100%'}
                />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button backgroundColor={'#f9faff'}
                    borderColor={'#648BF7'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#062989'}
                    height={'48px'}
                    width={'218px'} onClick={modalDisclosure.onClose}>
              Cancelar
            </Button>
            <Button backgroundColor={'#0A41D6'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#FFFFFF'}
                    height={'48px'}
                    width={'218px'}
                    onClick={async () => {
                      if (editMonitorData.name) {
                        const result = await updateTeacherApi(editMonitorData, monitorData.id)

                        if (result.response?.status === 400) {
                          callToast(buildMessage('error', 'Erro ao atualizar monitor', 'O monitor deve pertencer ao menos a uma turma.'))
                        }

                        fetchMonitor()
                        modalDisclosure.onClose()
                      }
                    }}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  function HandleAtividadeColumn({ atividadeName }) {
    return (
      <div className={'table-cell-container publicsans-normal-nevada-14px'}>{atividadeName}</div>
    )
  }

  function HandleTemaColumn({ temaName }) {
    return (
      <div className={'table-cell-container publicsans-normal-nevada-14px'}>{temaName}</div>
    )
  }

  function HandleEntregasColumn({ item }) {
    if (!item) {
      return (
        <div className={'table-cell-container'}/>
      )
    }
    const countSubmitted = item.amountSubmitted.length
    const countReviewed = item.amountReviewed.length
    const countClosed = item.amountClosed.length

    const totalCount = countSubmitted + countReviewed + countClosed

    return (
      <div className={'table-cell-container '}>
        <div className={'blue-background-cell-column publicsans-bold-absolute-zero-12px'}>{totalCount} Redações
          entregues
        </div>
      </div>
    )
  }

  function HandleTurmasVinculadas() {
    console.log(monitorData)

    return (
      loading ? '' : monitorData?.schools[0].groups?.map((item, key) => {
        return (
          <div className={'group-background'} key={key}>
            <p className="theme-name-detalhamento-monitor publicsans-normal-nevada-14px"
               onClick={() => navigate('/detalhamento-turma/' + item.id)}>
              {item.name}
            </p>
          </div>
        )
      })
    )
  }

  return (
    <div className="detalhamento-monitor screen">
      <Page authPage={true} allowedUserType={'teacher independentteacher'}>
        <Headbar returnTo={'/area-de-monitores'}/>
        <HStack width={'100%'} justifyContent={'space-between'} marginTop={'91px'}>
          <div className={'detalhamento-monitor-body'}>
            <div className="atividade-detalhamento-monitor">
              <div className="frame-2593-detalhamento-monitor">
                <div className="frame-2594-detalhamento-monitor">
                  <VStack width={'100%'} alignItems={'flex-start'}>
                    <div className={'publicsans-bold-nevada-14px'}>
                      MONITOR
                    </div>
                    <div
                      className="atividade-iii-unidade-2-detalhamento-monitor publicsans-normal-absolute-zero-24px">
                      {loading ? '' : monitorData.name}
                    </div>
                  </VStack>
                  <div className="editar-detalhamento-monitor">
                    <div className="edit-container-detalhamento-monitor">
                      <div
                        className="editar-1-detalhamento-monitor valign-text-middle publicsans-bold-nevada-14px">
                        Editar
                      </div>
                      <img
                        className="mode-edit-detalhamento-monitor"
                        src="/img/mode-edit@2x.png"
                        alt="Mode edit"
                        onClick={modalDisclosure.onOpen}
                      />
                      {!loading && monitorData?.schools[0] && <GetModalEditMonitor/>}
                    </div>
                  </div>
                </div>
                <Spacer/>
                <div className="frame-2592-detalhamento-monitor">
                  <div className="tema-detalhamento-monitor">
                    <HStack justifyContent={'space-between'} width={'100%'}>
                      <HStack>
                        <div className="place-detalhamento-monitor publicsans-bold-nevada-10px">
                          TURMAS VINCULADAS
                        </div>
                        <HandleTurmasVinculadas/>
                      </HStack>
                      <HStack>
                        <div className={'publicsans-bold-nevada-14px'}> Apagar Monitor?</div>
                        <div>
                          <Popover isOpen={popoverDisclosure.isOpen} onOpen={popoverDisclosure.onOpen}
                                   onClose={popoverDisclosure.onClose} placement="bottom-end" closeOnBlur={false}>
                            <PopoverTrigger>
                              <Image src={'/img/deleteIcon.svg'} marginRight={'15px'} cursor={'pointer'}/>
                            </PopoverTrigger>
                            <PopoverContent width={'448px'} borderRadius={'10px'}>
                              <PopoverArrow/>
                              <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'}/>
                              <PopoverHeader>
                                <div className={'text-popover'}>Confirme Remoção do monitor</div>
                              </PopoverHeader>
                              <PopoverFooter alignSelf={'center'} marginTop={'50px'} width={'100%'}>
                                <HStack width={'100%'}>
                                  <Button backgroundColor={'#f9faff'}
                                          borderColor={'#648BF7'}
                                          border={'1px'}
                                          borderRadius={'8px'}
                                          color={'#062989'}
                                          height={'48px'}
                                          width={'100%'} onClick={popoverDisclosure.onClose}>
                                    Cancelar
                                  </Button>
                                  <Button backgroundColor={'#0A41D6'}
                                          color={'white'}
                                          width={'100%'}
                                          height={'48px'}
                                          onClick={async () => {
                                            await deleteTeacherApi(monitorData.id)
                                            navigate('/area-de-monitores')
                                          }}
                                  > Confirmar Remoção </Button>
                                </HStack>
                              </PopoverFooter>
                            </PopoverContent>
                          </Popover>
                        </div>
                      </HStack>
                    </HStack>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-2595-detalhamento-monitor">
              <div className="switch-detalhamento-monitor">
                <div className={`frame-2469-detalhamento-monitor ${selectedTab === 'atividades' ? 'tab-border' : ''}`}
                     onClick={() => changeSelectedTab('atividades')}>
                  <div
                    className={`material-de-apoio-detalhamento-monitor ${selectedTab === 'atividades' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                    Atividades Vinculadas
                  </div>
                </div>
              </div>
            </div>
            {selectedTab === 'atividades' && (
              <div className={'redacoes-container-style'}>
                <div className="search-filter-detalhamento-monitor">
                  <div className="search-filtro-detalhamento-monitor">
                    <div className="search-detalhamento-monitor">
                      <img
                        className="search-2851823-detalhamento-monitor"
                        src="/img/search-2851823@2x.png"
                        alt="search-2851823"
                      />
                      <input
                        placeholder={'Buscar atividades...'}
                        type={'search'}
                        className="text-detalhamento-monitor publicsans-normal-nevada-16px"
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </div>
                </div>
                <SharedTablePagination
                  className={'table-adjustment-style'}
                  data={activities}
                  itemsPerPage={5}
                  columns={[
                    {
                      title: 'Atividade',
                      render: (itemData) => <HandleAtividadeColumn atividadeName={itemData.subject}/>
                    },
                    {
                      title: 'Tema',
                      render: (itemData) => <HandleTemaColumn temaName={itemData.theme.name}/>
                    },
                    {
                      title: 'Entregas',
                      render: (itemData) => <HandleEntregasColumn item={itemData}/>
                    },
                    {
                      title: 'Detalhes',
                      render: (itemData) => <HandleDetails activityId={itemData.id}/>
                    }
                  ]}
                />
              </div>
            )}
          </div>
          <div className="frame-2591-detalhamento-monitor">
            {/* <h1 className="title-1-detalhamento-monitor publicsans-normal-resolution-blue-24px"> */}
            {/*  Dados do monitor */}
            {/* </h1> */}

            <h1 className="title-1-detalhamento-monitor publicsans-normal-resolution-blue-24px">
              Contato
            </h1>
            <div>
              <div className={'publicsans-normal-nevada-16px'}>
                Email
              </div>
              <div>
                <HStack width={'100%'} marginTop={'8px'}>
                  <Image src={'/img/message-icon.svg'}/>
                  <div className={'publicsans-normal-nevada-14px'}> {monitorData.email} </div>
                </HStack>
              </div>
            </div>
            <div>
              <div className={'publicsans-normal-nevada-16px'}>
                Telefone
              </div>
              <div>
                <HStack width={'100%'} marginTop={'8px'}>
                  <Image src={'/img/phone-icon.svg'}/>
                  <div className={'publicsans-normal-nevada-14px'}> {monitorData.phoneNumber} </div>
                </HStack>
              </div>
            </div>
          </div>
        </HStack>
      </Page>
    </div>
  )
}

export default DetalhamentoMonitor
