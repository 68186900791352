import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const listAllInstitutionsFromSchool = async () => {
  const schoolID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/school/${schoolID}/institution`)

  return response.data.message
}

export const createInstitutionApi = async (data) => {
  const schoolID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.postWithAuth(BACKEND_API + `/v1/school/${schoolID}/institution`, data)

  return response.data.message
}

export const getInstitutionApi = async (institutionId) => {
  // const schoolID = localStorage.getItem('user_id')
  const schoolID = 'c5d9224f-6cf9-49bd-88d9-a7cb1b22226c'

  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/school/${schoolID}/institution/${institutionId}`)

  return response.data.message
}

export const deleteInstitutionApi = async (institutionId) => {
  const schoolID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.delWithAuth(BACKEND_API + `/v1/school/${schoolID}/institution/${institutionId}`)

  return response.data.message
}

export const putInstitutionApi = async (data) => {
  const schoolID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.putWithAuth(BACKEND_API + `/v1/school/${schoolID}/institution`, data)

  return response.data.message
}
