import './CadastroPage.css'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CadastroStep1 from '../../components/CadastroStep1'
import CadastroStep2 from '../../components/CadastroStep2'
import CadastroStep3 from '../../components/CadastroStep3'
import CadastroStep4 from '../../components/CadastroStep4'
import HeaderLogin from '../../components/LoginHeader'
import {createIndependentTeacherApi} from "../../apis/AccountApi";

const CadastroPage = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [userData, setUserData] = useState({})
  const navigate = useNavigate()

  const handleNextStep = (data) => {
    if (currentStep === 4) {
      setUserData({})
      navigate('/login')
    } else if (currentStep === 3) {
      setUserData({ ...userData, ...data })
      createIndependentTeacherApi(userData, window.location.protocol + '//' + window.location.host).then(() => {
        setCurrentStep(currentStep + 1)
      }).catch(() =>{
        setUserData({isLoading: false})
      })

    } else {
      setUserData({ ...userData, ...data })
      setCurrentStep(currentStep + 1)
    }


  }

  const handlePreviousStep = () => {
    if (currentStep === 4 || currentStep === 1) {
      navigate('/login')
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CadastroStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      case 2:
        return <CadastroStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} userData={userData}/>
      case 3:
        return <CadastroStep3 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} userData={userData}/>
      case 4:
        return <CadastroStep4 handlingNextStep={handleNextStep}/>
        // Add more cases for additional steps
      default:
        return null
    }
  }

  return (
        <div>
            {renderStepContent()}
            <HeaderLogin />
        </div>
  )
}

export default CadastroPage
