import { useState, useEffect } from 'react'
import { createAssistantAccountApi, getAllAssistantAccountsApi, getAllAssistantTypesApi, updateAssistantActivateApi, updateAssistantBalanceApi, updateAssistantDeactivateApi } from '../../apis/schoolApi/schoolApi'
import { Button, Card, CardBody, Center, CircularProgress, Flex, FormControl, FormLabel, Grid, GridItem, HStack, Heading, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Stack, Switch, Text } from '@chakra-ui/react'
import { AddIcon } from '../icons/Add'

export const SchoolAssistantComponent = ({ schoolId }) => {
  const [assistantAccount, setAssistantAccount] = useState(null)
  const [allAssistantTypes, setAllAssistantTypes] = useState([])
  const [selectedAssistantType, setSelectedAssistantType] = useState('')
  const [quota, setQuota] = useState(1)
  const [fetchLoading, setFetchLoading] = useState(true)

  function resetForm() {
    setSelectedAssistantType('')
    setQuota(1)
  }

  async function fetchAssistantAccounts() {
    const result = await getAllAssistantAccountsApi(schoolId)
    if (result) {
      setAssistantAccount(result[0])
    }
    setFetchLoading(false)
  }

  async function fetchAllAssistantTypes() {
    const result = await getAllAssistantTypesApi()
    setAllAssistantTypes(result)
  }

  useEffect(() => {
    fetchAssistantAccounts()
    fetchAllAssistantTypes()
  }, [])

  async function addNewAssistantAccount() {
    const data = {
      schoolId,
      assistantTypeId: selectedAssistantType,
      qty: quota
    }
    await createAssistantAccountApi(data)
    fetchAssistantAccounts()
    resetForm()
  }

  async function updateAssistantAccountBalance(newBalance) {
    await updateAssistantBalanceApi(schoolId, newBalance - assistantAccount.qty)
  }

  async function updateAssistantAccountActivate(value) {
    if (value) {
      await updateAssistantActivateApi(schoolId)
    } else {
      await updateAssistantDeactivateApi(schoolId)
    }
  }

  return (
    fetchLoading
      ? <CircularProgress isIndeterminate />
      : <Stack spacing={10}>
                <Stack spacing={4}>
                    <Heading size={'xs'} color='blackAlpha.700'>Assistente disponível</Heading>

                    <Card size='sm' variant='outline' border='1px solid #f1f1f1'>
                        <CardBody>
                            <Stack textAlign='center' justifyContent='center'>
                                {
                                    fetchLoading
                                      ? <Center><CircularProgress isIndeterminate size='20px' /></Center>
                                      : assistantAccount
                                        ? <Flex justifyContent='space-between' alignItems='center'>
                                                <HStack>
                                                    <img width="30" height="30" src="https://img.icons8.com/fluency/48/bot.png" alt="bot" />
                                                    <Text fontSize='sm'>
                                                        <span style={{ fontWeight: 'bold' }}>{assistantAccount.assistantType.name}</span>
                                                    </Text>
                                                </HStack>
                                                <HStack spacing={8}>
                                                    <NumberInput size='sm' width='80px' borderColor='#f1f1f1' defaultValue={assistantAccount.qty} min={0} max={100} onChange={(stringValue) => updateAssistantAccountBalance(Number(stringValue))}>
                                                        <NumberInputField />
                                                        <NumberInputStepper>
                                                            <NumberIncrementStepper />
                                                            <NumberDecrementStepper />
                                                        </NumberInputStepper>
                                                    </NumberInput>
                                                    <Switch size='sm' defaultChecked={assistantAccount.activated} onChange={(e) => updateAssistantAccountActivate(e.target.checked)} />
                                                </HStack>
                                            </Flex>
                                        : <Text color='blackAlpha.700'>Nenhum</Text>
                                }
                            </Stack>
                        </CardBody>
                    </Card>
                </Stack>

                <Stack spacing={4}>
                    <Heading size={'xs'} color='blackAlpha.700'>Criar novo assistente</Heading>

                    <form>
                        <Grid templateColumns='repeat(12, 1fr)' gap={4} height='100%' alignItems='end'>
                            <GridItem colSpan={{ sm: 12, lg: 8 }}>
                                <FormControl>
                                    <FormLabel>Tipo de assistente</FormLabel>
                                    <Select value={selectedAssistantType} onChange={e => setSelectedAssistantType(e.target.value)}>
                                        <option value=''>Selecionar</option>
                                        {
                                            allAssistantTypes.map(assistantType => (
                                                <option key={assistantType.id} value={assistantType.id}>{assistantType.name}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ sm: 12, lg: 3 }}>
                                <FormControl>
                                    <FormLabel>Saldo</FormLabel>
                                    <NumberInput value={quota} min={1} max={100} onChange={stringValue => setQuota(Number(stringValue))}>
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ sm: 12, lg: 1 }}>
                                <Button variant='primary' isDisabled={!selectedAssistantType} onClick={addNewAssistantAccount}>
                                    <AddIcon />
                                </Button>
                            </GridItem>
                        </Grid>
                    </form>
                </Stack>
            </Stack>
  )
}
