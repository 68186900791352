import React from 'react'
import './MenuEscolas.css'
import {useNavigate} from 'react-router-dom'
import useLoggedUserStore from '../../store/useLoggedUserStore'

function MenuEscolas() {
  const {loggedUser} = useLoggedUserStore()
  const navigate = useNavigate()

  let menuClass = 'menu-item'
  let menuPicturePath = '/img/icon-category.svg'
  let menuPictureText = 'publicsans-bold-nevada-16px'

  if (document.location.toString().includes('/area-de-escolas')) {
    menuClass += ' menu-foco'
    menuPicturePath = '/img/icon-category-selected.svg'
    menuPictureText = 'publicsans-bold-absolute-zero-16px'
  }

  if (loggedUser.user_role !== 'adm') {
    return ('')
  }

  return (
    <div className={menuClass} onClick={() => navigate('/area-de-escolas')}>
      <img className="menu-escolas-picture" src={menuPicturePath}/>
      <div className={menuPictureText}>Escolas</div>
    </div>
  )
}

export default MenuEscolas
