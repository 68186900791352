import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Portal,
  Stack,
  Text,
  Textarea,
  useDisclosure
} from '@chakra-ui/react'
import Headbar from '../../components/Headbar'
import { DocumentErrorIcon } from '../../components/icons/DocumentError'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ESSAY_STATUS, gradeColors } from './utils'
import { TouchIcon } from '../../components/icons/Touch'
import { CheckIcon } from '../../components/icons/Check'
import { TrashIcon } from '../../components/icons/Trash'
import { ReplayIcon } from '../../components/icons/Replay'
import { VoiceRecorder } from '../../components/voice-recorder'
import {
  deleteCommentaryApi,
  fetchComments,
  fetchEssay,
  finishReviewApi,
  postCommentaryApi,
  updateGradesApi
} from '../../apis/essayApi/essayApi'
import { useParams } from 'react-router-dom'
import { EssayCorrectionArea } from '../../components/correction-area'
import { CommentIcon } from '../../components/icons/Comment'
import { format } from 'date-fns'
import { Modal } from '../../components/modal'
import { CanvasDrawContainer } from '../../components/canvas-draw'
import Page from '../Page'

const EssayCorrection = (props) => {
  const [essay, setEssay] = useState(null)
  const [comments, setComments] = useState([])
  const [competencies, setCompetencies] = useState(null)
  const [selectedCompetencyIndex, setSelectedCompetencyIndex] = useState(0)
  const [selectedText, setSelectedText] = useState('')
  const [selectionRange, setSelectionRange] = useState([null, null, null])
  const [imageSelectionData, setImageSelectionData] = useState()
  const [cursorPosition, setCursorPosition] = useState([0, 0])
  const [allowedHighlight, setAllowedHighlight] = useState(false)
  const [isSelectionMode, setIsSelectionMode] = useState(true)
  // const [showAudioPlayer, setShowAudioPlayer] = useState(false)
  // const [audioUrl, setAudioUrl] = useState()
  const [selectedCommentary, setSelectedCommentary] = useState(null)
  const [newCommentary, setNewCommentary] = useState('')
  const [actionLoading, setActionLoading] = useState(false)
  const { isOpen: finishReviewIsOpen, onOpen: finishReviewOnOpen, onClose: finishReviewOnClose } = useDisclosure()

  const { id } = useParams()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const essayRef = useRef()
  const textAreaRef = useRef()

  async function getEssay() {
    const essay = await fetchEssay(id)

    setEssay(essay)
    setCompetencies(essay.competencies)
  }

  async function getComments() {
    const comments = await fetchComments(id)
    setComments(comments)
  }

  function incrementSelectedCompetency() {
    setSelectedCompetencyIndex((state) => state < 4 ? state + 1 : state)
  }

  function decrementSelectedCompetency() {
    setSelectedCompetencyIndex((state) => state > 0 ? state - 1 : state)
  }

  function setNewGrade(grade) {
    const newCompetencies = competencies.slice(0)
    newCompetencies[selectedCompetencyIndex] = { ...selectedCompetency, grade }
    setCompetencies(newCompetencies)
  }

  const isEssayImage = useMemo(() => !!(essay && essay.attachment), [essay])

  const selectedCompetency = useMemo(() => competencies ? competencies[selectedCompetencyIndex] : null, [selectedCompetencyIndex, competencies])

  const totalGrade = useMemo(() => competencies ? competencies.reduce((total, competency) => total + competency.grade, 0) : 0, [competencies])

  const gradeOptions = Object.keys(gradeColors).map(g => Number(g))

  function handleStateChange(func, value) {
    if (func) {
      func(value)
    }
  }

  useEffect(() => {
    getEssay()
    getComments()
  }, [])

  useEffect(() => {
    const handleSelectionChange = () => {
      // console.log('handleSelectionChange')
      const selection = window.getSelection()
      if (selection && essayRef.current && essayRef.current.contains(selection.anchorNode)) {
        const selectedString = selection.toString()
        handleStateChange(setSelectedText, selectedString)
        const values = [selection.anchorOffset, selection.focusOffset]
        const min = Math.min(...values)
        const max = Math.max(...values)
        handleStateChange(setSelectionRange, [min, max, max - min])
      }
    }

    document.addEventListener('selectionchange', handleSelectionChange)

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange)
    }
  }, [])

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isOpen) {
        handleStateChange(setCursorPosition, [e.clientX, e.clientY])
      }
    }
    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isOpen])

  function onClosePopover() {
    onClose()

    if (isEssayImage) {
      setImageSelectionData(null)
    } else {
      window.getSelection().removeAllRanges()
      setAllowedHighlight(false)
    }

    setNewCommentary('')
    setSelectedCommentary(null)
  }

  function openPopover() {
    onOpen()
    textAreaRef.current.focus()
  }

  function onClickUp() {
    if (selectionRange[2] > 0 && isSelectionMode) {
      openPopover()
      setAllowedHighlight(true)
    } else {
      onClosePopover()
    }
  }

  async function saveCommentary() {
    try {
      setActionLoading(true)
      const [startPos, endPos] = selectionRange
      const positionsPayload = {}
      if (isEssayImage) {
        positionsPayload.lineHeight = imageSelectionData.lineHeight
        positionsPayload.startPosX = imageSelectionData.startX
        positionsPayload.startPosY = imageSelectionData.startY
        positionsPayload.endPosX = imageSelectionData.endX
        positionsPayload.endPosY = imageSelectionData.endY
      } else {
        positionsPayload.startPos = startPos
        positionsPayload.endPos = endPos
      }
      console.log('imageSelectionData', imageSelectionData)
      console.log('positionsPayload', positionsPayload)

      await postCommentaryApi(essay.id, {
        comment: newCommentary,
        competencyId: selectedCompetency.id,
        ...positionsPayload
      })
      await getComments()
      onClosePopover()
      setIsSelectionMode(false)
    } catch (error) {
      console.log(error)
    } finally {
      setActionLoading(false)
    }
  }

  async function deleteCommentary() {
    try {
      setActionLoading(true)
      await deleteCommentaryApi(essay.id, selectedCommentary.id)
      await getComments()
      onClosePopover()
    } catch (error) {
      console.log(error)
    } finally {
      setActionLoading(false)
    }
  }

  async function finishReview() {
    try {
      setActionLoading(true)
      await updateGradesApi(essay.id, { grades: competencies, finalGrade: totalGrade })
      await finishReviewApi(essay.id)
      finishReviewOnClose()
    } catch (error) {
      console.log(error)
    } finally {
      setActionLoading(false)
    }
  }

  const onRecordingComplete = (blob) => {
    // const url = URL.createObjectURL(blob)
    // setAudioUrl(url)
    // setShowAudioPlayer(true)
  }

  return (
    <Page authPage={true} allowedUserType={'teacher monitor independentteacher'} >
      {
        essay && competencies &&
        <>
          <Stack spacing={0}>
            <Headbar returnTo={'/area-de-atividades'}/>
            <Grid templateColumns='repeat(12, 1fr)' height='100%' mt={20}>
              <GridItem colSpan={{ sm: 12, lg: 9 }}>
                <Stack spacing={10} p={10}>
                  <HStack
                    backgroundColor='#fff'
                    borderRadius='12px'
                    padding='8px'
                    border='1px solid #F3F5FF'
                    boxShadow='0px 4px 16px 0px rgba(0, 0, 0, 0.04)'
                    height='572px'
                    alignItems='start'
                  >
                    <Stack
                      backgroundColor='#F3F5FF'
                      justifyContent='space-between'
                      borderRadius='8px'
                      height='100%'
                      padding='16px'
                    >
                      <Stack>
                        {!isSelectionMode &&
                          <Button variant='vertical-primary' onClick={() => setIsSelectionMode(true)}>
                            <TouchIcon/>
                            <Text>Selecionar<br/> texto</Text>
                          </Button>}
                        {isSelectionMode && <Button variant='vertical-outline' onClick={() => setIsSelectionMode(false)}
                                                    isDisabled={comments.length === 0}>
                          <CommentIcon/>
                          <Text>Visualizar<br/> comentários</Text>
                        </Button>}
                      </Stack>

                      <Stack>
                        <Button variant='vertical-outline'>
                          <TrashIcon/>
                          <Text>Excluir</Text>
                        </Button>
                        <Button variant='vertical-outline'>
                          <ReplayIcon/>
                          <Text>Devolver</Text>
                        </Button>

                        <Button variant='vertical-primary' onClick={finishReviewOnOpen}>
                          <CheckIcon/>
                          <Text>Finalizar</Text>
                        </Button>
                      </Stack>
                    </Stack>

                    <Stack padding={3} width='100%'>
                      <Box maxHeight='520px' overflowY='auto'>
                        <Popover
                          isOpen={isOpen}
                          onClose={onClosePopover}
                          closeOnBlur={true}
                          initialFocusRef={textAreaRef}
                        >
                          <Portal>
                            <PopoverContent style={{ left: cursorPosition[0], top: cursorPosition[1] }}
                                            display={isOpen ? 'block' : 'none'}>
                              <PopoverArrow/>
                              <PopoverCloseButton/>
                              <PopoverBody>
                                <Stack spacing={3}>
                                  <Heading fontSize='16px' color='#6B6B79'>Competência</Heading>
                                  <HStack>
                                    <Center p={2} borderRadius={'3px'}
                                            backgroundColor={gradeColors[selectedCompetency.grade]}>
                                      <Text size='md' fontWeight={'700'} color='#fff'>{selectedCompetency.name}</Text>
                                    </Center>
                                    <Text size='xs' color='#6B6B79'>Descrição da competência</Text>
                                  </HStack>
                                  <Heading fontSize='16px' color='#6B6B79'>Comentário</Heading>
                                  <Textarea ref={textAreaRef}
                                            value={selectedCommentary ? selectedCommentary.comment : newCommentary}
                                            onChange={(e) => setNewCommentary(e.target.value)}
                                            readOnly={!!selectedCommentary}></Textarea>
                                  {
                                    selectedCommentary
                                      ? <Button variant='primary' backgroundColor='red' onClick={deleteCommentary}
                                                isLoading={actionLoading}>Excluir</Button>
                                      : <Button variant='primary' onClick={saveCommentary}
                                                isLoading={actionLoading}>Salvar</Button>
                                  }
                                </Stack>
                              </PopoverBody>
                            </PopoverContent>
                          </Portal>
                        </Popover>
                        {
                          isEssayImage
                            ? <Center width='100%' height='520px' paddingTop='500px'>
                              <CanvasDrawContainer
                                width={730}
                                height={980}
                                comments={comments}
                                openPopover={openPopover}
                                isOpen={isOpen}
                                imageSelectionData={imageSelectionData}
                                setImageSelectionData={setImageSelectionData}
                              />
                            </Center>

                            : essay && comments && <EssayCorrectionArea
                            allowedHighlight={allowedHighlight}
                            essay={essay}
                            essayRef={essayRef}
                            isOpen={isOpen}
                            isSelectionMode={isSelectionMode}
                            onClickUp={onClickUp}
                            openPopover={openPopover}
                            selectedText={selectedText}
                            setSelectedCommentary={setSelectedCommentary}
                            comments={comments}
                          />
                        }
                      </Box>
                    </Stack>
                  </HStack>
                  <Stack spacing={6}>
                    <Heading size={'md'}>Comentários gerais</Heading>
                    <Box backgroundColor='white' borderRadius='12px' border='1px solid #E7E6E6'>
                      <VoiceRecorder onRecordingComplete={onRecordingComplete}/>
                    </Box>

                    <Textarea
                      backgroundColor='white'
                      borderColor='#E7E6E6'
                      placeholder='Escreva seu comentário...'
                    ></Textarea>
                    {/* {showAudioPlayer && audioUrl && <AudioPlayer src={audioUrl} duration={audioDuration} />} */}
                  </Stack>
                </Stack>
              </GridItem>

              <GridItem colSpan={{ sm: 12, lg: 3 }}>
                <Stack backgroundColor={'#fff'} height='100%' spacing={5} p={10}>
                  <Heading size='xs'>Status da redação</Heading>
                  <Center
                    padding='8px'
                    borderRadius='8px'
                    border='1px solid #02B680'
                    backgroundColor='rgba(2, 182, 128, 0.15)'
                  >
                    <Text fontSize='12px'>{ESSAY_STATUS[essay.status]}</Text>
                  </Center>

                  <Heading size='xs'>Aluno</Heading>
                  <HStack spacing={4}>
                    <Avatar name='Samuel Alencar'/>
                    <Stack>
                      <Text size='xs'>Samuel Alencar</Text>
                      <Box
                        padding='8px'
                        borderRadius='8px'
                        background='linear-gradient(28deg, rgba(2, 182, 128, 0.16) 0%, rgba(33, 253, 187, 0.06) 57.13%, rgba(33, 253, 187, 0.02) 100%)'
                      >
                        <Text fontSize='xs'>Enem</Text>
                      </Box>
                    </Stack>
                  </HStack>

                  <HStack spacing={16}>
                    <Stack>
                      <Heading size='xs'>Atividade</Heading>
                      <Text size='xs' color='#828282'>{essay.activity.name}</Text>
                    </Stack>

                    <Stack>
                      <Heading size='xs'>Data de entrega</Heading>
                      <Text size='xs' color='#828282'>{format(new Date(essay.deadline), 'dd/MM/yyyy')}</Text>
                    </Stack>
                  </HStack>

                  <Stack>
                    <Heading size='xs'>Tema</Heading>
                    <Text size='xs' color='#828282'>Tema teste 01</Text>
                  </Stack>

                  <Divider color='#E3E3E3'/>

                  <Heading size={'md'}>Avaliação das competências</Heading>

                  <Stack
                    padding='16px'
                    borderRadius='8px'
                    border='1px solid #F9FAFF'
                    backgroundColor='#F9FAFF'
                    spacing={6}
                  >
                    <Text fontSize='14px' color='brand.100'>Competência {selectedCompetency.name}</Text>
                    <HStack width='100%' justifyContent='space-between'>
                      {
                        gradeOptions.map((gradeOption) => (
                          gradeOption && <Center
                            key={gradeOption}
                            backgroundColor={selectedCompetency.grade === gradeOption ? gradeColors[selectedCompetency.grade] : '#EDF0FF'}
                            boxShadow='0px 0px 10px 0px rgba(107, 107, 121, 0.24)'
                            borderRadius='12px'
                            padding='8px 12px'
                            cursor='pointer'
                            onClick={() => setNewGrade(gradeOption)}
                          >
                            <Text fontSize='20px' fontWeight='600'
                                  color={selectedCompetency.grade === gradeOption ? '#fff' : '#6D6D6D'}>{gradeOption}</Text>
                          </Center>
                        ))
                      }
                    </HStack>
                    <Button isDisabled={!selectedCompetency.grade} variant='outline' rightIcon={<DocumentErrorIcon/>}
                            onClick={() => setNewGrade(0)}>
                      Zerar competência
                    </Button>
                  </Stack>

                  <HStack width='100%' justifyContent='space-between'>
                    <Button
                      boxShadow='0px 0px 10px 0px rgba(107, 107, 121, 0.24)'
                      borderRadius='8px'
                      onClick={decrementSelectedCompetency}
                    >{'<'}</Button>
                    <HStack>
                      {
                        competencies.map((competency, competencyIndex) => (
                          <Stack key={competency.id} textAlign='center'>
                            <Box width='32px' height='8px' borderRadius='8px'
                                 backgroundColor={selectedCompetencyIndex === competencyIndex ? gradeColors[selectedCompetency.grade] : '#EDEDF1'}></Box>
                            <Text fontSize='14px'>{competency.name}</Text>
                          </Stack>
                        ))
                      }
                    </HStack>
                    <Button
                      boxShadow='0px 0px 10px 0px rgba(107, 107, 121, 0.24)'
                      borderRadius='8px'
                      onClick={incrementSelectedCompetency}
                    >{'>'}</Button>
                  </HStack>

                  <HStack
                    boxShadow='0px 0px 10px 0px rgba(107, 107, 121, 0.24)'
                    borderRadius='8px'
                    backgroundColor='#F9FAFF'
                    padding='16px'
                    my={7}
                    justifyContent='space-between'
                  >
                    <Heading size='sm'>Nota final</Heading>
                    <Input borderColor='gray.300' width='100px' readOnly value={totalGrade}></Input>
                  </HStack>
                </Stack>
              </GridItem>

            </Grid>
          </Stack>
          <Modal
            title={'Finalizar revisão'}
            body={'Nenhuma alteração poderá ser feita após finalizar a correção. Deseja continuar?'}
            isOpen={finishReviewIsOpen}
            onClose={finishReviewOnClose}
            submitLabel={'Finalizar correção'}
            isLoading={actionLoading}
            onSubmit={finishReview}
          />
        </>
      }
    </Page>
  )
}

export default EssayCorrection
