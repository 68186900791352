import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './RecuperarSenhaConfirmacao.css'
import HeaderCadastro from '../../components/HeaderCadastro'
import IlustracaoLogin from '../../components/IlustracaoLogin'
import { changePasswordApi } from '../../apis/LoginApi'


function RecuperarSenhaConfirmacao() {
  const [newPassword, setSenha] = useState('')
  const [newPasswordError, setSenhaError] = useState(false)
  const [confirmPassword, setSenhaConfirmacao] = useState('')
  const [confirmPasswordError, setSenhaConfirmacaoError] = useState(false)
  const navigate = useNavigate();
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password, confirmPassword, newPassword } = document.forms[0];
    if (newPassword && newPassword === confirmPassword) {
      const response = await changePasswordApi({
        email: email, 
        password: password,
        newPassword: newPassword,
        confirmPassword : confirmPassword
      });
      switch (response.status) {
        case 200:
        case 201:
        case 204:
          logoff();
          navigate('/login');
          break;
        default:
          // Coloca textinho fofinho na tela
          break;
      }
    } else {
      setSenhaError(password.length <= 8)
      setSenhaConfirmacaoError(confirmPassword.length < 8)
      setSenhaError(!(password === confirmPassword))
    }
  }

  const handleSenhaInputChange = (event) => {
    setSenha(event.target.value)
    setSenhaError(false)
  }

  const handleSenhaConfirmacaoInputChange = (event) => {
    setSenhaConfirmacao(event.target.value)
    setSenhaConfirmacaoError(false)
  }

  return (
    <div className="container-center-horizontal-recuperar-senha-confirmacao">
      <div className="cadastro-step01-recuperar-senha-confirmacao screen">
        <div className="overlap-group3-recuperar-senha-confirmacao">
          <HeaderCadastro />
        </div>
        <div className={'login-columns'}>
          <IlustracaoLogin />

          <form className={'login-body'} onSubmit={handleSubmit}>
            <div className="title-recuperar-senha-confirmacao">
              <h1 className="title-1-recuperar-senha-confirmacao valign-text-bottom">
                Atualizar senha
              </h1>
              <p className="entre-e-comece-a-agi-recuperar-senha-confirmacao publicsans-normal-nevada-24px">
                Insira uma nova senha com pelo menos 8 caracteres.
              </p>
            </div>

            <div className="frame-2621-recuperar-senha-confirmacao">
              <div className="frame-26-recuperar-senha-confirmacao">
                <div className={`e-mail-recuperar-senha-confirmacao publicsans-bold-nevada-16px`}>
                  E-Mail
                </div>
                <div className="text-imput-recuperar-senha-confirmacao">
                  <div className="email-box-login">
                    <div className="email-inner-login">
                      <img className="ic-email-login" src={icEmail} alt="ic-email" />
                      <input
                        className="text-login publicsans-normal-black-14px"
                        placeholder={'nome@dominio.com'}
                        type={'text'}
                        name="email"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-2621-recuperar-senha-confirmacao">
              <div className="frame-26-recuperar-senha-confirmacao">
                <div className={`e-mail-recuperar-senha-confirmacao publicsans-bold-nevada-16px`}>
                  Senha atual
                </div>
                <div className="text-imput-recuperar-senha-confirmacao">
                  <div className="email-box-recuperar-senha-confirmacao">
                    <div className="email-inner-recuperar-senha-confirmacao">
                      <img className="ic-email-recuperar-senha-confirmacao"
                        src={'/img/ic-senha.svg'}
                        alt="ic-email" />
                      <input
                        className={'text-recuperar-senha-confirmacao publicsans-normal-shady-lady-14px'}
                        placeholder={'Senha atual'}
                        type={'text'}
                        name="password" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-2621-recuperar-senha-confirmacao">
              <div className="frame-26-recuperar-senha-confirmacao">
                <div className={`${senhaConfirmacaoError || senhaError ? 'error-recuperacao-senha-confirmacao' : ''} e-mail-recuperar-senha-confirmacao publicsans-bold-nevada-16px`}>
                  Nova senha
                </div>
                <div className="text-imput-recuperar-senha-confirmacao">
                  <div className="email-box-recuperar-senha-confirmacao">
                    <div className="email-inner-recuperar-senha-confirmacao">
                      <img className="ic-email-recuperar-senha-confirmacao"
                        src={'/img/ic-senha.svg'}
                        alt="ic-email" />
                      <input
                        className={'text-recuperar-senha-confirmacao publicsans-normal-shady-lady-14px'}
                        type={'text'}
                        name="newPassword"
                        onChange={handleSenhaInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-2621-recuperar-senha-confirmacao">
              <div className="frame-26-recuperar-senha-confirmacao">
                <div className="text-imput-recuperar-senha-confirmacao">
                  <div className="email-box-recuperar-senha-confirmacao">
                    <div className="email-inner-recuperar-senha-confirmacao">
                      <img className="ic-email-recuperar-senha-confirmacao"
                        src={'/img/ic-senha.svg'}
                        alt="ic-email" />
                      <input
                        className={'text-recuperar-senha-confirmacao publicsans-normal-shady-lady-14px'}
                        placeholder={'Insira sua senha novamente'}
                        type={'text'}
                        name="confirmPassword"
                        onChange={handleSenhaConfirmacaoInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="buttons-container-recuperar-senha-confirmacao">
              <div className="mainbutton-1-recuperar-senha-confirmacao">
                <div className="content-1-recuperar-senha-confirmacao">
                  <input className='main-button-1-login publicsans-bold-white-14px'
                    type={'submit'} value={'Salvar'} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default RecuperarSenhaConfirmacao
