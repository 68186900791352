import React from 'react'
import './TabelaTitle22.css'

function TabelaTitle22(props) {
  const { children } = props

  return (
    <div className="tabela-title-7">
      <div className="detalhes publicsans-normal-gray-14px">{children}</div>
    </div>
  )
}

export default TabelaTitle22
