import { Icon } from '@chakra-ui/react'

export const DocumentErrorIcon = () => {
  return (
        <Icon xmlns="http://www.w3.org/2000/svg" width="none" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M14.737 3.22168H7.979C5.92 3.22168 4.25 4.89068 4.25 6.95068V17.7997C4.25 19.9757 6.014 21.5747 8.19 21.5747H16.073C18.133 21.5747 19.802 19.8597 19.802 17.7997V8.49768L14.737 3.22168Z" stroke="#0A41D6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.4739 3.20996V6.11896C14.4739 7.53896 15.6229 8.69096 17.0419 8.69396C18.3589 8.69696 19.7059 8.69796 19.7969 8.69196M13.5764 15.1074L10.1104 11.6414M10.1106 15.1074L13.5766 11.6414" stroke="#0A41D6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </Icon>
  )
}
