import { httpRequestHelper } from './ApiMiddleware'
import { BACKEND_API } from './Constants'
import axios from 'axios'

export const DoLogin = async (credentials, setLoggedUserData) => {
  const response = await httpRequestHelper.post(BACKEND_API + '/v1/account/login', {
    email: credentials.userName,
    password: credentials.password,
    language: 'pt-br'
  })

  if(!response.data.message.userToken){
    return response
  }

  const claims = response.data.message.userToken.claims

  localStorage.setItem('user_token', response.data.message.accessToken)

  claims.forEach((e) => {
    if (e.type === 'email') {
      localStorage.setItem('user_email', e.value)
      setLoggedUserData({ user_email: e.value })
    } else if (e.type === 'role') {
      localStorage.setItem('user_role', e.value)
      setLoggedUserData({ user_role: e.value })
    } else if (e.type === 'language') {
      localStorage.setItem('user_language', e.value)
      setLoggedUserData({ user_language: e.value })
    } else if (e.type === 'name') {
      localStorage.setItem('user_name', e.value)
      setLoggedUserData({ user_name: e.value })
    } else if (e.type === 'sub') {
      localStorage.setItem('user_id', e.value)
      setLoggedUserData({ user_id: e.value })
    } else if (e.type === 'iat') {
      localStorage.setItem('request_date', e.value)
    }
  })

  return response;
}

export const validateToken = async () => {
  const token = localStorage.getItem('user_token')

  if (!token) {
    return false
  }

  const currentDate = new Date()
  const requestDate = new Date(localStorage.getItem('request_date') * 1000)

  const result = await httpRequestHelper.get(BACKEND_API + '/v1/account/validate-token?token=' + token)

  return result.data.success && currentDate < requestDate.setDate(requestDate.getDate() + 1)
}

export const logoff = () => {
  localStorage.removeItem('user_email')
  localStorage.removeItem('user_role')
  localStorage.removeItem('user_language')
  localStorage.removeItem('user_name')
  localStorage.removeItem('user_id')
  localStorage.removeItem('request_date')
  localStorage.removeItem('user_token')
}

export const changePasswordApi = async (viewModel) => {
  const response = await httpRequestHelper.post(BACKEND_API + '/v1/account/change-password', {
    email: viewModel.email,
    password: viewModel.oldPassword,
    newPassword: viewModel.newPassword,
    confirmPassword: viewModel.newPassword
  })

  return response;
}

export const requestPasswordChangeApi = async (email) => {
  const response = await httpRequestHelper.post(BACKEND_API + '/v1/account/forgot-password', {
    email: email,
    language: 'pt-br'
  })

  return response
}
