import { Link } from 'react-router-dom'
import './HeaderCadastro.css'

function HeaderCadastro() {
  return (
        <div className="header-cadastro">
            <LogoPositivaHorizontal />
            <Button />
        </div>
  )
}

export default HeaderCadastro

function LogoPositivaHorizontal() {
  return (
        <div className="logo-positiva-horizontal-header-cadastro">
            <img className="group-1-header-cadastro" src={'/img/logo-positiva-horizontal.svg'} alt="Group"/>
        </div>
  )
}

function Button() {
  return (
        <Link to={'/cadastro'}>
            <div className="button-header-cadastro">
                <Mainbutton />
            </div>
        </Link>
  )
}

function Mainbutton() {
  return (
        <div className="mainbutton-header-cadastro">
            <div className="content-header-cadastro">
                <div className="main-button-header-cadastro publicsans-bold-absolute-zero-14px">
                    {'Cadastre-se'}
                </div>
            </div>
        </div>
  )
}
