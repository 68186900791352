import React, { useEffect, useState } from 'react'
import Headbar from '../../components/Headbar'
import Menu from '../../components/Menu'
import './AreaDeTurmas.css'
import { Link } from 'react-router-dom'
import SharedTablePagination from '../../components/SharedTablePagination'
import { listAllGroups } from '../../apis/schoolApi/groupApi'
import StatusAlunos from '../../components/StatusAlunos'
import { shiftMapping } from '../../utils/shiftENUM'
import { listAllStudentsFromSchoolById } from '../../apis/studentApi/studentApi'
import Page from '../Page'

function AreaDeTurmas() {
  const [geradorTurmasAtivas, setGeradorTurmasAtivas] = useState([])
  const [geradorStatusAluno, setGeradorStatusAluno] = useState([])
  const [turmas, setTurmas] = useState([])
  const [turmasData, setTurmasData] = useState([])
  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchInitialData = () => {
    return new Promise((resolve) => {
      Promise.all([listAllGroups(), listAllStudentsFromSchoolById()])
        .then(([groupsResult, studentsResult]) => {
          resolve({ groupsResult, studentsResult })
        })
    })
  }

  useEffect(() => {
    fetchInitialData().then(({ groupsResult, studentsResult }) => {
      setTurmasData(groupsResult)
      setStudents(studentsResult)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    handleRender()
  }, [loading])

  useEffect(() => {
    setTurmas(turmasData) // Atualiza turmas quando turmasData muda
  }, [turmasData])

  function handleRender() {
    gerarTurmasAtivas()
    gerarStatusAlunos()
  }

  function geradorTurma(turma) {
    return (
      <div className="atividade-txt-area-de-turmas">
        <div className="frame-2580-area-de-turmas">
          <div
            className="x1-ano-area-de-turmas valign-text-middle-area-de-turmas publicsans-normal-black-12px">{turma}</div>
        </div>
      </div>)
  }

  function geradorTurno(turno) {
    return (
      <div className="atividade-txt-2-area-de-turmas">
        <div className="frame-2580-2-area-de-turmas">
          <div className="noite-area-de-turmas valign-text-middle-area-de-turmas publicsans-bold-absolute-zero-12px">
            {shiftMapping[turno]}
          </div>
        </div>
      </div>
    )
  }

  function geradorStatus() {
    return (
      <div className="atividade-txt-2-area-de-atividades">
        <div className="frame-2581-1-area-de-atividades">
          <div
            className="ativa-area-de-atividades valign-text-middle-area-de-atividades publicsans-normal-black-12px">Ativa
          </div>
        </div>
      </div>
    )
  }

  function geradorSetas(itemData) {
    return (
      <Link to={`/detalhamento-turma/${itemData}`}>
        <div className="atividade-txt-4-area-de-turmas">
          <img className="ic-arrowright-area-de-turmas" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright"/>
        </div>
      </Link>
    )
  }

  function geradorQuantAlunos() {
    return students.length
  }

  function gerarStatusAlunos() {
    const alunosAtivos = geradorQuantAlunos(true)
    const result = <StatusAlunos alunosAtivos={alunosAtivos}></StatusAlunos>
    setGeradorStatusAluno(result)
  }

  function gerarTurmasAtivas() {
    const result = (
      <Frame2426 folder2851794="/img/folder-2851794@2x.png" aguardandoCorreo="Turmas ativas"
                 number={turmasData.length}/>
    )
    setGeradorTurmasAtivas(result)
  }

  function handleSearchInputChange(e) {
    const inputText = e.target.value

    setTurmas(
      turmasData.filter((item) => {
        const nameMatch = item.name.toLowerCase().includes(inputText.toLowerCase())

        const shiftMatch = shiftMapping[item.shift].toLowerCase().includes(inputText.toLowerCase())

        return nameMatch || shiftMatch
      })
    )
  }

  return (
    <div className="container-center-horizontal-area-de-turmas">
      <Page authPage={true} allowedUserType={'teacher independentteacher monitor'} />
      <div className="turmas-1-area-de-turmas screen-area-de-turmas">
        <Menu/>
        <Headbar/>
        <div className={'area-de-turmas-body'}>
          <div className="search-filter-1-area-de-turmas">
            <div className="title-cta-area-de-turmas">
              <h1 className="title-area-de-turmas publicsans-normal-absolute-zero-40px">Turmas</h1>
              <Link to={'/criar-turma'}>
                <div className="cta-criar-atividade-area-de-turmas">
                  <div className="button-area-de-turmas">
                    <div className="mainbutton-area-de-turmas">
                      <div className="frame-29-area-de-turmas">
                        <img className="ic-add-area-de-turmas" src="/img/ic-add@2x.png" alt="ic-add"/>
                        <div
                          className="criar-turma-area-de-turmas valign-text-middle-area-de-turmas inter-bold-zircon-14px">
                          Criar turma
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="search-filter-area-de-turmas">
            <div className="search-filtro-area-de-turmas">
              <div className="search-area-de-turmas">
                <img className="search-2851823-area-de-turmas" src="/img/search-2851823@2x.png" alt="search-2851823"/>
                <input
                  className="text-area-de-turmas"
                  placeholder={'Buscar turmas...'}
                  type={'search'}
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
          </div>
          <div className="status-area-de-turmas">
            {geradorTurmasAtivas}
            {geradorStatusAluno}
          </div>
          <SharedTablePagination
            data={turmas}
            itemsPerPage={5}
            className={'area-de-turmas-table-pagination'}
            columns={[
              {
                title: 'Turma',
                render: (itemData) => <div>{geradorTurma(itemData.name)}</div>
              },
              {
                title: 'Turno',
                render: (itemData) => <div>{geradorTurno(itemData.shift)}</div>
              },
              {
                title: 'Status',
                render: (itemData) => <div>{geradorStatus(itemData.active)}</div>
              },
              {
                title: 'Detalhes',
                render: (itemData) => <div>{geradorSetas(itemData.id)}</div>
              }
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default AreaDeTurmas

function Frame2426(props) {
  const { folder2851794, aguardandoCorreo, number } = props

  return (
    <div className="frame-2426-area-de-turmas">
      <img className="folder-2851794-area-de-turmas" src={folder2851794} alt="folder-2851794"/>
      <div className="turmas-ativas-area-de-turmas publicsans-bold-nevada-14px">{aguardandoCorreo}</div>
      <div className="number-1-area-de-turmas publicsans-extra-bold-absolute-zero-32px">{number}</div>
    </div>
  )
}
