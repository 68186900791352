import React, { useEffect, useState } from 'react'
import Frame2434 from '../Frame2434'
import './AtividadeCard.css'
import Button22 from '../Button22'
import Data from '../Data'

function AtividadeCard(props) {
  const { itemData } = props

  const currentDate = new Date()
  const deadLineDate = new Date(itemData.deadline)
  const startDate = new Date(itemData.validFrom)
  const startDateText = currentDate >= startDate ? 'Iniciou em:' : 'Inicia em:'

  const [controleRedacao, setcontroleRedacao] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      handleRender()
    }

    fetchData()
  }, [])

  function handleRender() {
    controleRedacoes()
  }

  function calculoProgressBar(redacoes, redacoesTotais) {
    if (redacoes === 0 && redacoesTotais === 0) {
      return 0
    }
    const result = (redacoes / redacoesTotais) * 100
    return result
  }

  function controleRedacoes() {
    const amountDraftLength = itemData.amountDraft.length
    const amountSubmittedLength = itemData.amountSubmitted.length
    const amountReviewedLength = itemData.amountReviewed.length
    const lengthTotal = amountDraftLength + amountSubmittedLength + amountReviewedLength
    const progressBarBlue = calculoProgressBar(amountDraftLength, lengthTotal)
    const progressBarGreen = calculoProgressBar(amountReviewedLength, lengthTotal)

    const result = (
      <Frame2434
        progressPercentageBlue={progressBarBlue}
        progressPercentageGreen={progressBarGreen}
        redacoesParaCorrigir={amountDraftLength}
        redacoesCorrigidas={amountReviewedLength}
        redacoesTotal={lengthTotal}
      />
    )

    setcontroleRedacao(result)
  }

  return (
    <div className="turma-02">
      <div className="title-link">
        {/* <div className="atomsphotopicalbum"></div> */}
        <div className="frame-2587">
          <div className="title-activity-card publicsans-bold-absolute-zero-16px">{itemData.subject}</div>
          <div className="tema">
            <div className="place publicsans-bold-nevada-10px">{'TEMA'}</div>
            <p className="subtext-card-style publicsans-normal-nevada-14px">
              {itemData.theme.name}
            </p>
          </div>
        </div>
      </div>
      <div className="content-9">
        {controleRedacao}
        <img
          className="line-97-1"
          src={'/img/line-97-1@2x.png'}
          alt="Line 97"
        />
        <div className="frame-2588">
          <div className={'criao-atividade-1-1'}>
            <div className="criada-em publicsans-normal-nevada-16px">{startDateText}</div>
            <Data editSquare2851790={'/img/edit-square-2851790@2x.png'} date={startDate.toLocaleDateString()}/>
          </div>
          <div className="criao-atividade">
            <div className="entrega-final publicsans-normal-nevada-16px">Entrega final:</div>
            <Data editSquare2851790={'/img/edit-square-2851790@2x.png'} date={deadLineDate.toLocaleDateString()}/>
          </div>
        </div>
        <div className="frame-258-1">
          <div className="frame-2584-1">
            <Button22
              mainbutton2Props={{
                className: '',
                contentProps: { children: 'Ver detalhes' }
              }}
              linkTo={'/detalhamento-atividade/' + itemData.id}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AtividadeCard
