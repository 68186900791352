import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Button, FormControl, FormLabel, Grid, GridItem, Input, Select, Stack, Textarea } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { getAllAssistantTypesApi } from '../../apis/schoolApi/schoolApi'
import { createNewAssistantTypeApi, createNewPromptApi } from '../../apis/ownerApi/ownerApi'

export const PromptModalBody = ({ prompt, onClose }) => {
  const [title, setTitle] = useState('')
  const [selectedModel, setSelectedModel] = useState('gpt-3.5-turbo-0301')
  const [mainContent, setMainContent] = useState('')
  const [systemContent, setSystemContent] = useState('')
  const [assistantTypeOptions, setAssistantTypeOptions] = useState([])
  const [selectedAssistantType, setSelectedAssistantType] = useState('')
  const [newAssistantType, setNewAssistantType] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const modelOptions = [
    'gpt-3.5-turbo-0301',
    'gpt-4-0314',
    'gpt-4-32k-0314'
  ]

  const variables = [
    {
      description: 'Descrição da atividade',
      key: 'activityDescription'
    },
    {
      description: 'Nome da atividade',
      key: 'activityName'
    },
    {
      description: 'Nome do tema',
      key: 'themeName'
    }
  ]

  async function fetchAllAssistantTypes() {
    const result = await getAllAssistantTypesApi()
    setAssistantTypeOptions(result)
  }

  useEffect(() => {
    fetchAllAssistantTypes()
  }, [])

  const enableCreationButton = useMemo(() => !!title && !!selectedModel && !!systemContent && !!mainContent && !!selectedAssistantType, [
    title, selectedModel, systemContent, mainContent, selectedAssistantType
  ])

  async function createNewAssistantType() {
    await createNewAssistantTypeApi(newAssistantType)
    setNewAssistantType('')
    fetchAllAssistantTypes()
  }

  async function createNewPrompt() {
    try {
      setIsLoading(true)
      const data = {
        assistantTypeId: selectedAssistantType,
        systemContent,
        content: mainContent,
        model: selectedModel,
        title,
        isDefault: true
      }

      await createNewPromptApi(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
        <>
            <Grid templateColumns='repeat(12, 1fr)' gap={5}>
                <GridItem colSpan={{ sm: 12, lg: 8 }}>
                    <Stack spacing={4}>
                        <Grid templateColumns='repeat(12, 1fr)' gap={5}>
                            <GridItem colSpan={{ sm: 6, lg: 8 }}>
                                <FormControl>
                                    <FormLabel>Titulo</FormLabel>
                                    <Input value={title} onChange={e => setTitle(e.target.value)} />
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ sm: 6, lg: 4 }}>
                                <FormControl>
                                    <FormLabel>Modelo</FormLabel>
                                    <Select onChange={e => setSelectedModel(e.target.value)}>
                                        {
                                            modelOptions.map(model => (
                                                <option key={model} value={selectedModel}>{model}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </GridItem>
                        </Grid>

                        <FormControl>
                            <FormLabel>Contexto</FormLabel>
                            <Textarea value={systemContent} onChange={e => setSystemContent(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Prompt Principal</FormLabel>
                            <Textarea minHeight='300px' value={mainContent} onChange={e => setMainContent(e.target.value)} />
                        </FormControl>
                    </Stack>
                </GridItem>
                <GridItem colSpan={{ sm: 12, lg: 4 }}>
                    <Stack spacing={4}>
                        <FormControl>
                            <FormLabel>Tipo de assistente</FormLabel>
                            <Select value={selectedAssistantType} onChange={e => setSelectedAssistantType(e.target.value)}>
                                <option value=''>Selecione</option>
                                {
                                    assistantTypeOptions.map((at) => (
                                        <option key={at.id} value={at.id}>{at.name}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <Accordion allowToggle defaultIndex={[0]}>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left'>
                                            Criar novo tipo de assistente
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel>
                                    <Stack>
                                        <Input value={newAssistantType} placeholder='Insira o nome' onChange={e => setNewAssistantType(e.target.value)} />
                                        <Button variant='primary' isDisabled={!newAssistantType} onClick={createNewAssistantType}>Salvar e selecionar</Button>
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>

                        <FormControl>
                            <FormLabel>Inserir Variáveis</FormLabel>
                            <Grid templateColumns='repeat(12, 1fr)' columnGap={8} rowGap={2}>
                                {
                                    variables.map(variable => (
                                        <GridItem key={variable.key} colSpan={6}>
                                            <Badge cursor='pointer' fontSize='14px' variant='outline' colorScheme='purple' onClick={() => setMainContent(state => state + `{{${variable.key}}}`)}>{variable.description}</Badge>
                                        </GridItem>
                                    ))
                                }
                            </Grid>
                        </FormControl>
                    </Stack>
                </GridItem>
            </Grid>
            {
                prompt
                  ? <Button float='right' variant='primary'>Salvar alterações</Button>
                  : <Button float='right' variant='primary' isDisabled={!enableCreationButton} isLoading={isLoading} onClick={createNewPrompt}>Criar prompt</Button>
            }
        </>
  )
}
