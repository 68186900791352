import React from 'react'
import Headbar from '../Headbar'
import BackStepButton from '../BackStepButton'
import ForwardButton from '../ForwardButton'
import './CriarTemaStep2.css'

function CriarTemaStep2(props) {
  const { handleReturn, handlingNextStep } = props

  return (
        <div className="criar-atividade-step2 screen body-distance-from-header">
            <div className="group-4-1-step-2">
                <div className="overlap-group2-step3">
                    <h1 className="voc-criou-uma-atividade-nova-step3 valign-text-bottom-step3 publicsans-normal-absolute-zero-32px">
            <span>
              <span className="publicsans-normal-absolute-zero-32px">Você criou um</span>
              <span className="publicsans-extra-bold-absolute-zero-32px">
                {' '}
                  <br/>
                tema novo
              </span>
            </span>
                    </h1>
                    <div className="group-step3">
                        <div className="overlap-group1-step3">
                            <div className="overlap-group-step3">
                                <img className="vector-2-step3" src="/img/vector-16.svg" alt="Vector"/>
                                <img className="vector-3-step3" src="/img/vector-17.svg" alt="Vector"/>
                                <img className="group-1-step3" src="/img/group@2x.png" alt="Group"/>
                                <img className="group-2-step3" src="/img/group-1@2x.png" alt="Group"/>
                                <img className="vector-4-step3" src="/img/vector-19.svg" alt="Vector"/>
                            </div>
                            <img className="vector-5-step3" src="/img/vector-18.svg" alt="Vector"/>
                        </div>
                    </div>
                    <img className="frame-2482-step3" src="/img/frame-2482.svg" alt="Frame 2482"/>
                </div>
                <div className="buttons-step3">
                    <ForwardButton
                        forwardTo={handlingNextStep}
                        className={'button-10'}
                        text={'Ver Tema'}
                        buttonClassName={''}
                    />
                    <BackStepButton handleReturn={handleReturn} text={'Voltar'}/>
                </div>
            </div>
            <Headbar returnTo={'/area-de-temas'} />
        </div>
  )
}

export default CriarTemaStep2
