import React from 'react'
import './MenuRedacoes.css'
import { useNavigate } from 'react-router-dom'
import useLoggedUserStore from '../../store/useLoggedUserStore'

function MenuRedacoes() {
  const { loggedUser } = useLoggedUserStore()
  const navigate = useNavigate()

  let menuClass = 'menu-item'
  let menuPicturePath = '/img/redacoes-menu-icon.svg'
  let menuPictureText = 'publicsans-bold-nevada-16px'

  if (document.location.toString().includes('/area-de-atividades')) {
    menuClass += ' menu-foco'
    menuPicturePath = '/img/redacoes-menu-icon-focus.svg'
    menuPictureText = 'publicsans-bold-absolute-zero-16px'
  }

  if (loggedUser.user_role === 'adm') {
    return ('')
  }

  return (
    <div className={menuClass} onClick={() => navigate('/area-de-atividades')}>
      <img className="menu-redacoes-picture" src={menuPicturePath} alt="edit-2851791"/>
      <div className={menuPictureText}>Atividades</div>
    </div>
  )
}

export default MenuRedacoes
