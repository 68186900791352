export const indepententTeacherColumns = [
  {
    Header: 'Nome',
    accessor: 'name',
    sample: 'Joao'
  },
  {
    Header: 'Email',
    accessor: 'email',
    sample: 'clienteanonimo@gmail.com'
  },
  {
    Header: 'Telefone',
    accessor: 'phoneNumber',
    sample: '98103980'
  },
  {
    Header: 'Max. Escolas',
    accessor: 'maxActiveSchoolsOrGroups',
    sample: '10'
  },
  {
    Header: 'Max. Alunos',
    accessor: 'maxActiveStudents',
    sample: '10'
  },
  {
    Header: 'Bloqueado',
    accessor: 'blocked',
    sample: 'Sim'
  },
  {
    Header: 'Detalhes',
    accessor: 'details',
    sample: 'asd'
  },
  {
    Header: 'Action',
    accessor: 'action'
  }
]
