import React from 'react'
import './ForwardButton.css'
import Content from '../Content'

function ForwardButton(props) {
  const { className, text, buttonClassName, forwardTo } = props

  return (
        <div className={`button-2-1 ${className || ''}`} onClick={forwardTo}>
            <div className={`mainbutton-2 ${buttonClassName || ''}`}>
                <Content>{text}</Content>
            </div>
        </div>
  )
}

export default ForwardButton
