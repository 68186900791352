// src/walk-object.ts
function isObject(value) {
  return typeof value === "object" && value != null && !Array.isArray(value);
}
function walkObject(target, predicate, options = {}) {
  const { stop, getKey } = options;
  function inner(value, path = []) {
    var _a;
    if (isObject(value) || Array.isArray(value)) {
      const result = {};
      for (const [prop, child] of Object.entries(value)) {
        const key = (_a = getKey == null ? void 0 : getKey(prop)) != null ? _a : prop;
        const childPath = [...path, key];
        if (stop == null ? void 0 : stop(value, childPath)) {
          return predicate(value, path);
        }
        result[key] = inner(child, childPath);
      }
      return result;
    }
    return predicate(value, path);
  }
  return inner(target);
}
function mapObject(obj, fn) {
  if (!isObject(obj))
    return fn(obj);
  return walkObject(obj, (value) => fn(value));
}

export {
  walkObject,
  mapObject
};
