import React, { useEffect, useState } from 'react'
import Headbar from '../../components/Headbar'
import './DetalhamentoTema.css'
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  VStack
} from '@chakra-ui/react'

import { activityStatus } from '../../utils/activityStatus'
import {
  deleteTheme,
  getActivityInformation,
  getThemeApi,
  putEditTheme,
  putEditThemeImage
} from '../../apis/themeApi/themeApi'
import { useNavigate, useParams } from 'react-router-dom'
import SupportMaterialList from '../../components/SupportMaterialList'
import { handleFileChange } from '../../utils/supportMaterialUpload'
import { createSupportMaterialApi, listSupportMaterialApi } from '../../apis/themeApi/supportMaterialApi'
import SharedTablePagination from '../../components/SharedTablePagination'
import AtividadeTxt52 from '../../components/AtividadeTxt52'
import Page from '../Page'

function DetalhamentoTema() {
  const { temaID } = useParams()

  console.log(temaID)

  const [temasData, setTemasData] = useState([])
  const [atividadesData, setAtividadesData] = useState([])
  const [atividades, setAtividades] = useState([])
  const [dataCriacao, setDataCriacao] = useState([])
  const [supportMaterialList, setSupportMaterialList] = useState([])
  const [loading, setLoading] = useState(true)
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedTab, setSelectedTab] = useState('sobre')
  const [filterTextActivity, setFilterTextActivity] = useState('')
  const popoverDisclosure = useDisclosure()
  const modalDisclosure = useDisclosure()
  const navigate = useNavigate()
  // const [activityData, setActivityData] = useState([])

  const fetchInitialData = () => {
    return new Promise((resolve) => {
      Promise.all([getThemeApi(temaID), getActivityInformation(temaID)]).then(([themeResult, activityResult]) => {
        new Promise((resolve) => {
          const result = listSupportMaterialApi(temaID)

          resolve(result)
        }).then((supportMaterialResult) => {
          resolve({ themeResult, activityResult, supportMaterialResult })
        })
      })
    })
  }

  function fetchTheme() {
    Promise.all([getThemeApi(temaID)]).then(([themeResult]) => {
      setTemasData(themeResult)
    })
  }

  useEffect(() => {
    fetchInitialData().then(({ themeResult, activityResult, supportMaterialResult }) => {
      setTemasData(themeResult)
      setAtividadesData(activityResult)
      setSupportMaterialList(supportMaterialResult)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    handleRender()
  }, [temasData])

  function getStatusValue(item) {
    const dataAtual = new Date()
    const dataValidFrom = new Date(item.validFrom)
    const dataDeadline = new Date(item.deadline)

    if (dataAtual < dataValidFrom) {
      return 0
    } else if (dataAtual >= dataValidFrom && dataAtual <= dataDeadline) {
      return 10
    } else if (dataAtual > dataDeadline) {
      return 20
    }

    return 0 // Considerando 0 como um "valor padrão"
  }

  useEffect(() => {
    setAtividades(
      atividadesData.filter((item) => {
        const activityMatch = item.subject.toLowerCase().includes(filterTextActivity.toLowerCase())

        const statusValue = getStatusValue(item)
        const statusMatch = activityStatus[statusValue].toLowerCase().includes(filterTextActivity.toLowerCase())

        return activityMatch || statusMatch
      })
    )
  }, [filterTextActivity, atividadesData])

  function handleRender() {
    geradorDataCriacao()
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function GetModalEditTurma() {
    const maxCharacteres = 180

    const [editTemaData, setEditTemaData] = useState({
      id: temasData.id,
      subject: temasData.subject,
      description: temasData.description,
      base64Image: temasData.base64Image
    })

    const [previewImage, setPreviewImage] = useState(editTemaData.base64Image)

    const [selectedImage, setSelectedImage] = useState(null)

    const handleImageUpload = (e) => {
      const file = e.target.files[0]
      setSelectedImage(file)

      // Atualize a pré-visualização da imagem
      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          setPreviewImage(reader.result)
          setEditTemaData({ ...editTemaData, base64Image: reader.result.split(',')[1] })
        }
        reader.readAsDataURL(file)
      } else {
        setPreviewImage('')
      }
    }

    const onSaveClick = async () => {
      const requestBodyEditTheme = {
        id: editTemaData.id,
        subject: editTemaData.subject,
        description: editTemaData.description
      }
      await putEditTheme(requestBodyEditTheme).then(async () => {
        if (selectedImage) {
          const requestBody = { base64Image: editTemaData.base64Image }

          await putEditThemeImage(temaID, requestBody)
        }
      })

      fetchTheme()

      modalDisclosure.onClose()
    }

    return (
      <Modal
        isOpen={modalDisclosure.isOpen}
        size={'2xl'}
        onClose={modalDisclosure.onClose}
        isCentered={true}
        closeOnOverlayClick={false}
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>
            Editar Tema
          </ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={'publicsans-bold-nevada-16px'}>Imagem do tema</div>
              {previewImage && <img src={previewImage} alt="Imagem do tema" width={200} height={200}/>}
              <Input type="file" onChange={(e) => handleImageUpload(e)}/>
              <div className={'publicsans-bold-nevada-16px'}>Título*</div>
              <Input
                className={'input-default-style'}
                onChange={(e) => setEditTemaData({ ...editTemaData, subject: e.target.value })}
                defaultValue={editTemaData.subject}
              />
              <div className={'publicsans-bold-nevada-16px'}>Descrição</div>
              <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
                <Textarea
                  placeholder=""
                  defaultValue={editTemaData.description}
                  onChange={(e) => setEditTemaData({ ...editTemaData, description: e.target.value })}
                  resize="vertical"
                  w={'100%'}
                  h={'100%'}
                  minHeight={'150px'}
                  borderColor={'#d7e3fb'}
                />
                <Text fontSize="sm" position="absolute" bottom="5px" right="20px" color="#a9a8a8"
                      fontFamily={'Public Sans'}>
                  {editTemaData.description ? editTemaData.description.length : 0}/{maxCharacteres}
                </Text>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button
              backgroundColor={'#f9faff'}
              borderColor={'#648BF7'}
              border={'1px'}
              borderRadius={'8px'}
              color={'#062989'}
              height={'48px'}
              width={'218px'}
              onClick={modalDisclosure.onClose}
            >
              Cancelar
            </Button>
            <Button
              backgroundColor={'#0A41D6'}
              border={'1px'}
              borderRadius={'8px'}
              color={'#FFFFFF'}
              height={'48px'}
              width={'218px'}
              onClick={onSaveClick}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  function geradorDeStatus(atividades) {
    let backgroundColor
    let status = 0
    const dataAtual = new Date()
    const dataValidFrom = new Date(atividades.validFrom)
    const dataDeadline = new Date(atividades.deadline)

    if (dataAtual < dataValidFrom) {
      backgroundColor = 'in-waiting-activity-status'
      status = 0
    } else if (dataAtual >= dataValidFrom && dataAtual <= dataDeadline) {
      backgroundColor = 'in-progress-activity-status'
      status = 10
    } else if (dataAtual > dataDeadline) {
      backgroundColor = 'finished-activity-status'
      status = 20
    }

    return (
      <div className={'atividade-txt-detalhamento-turma publicsans-normal-black-12px'}>
        <div className={backgroundColor}>{activityStatus[status]}</div>
      </div>
    )
  }

  function geradorDataCriacao() {
    const date = new Date(temasData.createdAt)
    const formattedDate = date.toLocaleDateString()
    setDataCriacao(formattedDate)
  }

  function geradorTema(tema) {
    return <AtividadeTxt21 tema={tema}/>
  }

  function geradorSetas() {
    return <AtividadeTxt62/>
  }

  function geradorRedacoesEntregues(amountClosed) {
    let redacoesFeitas = 0
    redacoesFeitas = amountClosed.length
    return <AtividadeTxt52 entregas={redacoesFeitas}/>
  }

  function handleUploadSupportMaterial(payload) {
    new Promise((resolve) => {
      const result = createSupportMaterialApi(payload)

      resolve(result)
    }).then(() => {
      refreshSupportMaterialList()
    })
  }

  function refreshSupportMaterialList() {
    new Promise((resolve) => {
      const result = listSupportMaterialApi(temaID)

      resolve(result)
    }).then((supportMaterialResult) => {
      setSupportMaterialList(supportMaterialResult)
    })
  }

  return (
    <div className="detalhamento-temas screen-detalhamento-tema">
      <Page authPage={true} allowedUserType={'student teacher monitor independentteacher'}>
        <Headbar returnTo={'/area-de-temas'}/>
        <div className={'detalhamento-tema-body'}>
          <div className="tema-detalhamento-tema">
            <Image className="theme-image-style" src={temasData.base64Image}/>
            <div className="content-3-detalhamento-tema">
              <div className="content-item-detalhamento-tema">
                <div className="frame-2594-detalhamento-tema">
                  <VStack alignItems={'flex-start'}>
                    <div className="tema-title-style publicsans-bold-nevada-10px">TEMA</div>
                    <p className="desmatamento-florest-detalhamento-tema publicsans-normal-absolute-zero-24px">
                      {loading ? '' : temasData.subject}
                    </p>
                  </VStack>
                </div>
              </div>
              <Spacer/>
              <div className="content-item-detalhamento-tema">
                <div className="frame-2592-detalhamento-tema">
                  <Entrega criao="CRIAÇÃO" dataCriacao={dataCriacao}/>
                </div>
              </div>
            </div>
            <VStack justifyContent={'space-between'} height={'100%'}>
              <HStack width={'100%'} justifyContent={'end'}>

                <div
                  className="editar-1-detalhamento-tema valign-text-middle-detalhamento-tema publicsans-bold-nevada-14px">
                  Editar
                </div>
                <img
                  className="mode-edit-detalhamento-tema"
                  src="/img/mode-edit@2x.png"
                  alt="Mode edit"
                  cursor={'pointer'}
                  onClick={modalDisclosure.onOpen}
                />

                <GetModalEditTurma/>
              </HStack>

              <HStack paddingRight={'12px'}>

                <div className={'publicsans-bold-nevada-14px'}>
                  {' '}
                  Apagar tema?{' '}
                </div>
                <Popover
                  isOpen={popoverDisclosure.isOpen}
                  onOpen={popoverDisclosure.onOpen}
                  onClose={popoverDisclosure.onClose}
                  placement="auto"
                  closeOnBlur={false}
                >
                  <PopoverTrigger>
                    <Image
                      src={'/img/deleteIcon.svg'}
                      cursor={'pointer'}
                    />
                  </PopoverTrigger>
                  <PopoverContent width={'448px'} borderRadius={'10px'}>
                    <PopoverArrow/>
                    <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'}/>
                    <PopoverHeader>
                      <div className={'text-popover'}>Confirme Remoção do tema</div>
                    </PopoverHeader>
                    <PopoverFooter alignSelf={'center'} marginTop={'50px'} width={'100%'}>
                      <HStack width={'100%'}>
                        <Button
                          backgroundColor={'#f9faff'}
                          borderColor={'#648BF7'}
                          border={'1px'}
                          borderRadius={'8px'}
                          color={'#062989'}
                          height={'48px'}
                          width={'100%'}
                          onClick={popoverDisclosure.onClose}
                        >
                          Cancelar
                        </Button>
                        <Button
                          backgroundColor={'#0A41D6'}
                          color={'white'}
                          width={'100%'}
                          height={'48px'}
                          onClick={async () => {
                            await deleteTheme(temaID)
                            navigate('/area-de-temas')
                          }}
                        >
                          {' '}
                          Confirmar Remoção{' '}
                        </Button>
                      </HStack>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </HStack>
            </VStack>

          </div>
          <div className="frame-2595-detalhamento-tema">
            <div className="switch-detalhamento-tema">
              <div
                className={`frame-2459-detalhamento-tema ${selectedTab === 'sobre' ? 'tab-border' : ''}`}
                onClick={() => changeSelectedTab('sobre')}
                style={{ cursor: 'pointer' }}
              >
                <div
                  className={`sobre-detalhamento-tema publicsans-bold-nevada-16px ${
                    selectedTab === 'sobre' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'
                  }`}
                >
                  Sobre
                </div>
              </div>
              <div
                className={`frame-2469-detalhamento-tema ${selectedTab === 'atividades vinculadas' ? 'tab-border' : ''}`}
                onClick={() => changeSelectedTab('atividades vinculadas')}
                style={{ cursor: 'pointer' }}
              >
                <div
                  className={`atividades-vinculadas-detalhamento-tema publicsans-bold-gray-16px ${
                    selectedTab === 'atividades vinculadas' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'
                  }`}
                >
                  Atividades vinculadas
                </div>
              </div>
            </div>
          </div>
          {selectedTab === 'sobre' && (
            <div className={'detalhes-tema'}>
              <div className={'descricao-detalhamento-tema'}>
                <div className="descrio-do-tema-detalhamento-tema publicsans-normal-resolution-blue-24px">
                  {'Descrição do tema'}
                </div>
              </div>
              <div className="frame-2604-detalhamento-tema">
                <p
                  className="lorem-ipsum-dolor-si-detalhamento-tema valign-text-middle-detalhamento-tema publicsans-normal-white-16px">
                <span>
                  <span className="publicsans-normal-nevada-16px">{temasData.description} </span>
                </span>
                </p>
              </div>
              <VStack width={'100%'}>
                <div className={'anexo-title-detalhamento-tema frame-2606-detalhamento-tema'}>
                  <div className="descrio-do-tema-detalhamento-tema publicsans-normal-resolution-blue-24px">
                    {'Anexos do tema'}
                  </div>
                </div>
                <SupportMaterialList
                  supportMaterialList={supportMaterialList}
                  deleteType={'detalhamentoTema'}
                  refreshSupportMaterialList={refreshSupportMaterialList}
                />
                <Box className="upload-material-detalhamento-tema">
                  <label>
                    <Box className="frame-2600-detalhamento-tema">
                      <img className="frame-5-detalhamento-tema" src="/img/frame-5-2@2x.png" alt="Frame 5"/>
                      <p className="text-1-detalhamento-tema publicsans-bold-white-14px-2">
                        <span className="publicsans-bold-absolute-zero-14px">Clique</span>
                        <span className="publicsans-normal-nevada-14px"> para adicionar um arquivo</span>
                      </p>
                    </Box>
                    <Input
                      type="file"
                      onChange={async (e) => {
                        handleUploadSupportMaterial(await handleFileChange(e, temaID))
                      }}
                      display="none"
                    />
                  </label>
                </Box>
              </VStack>
            </div>
          )}
          {selectedTab === 'atividades vinculadas' && (
            <div className={'detalhes-tema'}>
              <HStack width={'100%'}>
                <div className="search-filter-detalhamento-turma">
                  <div className="search-filtro-detalhamento-turma">
                    <div className="search-detalhamento-turma">
                      <img className="search-2851823-detalhamento-turma" src="/img/search-2851823@2x.png"
                           alt="search-2851823"/>
                      <input
                        className="text-area-de-turmas"
                        placeholder={'Buscar atividades...'}
                        type={'search'}
                        onChange={(e) => setFilterTextActivity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </HStack>
              <SharedTablePagination
                data={atividades}
                itemsPerPage={5}
                className={'table-margin'}
                columns={[
                  {
                    title: 'Status',
                    render: (atividadesData) => <div>{geradorDeStatus(atividadesData)}</div>
                  },
                  {
                    title: 'Atividade',
                    render: (atividadesData) => <div>{geradorTema(atividadesData.subject)}</div>
                  },

                  {
                    title: 'Entregas',
                    render: (atividadesData) => <div>{geradorRedacoesEntregues(atividadesData.amountClosed)}</div>
                  },
                  {
                    title: 'Detalhes',
                    render: (atividadesData) => <div>{geradorSetas()}</div>
                  }
                ]}
              />
            </div>
          )}
        </div>
      </Page>
    </div>
  )
}

export default DetalhamentoTema

function Entrega(props) {
  const { criao, className, dataCriacao } = props

  return (
    <div className={`entrega-1-detalhamento-tema ${className || ''}`}>
      <div className="criao-detalhamento-tema publicsans-bold-nevada-10px">{criao}</div>
      <div className="data-detalhamento-tema">
        <img className="x-28517-detalhamento-tema" src={'/img/calendar-2851774-5@2x.png'} alt="calendar-2851774"/>
        <div className="date-detalhamento-tema publicsans-normal-nevada-14px">{dataCriacao}</div>
      </div>
    </div>
  )
}

function AtividadeTxt21(props) {
  const { tema } = props
  return (
    <div className="atividade-txt-4-detalhamento-turma">
      <div className="content-4-detalhamento-turma">
        <p
          className="gerao-do-sec-21-a-detalhamento-turma valign-text-middle-detalhamento-turma publicsans-normal-nevada-14px">
          {tema}
        </p>
      </div>
    </div>
  )
}

function AtividadeTxt62() {
  return (
    <div className="atividade-txt-6-detalhamento-turma">
      <img className="ic-arrowright-detalhamento-turma" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright"/>
    </div>
  )
}
