import { create } from 'zustand'

const useCriarTemaStore = create((set) => ({
  currentStep: 1,
  criarTemaData: {
    subject: '',
    description: '',
    schoolId: '',
    groups: [],
    image: ''
  },

  setCurrentStep: (step) => set({ currentStep: step }),
  setCriarTemaData: (data) => set((state) => ({
    criarTemaData: { ...state.criarTemaData, ...data }
  })),
  cleanCriarTemaData: () => set({ criarTemaData: {} })
}))

export default useCriarTemaStore
