import { HStack, Image, VStack } from '@chakra-ui/react'
import React from 'react'
import './SupportMaterialList.css'
import { deleteSupportMaterialApi } from '../../apis/themeApi/supportMaterialApi'

function SupportMaterialList(props) {
  const { supportMaterialList, deleteType, refreshSupportMaterialList, handleRemoveItem } = props

  function HandleSupportMaterial() {
    return supportMaterialList.map((item, key) => {
      const extension = deleteType !== 'criarTema' ? item.fileName.substring(item.fileName.indexOf('.'), item.fileName.length) : '.' + item.extension

      return (
        <HStack spacing={4} className={'support-material-file'} key={key}>
          <a href={item.value} download style={{ textDecoration: 'none', cursor: 'pointer' }}>
            <Image src={'/img/paper-upload.svg'} marginLeft={'16px'}/>
          </a>
          <HStack width={'100%'} justifyContent={'space-between'}>
            <div
              className={'publicsans-normal-gray-16px'}>{item.title + extension}</div>
            {
              deleteType === 'detalhamentoTema' && <Image src={'/img/deleteIcon.svg'} marginRight={'16px'} cursor={'pointer'} onClick={async () => {
                await deleteSupportMaterialApi(item.id)
                refreshSupportMaterialList()
              }}/>
            }
            {
              deleteType === 'criarTema' && <Image src={'/img/deleteIcon.svg'} marginRight={'16px'} cursor={'pointer'} onClick={() => handleRemoveItem(item.title)}/>
            }
          </HStack>
        </HStack>
      )
    })
  }

  return (
    <VStack width={'100%'} marginBottom={'32px'}>
      <HandleSupportMaterial/>
    </VStack>
  )
}

export default SupportMaterialList
