import React, { useEffect, useState } from 'react'
import './DetalhamentoEscola.css'
import Headbar from '../../components/Headbar'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Select,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import SharedTablePagination from '../../components/SharedTablePagination'
import { deleteSchoolApi, getSchoolByIdApi, updateSchoolApi } from '../../apis/schoolApi/schoolApi'
import { createGroupApi, listAllGroups } from '../../apis/schoolApi/groupApi'
import { listAllTeachers } from '../../apis/teacherApi/teacherApi'
import { shiftMapping } from '../../utils/shiftENUM'
import { buildMessage } from '../../utils/toastMiddleware'
import { createSchoolAddressApi } from '../../apis/schoolApi/sharedApi'
import Page from '../Page'

function DetalhamentoEscola() {
  const navigate = useNavigate()
  const { escolaID } = useParams()

  console.log(escolaID)

  const [schoolData, setSchoolData] = useState({})
  const [groupsData, setGroupsData] = useState([])
  const [teachersData, setTeachersData] = useState([])
  const [loading, setLoading] = useState(true)
  const [teachersFilterText, setTeachersFilterText] = useState('')
  const [groupsFilterText, setGroupsFilterText] = useState('')
  const [selectedTab, setSelectedTab] = useState('groups')
  const [groups, setGroups] = useState([])
  const [teachers, setTeachers] = useState([])
  const { isOpenGroup, onOpenGroup, onCloseGroup } = useDisclosure()

  const modalDisclosure = useDisclosure()
  const modalAddressDisclosure = useDisclosure()
  const popoverDisclosure = useDisclosure()

  const toast = useToast()

  const fetchInitialData = () => {
    return new Promise((resolve) => {
      Promise.all([getSchoolByIdApi(escolaID), listAllGroups(escolaID), listAllTeachers(escolaID)])
        .then(([schoolResult, groupsResult, teachersResult]) => {
          resolve({ schoolResult, groupsResult, teachersResult })
        })
    })
  }

  useEffect(() => {
    fetchInitialData().then(({ schoolResult, groupsResult, teachersResult }) => {
      setSchoolData(schoolResult || {})
      setGroupsData(groupsResult || {})
      setTeachersData(teachersResult || {})
      setLoading(false)
      console.log(schoolResult, groupsResult, teachersResult)
    })
  }, [])

  function fetchSchool() {
    Promise.all([getSchoolByIdApi(escolaID)])
      .then(([schoolResult]) => {
        setSchoolData(schoolResult)
      })
  }

  function fetchGroups() {
    Promise.all([listAllGroups(escolaID)])
      .then(([groupsResult]) => {
        setGroupsData(groupsResult)
      })
  }

  useEffect(() => {
    if (groupsData) {
      setGroups(
        groupsData.filter((item) => {
          const result = item.name.toLowerCase().includes(groupsFilterText.toLowerCase()) ||
            shiftMapping[item.shift].toLowerCase().includes(groupsFilterText.toLowerCase())

          return result
        })
      )
    }
  }, [groupsFilterText, groupsData])

  useEffect(() => {
    if (teachersData) {
      setTeachers(
        teachersData.filter((item) => {
          const result = item.name.toLowerCase().includes(teachersFilterText.toLowerCase()) ||
            item.phoneNumber.toLowerCase().includes(teachersFilterText.toLowerCase()) ||
            item.email.toLowerCase().includes(teachersFilterText.toLowerCase())

          return result
        })
      )
    }
  }, [teachersFilterText, teachersData])

  function handleGroupsSearchInputChange(e) {
    setGroupsFilterText(e.target.value)
  }

  function handleTeachersSearchInputChange(e) {
    setTeachersFilterText(e.target.value)
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function GetModalEditSchool() {
    const [schoolNameError, setSchoolNameError] = useState(false)

    const [editSchoolData, setEditSchoolData] = useState({
      id: escolaID,
      name: schoolData.name
    })

    return (
      <Modal isOpen={modalDisclosure.isOpen} size={'2xl'} onClose={modalDisclosure.onClose} isCentered={true}
             closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>Editar Escola</ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={`${schoolNameError ? 'error-form' : ''} publicsans-bold-nevada-16px`}>Nome da Escola*
              </div>
              <Input className={'input-default-style'}
                     onChange={(e) => {
                       setSchoolNameError(false)
                       setEditSchoolData({ ...editSchoolData, name: e.target.value })
                     }}
                     defaultValue={editSchoolData.name}
              />

            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button backgroundColor={'#f9faff'}
                    borderColor={'#648BF7'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#062989'}
                    height={'48px'}
                    width={'218px'} onClick={modalDisclosure.onClose}>
              Cancelar
            </Button>
            <Button backgroundColor={'#0A41D6'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#FFFFFF'}
                    height={'48px'}
                    width={'218px'}
                    onClick={async () => {
                      if (!editSchoolData.name) {
                        setSchoolNameError(!editSchoolData.name)
                      } else {
                        await updateSchoolApi(editSchoolData)
                        fetchSchool()
                        modalDisclosure.onClose()
                      }
                    }}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightblue' : null
    }),
    control: (provided) => ({
      ...provided
    })
  }

  function HandleTeacherDetails({ teacherId }) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>{
        <Link to={'/detalhamento-monitor/' + teacherId}>
          <div className="atividade-txt-4-area-de-turmas">
            <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'}
                 alt="ic-arrowright"/>
          </div>
        </Link>}
      </div>
    )
  }

  function geradorTurma(turma) {
    return (
      <div className="atividade-txt-area-de-turmas">
        <div className="">
          <div
            className="x1-ano-area-de-turmas valign-text-middle-area-de-turmas publicsans-normal-black-14px">{turma}</div>
        </div>
      </div>)
  }

  function geradorTurno(turno) {
    return (
      <div className="atividade-txt-2-area-de-turmas">
        <div className="frame-2580-2-area-de-turmas">
          <div className="noite-area-de-turmas valign-text-middle-area-de-turmas publicsans-bold-absolute-zero-12px">
            {shiftMapping[turno]}
          </div>
        </div>
      </div>
    )
  }

  function geradorStatus() {
    return (
      <div className="atividade-txt-2-area-de-atividades">
        <div className="frame-2581-1-area-de-atividades">
          <div
            className="ativa-area-de-atividades valign-text-middle-area-de-atividades publicsans-normal-black-12px">Ativa
          </div>
        </div>
      </div>
    )
  }

  function geradorSetas(itemData) {
    return (
      <Link to={`/detalhamento-turma/${itemData}`} state={{ schoolID: escolaID }}>
        <div className="atividade-txt-4-area-de-turmas">
          <img className="ic-arrowright-area-de-turmas" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright"/>
        </div>
      </Link>
    )
  }

  async function handleAddGroup() {
    const name = document.getElementById('name').value
    const description = document.getElementById('description').value
    const shift = document.getElementById('shift').value

    if (!shift) {
      toast(buildMessage('error', 'Faltando informações de turma', 'Informe o turno da turma.'))
      return
    }

    const requestBody = {
      name,
      description,
      shift
    }

    console.log(!shift, shift)

    if (requestBody.name && requestBody.description && requestBody.shift) {
      try {
        await createGroupApi(requestBody, escolaID)
        toast(buildMessage('success', 'Turma adicionada com sucesso.', 'A turma já pode ser utilizada pelos professores.'))
      } catch (error) {
        toast(buildMessage('error', 'Erro ao adicionar aluno.', 'Entre em contato com o suporte do Correção Online.'))
      }

      fetchGroups()
    }

    document.getElementById('name').value = null
    document.getElementById('description').value = null
  }

  function GetModalEditAddressSchool() {
    const [editSchoolAddressData, setEditSchoolAddressData] = useState({
      street: schoolData?.address?.street,
      number: schoolData?.address?.number,
      zipCode: schoolData?.address?.zipCode,
      city: schoolData?.address?.city,
      state: schoolData?.address?.state,
      country: schoolData?.address?.country
    })

    const [ruaError, setRuaError] = useState(false)
    const [numeroError, setNumeroError] = useState(false)
    const [cepError, setCepError] = useState(false)
    const [cidadeError, setCidadeError] = useState(false)
    const [estadoError, setEstadoError] = useState(false)

    const formatCep = (value) => {
      // Remove qualquer caracter não numérico
      value = value.replace(/\D/g, '')
      if (value.length > 5) {
        value = value.slice(0, 5) + '-' + value.slice(5, 8)
      }
      return value
    }

    const handleCepChange = (e) => {
      let value = e.target.value

      value = formatCep(value)

      if (value) {
        setCepError(false)
      }

      // Atualiza o valor no estado da sua aplicação
      setEditSchoolAddressData({ ...editSchoolAddressData, zipCode: value })
    }

    return (
      <Modal isOpen={modalAddressDisclosure.isOpen} size={'2xl'} onClose={modalAddressDisclosure.onClose}
             isCentered={true}
             closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>Editar Endereço da
            Escola</ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <HStack width={'100%'}>
                <VStack width={'100%'} alignItems={'start'}>
                  <div className={`${ruaError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                    Rua
                  </div>

                  <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                         borderColor={'#d7e3fb'}
                         onChange={(e) => {
                           if (e.target.value) {
                             setRuaError(false)
                           }
                           setEditSchoolAddressData({ ...editSchoolAddressData, street: e.target.value })
                         }}
                         defaultValue={editSchoolAddressData.street}
                         maxLength={150}
                  />
                </VStack>

                <VStack width={'30%'} alignItems={'start'}>
                  <div
                    className={`${numeroError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                    Número
                  </div>
                  <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                         borderColor={'#d7e3fb'}
                         onChange={(e) => {
                           if (e.target.value) {
                             setNumeroError(false)
                           }
                           setEditSchoolAddressData({ ...editSchoolAddressData, number: e.target.value })
                         }}
                         defaultValue={editSchoolAddressData.number}
                         maxLength={10}
                  />
                </VStack>

              </HStack>

              <div className={`${cepError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                CEP
              </div>
              <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                     borderColor={'#d7e3fb'}
                     onChange={handleCepChange}
                     value={editSchoolAddressData?.zipCode}
                     maxLength={9}
              />
              <div className={`${cidadeError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                Cidade
              </div>
              <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                     borderColor={'#d7e3fb'}
                     onChange={(e) => {
                       if (e.target.value) {
                         setCidadeError(false)
                       }
                       setEditSchoolAddressData({ ...editSchoolAddressData, city: e.target.value })
                     }}
                     defaultValue={editSchoolAddressData.city}
                     maxLength={50}
              />
              <div className={`${estadoError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                Estado
              </div>
              <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                     borderColor={'#d7e3fb'}
                     onChange={(e) => {
                       if (e.target.value) {
                         setEstadoError(false)
                       }
                       setEditSchoolAddressData({ ...editSchoolAddressData, state: e.target.value })
                     }}
                     defaultValue={editSchoolAddressData.state}
                     maxLength={50}
              />

            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button backgroundColor={'#f9faff'}
                    borderColor={'#648BF7'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#062989'}
                    height={'48px'}
                    width={'218px'} onClick={modalAddressDisclosure.onClose}>
              Cancelar
            </Button>
            <Button backgroundColor={'#0A41D6'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#FFFFFF'}
                    height={'48px'}
                    width={'218px'}
                    onClick={async () => {
                      if ((editSchoolAddressData.street && editSchoolAddressData.number &&
                        editSchoolAddressData.zipCode && editSchoolAddressData.city &&
                        editSchoolAddressData.state)) {
                        await createSchoolAddressApi(escolaID, editSchoolAddressData)
                        fetchSchool()
                        toast(buildMessage('success', 'Endereço da escola alterado com sucesso.', ''))
                        modalAddressDisclosure.onClose()
                      } else {
                        setRuaError(!editSchoolAddressData.street)
                        setNumeroError(!editSchoolAddressData.number)
                        setCepError(!editSchoolAddressData.zipCode)
                        setCidadeError(!editSchoolAddressData.city)
                        setEstadoError(!editSchoolAddressData.state)
                      }
                    }}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <div className="detalhamento-escola screen-detalhamento-escola">
      <Page authPage={true} allowedUserType={'adm'}>
        <Headbar returnTo={'/area-de-escolas'}/>
        <div className="frame-2591-detalhamento-escola">
          <TitleCta>Dados da escola</TitleCta>

          <div className="criao-atividade-detalhamento-escola">
            <div className="email-detalhamento-escola publicsans-normal-nevada-16px">
              {'Endereço'}
            </div>

            <div className="data-detalhamento-escola">
              <img className="x-2851-detalhamento-escola" src={'/img/location.svg'} alt="message-2851808"/>
              <div className="joosilvagmailcom-detalhamento-escola publicsans-normal-nevada-14px">
                {loading ? '' : schoolData?.address ? schoolData?.address?.street + ' ' + schoolData?.address?.number : 'Não informado'}
                <p/>
                {loading ? '' : schoolData?.address ? schoolData?.address?.zipCode : ''}
                <p/>
                {loading ? '' : schoolData?.address ? schoolData?.address?.city + ' ' + schoolData?.address?.state : ''}
              </div>
            </div>

            <div className="button-edit-endereco" onClick={modalAddressDisclosure.onOpen}>
              <div className="mainbutton-detalhamento-tema">
                <div className="content-2-detalhamento-tema">
                  <img className="ic-add-detalhamento-tema" src={'/img/edit-2851791-7.svg'} alt="ic-add"/>
                  <div
                    className="main-button-detalhamento-tema publicsans-bold-resolution-blue-14px">{'Editar endereço'}</div>
                </div>
              </div>
            </div>
          </div>
          <GetModalEditAddressSchool/>
        </div>
        <div className={'detalhamento-escola-body'}>
          <div className="tema-detalhamento-escola">
            <div className="content-3-detalhamento-escola">
              <div className="frame-2593-detalhamento-escola">
                <div className="frame-2594-detalhamento-escola">
                  <div className="frame-2609-detalhamento-escola">
                    <div className="alunoa-detalhamento-escola publicsans-bold-nevada-10px">
                      ESCOLA
                    </div>
                    <div className="frame-2610-detalhamento-escola">
                      <div
                        className="joo-silva-oliveira-detalhamento-escola publicsans-normal-absolute-zero-24px">
                        {loading ? '' : schoolData.name}
                      </div>
                      <Frame2580>Ativa</Frame2580>
                    </div>
                  </div>
                  <div className="editar-detalhamento-escola">
                    <div className="edit-container-detalhamento-escola">
                      <div
                        className="editar-1-detalhamento-escola valign-text-middle-detalhamento-escola publicsans-bold-nevada-14px">
                        {'Editar'}
                      </div>
                      <img className="mode-edit-detalhamento-escola"
                           src={'/img/mode-edit@2x.png'}
                           alt="Mode edit"
                           onClick={modalDisclosure.onOpen}/>
                      <GetModalEditSchool/>
                    </div>
                  </div>
                </div>
              </div>
              <HStack justifyContent={'space-between'} width={'100%'}>
                <div className="descrio-detalhamento-escola">
                  <div className="entrega-detalhamento-escola">
                    <div className="turma-detalhamento-escola publicsans-bold-nevada-10px">
                      CNPJ
                    </div>
                    <div className="frame-2580-1-detalhamento-escola">
                      <div
                        className="x1-ano-a-detalhamento-escola valign-text-middle-detalhamento-escola publicsans-normal-black-12px">
                        {schoolData.document}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <HStack>
                    <div className={'publicsans-bold-nevada-14px'}> Apagar escola?</div>
                    <Popover isOpen={popoverDisclosure.isOpen} onOpen={popoverDisclosure.onOpen}
                             onClose={popoverDisclosure.onClose} placement="bottom-end" closeOnBlur={false}>
                      <PopoverTrigger>
                        <Image src={'/img/deleteIcon.svg'} marginRight={'15px'} cursor={'pointer'}/>
                      </PopoverTrigger>
                      <PopoverContent width={'448px'} borderRadius={'10px'}>
                        <PopoverArrow/>
                        <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'}/>
                        <PopoverHeader>
                          <div className={'text-popover'}>Confirme Remoção da escola</div>
                        </PopoverHeader>
                        <PopoverFooter alignSelf={'center'} marginTop={'50px'} width={'100%'}>
                          <HStack width={'100%'}>
                            <Button backgroundColor={'#f9faff'}
                                    borderColor={'#648BF7'}
                                    border={'1px'}
                                    borderRadius={'8px'}
                                    color={'#062989'}
                                    height={'48px'}
                                    width={'100%'} onClick={popoverDisclosure.onClose}>
                              Cancelar
                            </Button>
                            <Button backgroundColor={'#0A41D6'}
                                    color={'white'}
                                    width={'100%'}
                                    height={'48px'}
                                    onClick={async () => {
                                      await deleteSchoolApi(escolaID)
                                      toast(buildMessage('success', 'Remoção de escola realizada com sucesso.', ''))
                                      navigate(-1)
                                    }}
                            > Confirmar Remoção </Button>
                          </HStack>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  </HStack>
                </div>
              </HStack>
            </div>
          </div>
          <div className="frame-2595-detalhamento-escola">
            <div className="switch-detalhamento-escola">
              <div className={`frame-2471-detalhamento-escola ${selectedTab === 'groups' ? 'tab-border' : ''}`}
                   onClick={() => changeSelectedTab('groups')}>
                <div
                  className={`redaes-detalhamento-escola ${selectedTab === 'groups' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                  {'Turmas'}
                </div>
              </div>
              <div className={`frame-2470-detalhamento-escola ${selectedTab === 'teachers' ? 'tab-border' : ''}`}
                   onClick={() => changeSelectedTab('teachers')}>
                <div
                  className={`atividades-vinculadas-detalhamento-escola ${selectedTab === 'teachers' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                  {'Professores'}
                </div>
              </div>
            </div>
          </div>
          {!loading && selectedTab === 'groups' && (
            <div className={'redacoes-container-detalhamento-escola-style'}>
              <div className="search-filter-detalhamento-escola">
                <div className="search-filtro-detalhamento-escola">
                  <HStack width={'100%'} alignItems="end">
                    <div className="search-detalhamento-escola">
                      <img
                        className="search-2851823-detalhamento-escola"
                        src="/img/search-2851823@2x.png"
                        alt="search-2851823"
                      />
                      <input
                        placeholder={'Buscar turmas...'}
                        type={'search'}
                        className="text-detalhamento-escola publicsans-normal-nevada-16px"
                        onChange={handleGroupsSearchInputChange}
                      />
                    </div>

                    <div>
                      <Popover
                        isOpen={isOpenGroup}
                        onOpen={onOpenGroup}
                        onClose={onCloseGroup}
                        placement="bottom-end"
                        closeOnBlur={false}
                      >
                        <PopoverTrigger>
                          <div className="button-detalhamento-tema" onClick={onOpenGroup}>
                            <div className="mainbutton-detalhamento-tema custom-button" style={{ height: '48px' }}>
                              <div className="content-2-detalhamento-tema">
                                <img
                                  className="ic-add-detalhamento-tema"
                                  src={'/img/ic-add-1.svg'}
                                  alt="button-addStudent"
                                  style={{ filter: 'brightness(0) invert(1)' }}
                                />
                                <div
                                  className="main-button-detalhamento-tema custom-button-text publicsans-bold-white-14px">{'Adicionar Turma'}</div>
                              </div>
                            </div>
                          </div>
                        </PopoverTrigger>
                        <PopoverContent width={'448px'} borderRadius={'10px'}>
                          <PopoverArrow/>
                          <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'}/>
                          <PopoverHeader>
                            <div className={'text-popover'}>Nova turma</div>
                          </PopoverHeader>
                          <PopoverBody marginTop={'18px'}>
                            <br/>
                            <br/>
                            <Input className='input-default-style'
                                   id='name'
                                   style={{
                                     width: '100%',
                                     backgroundColor: 'white',
                                     border: '1px solid #d7e3fb',
                                     padding: '5px'
                                   }}
                                   placeholder={'Digite o nome da turma'}
                                   type="text"
                            />
                            <br/>
                            <br/>
                            <Input className='input-default-style'
                                   id='description'
                                   style={{
                                     width: '100%',
                                     backgroundColor: 'white',
                                     border: '1px solid #d7e3fb',
                                     padding: '5px'
                                   }}
                                   placeholder={'Informe uma descrição da turma (opcional)'}
                                   type="text"
                            />
                            <br/>
                            <br/>
                            <Box w={'100%'} h={'48px'}>
                              <Select
                                placeholder={loading ? 'Aguarde um momento...' : 'Selecionar Turno da turma'}
                                id={'shift'}
                                borderColor={'#d7e3fb'}
                                backgroundColor={'white'}
                                styles={customStyles}
                                isDisabled={loading}
                                w={'100%'}>
                                {Object.entries(shiftMapping).map((option, key) => (
                                  <option className={'select-tema-text'} key={key} value={option[0]}>
                                    {option[1]}
                                  </option>
                                ))}
                              </Select>
                            </Box>
                          </PopoverBody>
                          <PopoverFooter alignSelf={'center'}>
                            <Button backgroundColor={'#0A41D6'} color={'white'} width={'400px'} height={'48px'}
                                    marginBottom={'15px'} onClick={async () => {
                                      await handleAddGroup()
                                    }}>
                              {' '}
                              Adicionar{' '}
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </HStack>
                </div>

                <SharedTablePagination
                  data={groups}
                  itemsPerPage={5}
                  columns={[
                    {
                      title: 'Turma',
                      render: (itemData) => <div>{geradorTurma(itemData.name)}</div>
                    },
                    {
                      title: 'Turno',
                      render: (itemData) => <div>{geradorTurno(itemData.shift)}</div>
                    },
                    {
                      title: 'Status',
                      render: (itemData) => <div>{geradorStatus(itemData.active)}</div>
                    },
                    {
                      title: 'Detalhes',
                      render: (itemData) => <div>{geradorSetas(itemData.id)}</div>
                    }
                  ]}
                />
              </div>

            </div>
          )}

          {!loading && selectedTab === 'teachers' && (
            <div className={'redacoes-container-detalhamento-escola-style'}>
              <div className="search-filter-detalhamento-escola">
                <div className="search-filtro-detalhamento-escola">
                  <div className="search-detalhamento-escola">
                    <img
                      className="search-2851823-detalhamento-escola"
                      src="/img/search-2851823@2x.png"
                      alt="search-2851823"
                    />
                    <input
                      placeholder={'Buscar professores...'}
                      type={'search'}
                      className="text-detalhamento-escola publicsans-normal-nevada-16px"
                      onChange={handleTeachersSearchInputChange}
                    />
                  </div>
                </div>

                <SharedTablePagination
                  data={teachers}
                  itemsPerPage={5}
                  columns={[
                    {
                      title: 'Nome',
                      render: (itemData) => <div
                        className={'table-cell-container publicsans-normal-black-14px'}>{itemData.name}</div>
                    },
                    {
                      title: 'Email',
                      render: (itemData) => <div
                        className={'table-cell-container publicsans-normal-black-14px'}>{itemData.email}</div>
                    },
                    {
                      title: 'Telefone',
                      render: (itemData) => <div
                        className={'table-cell-container publicsans-normal-black-14px'}>{itemData.phoneNumber}</div>
                    },
                    {
                      title: 'Detalhes',
                      render: (itemData) => <HandleTeacherDetails teacherId={itemData.id}/>
                    }
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </Page>
    </div>
  )
}

export default DetalhamentoEscola

function TitleCta(props) {
  const { children } = props

  return (
    <div className="title-cta-detalhamento-escola">
      <div className="title-detalhamento-escola">
        <h1 className="title-1-detalhamento-escola publicsans-normal-resolution-blue-24px">
          {children}
        </h1>
      </div>
    </div>
  )
}

function Frame2580(props) {
  const { children } = props

  return (
    <div className="frame-2580-detalhamento-escola">
      <div
        className="ativo-detalhamento-escola valign-text-middle-detalhamento-escola publicsans-normal-black-14px">
        {children}
      </div>
    </div>
  )
}
