import React from 'react'
import './Frame25802.css'

function Frame25802(props) {
  function handlePlural() {
    return props.numeroDeRedacoesEntregues === 1
      ? props.numeroDeRedacoesEntregues + ' redação entregue'
      : props.numeroDeRedacoesEntregues + ' redações entregues'
  }

  return (
    <div className="frame-2580-18">
      <div className="address-1 valign-text-middle publicsans-bold-absolute-zero-12px">{handlePlural()}</div>
    </div>
  )
}

export default Frame25802
