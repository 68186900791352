import React from 'react'
import Frame25802 from '../Frame25802'
import './AtividadeTxt52.css'

function AtividadeTxt52(props) {
  const { entregas } = props
  return (
    <div className="atividade-txt-21">
      <Frame25802 numeroDeRedacoesEntregues= {entregas} />
    </div>
  )
}

export default AtividadeTxt52
