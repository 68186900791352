export const Heading = {
  baseStyle: {
    color: 'brand.100'
  },
  sizes: {
    md: (props) => ({
      fontWeight: 400,
      lineHeight: '31.2px',
      fontSize: '24px',
      px: '0'
    })
  }
}
