import { Link } from 'react-router-dom'
import './LoginHeader.css'

function HeaderLogin() {
  return (
    <div className="header-login">
      <div className="logo-positiva-horizontal-login">
        <img className="group-1-login" src={'img/logo-positiva-horizontal.svg'} alt="Group"/>
      </div>
      <Link style={{ textDecoration: 'none' }} to={'/login'}>

        <div className="content-2-login">
          <div className="main-button-2-login publicsans-bold-absolute-zero-14px">
            {'Entrar'}
          </div>
          <img className="buttonicon-login" src={'/img/ic-login.svg'} alt={'entrar icon'}/>
        </div>
      </Link>
    </div>
  )
}

export default HeaderLogin
