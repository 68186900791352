import React from 'react'
import Mainbutton from '../Mainbutton'
import './Button.css'

function Button(props) {
  return (
    <div className="button-14">
      <Mainbutton>{'Ver detalhes'}</Mainbutton>
    </div>
  )
}

export default Button
