import { Avatar, AvatarGroup, Box, Button, Card, CardBody, Center, CircularProgress, Flex, FormControl, FormLabel, Grid, GridItem, HStack, Heading, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { addBatchOfStudents, getAllStudentsApi } from '../../apis/schoolApi/schoolApi'
import { ArrowRightIcon } from '../icons/ArrowRight'
import { AddIcon } from '../icons/Add'
import { SchoolAssistantComponent } from '../school-assistant'

export const IndependentTeacherDetailsModalBody = ({ independentTeacherId, onClose }) => {
  const [invitesToBeSent, setInvitesToBeSent] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [studentsAreLoading, setStudentsAreLoading] = useState(true)
  const [students, setStudents] = useState([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    getStudents()
  }, [])

  async function getStudents() {
    try {
      const result = await getAllStudentsApi(independentTeacherId)
      setStudents(result)
    } catch (error) {
      console.log(error)
    } finally {
      setStudentsAreLoading(false)
    }
  }

  function resetForm() {
    setName('')
    setEmail('')
  }

  function addToInvitesToBeSent() {
    const newInvite = {
      name,
      email
    }

    const invitesToBeSentCopy = invitesToBeSent.slice(0)
    invitesToBeSentCopy.push(newInvite)
    setInvitesToBeSent(invitesToBeSentCopy)
    resetForm()
  }

  function removeInvite(inviteIndex) {
    const invitesToBeSentCopy = invitesToBeSent.slice(0)
    const newInvites = invitesToBeSentCopy.filter((_, index) => index !== inviteIndex)

    setInvitesToBeSent(newInvites)
  }

  async function sendInvites() {
    setIsLoading(true)
    try {
      const body = {
        studentsToInvite: invitesToBeSent,
        language: 'pt-br',
        schoolId: independentTeacherId,
        groupId: independentTeacherId
      }

      const response = await addBatchOfStudents(body)

      if (response.success) {
        onClose()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  // async function deleteIndependentTeacher() {
  //   try {
  //     setDeleteIsLoading(true)
  //     await deleteIndependentTeacherApi(independentTeacherId)
  //     onClose()
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setDeleteIsLoading(false)
  //   }
  // }

  return (
    <Tabs paddingTop={4} isFitted variant='enclosed'>
      <TabList mb='1em'>
        <Tab>Alunos</Tab>
        <Tab>Assistente</Tab>
        <Tab>Outros</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stack spacing={7} minHeight='260px'>
            <Stack>
              <Card size='sm'>
                <CardBody>
                  <Stack textAlign='center' justifyContent='center'>
                    {
                      studentsAreLoading
                        ? <Center><CircularProgress isIndeterminate size='20px' /></Center>
                        : students.length
                          ? <Popover placement='right' closeOnBlur closeOnEsc>
                            <PopoverTrigger>
                              <Flex cursor='pointer' justifyContent='space-between' alignItems='center'>
                                <HStack>
                                  <AvatarGroup size='sm' max={1}>
                                    {
                                      students.map((student) => (
                                        <Avatar key={student.email} name={student.name} />
                                      ))
                                    }
                                  </AvatarGroup>
                                  <Text fontSize='sm'>
                                    <span style={{ fontWeight: 'bold' }}>{students[0].name}</span> e mais {students.length - 1} alunos
                                  </Text>
                                </HStack>
                                <ArrowRightIcon />
                              </Flex>
                            </PopoverTrigger>
                            <PopoverContent border={0} boxShadow={'md'}>
                              <PopoverArrow />
                              <PopoverHeader>Alunos</PopoverHeader>
                              <PopoverCloseButton />
                              <PopoverBody>
                                <Stack height='100%'>
                                  <Stack position='relative' overflowY='scroll' height='300px'>
                                    {
                                      students.map((student) => (
                                        <HStack key={student.email}>
                                          <Avatar size='sm' name={student.name}></Avatar>
                                          <Text fontSize='sm'>{student.name}</Text>
                                        </HStack>
                                      ))
                                    }
                                  </Stack>
                                </Stack>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                          : <Text color='blackAlpha.700'>Nenhum aluno ativo</Text>
                    }
                  </Stack>
                </CardBody>
              </Card>
            </Stack>

            <Stack>
              <Heading size={'xs'} color='blackAlpha.700'>Convidar novos alunos</Heading>
              <Stack>
                {
                  invitesToBeSent.map((invite, index) => (
                    <HStack key={invite.email} width='fit-content' backgroundColor='gray.100' boxShadow='sm' borderRadius='4px' px={2}>
                      <Text fontSize='14px'>{`<${invite.name}>`} {invite.email}</Text>
                      <Button size='xs' variant='unstyled' onClick={() => removeInvite(index)}>x</Button>
                    </HStack>
                  ))
                }
              </Stack>
              <form>
                <Grid templateColumns='repeat(12, 1fr)' gap={4} height='100%' alignItems='end'>
                  <GridItem colSpan={{ sm: 12, lg: 5 }}>
                    <FormControl>
                      <FormLabel>Nome</FormLabel>
                      <Input value={name} onChange={e => setName(e.target.value)} placeholder='Nome do aluno' />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ sm: 12, lg: 6 }}>
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <Input type='email' value={email} onChange={e => setEmail(e.target.value)} placeholder='emaildoaluno@gmail.com' />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ sm: 12, lg: 1 }}>
                    <Button type='submit' variant='primary' isDisabled={!name || !email} onClick={addToInvitesToBeSent}>
                      <AddIcon />
                    </Button>
                  </GridItem>
                </Grid>
              </form>
            </Stack>

            <Button variant='primary' isDisabled={!invitesToBeSent.length} onClick={sendInvites} isLoading={isLoading}>Enviar convites</Button>
          </Stack>
        </TabPanel>
        <TabPanel>
          <Box minHeight='260px'>
            <SchoolAssistantComponent schoolId={independentTeacherId} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Stack spacing={7} minHeight='260px'>
            <Card size='sm' cursor='pointer'>
              <CardBody>
                <HStack alignItems='center'>
                  <img width="40" height="40" src="https://img.icons8.com/color/48/delete-forever.png" alt="delete-forever" />
                  <Box>
                    <Text fontSize='16px' fontWeight='700'>Exluir usuário</Text>
                    <Text fontSize='14px' color='blackAlpha.700'>Remover usuário do sistema completamente.</Text>
                  </Box>
                </HStack>
              </CardBody>
            </Card>
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
