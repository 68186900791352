import { Button, HStack, Stack } from '@chakra-ui/react'
import { useEffect, useRef, useState, useMemo } from 'react'
import CanvasDraw from 'react-canvas-draw'
import { sleep } from '../../pages/CorrigirRedacao/utils'

// import essayImage from '../../../static/img/redacao.jpg'

export const CanvasDrawContainer = ({ onExpand, width = 380, height = 520, openPopover, comments = [], imageSelectionData, setImageSelectionData }) => {
  const [canvasResultLayer, setCanvasResultLayer] = useState(null)
  const [canvasDrawLayer, setCanvasDrawLayer] = useState(null)
  const [lineHeight] = useState(15)
  const [selectedLine, setSelectedLine] = useState(null)

  const canvasResultCtx = useMemo(() => canvasResultLayer ? canvasResultLayer.getContext('2d') : null, [canvasResultLayer])

  const defaultLineColor = 'rgba(82, 204, 100, 0.3)'

  const ref = useRef(null)

  function handleStateChange(func, value) {
    if (func) {
      func(value)
    }
  }

  function clear() {
    ref.current.eraseAll()
  }

  useEffect(() => {
    if (!canvasDrawLayer) return

    function handleMouseMove(e) {
      if (
        isMouseOnSomeLine(e)
      ) {
        canvasDrawLayer.style.cursor = 'pointer'
        resetCanvas()
      } else {
        canvasDrawLayer.style.cursor = 'default'
        resetCanvas()
      }
    }

    canvasDrawLayer.addEventListener('mousemove', handleMouseMove)

    return () => {
      canvasDrawLayer.removeEventListener('mousemove', handleMouseMove)
    }
  }, [canvasDrawLayer])

  useEffect(() => {
    if (!canvasDrawLayer) return

    function handleMouseClick(e) {
      if (isMouseOnSomeLine(e)) {
        console.log('Clicked on the line', selectedLine)
      }
    }

    canvasDrawLayer.addEventListener('click', handleMouseClick)

    return () => {
      canvasDrawLayer.removeEventListener('click', handleMouseClick)
    }
  }, [canvasDrawLayer])

  function drawNewLine({ lineHeight, startPosX, startPosY, endPosX, endPosY }) {
    canvasResultCtx.strokeStyle = defaultLineColor
    canvasResultCtx.lineWidth = lineHeight
    canvasResultCtx.lineCap = 'round'
    canvasResultCtx.beginPath()
    canvasResultCtx.moveTo(startPosX, startPosY)
    canvasResultCtx.lineTo(endPosX, endPosY)
    canvasResultCtx.stroke()
  }

  function isMouseOnLine(e, { id, lineHeight, startPosX, startPosY, endPosX, endPosY }) {
    const mouseX = e.clientX - canvasResultLayer.getBoundingClientRect().left
    const mouseY = e.clientY - canvasResultLayer.getBoundingClientRect().top

    const result = (
      mouseX >= (startPosX - (lineHeight / 2)) &&
      mouseX <= (endPosX + (lineHeight / 2)) &&
      mouseY >= (startPosY - (lineHeight / 2)) &&
      mouseY <= endPosY + (lineHeight / 2)
    )

    let selectedLine

    result ? selectedLine = id : selectedLine = null

    console.log(selectedLine)
    if (selectedLine) handleStateChange(setSelectedLine, selectedLine)

    return result
  }

  const isMouseOnSomeLine = (e) => comments.some(line => isMouseOnLine(e, line))

  function resetCanvas(currentLines) {
    clear()
    const linesToBeDrawed = currentLines || comments
    linesToBeDrawed.forEach(line => drawNewLine(line))
  }

  function mountCanvasResult() {
    const listOfCanvas = document.getElementsByTagName('canvas')

    const resultLayer = listOfCanvas[1]
    const drawLayer = listOfCanvas[3]
    setCanvasResultLayer(resultLayer)
    setCanvasDrawLayer(drawLayer)
  }

  useEffect(async () => {
    await sleep(2000)
    mountCanvasResult()
  }, [])

  useEffect(() => {
    if (canvasResultLayer) {
      resetCanvas()
    }
  }, [canvasResultLayer])

  useEffect(() => {
    if (imageSelectionData === null) {
      resetCanvas()
    }
  }, [imageSelectionData])

  function onClickDown(e) {
    const mouseX = Math.round(e.clientX - canvasResultLayer.getBoundingClientRect().left)
    const mouseY = Math.round(e.clientY - canvasResultLayer.getBoundingClientRect().top)

    setImageSelectionData({ lineHeight, startX: mouseX, startY: mouseY })
  }

  function onClickUp(e) {
    const mouseX = Math.round(e.clientX - canvasResultLayer.getBoundingClientRect().left)
    const mouseY = Math.round(e.clientY - canvasResultLayer.getBoundingClientRect().top)

    const lineToBeDrawed = { ...imageSelectionData, endX: mouseX, endY: mouseY }

    setImageSelectionData(lineToBeDrawed)
    console.log('lineToBeDrawed', lineToBeDrawed)
    // const linesCopy = lines.slice(0)
    // linesCopy.push(lineToBeDrawed)
    // setLines(linesCopy)
    // resetCanvas(linesCopy)
    openPopover()
  }

  return (
    <>
      <HStack position='relative' onClick={onClickUp} onMouseDown={onClickDown}>
        <CanvasDraw
          ref={ref}
          imgSrc={'https://cdn.blog.estrategiavestibulares.com.br/vestibulares/wp-content/uploads/2022/04/Luis-Felipe-Alves-Paiva-de-Brito_-24-anos-%E2%80%93-Maceio-_AL_-redacao-nota-mil-enem-2022-756x1024.jpg'}
          canvasWidth={width}
          canvasHeight={height}
          brushRadius={lineHeight / 2}
          brushColor='rgba(82, 204, 100, 0.3)'
          clampLinesToDocument
          hideGrid
          lazyRadius={0}
          catenaryColor={defaultLineColor}
          // onChange={onChange}
          style={{
            zIndex: 2
          }}
          onclick={() => console.log('canvas click??')}
        />
        {onExpand && <Button onClick={onExpand} position='absolute' bottom='8px' right='5px' boxShadow='dark-lg'>
          <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/expand--v1.png" alt="expand--v1" />
        </Button>}
        <Stack>
          {/* <Button onClick={resetCanvas}>Undo</Button>
          <Button onClick={clear}>Clear</Button> */}

        </Stack>
      </HStack>
    </>
  )
}
