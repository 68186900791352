import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Headbar from '../../components/Headbar'
import StepsImage from '../StepsImage'
import './CriarTemaStep1.css'
import BackStepButton from '../BackStepButton'
import ForwardButton from '../ForwardButton'
import FormStepNotSelected from '../FormStepNotSelected'
import { listAllGroups } from '../../apis/schoolApi/groupApi'
import { Box, Flex, Heading, Input, Text, Textarea, VStack } from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'
import { handleFileChange } from '../../utils/supportMaterialUpload'
import useCriarTemaStore from '../../store/useCriarTemaStore'
import { createThemeApi } from '../../apis/themeApi/themeApi'
import { createSupportMaterialApi } from '../../apis/themeApi/supportMaterialApi'
import SupportMaterialList from '../SupportMaterialList'

function CriarTemaStep1(props) {
  const { handleReturn, handlingNextStep } = props
  const { criarTemaData, setCriarTemaData } = useCriarTemaStore()

  const maxCharacteres = 180

  const [turmaOptions, setTurmaOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [turmaValue, setTurmaValue] = useState([])
  const [supportMaterialList, setSupportMaterialList] = useState([])
  const [turmaError, setTurmaError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [imagePreview, setImagePreview] = useState('')

  const handleTextChange = (event) => {
    const newText = event.target.value
    if (newText.length <= maxCharacteres) {
      setCriarTemaData({ description: newText })
    }
  }

  const fetchOptions = () => {
    return new Promise((resolve) => {
      Promise.all([listAllGroups()]).then(([turmaResult]) => {
        resolve({ turmaResult })
      })
    })
  }

  useEffect(() => {
    fetchOptions()
      .then(({ turmaResult }) => {
        setTurmaOptions(
          turmaResult.map((turma, key) => {
            return { value: turma.name, label: turma.name, id: turma.id }
          })
        )
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  function handleUploadSupportMaterial(payload) {
    setSupportMaterialList([payload, ...supportMaterialList])
  }

  useEffect(() => {
    if (turmaValue) {
      setCriarTemaData({ groups: turmaValue.map(turma => turmaOptions.find(item => turma === item.value).id) })
    }
  }, [turmaValue])

  const createTheme = () => {
    return new Promise((resolve) => {
      const result = createThemeApi(criarTemaData)
      resolve(result)
    })
  }

  const createSupportMaterial = (supportMaterial) => {
    return new Promise((resolve) => {
      const result = createSupportMaterialApi(supportMaterial)
      resolve(result)
    })
  }

  function handleSubmit() {
    if (criarTemaData.subject && turmaValue.length > 0) {
      createTheme().then((result) => {
        supportMaterialList.forEach(item => {
          item.themeId = result
          createSupportMaterial(item).then(() => {
            handlingNextStep(result)
          }).catch(error => {
            console.log(error)
          })
        })
      })
    } else {
      setNameError(!criarTemaData.subject)
      setTurmaError(turmaValue.length === 0)
    }
  }

  function ThemeImageUpload() {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg']
      },
      multiple: false,
      onDrop: (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
          const imageFile = acceptedFiles[0]
          const reader = new FileReader()
          reader.onload = () => {
            setImagePreview(reader.result)
            setCriarTemaData({ image: reader.result.split(',')[1] })
          }
          reader.readAsDataURL(imageFile)
        }
      }
    })

    return (
      <div className="email-criar-tema">
        <div className="imagem-do-tema-criar-tema publicsans-bold-nevada-16px">
          {imagePreview ? (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <img src={imagePreview} alt="Imagem do tema"/>
            </div>
          ) : (
            <div className="frame-2601-criar-tema" {...getRootProps()}>
              <input {...getInputProps()} />
              <img className="frame-5-criar-tema" src="/img/frame-5-1@2x.png" alt="Frame 5"/>
              <div className="text-criar-tema publicsans-bold-white-14px-2">
                {isDragActive ? (
                  <span className="publicsans-bold-absolute-zero-14px">Solte a imagem aqui</span>
                ) : (
                  <>
                    <span className="publicsans-bold-absolute-zero-14px">Clique</span>
                    <span className="publicsans-normal-nevada-14px">{' '}para escolher<br/>uma imagem</span>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  function transformSupportMaterialList() {
    return supportMaterialList.map(item => {
      console.log(item)
      return { value: item.value, title: item.title, extension: item.extension }
    })
  }

  const handleRemoveItem = (title) => {
    const updatedList = supportMaterialList.filter(item => item.title !== title)

    setSupportMaterialList(updatedList)
  }

  return (
    <Flex w={'100%'}>
      <VStack w={'100%'}>
        <Headbar returnTo={'/area-de-temas'}/>
        <VStack marginTop={'107px'}>
          <Heading alignSelf={'start'} ml={'35px'} className={'publicsans-extra-extra-bold-absolute-zero-32px'}
                   color={'#0A41D6'}>
            Criar tema
          </Heading>
          <VStack mt={'32px'}>
            <StepsImage text={'Sobre o tema'} number={1}/>
            <div className="cards">
              <div className="frame-2546">
                <div className="material-de-apoio-criar-tema publicsans-bold-nevada-16px">Imagem do tema</div>
                <ThemeImageUpload foto="Imagem do tema"/>
                <div
                  className={`${nameError ? 'error-form' : ''} nome-criar-atividade-step-1 publicsans-bold-nevada-16px`}>{'Título*'}</div>
                <Input h={'48px'} w={'100%'}
                       backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       value={criarTemaData.subject}
                       onChange={(e) => {
                         setNameError(false)
                         setCriarTemaData({ subject: e.target.value })
                       }}
                />
                <div
                  className={`${turmaError ? 'error-form' : ''} turmas-criar-tema publicsans-bold-nevada-16px`}>Turmas*
                </div>
                <Box w={'100%'} h={'48px'}>
                  <MultiSelect
                    placeholder={loading ? 'Aguarde um momento...' : 'Selecionar turma'}
                    onChange={(e) => {
                      setTurmaError(false)
                      setTurmaValue(e)
                    }}
                    borderColor={'#d7e3fb'}
                    backgroundColor={'white'}
                    isDisabled={loading}
                    options={turmaOptions}
                    value={turmaValue}
                    w={'100%'}
                  />
                </Box>
                <div className="atividade-descricao-container">
                  <div className="descricao-title publicsans-bold-nevada-16px">{'Descrição'}</div>
                  <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
                    <Textarea
                      placeholder=""
                      value={criarTemaData.description}
                      onChange={handleTextChange}
                      resize="vertical"
                      w={'100%'}
                      h={'100%'}
                      borderColor={'#d7e3fb'}
                    />
                    <Text
                      fontSize="sm"
                      position="absolute"
                      bottom="5px"
                      right="20px"
                      color="#a9a8a8"
                      fontFamily={'Public Sans'}
                    >
                      {setCriarTemaData.description ? setCriarTemaData.description.length : 0}/{maxCharacteres}
                    </Text>
                  </Box>
                </div>
                <div className="email-4-criar-tema">
                  <div className="material-de-apoio-criar-tema publicsans-bold-nevada-16px">Material de apoio</div>
                  <VStack width={'100%'}>
                    {supportMaterialList && <SupportMaterialList supportMaterialList={transformSupportMaterialList()} deleteType={'criarTema'} handleRemoveItem={handleRemoveItem}/>}
                    <Box className="upload-material-create-theme">
                      <label>
                        <Box className="frame-2600-create-theme">
                          <img
                            className="frame-5-detalhamento-tema"
                            src="/img/frame-5-2@2x.png"
                            alt="Frame 5"
                          />
                          <p className="text-1-detalhamento-tema publicsans-bold-white-14px-2">
                            <span className="publicsans-bold-absolute-zero-14px">Clique</span>
                            <span className="publicsans-normal-nevada-14px"> para adicionar um arquivo</span>
                          </p>
                        </Box>
                        <Input
                          type="file"
                          onChange={async (e) => {
                            handleUploadSupportMaterial(await handleFileChange(e, 'temaIdPlaceHolder'))
                          }}
                          display="none"
                        />
                      </label>
                    </Box>
                  </VStack>
                </div>
              </div>
            </div>
            <div className="steps">
              <FormStepNotSelected number={2} configuraesDaAtividade={'Enviar'}/>
            </div>
          </VStack>
          <div className="buttons">
            <BackStepButton handleReturn={handleReturn} text={'Voltar'}/>
            <ForwardButton forwardTo={handleSubmit} className={'button-10'} text={'Finalizar'}
                           buttonClassName={''}/>
          </div>
        </VStack>
      </VStack>
    </Flex>
  )
}

export default CriarTemaStep1
