import React from 'react'
import './IlustracaoLogin.css'

function IlustracaoLogin() {
  return (
        <div className="ilustrao-login">
            <img
                className="vector-3-login"
                src="/img/ilustracao-horizontal.svg"
                alt="Vector"
            />
        </div>
  )
}

export default IlustracaoLogin
