import React from 'react'
import './Frame2426.css'

function Frame2426(props) {
  const { aguardandoCorreo, number, className, folderIcon } = props

  return (
    <div className={`frame-24-1 ${className || ''}`}>
      <img className="folder-2851794-1" src={folderIcon} alt="folder-2851794" />
      <div className="x-corr publicsans-bold-nevada-14px">{aguardandoCorreo}</div>
      <div className="number-47 publicsans-extra-extra-bold-absolute-zero-32px">{number}</div>
    </div>
  )
}

export default Frame2426
