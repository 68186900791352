import React, { useState } from 'react'
import './CriarEscolaStep1.css'
import { Flex, Heading, Input, VStack } from '@chakra-ui/react'
import Headbar from '../Headbar'
import StepsImage from '../StepsImage'
import FormStepNotSelected from '../FormStepNotSelected'
import BackStepButton from '../BackStepButton'
import ForwardButton from '../ForwardButton'
import useCriarEscolaStore from "../../store/useCriarEscolaStore";
import {maskCNPJ} from "../../utils/cnpjFormatter";

function CriarEscolaStep1(props) {
  const { handleReturn, handlingNextStep } = props
  const { criarEscolaData, setCriarEscolaData } = useCriarEscolaStore()

  const [nomeEscolaError, setNomeEscolaError] = useState(false)
  const [documentError, setDocumentError] = useState(false)

  const handleSubmit = () => {
    if (criarEscolaData.name && criarEscolaData.document) {
      handlingNextStep()
    } else {
      setNomeEscolaError(!criarEscolaData.name)
      setDocumentError(!criarEscolaData.document)
    }
  }

  const handleCpnjInputChange = (event) => {
    const formattedValue = maskCNPJ(event.target.value)

    setCriarEscolaData({ document: formattedValue })
    setDocumentError(false)
  }

  return (
    <Flex h={'100%'} w={'100%'}>
      <VStack w={'100%'} h={'100%'}>
        <Headbar returnTo={'/area-de-escolas'} />
        <VStack marginTop={'107px'}>
          <Heading alignSelf={'start'} ml={'35px'} className={'publicsans-extra-extra-bold-absolute-zero-32px'}>
            Criar Escola
          </Heading>
          <VStack mt={'32px'}>
            <StepsImage text={'Sobre a Escola'} number={1} />
            <div className="cards">
              <div className="frame-2546">
                <div className={`${nomeEscolaError ? 'error-form' : ''} nome-criar-escola-step-1 publicsans-bold-nevada-16px`}>{'Nome da escola*'}</div>
                <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       onChange={(e) => {
                         if (e.target.value) {
                           setNomeEscolaError(false)
                         }
                         setCriarEscolaData({ name: e.target.value })
                       }}
                       defaultValue={criarEscolaData.name}
                />
                <div className="atividade-descricao-container">
                  <div className={`${documentError ? 'error-form' : ''} descricao-title publicsans-bold-nevada-16px`}>{'CNPJ*'}</div>

                  <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                         borderColor={'#d7e3fb'}
                         onChange={handleCpnjInputChange}
                         value={criarEscolaData?.document}
                  />
                </div>

              </div>
            </div>
            <div className="steps">
              <FormStepNotSelected number={2} configuraesDaAtividade={'Endereço'} />
              <FormStepNotSelected number={3} configuraesDaAtividade={'Criar'} />
            </div>
          </VStack>

          <div className="buttons">
            <BackStepButton handleReturn={handleReturn} text={'Voltar'} />
            <ForwardButton forwardTo={handleSubmit} className={'button-10'} text={'Continuar'} buttonClassName={''} />
          </div>
        </VStack>
      </VStack>
    </Flex>
  )
}

export default CriarEscolaStep1
