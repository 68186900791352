import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import './Redacao.css'
import Headbar from '../../components/Headbar'
import CommonLayout from '../../layouts/common'
import { Avatar, Box, Button, Center, Divider, Fade, Flex, Grid, GridItem, HStack, Heading, Img, Select, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { UploadArea } from '../../components/upload-area'
import { TextEditor } from '../../components/text-editor'
import { PencilIcon } from '../../components/icons/Pencil'
import { FileUploadIcon } from '../../components/icons/FileUpload'
import { getActivityByIdApi, listAllAcitivitiesFromSchool } from '../../apis/activitityApi/activityApi'
import { listSupportMaterialApi } from '../../apis/themeApi/supportMaterialApi'
import { createAvailableEssay, editExitingEssay, fetchEssay, submitEssayForCorrection } from '../../apis/essayApi/essayApi'
import { formatRelative } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Modal } from '../../components/modal'
import submitConfirmImage from '../../assets/submit-confirm.png'
import { ESSAY_STATUS } from '../CorrigirRedacao/utils'
import { ActivityDetails } from '../../components/activity-details'
import { base64ToBlob, toBase64 } from './utils'
import Page from '../Page'

const EssayCreation = () => {
  const search = useLocation().search
  const params = new URLSearchParams(search)
  const atv = params.get('atv')
  const id = params.get('id')
  const submitConfirmation = params.get('submitConfirmation')

  const navigate = useNavigate()

  const { isOpen: activityIsOpen, onOpen: activityOnOpen, onClose: activityOnClose } = useDisclosure()
  const { isOpen: submitIsOpen, onOpen: submitOnOpen, onClose: submitOnClose } = useDisclosure()
  const { isOpen: discartIsOpen, onOpen: discartOnOpen, onClose: discartOnClose } = useDisclosure()

  const [activities, setActivities] = useState([])
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [selectedActivityId, setSelectedActivityId] = useState(null)
  const [supportMaterials, setSupportMaterials] = useState([])
  const [essayContent, setEssayContent] = useState('')
  const [essay, setEssay] = useState(null)
  const [essayImageUrl, setEssayImageUrl] = useState(null)
  const [droppedImage, setDroppedImage] = useState(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const getActivityOptions = async () => {
    const activities = await listAllAcitivitiesFromSchool()

    setActivities(activities)
  }

  async function checkPreSelectedActivity() {
    if (atv) {
      const activity = await getActivityByIdApi(atv)
      setSelectedActivity(activity)
      setSelectedActivityId(atv)
    }
  }

  async function checkExitingEssay() {
    if (id && !essay) {
      const exitingEssay = await fetchEssay(id)
      setEssay(exitingEssay)
      setSelectedActivityId(exitingEssay.activity.id)
    }
  }

  async function getSupportMaterials() {
    if (selectedActivity) {
      const supportMaterials = await listSupportMaterialApi(selectedActivity.theme.id)
      setSupportMaterials(supportMaterials)
    }
  }

  async function generateEssayImageUrl() {
    const blob = await base64ToBlob(droppedImage.base64)
    const url = URL.createObjectURL(blob)
    console.log('url', url)
    setEssayImageUrl(url)
  }

  useEffect(() => {
    getActivityOptions()
    checkPreSelectedActivity()
    checkExitingEssay()
  }, [])

  useEffect(() => {
    getSupportMaterials()
  }, [selectedActivity])

  useEffect(() => {
    if (droppedImage) {
      generateEssayImageUrl()
    }
  }, [droppedImage])

  const canEdit = useMemo(() => !essay || (essay && essay.status === 10), [essay])

  function handleActivityChange(e) {
    const selectedActivityId = e.target.value
    setSelectedActivityId(selectedActivityId)

    const selectedActivity = activities.find(activity => activity.id === selectedActivityId)
    setSelectedActivity(selectedActivity)

    const currentPath = document.location.pathname
    navigate(`${currentPath}?atv=${selectedActivityId}`)
  }

  async function createDraftEssay() {
    try {
      setSaveLoading(true)
      const draftEssayId = await createAvailableEssay({
        activityId: selectedActivity.id,
        groupId: selectedActivity.groups[0].id,
        teacherId: '7fb9e752-e9a9-4f0f-a81c-0b8c4b81944e',
        draft: essayContent,
        attachment: droppedImage ? droppedImage.base64 : undefined,
        isText: !droppedImage
      })

      const newEssay = await fetchEssay(draftEssayId)
      setEssay(newEssay)
      setDroppedImage(null)

      const currentPath = document.location.pathname
      navigate(`${currentPath}?atv=${selectedActivityId}&id=${draftEssayId}`)
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  }

  async function editEssay() {
    try {
      setSaveLoading(true)
      await editExitingEssay(essay.id, essayContent)

      const newEssay = await fetchEssay(essay.id)
      setEssay(newEssay)
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  }

  async function submitEssay() {
    try {
      setSubmitLoading(true)
      await submitEssayForCorrection(essay.id)
      const newEssay = await fetchEssay(essay.id)
      setEssay(newEssay)
      submitOnClose()
      const currentPath = document.location.pathname
      navigate(`${currentPath}?atv=${selectedActivityId}&id=${essay.id}&submitConfirmation=true`)
    } catch (error) {
      console.log(error)
    } finally {
      setSubmitLoading(false)
    }
  }

  async function deleteEssay() {
    try {
      setDeleteLoading(true)
      //   await submitEssayForCorrection(essay.id)
      navigate('/area-de-atividades')
    } catch (error) {
      console.log(error)
    } finally {
      setDeleteLoading(false)
    }
  }

  async function onDropImage(files) {
    const file = files[0]

    const newDropedFile = {
      id: String(file.lastModified),
      preview: URL.createObjectURL(file),
      base64: await toBase64(file)
    }

    console.log('newDropedFile', newDropedFile)

    setDroppedImage(newDropedFile)
  }

  return (
    <Page authPage={true} allowedUserType={'student'} >
      <Stack spacing={0} height='100%'>
        <Headbar returnTo={'/area-de-atividades'} />
        {
          essay && submitConfirmation && essay.status === 20
            ? <Center height='100%'>
              <Stack textAlign='center'>
                <Img width='300px' height='auto' src={submitConfirmImage} />
                <Text fontSize='32px' color='brand.200'>Sua redação foi</Text>
                <Heading size='lg' color='brand.200'>enviada para correção!</Heading>
                <Button variant='primary' mt={12}>Ver redações</Button>
              </Stack>
            </Center>

            : <Grid templateColumns='repeat(12, 1fr)' height='100%' mt={20}>
              <GridItem colSpan={{ sm: 12, lg: 8 }}>
                <Stack spacing={canEdit ? 10 : 3} px={10} py={4}>
                  <Stack>
                    {
                      canEdit &&
                      <>
                        <Fade in={(!essay && essayContent) || droppedImage}>
                          <Flex justifyContent='space-between' height='24px'>
                            <Text fontSize='xs' color='#828282'>Nenhuma alteração foi salva ainda</Text>
                            <Button size='xs' isLoading={saveLoading} onClick={createDraftEssay}>Salvar como rascunho</Button>

                          </Flex>
                        </Fade>
                        {essay && <Fade in={essay}>
                          <Flex justifyContent='space-between' height='24px'>
                            <Text fontSize='xs' color='#828282'>Salvo pela última vez {formatRelative(new Date(essay.updatedAt), new Date(), { locale: ptBR })}</Text>
                            <Button size='xs' isLoading={saveLoading} onClick={editEssay} isDisabled={essay.draft === essayContent}>Salvar alterações</Button>
                          </Flex>
                        </Fade>}
                      </>
                    }

                    {
                      essayImageUrl || droppedImage
                        ? <Center height='520px' overflowY='auto' paddingTop={'300px'}>
                          <Box position='relative'>
                            <Box
                              width={730}
                              height={980}
                              backgroundImage={essayImageUrl || droppedImage.preview}
                              bgRepeat='no-repeat'
                              bgSize='contain'
                              bgPosition='center center'
                              borderRadius={10}
                            />
                          </Box>
                        </Center>
                        : canEdit
                          ? <TextEditor onChange={setEssayContent} essay={essay} />
                          : <Stack
                            backgroundColor='#fff'
                            borderRadius='12px'
                            padding='8px'
                            border='1px solid #F3F5FF'
                            boxShadow='0px 4px 16px 0px rgba(0, 0, 0, 0.04)'
                            height='480px'
                            alignItems='start'
                            mt={5}
                          >
                            <Text color='#828282' fontSize='16px'>{essay.draft}</Text>
                          </Stack>
                    }
                  </Stack>

                  {
                    !canEdit &&
                    <>
                      <Divider mt={2} color='#E3E3E3' />

                      <HStack spacing={5}>
                        <Heading size='md'>Corretor</Heading>
                        <HStack>
                          <Avatar name={essay.teacher.name} />
                          <Text size='xs'>{essay.teacher.name}</Text>
                        </HStack>
                      </HStack>
                    </>
                  }

                  {canEdit &&
                    <Stack spacing={9}>
                      <Heading size={'md'}>Envie uma foto da sua redação</Heading>
                      <UploadArea onDrop={onDropImage} />
                    </Stack>}

                  {
                    canEdit &&
                    <Grid templateColumns='repeat(12, 1fr)' height='100%' gap={5}>
                      <GridItem colSpan={6}>
                        <Button variant='outline' width='100%' onClick={discartOnOpen}>Descartar</Button>
                      </GridItem>

                      <GridItem colSpan={6}>
                        <Button variant='primary' width='100%' onClick={submitOnOpen}>Enviar redação</Button>
                      </GridItem>
                    </Grid>
                  }
                </Stack>
              </GridItem>

              <GridItem colSpan={{ sm: 12, lg: 4 }}>
                <Stack backgroundColor={'#fff'} height='100%' spacing={4} p={10}>
                  {essay && (<><Heading size='xs'>Status da redação</Heading>
                    <Center p={1} backgroundColor='rgba(249, 179, 70, 0.15)' borderRadius='8px' border='1px solid #F9B346'>
                      <Text fontSize={'sm'}>{ESSAY_STATUS[essay.status]}</Text>
                    </Center></>)}
                  <Heading size='xs'>Atividade</Heading>
                  {essay && <Text size='xs'>{essay.activity.name}</Text>}
                  {!essay && <Select
                    backgroundColor='#F9FAFF'
                    color='brand.100'
                    placeholder='Selecionar a atividade'
                    border='1px solid #F9FAFF'
                    borderRadius='8px'
                    variant='filled'
                    size='lg'
                    onChange={handleActivityChange}
                    value={selectedActivityId}
                  >
                    {
                      activities.map(activity => (
                        <option key={activity.id} value={activity.id}>{activity.subject}</option>
                      ))
                    }
                  </Select>}
                  {
                    atv && selectedActivity && <Stack spacing={3}>
                      <Heading size='xs'>Tema</Heading>
                      <Text size='xs'>{selectedActivity.theme.name}</Text>
                      <HStack my={4}>
                        <PencilIcon />
                        <Stack>
                          <Heading size='xs'>Descrição da atividade</Heading>
                          <Text size='xs' color='#828282'>Entenda um pouco mais sobre a atividade</Text>
                        </Stack>
                      </HStack>

                      <Text size='xs'>{selectedActivity.description}</Text>

                      <HStack p='16px' borderRadius='12px' backgroundColor='#F3F5FF' my={5}>
                        <FileUploadIcon />
                        <Text size='xs' color='#828282'>{supportMaterials.length || 'Nenhum'} materia{supportMaterials.length > 1 ? 'is' : 'l'} de apoio em anexo</Text>
                      </HStack>

                      <Heading size='xs' color='#2578F4' cursor='pointer' onClick={activityOnOpen}>Ver mais</Heading>

                      {
                        canEdit &&
                        <>
                          <Divider my={7} color='#E3E3E3' />

                          <Heading size='xs'>Corretor</Heading>

                          {essay ? <HStack>
                            <Avatar name={essay.teacher.name} />
                            <Text size='xs'>{essay.teacher.name}</Text>
                          </HStack>
                            : <Text>Nenhum corretor selecionado</Text>
                          }
                        </>
                      }

                    </Stack>
                  }
                </Stack>
              </GridItem>

            </Grid>
        }
      </Stack>

      {
        canEdit &&
        <>
          <Modal
            title={'Enviar redação'}
            isOpen={submitIsOpen}
            onOpen={submitOnOpen}
            onClose={submitOnClose}
            onSubmit={submitEssay}
            isLoading={submitLoading}
            submitLabel={'Enviar para correção'}
            body={'Uma vez que a redação é submetida para correção, você não poderá mais alterá-la. Deseja continuar?'}
          />
          <Modal
            title={'Descartar redação'}
            isOpen={discartIsOpen}
            onOpen={discartOnOpen}
            onClose={discartOnClose}
            onSubmit={deleteEssay}
            isLoading={deleteLoading}
            submitLabel={'Descartar'}
            body={'A redação será excluida e essa operação não pode ser desfeita. Deseja continuar?'}
          />

        </>
      }
      {essay && <Modal
        title={essay.activity.name}
        isOpen={activityIsOpen}
        onClose={activityOnClose}
        body={<ActivityDetails />}
        size='3xl'
      />}
    </Page>
  )
}

EssayCreation['Layout'] = CommonLayout

export default EssayCreation
