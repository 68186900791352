import React, {useState} from 'react'
import './CriarEscolaStep2.css'
import BackStepButton from '../BackStepButton'
import ForwardButton from '../ForwardButton'
import Headbar from '../Headbar'
import {Flex, Heading, HStack, Input, VStack} from '@chakra-ui/react'
import FormStepNotSelected from '../FormStepNotSelected'
import StepsImage from '../StepsImage'
import 'react-datepicker/dist/react-datepicker.css'
import useCriarEscolaStore from "../../store/useCriarEscolaStore";
import {maskCNPJ} from "../../utils/cnpjFormatter";

function CriarEscolaStep2(props) {
  const {handleReturn, handlingNextStep} = props

  const [loading, setLoading] = useState(true)

  const {criarEscolaData, setCriarEnderecoEscolaData} = useCriarEscolaStore()

  const [ruaError, setRuaError] = useState(false)
  const [numeroError, setNumeroError] = useState(false)
  const [cepError, setCepError] = useState(false)
  const [cidadeError, setCidadeError] = useState(false)
  const [estadoError, setEstadoError] = useState(false)

  const handleSubmit = () => {
    if ((criarEscolaData.address.street && criarEscolaData.address.number
      && criarEscolaData.address.zipCode && criarEscolaData.address.city
      && criarEscolaData.address.state)) {
      handlingNextStep()
    } else {
      setRuaError(!criarEscolaData.address.street)
      setNumeroError(!criarEscolaData.address.number)
      setCepError(!criarEscolaData.address.zipCode)
      setCidadeError(!criarEscolaData.address.city)
      setEstadoError(!criarEscolaData.address.state)
    }
  }

  const formatCep = (value) => {
    // Remove qualquer caracter não numérico
    value = value.replace(/\D/g, "");
    if (value.length > 5) {
      value = value.slice(0, 5) + "-" + value.slice(5, 8);
    }
    return value;
  };

  const handleCepChange = (e) => {
    let value = e.target.value;

    value = formatCep(value);

    if (value) {
      setCepError(false);
    }

    // Atualiza o valor no estado da sua aplicação
    setCriarEnderecoEscolaData({ zipCode: value });
  };

  return (
    <Flex h={'100%'} w={'100%'}>
      <VStack w={'100%'} h={'100%'}>
        <Headbar returnTo={'/area-de-escolas'}/>
        <VStack marginTop={'107px'}>
          <Heading alignSelf={'start'} ml={'35px'}
                   className={'publicsans-extra-extra-bold-absolute-zero-32px'}>Criar escola</Heading>
          <VStack mt={'32px'}>
            <div className="concluido-step-2">
              <img className="frame-2482-step-2" src="/img/frame-2482@2x.png" alt="Frame 2482"/>
              <div className="frame-2486-step-2">
                <div className="text-step-2 publicsans-normal-gray-16px">
                  Sobre a escola
                </div>
                <div className="atividade-unidade-2-step-2 publicsans-normal-nevada-20px">
                  {criarEscolaData.name}
                </div>
              </div>
            </div>
            <div className="cards-step-2-container">
              <StepsImage text={'Endereço'} number={2}/>
              <div className="cards-step-2">
                <HStack width={'100%'}>
                  <VStack width={'100%'} alignItems={'start'}>
                    <div className={`${ruaError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                      Rua
                    </div>

                    <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                           borderColor={'#d7e3fb'}
                           onChange={(e) => {
                             if (e.target.value) {
                               setRuaError(false)
                             }
                             setCriarEnderecoEscolaData({street: e.target.value})
                           }}
                           defaultValue={criarEscolaData.address.street}
                           maxLength={150}
                    />
                  </VStack>

                  <VStack width={'30%'} alignItems={'start'}>
                    <div className={`${numeroError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                      Número
                    </div>
                    <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                           borderColor={'#d7e3fb'}
                           onChange={(e) => {
                             if (e.target.value) {
                               setNumeroError(false)
                             }
                             setCriarEnderecoEscolaData({number: e.target.value})
                           }}
                           defaultValue={criarEscolaData.address.number}
                           maxLength={10}
                    />
                  </VStack>

                </HStack>

                <div className={`${cepError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                  CEP
                </div>
                <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       onChange={handleCepChange}
                       value={criarEscolaData?.address?.zipCode}
                       maxLength={9}
                />
                <div className={`${cidadeError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                  Cidade
                </div>
                <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       onChange={(e) => {
                         if (e.target.value) {
                           setCidadeError(false)
                         }
                         setCriarEnderecoEscolaData({city: e.target.value})
                       }}
                       defaultValue={criarEscolaData.address.city}
                       maxLength={50}
                />
                <div className={`${estadoError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                  Estado
                </div>
                <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       onChange={(e) => {
                         if (e.target.value) {
                           setEstadoError(false)
                         }
                         setCriarEnderecoEscolaData({state: e.target.value})
                       }}
                       defaultValue={criarEscolaData.address.state}
                       maxLength={50}
                />

              </div>
            </div>
            <div className={'steps-step-2'}>
              <FormStepNotSelected number="3" configuraesDaAtividade="Criar"/>
            </div>
          </VStack>
          <div className="buttons">
            <BackStepButton handleReturn={handleReturn} text={'Voltar'}/>
            <ForwardButton
              forwardTo={handleSubmit}
              className={'button-10'}
              text={'Enviar'}
              buttonClassName={''}
            />
          </div>

        </VStack>
      </VStack>
    </Flex>
  )
}

export default CriarEscolaStep2
