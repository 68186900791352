import React, { useEffect, useState } from 'react'
import TitleLink from '../TitleLink'
import './EscolaCard.css'
import Button22 from '../Button22'
import Data from '../Data'

function EscolaCard(props) {
  const { itemData } = props

  const currentDate = new Date()
  const deadLineDate = new Date(itemData.deadline)
  const startDate = new Date(itemData.validFrom)
  const startDateText = currentDate >= startDate ? 'Iniciou em:' : 'Inicia em:'

  const [controleRedacao] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      handleRender()
    }

    fetchData()
  }, [])

  function handleRender() {
    // controleRedacoes()
  }

  return (
    <div className="turma-02">
      <TitleLink titleContent={itemData.name} subTitleContent={itemData.address ? itemData.address.city + ' - ' +  itemData.address.state : 'Não informado'} subTitleName={'Cidade - UF'} />
      <div className="escola-card-body-style">
        {controleRedacao}
        {/*<img*/}
        {/*  className="line-97-1"*/}
        {/*  src={'/img/line-97-1@2x.png'}*/}
        {/*  alt="Line 97"*/}
        {/*/>*/}
        <div className="frame-258-1">
          <div className="frame-2584-1">
            <Button22
              mainbutton2Props={{
                className: '',
                contentProps: { children: 'Ver detalhes' }
              }}
              linkTo={'/detalhamento-escola/' + itemData.id}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EscolaCard
