import '../../../App.css'
import './LandingPage.css'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'react-router-dom'
import { css, keyframes } from '@emotion/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'

const LandingPage = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://getlaunchlist.com/js/widget-diy.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const [openFAQ, setOpenFAQ] = useState(null)

  const handleToggle = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex)
  }

  const bg = useColorModeValue('gray.200', 'gray.800')

  return (
    <>
       {/* <Flex */}
       {/* position="absolute" */}
       {/* zIndex="999" */}
       {/* bg="#01204C" */}
       {/* width={'auto'} */}
       {/* right={'32px'} */}
       {/* top={'16px'} */}
       {/* > */}
       {/* <Link style={{ textDecoration: 'none', background: 'none' }} to={'/login'}> */}
       {/*   <div className="content-2-login"> */}
       {/*     <div className="main-button-2-login publicsans-bold-absolute-zero-14px"> */}
       {/*       {'Entrar'} */}
       {/*     </div> */}
       {/*     <img className="buttonicon-login" src={'/img/ic-login.svg'} alt={'entrar icon'}/> */}
       {/*   </div> */}
       {/* </Link> */}
       {/* </Flex> */}

      <VStack spacing={0} align="stretch">
        {/* Seção 1 */}
        <Flex
          id="section1"
          className={'section-1'}
        >
          <HStack width={'100%'} height={'100%'}>
            <Section1/>
            <Image src={'/img/landingpage-2.svg'} width={'50%'} flex={1} className={'landingpage-image'} alt={'imagem-landingpage'}/>
          </HStack>
        </Flex>

        {/* Seção 2 */}
        <Box
          id="section2"
          minHeight="105vh"
          bg={bg}
          d="flex"
          alignItems="center"
          justifyContent="start"
          flexDirection="column"
          paddingBottom={'32px'}
          paddingLeft={'32px'}
          paddingRight={'32px'}
          backgroundColor={'#21FDBB'}
        >
          <Flex height={'100%'} width={'100%'}>
            <VStack height={'100%'} width={'100%'} justifyContent={'start'} marginTop={'5%'}>
              <Box marginBottom={'5%'} justifyContent={'start'}>
                <Heading className={'section-2-heading'}>POR QUE ESCOLHER O CORREÇÃO
                  ONLINE? </Heading>
                <Text className={'section-2-text'}>Benefícios exclusivos para professores e gestores
                  educacionais:</Text>
              </Box>
              <HStack width={'100%'} justifyContent={'center'} gap={'32px'} className={'sections-style'}>
                <Image src={'/img/secao2-img1.svg'} flex={1} maxWidth={'450px'} alt={'correcao-online-vantagens-1'}></Image>
                <Image src={'/img/secao2-img2.svg'} flex={1} maxWidth={'450px'} alt={'correcao-online-vantagens-2'}></Image>
                <Image src={'/img/secao2-img3.svg'} flex={1} maxWidth={'450px'} alt={'correcao-online-vantagens-3'}></Image>
              </HStack>
            </VStack>
            {/* <Image src={'/img/section2.svg'} height={'75%'} width={'100%'} alignSelf={'center'} */}
            {/*       marginTop={'70px'}/> */}
          </Flex>
        </Box>

        {/* Seção 3 */}
        <Box
          id="section3"
          minHeight="110vh"
          bg={bg}
          d="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          paddingBottom={'32px'}
          backgroundColor={'#21FDBB'}
        >
          <Section3/>
        </Box>

        {/* SEÇÃO 4 */}

        <Box
          id="section4"
          minHeight="105vh"
          bg={bg}
          d="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          backgroundColor={'#01204C'}
          paddingBottom={'32px'}
        >
          <Section4/>
        </Box>

        {/* SEÇÃO 5 */}
        <Box
          id="section5"
          minHeight="110vh"
          bg={bg}
          d="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          paddingBottom={'15%'}
          backgroundColor={'#21FDBB'}
        >
          <VStack height={'100%'} justifyContent={'start'}>
            <VStack width={'80%'} alignItems={'start'} marginTop={'5%'}>
              <Heading className={'section5-header'}>PLANOS DO CORREÇÃO ONLINE</Heading>
              <Text className={'section5-text'}>Escolha a Opção Ideal para Você:</Text>
            </VStack>
            <HStack width={'70%'} justifyContent={'center'} marginTop={'5%'} className={'sections-style section5-images'}>
              <Image src={'/img/section5-1.svg'} maxWidth={'450px'} flex={1} marginRight={'32px'} alt={'correcao-online-precos-1'}/>
              <Image src={'/img/section5-2.svg'} maxWidth={'450px'} flex={1} marginRight={'16px'} alt={'correcao-online-precos-2'}/>
              <Image src={'/img/section5-3.png'} width={'61px'} height={'61px'} alt={'correcao-online-precos-plus'}/>
              <Image src={'/img/section5-4.svg'} maxWidth={'450px'} flex={1} marginRight={'32px'} alt={'correcao-online-precos-3'}/>
            </HStack>
            <Text className={'section5-text2'}>Estamos preparados para atender demandas do setor público! Entre em
              contato para mais informações.</Text>
          </VStack>
        </Box>

        {/* SEÇÃO 6 */}

        <Box
          id="section6"
          minHeight="100vh"
          bg={bg}
          d="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          backgroundColor={'#21FDBB'}
          paddingBottom={'32px'}
        >
          <VStack height={'100%'} justifyContent={''}>
            <Heading className={'faq-header'}>FAQ - Perguntas Frequentes</Heading>
            <HStack width={'80%'}>
              <VStack flex={1} alignSelf={'start'} marginTop={'2%'}>
                <FAQItem question="O que é o Correção Online e como ele pode me beneficiar?"
                         isOpen={openFAQ === 0}
                         onToggle={() => handleToggle(0)}
                         answer={
                           <>
                             O Correção Online não é apenas uma plataforma, é a solução definitiva para professores que
                             desejam otimizar o processo de <Box as="span" fontWeight={'700'}>solicitação</Box>, <Box
                             as="span" fontWeight={'700'}>recebimento</Box>, <Box as="span"
                                                                                  fontWeight={'700'}>correção</Box> e <Box
                             as="span" fontWeight={'700'}>avaliação</Box> de redações. Transforme sua maneira de ensinar
                             e interagir com seus alunos através da nossa tecnologia inovadora.
                           </>
                         }/>
                <FAQItem
                  question="O Correção Online visa substituir o papel do professor?"
                  isOpen={openFAQ === 5}
                  onToggle={() => handleToggle(5)}
                  answer={
                    <>
                      De forma alguma! O Correção Online foi projetado para ser um aliado do professor, facilitando e
                      agilizando a correção de redações. Enquanto nossa assistente oferece sugestões para melhorar os
                      textos, a decisão final e a expertise sempre pertencem ao educador.
                    </>
                  }
                />

                <FAQItem question="Como posso cadastro turmas e alunos de forma prática?"
                         isOpen={openFAQ === 1}
                         onToggle={() => handleToggle(1)}
                         answer={
                           <>
                             Com o Correção Online, você tem a flexibilidade de cadastrar suas turmas de duas maneiras:
                             <ul>
                               <li><Box as="span" fontWeight={'700'}>Manualmente:</Box> Simplesmente crie sua turma e
                                 adicione os e-mails dos alunos.
                               </li>
                               <li><Box as="span" fontWeight={'700'}>Importação via Excel:</Box> Ideal para quem tem um
                                 grande número de alunos, facilitando a inclusão de todos de uma só vez.
                               </li>
                             </ul>
                           </>
                         }/>
                <FAQItem question="Sou professor particular. O Correção Online é para mim?"
                         isOpen={openFAQ === 2}
                         onToggle={() => handleToggle(2)}
                         answer={
                           <>
                             Absolutamente! O Correção Online é a chave para elevar seu ensino particular a <Box
                             as="span" fontWeight={'700'}>outro nível</Box>. Além de ser uma poderosa ferramenta
                             competitiva, nosso sistema auxilia na <Box as="span"
                                                                        fontWeight={'700'}>organização</Box> e <Box
                             as="span" fontWeight={'700'}>economia de tempo</Box>. Possibilitando que você atenda mais
                             alunos sem comprometer a excelência no ensino.
                           </>
                         }/>
                <FAQItem question="É seguro confiar meus documentos ao Correção Online?"
                         isOpen={openFAQ === 3}
                         onToggle={() => handleToggle(3)}
                         answer={
                           <>
                             Sua segurança é nossa prioridade! O Correção Online segue rigorosamente a Lei Geral de
                             Proteção de Dados Pessoais (<Box as="span" fontWeight={'700'}>LGPD</Box>) e emprega
                             tecnologias avançadas para garantir a integridade e confidencialidade de todos os arquivos
                             enviados.
                           </>
                         }/>
                <FAQItem question="Posso confiar no Correção Online para detectar plágios?"
                         isOpen={openFAQ === 4}
                         onToggle={() => handleToggle(4)}
                         answer={
                           <>
                             Com certeza! Estamos na vanguarda da tecnologia educacional com um assistente que
                             utiliza <Box as="span" fontWeight={'700'}>inteligência artificial</Box> para identificar
                             textos copiados da internet. Dê adeus às incertezas e garanta a originalidade dos trabalhos
                             dos seus alunos em segundos.
                           </>
                         }/>
              </VStack>
              <Image width={'50%'} src={'/img/ladingpage-faq.png'} className={'FAQ-image'} alt={'correcao-online-FAQ'}/>
            </HStack>
          </VStack>
        </Box>

        {/* SEÇÃO 7 */}

        <Flex
          id="section7"
          minHeight="50vh"
          bg={bg}
          d="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          paddingBottom={'32px'}
          backgroundColor={'#01204C'}
        >
          <HStack height={'100%'} width={'100%'}>
            <Flex flex={1} align="center" justify="center" height={'100%'}>
              <Image
                className="responsive-logo"
                src="/img/logo-versao-escura.png"
                alt="logo"
                objectFit="contain"
              />
            </Flex>
            <VStack flex={1} height={'100%'}>
              <Heading className={'section7-text1'}>Fale conosco</Heading>
              <Text className={'section7-text2'}>suporte@correcao-online.com</Text>
              <Text className={'section7-text2'} marginTop={'6%'}>Siga nas redes sociais</Text>
              <HStack gap={'32px'}>
                <Image src={'/img/linkedin-icon.svg'}
                       width={'45px'}
                       height={'45px'}
                       alt={'correcao-online-linkedin'}
                       onClick={() => {
                         window.open('https://www.linkedin.com/company/corre%C3%A7%C3%A3o-online')
                       }}
                       cursor={'pointer'}/>
                <Image src={'/img/instagram-icon.svg'}
                       width={'45px'}
                       height={'45px'}
                       alt={'correcao-online-instagram'}
                       onClick={() => {
                         window.open('https://www.instagram.com/correcao_online/')
                       }}
                       cursor={'pointer'}/>
              </HStack>
            </VStack>
          </HStack>
        </Flex>

      </VStack>
    </>
  )
}

function Section1() {
  const slideFromLeft = keyframes`
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  `

  return (
    <VStack
      flex="1"
      className={'section-1-style'}
      spacing={4}
    >
      <Image
        className="responsive-logo"
        src="/img/logo-recortada.svg"
        alt="logo"
        maxWidth={'80%'}
        animation={`${slideFromLeft} 1s ease forwards`}
        animationDelay="0.5s"
      />

      <Text
        className="responsive-text-1"
        color={'#05FFB3'}
        fontWeight={'semibold'}
        animation={`${slideFromLeft} 1s ease forwards`}
        animationDelay="1s"
      >
        Menos tempo corrigindo, mais tempo educando.
      </Text>

      <Text
        className="responsive-text-2"
        color={'white'}
        fontWeight={'650'}
        width={'85%'}
        animation={`${slideFromLeft} 1s ease forwards`}
        animationDelay="1s"
        marginTop={'3%'}>Bem-vindo ao
        Correção Online, a plataforma líder em correção de textos para educadores. </Text>

      <Text className={'responsive-text-3'}
            color={'white'}
            fontWeight={'400'}
            width={'85%'}
            animation={`${slideFromLeft} 1s ease forwards`}
            animationDelay="1s"
            marginTop={'4%'}>
        Descubra como tornar o correção de redações mais eficiente!
      </Text>

      <ScrollLink to="section4" smooth={true} duration={500}>
        <Button
          className="responsive-button"
          backgroundColor={'#05FFB3'}
          color={'#01204C'}
          borderRadius={'40px'}
          animation={`${slideFromLeft} 1s ease forwards`}
          animationDelay="1s"
          fontWeight={'700'}
        >
          <Link>Tenho Interesse!</Link>
        </Button>
      </ScrollLink>

    </VStack>
  )
}

function Section3() {
  const [currentImage, setCurrentImage] = useState('/img/imagem-computador-landingpage.png')
  const [animate, setAnimate] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleImageChange = (imageSrc) => {
    setAnimate('slideRight')
    setTimeout(() => {
      setCurrentImage(imageSrc)
      setAnimate('fadeIn')
    }, 400)
  }

  const slideRight = keyframes`
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  `

  const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `

  const animationStyles = {
    slideRight: css`
      animation: 0.4s ${slideRight} forwards;
    `,
    fadeIn: css`
      animation: 0.4s ${fadeIn} forwards;
    `
  }

  return (
    <HStack width={'100%'} height={'100%'} className={'sections-style'} paddingRight={'10px'}>
      <Flex alignSelf={'start'} flex={1}>
        <VStack alignItems={'start'} marginLeft={'5%'}>
          <Heading color={'#01204C'}
                   className={'responsive-text-2-section-3'}
          > RECURSOS DESTACADOS </Heading>
          <Text color={'#01204C'}
                className={'responsive-text-1-section-3'}
                marginTop={'3%'}>
            Nossas ferramentas e recursos são projetados para <Box as="span" fontWeight={'700'}>facilitar a vida
            dos
            educadores.</Box> São recursos que <Box as="span" fontWeight={'700'}>auxiliam</Box> o professor e
            não
            substituem o seu trabalho!</Text>
          <Flex width={'100%'} height={'50%'}>
            <Box onClick={onOpen}>
              <Image src={currentImage} width={'100%'} height={'100%'} marginTop={'20px'}
                     marginBottom={'30px'} css={animationStyles[animate]} cursor={'pointer'} alt={'correcao-online-preview'}/>
            </Box>
          </Flex>

          <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay/>
            <ModalContent>
              <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                <Image src={currentImage} alt={'correcao-online-preview-zoom'}/>
              </Box>
              <Box position="absolute" top="10px" right="10px">
                <Button onClick={onClose}>Fechar</Button>
              </Box>
            </ModalContent>
          </Modal>
        </VStack>
      </Flex>
      <Flex flex={1} alignSelf={'start'} justifyContent={'center'}>
        <VStack className={'section-3-style'} marginTop={'1%'} alignItems={'start'}>

          <Heading color={'#01204C'} fontSize={'3.5vh'}>Correção Remota</Heading>
          <Text color={'#01204C'} fontSize={'3vh'} fontWeight={'400'}>
            Com o Correção Online, professores podem revisar redações à distância, destacando e detalhando os pontos a
            melhorar dos alunos.
          </Text>
          <Text color={'#5479F7'} fontSize={'2.5vh'} cursor={'pointer'}
                onClick={() => handleImageChange('/img/redacao-finalizada.svg')}>Clique para ver!</Text>

          <Divider borderWidth="1px" marginTop={'1%'}/>

          <Heading color={'#01204C'} fontSize={'3.5vh'} marginTop={'30px'}>Organização
            Simplificada</Heading>
          <Text color={'#01204C'} fontSize={'3vh'} fontWeight={'400'}>
            Mantenha atividades de suas turmas em ordem e fique por dentro das datas de entrega.
          </Text>

          <Text color={'#5479F7'} fontSize={'2.5vh'} cursor={'pointer'}
                onClick={() => handleImageChange('/img/area-de-atividades.svg')}>Clique para ver!</Text>

          <Divider borderWidth="1px" marginTop={'1%'}/>

          <Heading color={'#01204C'} fontSize={'3.5vh'} marginTop={'30px'}>Assistente IA</Heading>
          <Text color={'#01204C'} fontSize={'3vh'} fontWeight={'400'}>
            Identifica automaticamente melhorias ortográficas e semânticas nos textos dos alunos.
          </Text>
          <Text color={'#5479F7'} fontSize={'2.5vh'} cursor={'pointer'}
                onClick={() => handleImageChange('/img/ia-sugestao.svg')}>Clique para ver a sugestão!</Text>
          <Text color={'#5479F7'} fontSize={'2.5vh'} cursor={'pointer'}
                onClick={() => handleImageChange('/img/ia-deteccao-plagio.svg')}>Clique para ver o detector de
            plágio!</Text>
        </VStack>
      </Flex>
    </HStack>
  )
}

function Section4() {
  return (
    <HStack width={'100%'} height={'100%'} alignItems={'start'} className={'sections-style'}>
      <Flex width={'100%'} flex={1}>
        <VStack width={'100%'} alignItems={'start'} marginLeft={'5%'} marginTop={'10%'}>
          <Heading color={'#05FFB3'} fontSize={'4vh'}>Entre em contato agora!</Heading>
          <Divider color={'#05FFB3'} borderWidth={'2px'} marginTop={'2%'} width={'85%'}/>
          <Text className={'section-4-text-style'}>30 Dias Grátis:
            <Box as={'span'} color={'white'} fontWeight={'400'}> Registre-se agora e desfrute de um mês gratuito
              do Correção Online, sem compromisso.</Box></Text>

          <Text className={'section-4-text-style'}>Descontos Exclusivos:
            <Box as={'span'} color={'white'} fontWeight={'400'}> Além disso, os membros da nossa lista de
              interesse recebem descontos exclusivos em planos premium.</Box></Text>

          <Text className={'section-4-text-style'}>Fique por dentro das novidades:
            <Box as={'span'} color={'white'} fontWeight={'400'}> Você será um dos primeiros a ficar sabendo das
              novidades da nossa plataforma!</Box></Text>
        </VStack>
      </Flex>
      <Flex flex={1} alignSelf={'center'}
            height={'100%'}
            alignItems={'center'}
            marginBottom={'8%'}
            className={'form-style'}
            justifyContent={'center'}
            width={'100%'}>
        <form action="https://getlaunchlist.com/s/ysV8TS" method="POST"
              style={{ maxWidth: '95%' }}>
          <VStack height={'100%'}
                  backgroundColor={'#05FFB3'}
                  className="launchlist-form"
                  padding={'32px 32px 32px 32px'}
                  borderRadius={'30px'}
                  alignItems={'start'}>
            <Text color={'#01204C'} className={'responsive-text-form'} fontWeight={'700'}>Garanta seu
              acesso grátis!</Text>
            <Text color={'#01204C'} className={'responsive-text-form-2'} fontWeight={'500'}>Invista seu
              tempo em educação de qualidade!</Text>
            <VStack width={'100%'} gap={'16px'} marginTop={'4%'}>
              <FormControl isRequired>
                <FormLabel fontSize={'2.5vh'} marginLeft={'15px'}
                           color={'#01204C'}>Nome</FormLabel>
                <Input required name="nome" type="text" backgroundColor={'white'} height={'48px'}
                       border={'none'} borderRadius={'30px'} fontSize={'2.5vh'}/>
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize={'2.5vh'} marginLeft={'15px'}
                           color={'#01204C'}>Email</FormLabel>

                <Input required name="email" type="email" backgroundColor={'white'} height={'48px'}
                       border={'none'} borderRadius={'30px'} fontSize={'2.5vh'}/>
              </FormControl>

              <FormControl>
                <FormLabel fontSize={'2.5vh'} marginLeft={'15px'}
                           color={'#01204C'}>Telefone</FormLabel>
                <Input name="telefone" type="text" backgroundColor={'white'} height={'48px'} border={'none'}
                       borderRadius={'30px'} fontSize={'2.5vh'}/>
              </FormControl>

              <FormControl>
                <FormLabel fontSize={'2.5vh'} marginLeft={'15px'}
                           color={'#01204C'}>Instituição</FormLabel>
                <Input name="instituicao" type="text" backgroundColor={'white'} height={'48px'} border={'none'}
                       borderRadius={'30px'} fontSize={'2.5vh'}/>
              </FormControl>
            </VStack>
            <Button type="submit"
                    className="responsive-button-cadastro"
                    backgroundColor={'#01204C'}
                    color={'white'}
                    borderRadius={'40px'}
                    alignSelf={'center'}
                    height={'48px'}
                    marginTop={'10% !important'}
                    fontWeight={'700'}>QUERO ME CADASTRAR</Button>
          </VStack>
        </form>
      </Flex>
    </HStack>
  )
}

function FAQItem({ question, answer, isOpen, onToggle }) {
  return (
    <VStack align="start" width="100%" alignItems={'center'}>
      <Button backgroundColor={'#01204C'}
              color={'white'}
              borderRadius={'40px'}
              onClick={onToggle}
              className={'faq-button'}>
        <HStack justifyContent="space-between" width="100%">
          <Text paddingLeft={'4%'} textAlign={'start'} className={'faq-question'}>{question}</Text>
          <Icon marginRight={'4%'} as={isOpen ? MinusIcon : AddIcon}/>
        </HStack>
      </Button>
      <Collapse in={isOpen} animateOpacity style={{ textAlign: '-webkit-center' }}>
        <Flex className={'faq-answer-container'}>
          <Text color={'#01204C'} className={'faq-answer'}>{answer}</Text>
        </Flex>
      </Collapse>
    </VStack>
  )
}

export default LandingPage
