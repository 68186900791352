import React from 'react'
import ReactDOM from 'react-dom/client'
import './globals.css'
import './styleguide.css'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('app'))
root.render(
  <App/>
)

// ReactDOM.render(<App />, document.getElementById("app"));
