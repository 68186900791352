import './AreaDeInstituicoes.css'
import Headbar from '../../components/Headbar'
import Menu from '../../components/Menu'
import { Link } from 'react-router-dom'
import SharedCardPagination from '../../components/SharedCardPagination'
import { useEffect, useState } from 'react'
import { listAllInstitutionsFromSchool } from '../../apis/institutionApi/institutionApi'
import InstitutionCard from '../../components/InstitutionCard'
import Page from '../Page'

function AreaDeInstitutions() {
  const itemsPerPage = window.innerWidth > 2390 ? 9 : 10

  const [institutions, setInstitutions] = useState()
  const [institutionsData, setInstitutionsData] = useState()

  useEffect(() => {
    fetchAllInstitutions()
  }, [])

  async function fetchAllInstitutions() {
    const data = await listAllInstitutionsFromSchool()
    setInstitutionsData(data)
  }

  useEffect(() => {
    setInstitutions(institutionsData)
  }, [institutionsData])

  function handleSearchInputChange(e) {
    const inputText = e.target.value

    setInstitutions(
      institutionsData.filter((item) => {
        return item.name.toLowerCase().includes(inputText.toLowerCase())
      })
    )
  }

  return (
    <div className="area-de-institutions screen-area-de-institutions">
      <Page authPage={true} allowedUserType={'teacher monitor independentteacher'}>
        <Menu/>
        <Headbar/>
        <div className={'area-de-institutions-body'}>
          <div className="search-filter-area-de-institutions">
            <div className="title-cta-area-de-institutions">
              <h1 className="rea-de-institutions-area-de-institutions publicsans-normal-absolute-zero-40px">Área de
                Instituições</h1>
              <Link to={'/criar-instituicao'}>
                <div className="cta-criar-atividade-area-de-institutions">
                  <div className="button-1-area-de-institutions">
                    <div className="mainbutton-1-area-de-institutions">
                      <div className="frame-29-area-de-institutions">
                        <img className="ic-add-area-de-institutions" src="/img/ic-add-6.svg" alt="ic-add"/>
                        <div
                          className="criar-institution-area-de-institutions valign-text-middle-area-de-institutions inter-bold-zircon-14px">
                          Criar Instituição
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="search-filter-1-area-de-institutions">
          <div className="search-filtro-area-de-institutions">
            <div className="search-area-de-institutions">
              <img className="search-2851823-area-de-institutions" src="/img/search-2851823@2x.png"
                   alt="search-2851823"/>
              <input
                className="text-search-input-area-atividades publicsans-normal-nevada-16px"
                placeholder={'Buscar Instituições...'}
                type={'search'}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
        </div>
        <div className="area-de-instituicoes-body">
          {institutions ? <SharedCardPagination data={institutions} RenderComponent={InstitutionCard}
                                                itemsPerPage={itemsPerPage} props={{ fetchAllInstitutions }}/> : null}
        </div>
      </Page>
    </div>
  )
}

export default AreaDeInstitutions
