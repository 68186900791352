import './App.css'
import React from 'react'
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom'
import AreaDeAtividades from './src/pages/AreaDeAtividades'
import InicioProfessor from './src/pages/InicioProfessor'
import Index from './src/pages/LandingPage'
import LoginPage from './src/pages/Login'
import CriarAtividade from './src/pages/CriarAtividade'
import DetalhamentoAtividade from './src/pages/DetalhamentoAtividade'
import AreaDeTurmas from './src/pages/AreaDeTurmas'
import CriarTurma from './src/pages/CriarTurma'
import DetalhamentoTurma from './src/pages/DetalhamentoTurma'
import DetalhamentoAluno from './src/pages/DetalhamentoAluno'
import AreaDeTemas from './src/pages/AreaDeTemas'
import CriarTema from './src/pages/CriarTema'
import DetalhamentoTema from './src/pages/DetalhamentoTema'
import CadastroPage from './src/pages/CadastroPage'
import RecuperarSenha from './src/pages/RecuperarSenha'
import RecuperacaoSenhaConfirmacao from './src/pages/RecuperacaoSenhaConfirmacao'
import { ChakraProvider, useToast } from '@chakra-ui/react'
import { UITema } from './src/theme'
import EssayCorrection from './src/pages/CorrigirRedacao'
import EssayCreation from './src/pages/CriarRedacao'
import AreaDeInstituicoes from './src/pages/AreaDeInstituicoes'
import CriarInstituicao from './src/pages/CriarInstituição'
import AreaDeMonitores from './src/pages/AreaDeMonitores'
import CriarMonitor from './src/pages/CriarMonitor'
import DetalhamentoMonitor from './src/pages/DetalhamentoMonitor'
import InicioAluno from './src/pages/InicioAluno'
import InicioOwner from './src/pages/InicioOwner'
import AreaDeEscolas from './src/pages/AreaDeEscolas'
import CriarEscola from './src/pages/CriarEscola'
import DetalhamentoEscola from './src/pages/DetalhamentoEscola'
import ConfirmacaoConta from './src/pages/ConfirmacaoConta'
import PromptsPage from './src/pages/Prompts'

function App() {
  const toast = useToast()

  function callToast(toastBody) {
    toast(toastBody)
  }

  return (
    <ChakraProvider theme={UITema}>
      <Router>
        <Switch>
          <Route path='/' element={<Index/>}/>
          <Route path='/login' element={<LoginPage/>}/>
          <Route path={'/cadastro'} element={<CadastroPage/>}/>
          <Route path='/confirm-email' element={<ConfirmacaoConta/>}/>

          <Route path="/inicio-professor" element={<InicioProfessor/>}/>
          <Route path="/inicio-aluno" element={<InicioAluno/>}/>
          <Route path="/inicio-owner" element={<InicioOwner/>}/>

          <Route path="/prompts" element={<PromptsPage/>}/>
          <Route path="/area-de-atividades" element={<AreaDeAtividades/>}/>
          <Route path="/detalhamento-atividade/:atividadeID" element={<DetalhamentoAtividade/>}/>
          <Route path="/criar-atividade" element={<CriarAtividade/>}/>

          <Route path="/area-de-turmas" element={<AreaDeTurmas/>}/>
          <Route path="/detalhamento-turma/:turmaID" element={<DetalhamentoTurma/>}/>
          <Route path="/criar-turma" element={<CriarTurma/>}/>

          <Route path="/detalhamento-aluno/:alunoID" element={<DetalhamentoAluno/>}/>
          <Route path="/redacao" element={<EssayCreation/>}/>
          <Route path="/correcao-redacao/:id" element={<EssayCorrection/>}/>

          <Route path='/area-de-temas' element={<AreaDeTemas/>}/>
          <Route path="/detalhamento-tema/:temaID" element={<DetalhamentoTema/>}/>
          <Route path='/criar-tema' element={<CriarTema/>}/>

          <Route path="/recuperar-senha" element={<RecuperarSenha/>}/>
          <Route path="/recuperar-senha-confirmacao/:tokenConfirmacao"
                 element={<RecuperacaoSenhaConfirmacao/>}/>

          <Route path={'/area-de-instituicoes'} element={<AreaDeInstituicoes/>}/>
          <Route path={'/criar-instituicao'} element={<CriarInstituicao/>}/>

          <Route path={'/area-de-monitores'} element={<AreaDeMonitores/>}/>
          <Route path={'/adicionar-monitor'} element={<CriarMonitor/>}/>
          <Route path={'/detalhamento-monitor/:monitorID'} element={<DetalhamentoMonitor callToast={callToast}/>}/>

          <Route path={'/area-de-escolas'} element={<AreaDeEscolas/>}/>
          <Route path={'/criar-escola'} element={<CriarEscola/>}/>
          <Route path="/detalhamento-escola/:escolaID" element={<DetalhamentoEscola/>}/>

        </Switch>
      </Router>
    </ChakraProvider>
  )
}

export default App
