import React, { useEffect, useState } from 'react'
import './DetalhamentoAluno.css'
import Headbar from '../../components/Headbar'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { getActivitiesByStudentIdApi } from '../../apis/activitityApi/activityApi'
import { deleteStudentById, getStudentById, putStudentById } from '../../apis/studentApi/studentApi'
import { essayStatus } from '../../utils/essayStatus'
import { phoneNumberFormatter } from '../../utils/phoneNumberFormatter'
import SharedTablePagination from '../../components/SharedTablePagination'
import sanitizedDate from '../../utils/DateSatinizerInput'
import Page from '../Page'

function DetalhamentoAluno() {
  const navigate = useNavigate()
  const { alunoID } = useParams()

  console.log(alunoID)

  const [studentData, setStudentData] = useState({})
  const [activities, setActivities] = useState([])
  const [essays, setEssays] = useState([])
  const [activitiesData, setActivitiesData] = useState([])
  const [essaysData, setEssaysData] = useState([])
  const [loading, setLoading] = useState(true)
  const [activitiesFilterText, setActivitiesFilterText] = useState('')
  const [essaysFilterText, setEssaysFilterText] = useState('')
  const [selectedTab, setSelectedTab] = useState('redacoes')

  const modalDisclosure = useDisclosure()
  const popoverDisclosure = useDisclosure()

  const fetchInitialData = () => {
    return new Promise((resolve) => {
      Promise.all([getStudentById(alunoID), getActivitiesByStudentIdApi(alunoID)])
        .then(([studentResult, activitiesResult]) => {
          resolve({ studentResult, activitiesResult })
        })
    })
  }

  useEffect(() => {
    fetchInitialData().then(({ studentResult, activitiesResult }) => {
      console.log(studentResult, activitiesResult)
      setStudentData(studentResult || {})
      setEssaysData(studentResult !== null ? studentResult.essays : [])
      setActivitiesData(activitiesResult)
      setLoading(false)
    })
  }, [])

  function fetchStudent() {
    Promise.all([getStudentById(alunoID)])
      .then(([studentResult]) => {
        setStudentData(studentResult)
      })
  }

  useEffect(() => {
    if (activitiesData) {
      setActivities(
        activitiesData.filter((item) => {
          const result = item.subject.toLowerCase().includes(activitiesFilterText.toLowerCase()) ||
            item.theme.name.toLowerCase().includes(activitiesFilterText.toLowerCase()) ||
            (item.deadline && sanitizedDate(item.deadline).includes(activitiesFilterText)) ||
            (item.validFrom && sanitizedDate(item.validFrom).includes(activitiesFilterText))

          return result
        })
      )
    }
  }, [activitiesFilterText, activitiesData])

  useEffect(() => {
    if (essaysData) {
      setEssays(
        essaysData.filter((item) => {
          const result = item.activitySubject.toLowerCase().includes(essaysFilterText.toLowerCase()) ||
            (item.dateSubmitted && sanitizedDate(item.dateSubmitted).includes(essaysFilterText)) ||
            essayStatus[item.status].toLowerCase().includes(essaysFilterText.toLowerCase())

          return result
        })
      )
    }
  }, [essaysFilterText, essaysData])

  function handleEssaysSearchInputChange(e) {
    setEssaysFilterText(e.target.value)
  }

  function handleActivitiesSearchInputChange(e) {
    setActivitiesFilterText(e.target.value)
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function HandleStatusInput({ status }) {
    let backgroundColor
    if (status === 10) {
      backgroundColor = 'in-progress-essay-status'
    } else if (status === 20) {
      backgroundColor = 'in-grading-essay-status'
    } else if (status === 30) {
      backgroundColor = 'in-review-essay-status'
    } else if (status === 40) {
      backgroundColor = 'finished-essay-status'
    }
    return (
      <div className={'atividade-txt-detalhamento-aluno publicsans-normal-black-12px'}>
        <div className={backgroundColor}>
          {essayStatus[status]}
        </div>
      </div>)
  }

  function GetModalEditStudent() {
    const [studentNameError, setStudentNameError] = useState(false)

    const [editStudentData, setEditStudentData] = useState({
      name: studentData.name,
      phoneNumber: studentData.phoneNumber,
      groups: Array.of(studentData.schools ? studentData.schools[0].groups[0].id : [])
    })

    return (
      <Modal isOpen={modalDisclosure.isOpen} size={'2xl'} onClose={modalDisclosure.onClose} isCentered={true}
             closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>Editar Aluno</ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={`${studentNameError ? 'error-form' : ''} publicsans-bold-nevada-16px`}>Nome do Aluno*
              </div>
              <Input className={'input-default-style'}
                     onChange={(e) => {
                       setStudentNameError(false)
                       setEditStudentData({ ...editStudentData, name: e.target.value })
                     }}
                     defaultValue={editStudentData.name}
              />
              <div className={'publicsans-bold-nevada-16px'}>Numero de telefone</div>
              <Input className={'input-default-style'}
                     onChange={(e) => {
                       const formattedValue = phoneNumberFormatter(e.target.value)
                       setEditStudentData({ ...editStudentData, phoneNumber: formattedValue })
                     }}
                     value={editStudentData.phoneNumber}
              />

            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button backgroundColor={'#f9faff'}
                    borderColor={'#648BF7'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#062989'}
                    height={'48px'}
                    width={'218px'} onClick={modalDisclosure.onClose}>
              Cancelar
            </Button>
            <Button backgroundColor={'#0A41D6'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#FFFFFF'}
                    height={'48px'}
                    width={'218px'}
                    onClick={async () => {
                      if (!editStudentData.name) {
                        setStudentNameError(!editStudentData.name)
                      } else {
                        await putStudentById(alunoID, editStudentData)
                        fetchStudent()
                        modalDisclosure.onClose()
                      }
                    }}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  function HandleGroups() {
    if (studentData.schools) {
      return studentData.schools[0].groups.map((group, key) => {
        return (
          <Frame25802 key={key}>{group.name}</Frame25802>
        )
      })
    }
  }

  function StudentDetails(props) {
    const { title, imgPath, details } = props

    return (
      <div className="criao-atividade-detalhamento-aluno">
        <div className="email-detalhamento-aluno publicsans-normal-nevada-16px">
          {title}
        </div>
        <div className="data-detalhamento-aluno">
          <img className="x-2851-detalhamento-aluno" src={imgPath} alt="message-2851808"/>
          <div className="joosilvagmailcom-detalhamento-aluno publicsans-normal-nevada-14px">
            {details}
          </div>
        </div>
      </div>
    )
  }

  function HandleDate({ dateSubmitted }) {
    let date = ''
    if (dateSubmitted) {
      date = new Date(dateSubmitted).toLocaleDateString() + ' às ' + new Date(dateSubmitted).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      })
    }
    return (<div className={'table-cell-container publicsans-normal-black-12px'}>
      {date !== '' ? <img className="calendar-2851774-1-detalhamento-atividade" src={'/img/calendar-2851774@2x.png'}
                          alt="calendar-2851774"/> : ''}
      {date}</div>)
  }

  function HandleGroup({ groupName }) {
    return (
      <div className={'table-cell-container publicsans-normal-black-12px'}>
        <div className={'group-background'}> {groupName} </div>
      </div>
    )
  }

  function HandleDetails({ essayId }) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>{
        <Link to={'/correcao-redacao/' + essayId}>
          <div className="atividade-txt-4-area-de-turmas">
            <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'}
                 alt="ic-arrowright"/>
          </div>
        </Link>}
      </div>
    )
  }

  function HandleActivityDetails({ activityId }) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>{
        <Link to={'/detalhamento-atividade/' + activityId}>
          <div className="atividade-txt-4-area-de-turmas">
            <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'}
                 alt="ic-arrowright"/>
          </div>
        </Link>}
      </div>
    )
  }

  return (
    <div className="detalhamento-aluno screen-detalhamento-aluno">
      <Page authPage={true} allowedUserType={'teacher monitor independentteacher'} />
      <Headbar returnTo={'/detalhamento-turma/123'}/>
      <div className="frame-2591-detalhamento-aluno">
        <TitleCta>Dados do aluno</TitleCta>

        <div className="criao-atividade-detalhamento-aluno">
          <div className="email-detalhamento-aluno publicsans-normal-nevada-16px">
            {'Email'}
          </div>
          <div className="data-detalhamento-aluno">
            <img className="x-2851-detalhamento-aluno" src={'/img/message-icon.svg'} alt="message-2851808"/>
            <div className="joosilvagmailcom-detalhamento-aluno publicsans-normal-nevada-14px">
              {loading ? '' : studentData.email}
            </div>
          </div>
        </div>
        <StudentDetails
          title="Telefone"
          imgPath="/img/phone-icon.svg"
          details={loading ? '' : studentData.phoneNumber || 'Não informado'}
        />
      </div>
      <div className={'detalhamento-aluno-body'}>
        <div className="tema-detalhamento-aluno">
          <div className="content-3-detalhamento-aluno">
            <div className="frame-2593-detalhamento-aluno">
              <div className="frame-2594-detalhamento-aluno">
                <div className="frame-2609-detalhamento-aluno">
                  <div className="alunoa-detalhamento-aluno publicsans-bold-nevada-10px">
                    ALUNO(A)
                  </div>
                  <div className="frame-2610-detalhamento-aluno">
                    <div
                      className="joo-silva-oliveira-detalhamento-aluno publicsans-normal-absolute-zero-24px">
                      {loading ? '' : studentData.name}
                    </div>
                    <Frame2580>Ativo</Frame2580>
                  </div>
                </div>
                <div className="editar-detalhamento-aluno">
                  <div className="edit-container-detalhamento-aluno">
                    <div
                      className="editar-1-detalhamento-aluno valign-text-middle-detalhamento-aluno publicsans-bold-nevada-14px">
                      {'Editar'}
                    </div>
                    <img className="mode-edit-detalhamento-aluno"
                         src={'/img/mode-edit@2x.png'}
                         alt="Mode edit"
                         onClick={modalDisclosure.onOpen}/>
                    <GetModalEditStudent/>
                  </div>
                </div>
              </div>
            </div>
            <HStack justifyContent={'space-between'} width={'100%'}>
              <div className="descrio-detalhamento-aluno">
                <div className="entrega-detalhamento-aluno">
                  <div className="turma-detalhamento-aluno publicsans-bold-nevada-10px">
                    TURMA
                  </div>
                  <HandleGroups/>
                </div>
              </div>
              <div>
                <HStack>
                  <div className={'publicsans-bold-nevada-14px'}> Apagar aluno?</div>
                  <Popover isOpen={popoverDisclosure.isOpen} onOpen={popoverDisclosure.onOpen}
                           onClose={popoverDisclosure.onClose} placement="bottom-end" closeOnBlur={false}>
                    <PopoverTrigger>
                      <Image src={'/img/deleteIcon.svg'} marginRight={'15px'} cursor={'pointer'}/>
                    </PopoverTrigger>
                    <PopoverContent width={'448px'} borderRadius={'10px'}>
                      <PopoverArrow/>
                      <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'}/>
                      <PopoverHeader>
                        <div className={'text-popover'}>Confirme Remoção do aluno</div>
                      </PopoverHeader>
                      <PopoverFooter alignSelf={'center'} marginTop={'50px'} width={'100%'}>
                        <HStack width={'100%'}>
                          <Button backgroundColor={'#f9faff'}
                                  borderColor={'#648BF7'}
                                  border={'1px'}
                                  borderRadius={'8px'}
                                  color={'#062989'}
                                  height={'48px'}
                                  width={'100%'} onClick={popoverDisclosure.onClose}>
                            Cancelar
                          </Button>
                          <Button backgroundColor={'#0A41D6'}
                                  color={'white'}
                                  width={'100%'}
                                  height={'48px'}
                                  onClick={async () => {
                                    await deleteStudentById(alunoID)
                                    navigate(-1)
                                  }}
                          > Confirmar Remoção </Button>
                        </HStack>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                </HStack>
              </div>
            </HStack>
          </div>
        </div>
        <div className="frame-2595-detalhamento-aluno">
          <div className="switch-detalhamento-aluno">
            <div className={`frame-2471-detalhamento-aluno ${selectedTab === 'redacoes' ? 'tab-border' : ''}`}
                 onClick={() => changeSelectedTab('redacoes')}>
              <div
                className={`redaes-detalhamento-aluno ${selectedTab === 'redacoes' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                {'Redações'}
              </div>
            </div>
            <div className={`frame-2470-detalhamento-aluno ${selectedTab === 'atividades' ? 'tab-border' : ''}`}
                 onClick={() => changeSelectedTab('atividades')}>
              <div
                className={`atividades-vinculadas-detalhamento-aluno ${selectedTab === 'atividades' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                {'Atividades vinculadas'}
              </div>
            </div>
          </div>
        </div>
        {!loading && selectedTab === 'redacoes' && (
          <div className={'redacoes-container-detalhamento-aluno-style'}>
            <div className="search-filter-detalhamento-aluno">
              <div className="search-filtro-detalhamento-aluno">
                <div className="search-detalhamento-aluno">
                  <img
                    className="search-2851823-detalhamento-aluno"
                    src="/img/search-2851823@2x.png"
                    alt="search-2851823"
                  />
                  <input
                    placeholder={'Buscar redações...'}
                    type={'search'}
                    className="text-detalhamento-aluno publicsans-normal-nevada-16px"
                    onChange={handleEssaysSearchInputChange}
                  />
                </div>
              </div>

              <SharedTablePagination
                data={essays}
                itemsPerPage={5}
                columns={[
                  {
                    title: 'Status',
                    render: (itemData) => <HandleStatusInput status={itemData.status}/>
                  },
                  {
                    title: 'Data de Entrega',
                    render: (itemData) => <HandleDate dateSubmitted={itemData.dateSubmitted}/>
                  },
                  {
                    title: 'Atividade',
                    render: (itemData) => <HandleGroup groupName={itemData.activitySubject}/>
                  },
                  {
                    title: 'Detalhes',
                    render: (itemData) => <HandleDetails activityId={itemData.id}/>
                  }
                ]}
              />
            </div>
          </div>
        )}

        {!loading && selectedTab === 'atividades' && (
          <div className={'redacoes-container-detalhamento-aluno-style'}>
            <div className="search-filter-detalhamento-aluno">
              <div className="search-filtro-detalhamento-aluno">
                <div className="search-detalhamento-aluno">
                  <img
                    className="search-2851823-detalhamento-aluno"
                    src="/img/search-2851823@2x.png"
                    alt="search-2851823"
                  />
                  <input
                    placeholder={'Buscar atividades...'}
                    type={'search'}
                    className="text-detalhamento-aluno publicsans-normal-nevada-16px"
                    onChange={handleActivitiesSearchInputChange}
                  />
                </div>
              </div>

              <SharedTablePagination
                data={activities}
                itemsPerPage={5}
                columns={[
                  {
                    title: 'Atividade',
                    render: (itemData) => <div
                      className={'table-cell-container publicsans-normal-black-14px'}>{itemData.subject}</div>
                  },
                  {
                    title: 'Tema',
                    render: (itemData) => <div
                      className={'table-cell-container publicsans-normal-black-14px'}>{itemData.theme.name}</div>
                  },
                  {
                    title: 'Data de início',
                    render: (itemData) => <HandleDate dateSubmitted={itemData.validFrom}/>
                  },
                  {
                    title: 'Data limite de entrega',
                    render: (itemData) => <HandleDate dateSubmitted={itemData.deadline}/>
                  },
                  {
                    title: 'Detalhes',
                    render: (itemData) => <HandleActivityDetails activityId={itemData.id}/>
                  }
                ]}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DetalhamentoAluno

function TitleCta(props) {
  const { children } = props

  return (
    <div className="title-cta-detalhamento-aluno">
      <div className="title-detalhamento-aluno">
        <h1 className="title-1-detalhamento-aluno publicsans-normal-resolution-blue-24px">
          {children}
        </h1>
      </div>
    </div>
  )
}

function Frame2580(props) {
  const { children } = props

  return (
    <div className="frame-2580-detalhamento-aluno">
      <div
        className="ativo-detalhamento-aluno valign-text-middle-detalhamento-aluno publicsans-normal-black-14px">
        {children}
      </div>
    </div>
  )
}

function Frame25802(props) {
  const { children } = props

  return (
    <div className="frame-2580-1-detalhamento-aluno">
      <div
        className="x1-ano-a-detalhamento-aluno valign-text-middle-detalhamento-aluno publicsans-normal-black-12px">
        {children}
      </div>
    </div>
  )
}
