import React from 'react'
import { Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import CriarTurmaStep1 from '../../components/CriarTurmaStep1'
import './CriarTurma.css'
import CriarTurmaStep2 from '../../components/CriarTurmaStep2'
import { createGroupApi } from '../../apis/schoolApi/groupApi'
import useCriarTurmaStore from '../../store/useCriarTurmaStore'
import Page from '../Page'

const CriarTurma = () => {
  const navigate = useNavigate()
  let turmaCreatedId
  const {
    currentStep,
    criarTurmaData,
    setCurrentStep,
    cleanCriarTurmaData
  } = useCriarTurmaStore()

  const handleNextStep = () => {
    if (currentStep === 1) {
      createTurma().then((result) => {
        turmaCreatedId = result
        cleanCriarTurmaData()
        setCurrentStep(currentStep + 1)
      })
    } else {
      navigate('/detalhamento-turma/' + turmaCreatedId)
    }
  }

  const createTurma = () => {
    return new Promise((resolve) => {
      const result = createGroupApi(criarTurmaData)

      resolve(result)
    })
  }

  const handlePreviousStep = () => {
    navigate('/area-de-turmas')
    setCurrentStep(1)
    cleanCriarTurmaData()
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CriarTurmaStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      case 2:
        return <CriarTurmaStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <Flex alignItems={'center'} justifyContent={'center'} h={'100%'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CriarTurma
