import { Icon } from '@chakra-ui/react'

export const FileUploadIcon = () => {
  return (
        <Icon xmlns="http://www.w3.org/2000/svg" width="15" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M14.7362 2.86328H8.08424C6.02524 2.86328 4.25024 4.53228 4.25024 6.59228V17.3293C4.25024 19.5053 5.90824 21.2163 8.08424 21.2163H16.0722C18.1322 21.2163 19.8022 19.3893 19.8022 17.3293V8.13928L14.7362 2.86328Z" stroke="#0A41D6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.4737 2.85156V5.76056C14.4737 7.18056 15.6227 8.33256 17.0427 8.33556C18.3587 8.33856 19.7057 8.33956 19.7967 8.33356M11.6407 9.54196V16.115M11.6407 9.54196L8.80225 12.3926M11.6407 9.54196L14.4793 12.3926" stroke="#0A41D6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </Icon>
  )
}
