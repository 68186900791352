import React from 'react'
import './MenuMonitores.css'
import {useNavigate} from 'react-router-dom'
import useLoggedUserStore from '../../store/useLoggedUserStore'

function MenuMonitores() {
  const {loggedUser} = useLoggedUserStore()
  const navigate = useNavigate()

  let menuClass = 'menu-item'
  let menuPicturePath = '/img/profile-2851821-1.svg'
  let menuPictureText = 'publicsans-bold-nevada-16px'

  if (document.location.toString().includes('/area-de-monitores')) {
    menuClass += ' menu-foco'
    menuPicturePath = '/img/profile-2851821-5.svg'
    menuPictureText = 'publicsans-bold-absolute-zero-16px'
  }

  if (loggedUser.user_role === 'student' || loggedUser.user_role === 'monitor' || loggedUser.user_role === 'adm') {
    return ('')
  }

  return (
    <div className={menuClass} onClick={() => navigate('/area-de-monitores')}>
      <img className="monitores-picture" src={menuPicturePath} alt="profile-2851821"/>
      <div className={menuPictureText}>{'Monitores'}</div>
    </div>
  )
}

export default MenuMonitores
