import React, { useState } from 'react'
import './CadastroStep2.css'
import IlustracaoLogin from '../IlustracaoLogin'
import { emailRegex } from '../../utils/emailRegex'
import { phoneNumberFormatter } from '../../utils/phoneNumberFormatter'
import { Box, HStack } from '@chakra-ui/react'

function CadastroStep2(props) {
  const { handleReturn, handlingNextStep, userData } = props

  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [email, setEmail] = useState(userData.email || '')
  const [telefone, setTelefone] = useState(userData.telefone || '')
  const [senha, setSenha] = useState(userData.senha || '')
  const [eulaChecked, setEulaChecked] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [senhaError, setSenhaError] = useState(false)
  const [eulaError, setEulaError] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible)
  }

  const handleEmailInputChange = (event) => {
    const inputValue = event.target.value
    setEmail(inputValue)
    userData.email = inputValue
    setEmailError(false)
  }

  const handleTelefoneInputChange = (event) => {
    const formattedValue = phoneNumberFormatter(event.target.value)
    setTelefone(formattedValue)
    userData.telefone = formattedValue
  }

  const handleSenhaInputChange = (event) => {
    setSenha(event.target.value)
    userData.senha = event.target.value
    setSenhaError(false) // Reset the error state
  }

  const handleSubmit = () => {
    if ((email && senha && eulaChecked) && (emailRegex.test(email) && senha.length > 8)) {
      // Perform the form submission
      handlingNextStep(userData)
    } else {
      // Set error states for missing or invalid values
      setEmailError(!email)
      setEmailError(!emailRegex.test(email))
      setSenhaError(senha.length < 8)
      setEulaError(!eulaChecked)
    }
  }

  const handleEulaCheckboxChange = (event) => {
    setEulaChecked(event.target.checked)
    userData.eula = event.target.checked
    setEulaError(false)
  }

  return (
    <div className="container-center-horizontal-cadastro">
      <div className="cadastro-step01-cadastro screen-cadastro">
        <div className={'cadastro-columns'}>
          <HStack width={'100%'} height={'100%'}>
            <IlustracaoLogin/>

            <Box className={'cadastro-body-step-2'} overflowY="auto" maxHeight="100vh" flex="1">
              <div className="title-cadastro-step-2">
                <h1 className="cadastre-se-no-correo-online-cadastro-step-2 valign-text-bottom-cadastro-step-2">
                                <span>
                                  <span className="publicsans-bold-absolute-zero-40px">Cadastre-se</span>
                                  <span className="span1-cadastro-step-2">&nbsp;</span>
                                  <span className="span2-cadastro-step-2">no Correção Online</span>
                                </span>
                </h1>
              </div>
              <div className="content-3-cadastro-step-2">
                <img
                  className="line-cadastro-step-2"
                  src="/img/line.png"
                  alt="Line"
                />
                <div className="concluido-cadastro-step-2">
                  <img
                    className="frame-2482-1-cadastro-step-2"
                    src="/img/frame-2482@2x.png"
                    alt="Frame 2482"
                  />
                  <div className="frame-2486-cadastro-step-2">
                    <div className="text-2-cadastro-step-2 publicsans-normal-nevada-16px">
                      Seu perfil
                    </div>
                    <div className="professor-cadastro-step-2 publicsans-normal-nevada-20px">
                      {userData.userType === 'Professor' ? 'Professor Independente' : 'Gestor Escolar'}
                    </div>
                  </div>
                </div>
                <div className="step-01-cadastro-step-2">
                  <Frame2482/>
                  <div className="criar-conta-cadastro-step-2 publicsans-normal-nevada-24px">
                    Criar conta
                  </div>
                </div>
                <div className="frame-2546-cadastro-step-2">
                  <div className={'email-cadastro-step-2'}>
                    <div
                      className={`${emailError ? 'error-cadastro' : ''} email-1-cadastro-step-2 publicsans-bold-nevada-16px`}>
                      {'Email'}
                    </div>
                    <div className="text-imput-cadastro-step-2">
                      <div className="maintextimput-cadastro-step-2">
                        <div className="buscar-cadastro-step-2">
                          <input
                            className="text-cadastro-step-2 valign-text-middle-cadastro-step-2 publicsans-normal-black-14px"
                            placeholder={'nome@email.com.br'}
                            type={'text'}
                            onChange={handleEmailInputChange}
                            name={'email'}
                            value={email}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="email-cadastro-step-2">
                    <div className="email-1-cadastro-step-2 publicsans-bold-nevada-16px">
                      {'Telefone'}
                    </div>
                    <div className="text-imput-cadastro-step-2">
                      <div className="maintextimput-cadastro-step-2">
                        <div className="buscar-cadastro-step-2">
                          <input
                            className="text-cadastro-step-2 valign-text-middle-cadastro-step-2 publicsans-normal-black-14px"
                            placeholder={'(81) 9999-9999'}
                            type={'text'}
                            onChange={handleTelefoneInputChange}
                            name={'telefone'}
                            value={telefone}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'email-2-cadastro-step-2'}>
                    <div
                      className={`${senhaError ? 'error-cadastro' : ''} senha-cadastro-step-2 publicsans-bold-nevada-16px`}>
                      Senha
                    </div>
                    <div className="text-imput-1-cadastro-step-2">
                      <div className="maintextimput-1-cadastro-step-2">
                        <div className="buscar-1-cadastro-step-2">
                          <input
                            className="text-1-cadastro-step-2 valign-text-middle-cadastro-step-2 publicsans-normal-black-14px"
                            placeholder={'mínimo de 8 caracteres, 1 número e 1 caractere'}
                            type={isPasswordVisible ? 'text' : 'password'}
                            onChange={handleSenhaInputChange}
                            name={'senha'}
                            autoComplete={'new-password'}
                            value={senha}
                          />
                          <img className="ic-visibility-cadastro-step-2"
                               src={'/img/ic-visibility.svg'} alt="ic-visibility"
                               onClick={togglePasswordVisibility}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`frame-2545-cadastro-step-2 ${eulaError ? 'error-cadastro' : ''}`}>

                    <input
                      className={'check-box-cadastro-step-2'}
                      type={'checkbox'}
                      onChange={handleEulaCheckboxChange}
                    />
                    <p className="text-3-cadastro-step-2 publicsans-normal-white-16px">
                      <span className="publicsans-normal-nevada-16px">Aceito todos os </span>
                      <span
                        className="span-1-cadastro-step-2 publicsans-normal-absolute-zero-16px">Termos de uso</span>
                      <span className="publicsans-normal-nevada-16px"> e </span>
                      <span
                        className="span-1-cadastro-step-2 publicsans-normal-absolute-zero-16px">Politicas de privacidade</span>
                    </p>
                  </div>
                </div>
                <div className="steps-cadastro-step-2">
                  <Frame2550 number="3" criarConta="Informações adicionais"/>
                  <Frame2550 number="4" criarConta="Finalizar cadastro"/>
                </div>
              </div>
              <div className="buttons-cadastro-step-2">
                <Button mainbutton6Props={buttonData.mainbutton6Props} handleReturn={handleReturn}/>
                <div onClick={handleSubmit} className="button-2-cadastro-step-2">
                  <Mainbutton2>{button2Data.mainbutton2Props.children}</Mainbutton2>
                </div>
              </div>
            </Box>
          </HStack>
        </div>
      </div>
    </div>
  )
}

export default CadastroStep2

function Frame2482() {
  return (
    <div className="frame-2482-cadastro-step-2">
      <div className="number-cadastro-step-2 publicsans-normal-aqua-deep-24px">
        2
      </div>
    </div>
  )
}

function Frame2550(props) {
  const { number, criarConta } = props

  return (
    <div className="frame-255-cadastro-step-2">
      <div className="frame-2483-cadastro-step-2">
        <div className="number-1-cadastro-step-2 publicsans-normal-shady-lady-20px">
          {number}
        </div>
      </div>
      <div className="informaes-da-instituio-cadastro-step-2 publicsans-normal-shady-lady-20px">
        {criarConta}
      </div>
    </div>
  )
}

function Button(props) {
  const { handleReturn } = props

  return (
    <div onClick={handleReturn} className="button-1-cadastro-step-2">
      <Mainbutton/>
    </div>
  )
}

function Mainbutton() {
  return (
    <div className="mainbutton-1-cadastro-step-2">
      <div className="content-1-cadastro-step-2">
        <div className="main-button-1-cadastro-step-2 publicsans-bold-resolution-blue-14px">
          {'Voltar'}
        </div>
      </div>
    </div>
  )
}

function Mainbutton2(props) {
  const { children } = props

  return (
    <div className="mainbutton-2-cadastro-step-2">
      <div className="content-2-cadastro-step-2">
        <div className="main-button-2-cadastro-step-2 publicsans-bold-white-14px">
          {children}
        </div>
      </div>
    </div>
  )
}

const mainbuttonData = {
  children: 'Voltar para home'
}

const buttonData = {
  mainbutton6Props: mainbuttonData
}

const mainbutton2Data = {
  children: 'Continuar'
}

const button2Data = {
  mainbutton2Props: mainbutton2Data
}
