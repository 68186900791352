import { create } from 'zustand'

const useCriarInstituicaoStore = create((set) => ({
  currentStep: 1,
  criarInstituicaoData: {
    name: '',
    institutionGradeType: '0',
    maxGrade: ''
  },
  setCurrentStep: (step) => set({ currentStep: step }),
  setCriarInstituicaoData: (data) => set((state) => ({
    criarInstituicaoData: { ...state.criarInstituicaoData, ...data }
  })),
  cleanCriarInstituicaoData: () => set({
    criarInstituicaoData: {
      name: '',
      institutionGradeType: '0',
      maxGrade: ''
    }
  })
}))

export default useCriarInstituicaoStore
