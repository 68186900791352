import React from 'react'
import './LogoSVG.css'

function LogoSVG() {
  return (
            <div className="perfil">
                <div className="group">
                    <div className="overlap-group1">
                        <img className="logo-image" src="/img/logo-compacted.svg" alt="Vector"/>
                    </div>
                </div>
            </div>)
}

export default LogoSVG
