import { Flex, HStack, Text, Input } from '@chakra-ui/react'
import { PaginationButton } from '../PaginationButton'

export const Pagination = ({ page, nPages, onNext, onPrevious }) => {
  return (
        <HStack justifyContent='end' spacing={7}>
            <Text>Exibindo página</Text>
            <Flex>
                <PaginationButton direction='previous' onClick={onPrevious} isDisabled={page <= 1} />
                <Input value={page} size='xs' width='35px' />
                <Text ml='6px'>de</Text>
                <Text mx='6px' textDecoration='underline'>{nPages}</Text>
                <PaginationButton direction='next' onClick={onNext} isDisabled={page === nPages || !nPages} />
            </Flex>
        </HStack>
  )
}
