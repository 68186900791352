import './CriarMonitor.css'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import CriarMonitorStep1 from '../../components/CriarMonitorStep1'
import CriarMonitorStep2 from '../../components/CriarMonitorStep2'
import Page from '../Page'

const CriarMonitor = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const navigate = useNavigate()

  const handleNextStep = (newMonitorID) => {
    if (currentStep === 1) {
      setCurrentStep(currentStep + 1)
    } else {
      console.log(newMonitorID)
      navigate('/detalhamento-monitor/' + newMonitorID)
    }
  }

  const handlePreviousStep = () => {
    setCurrentStep(1)
    navigate('/area-de-monitores')
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CriarMonitorStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      case 2:
        return <CriarMonitorStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <Flex alignItems={'center'} justifyContent={'center'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CriarMonitor
