import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const createSupportMaterialApi = async (data) => {
  const response = await httpRequestHelper.postWithAuth(BACKEND_API + '/v1/theme/support-material', data)

  return response.data.message
}

export const deleteSupportMaterialApi = async (themeId) => {
  const response = await httpRequestHelper.delWithAuth(BACKEND_API + `/v1/theme/support-material/${themeId}`)

  return response.data.message
}

export const listSupportMaterialApi = async (themeId) => {
  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/theme/${themeId}/support-materials`)
  return response.data.message
}
