import { Button, HStack, Square, Stack, Box, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { MicIcon } from '../icons/Mic'

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const VoiceRecorder = ({ onRecordingComplete }) => {
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true
    },
    (err) => console.table(err) // onNotAllowedOrFound
  )

  async function insertRecordingWaves() {
    await sleep(500) // Waiting the dom is completed loaded
    const waveformElements = document.getElementsByClassName('audio-recorder-visualizer')
    const waveformSpan = waveformElements[0]
    const waveformContainer = document.getElementById('waveform-container')
    waveformContainer.style.width = '100%'
    const parentWidth = waveformContainer.offsetWidth
    const parentHeight = waveformContainer.offsetHeight
    console.log(parentWidth, parentHeight)
    const canvasElement = waveformSpan.children[0]
    canvasElement.width = parentWidth - 40
    canvasElement.height = '60'
    waveformContainer.appendChild(waveformSpan)
  }

  useEffect(() => {
    if (recorderControls.isRecording) {
      insertRecordingWaves()
    }
  }, [recorderControls.isRecording])

  function toggleRecordingButton() {
    if (recorderControls.isRecording) {
      recorderControls.stopRecording()
    } else {
      recorderControls.startRecording()
    }
  }

  return (
    <div>
      <div style={{ display: 'none' }}>
        <AudioRecorder
          onRecordingComplete={onRecordingComplete}
          recorderControls={recorderControls}
          downloadFileExtension='mp3'
          showVisualizer
        />
      </div>
      <HStack spacing={4} padding={3}>
        <Button
          variant='outline'
          borderRadius='full'
          backgroundColor='#F9FAFF'
          borderColor='brand.200'
          width='68px'
          height='68px'
          boxShadow='0px 0px 10px 0px rgba(107, 107, 121, 0.24)'
          onClick={toggleRecordingButton}
        >
          {
            recorderControls.isRecording
              ? <Square size='20px' border='2px solid' borderColor='brand.200' />
              : <MicIcon />
          }

        </Button>
        {
          !recorderControls.isRecording
            ? <Stack width='100%'>
              <Text fontSize='md' color='#6B6B79'>Iniciar gravação</Text>
              <Box width='100%' height='5px' backgroundColor='gray.200' />
            </Stack>

            : <div id='waveform-container'></div>
        }
        <div>
          <p>00:00</p>
        </div>
      </HStack>
    </div>
  )
}
