import React, {useEffect} from 'react'
import './ConfirmacaoConta.css'
import HeaderCadastro from '../../components/HeaderCadastro'
import IlustracaoLogin from '../../components/IlustracaoLogin'
import {Link, useNavigate} from 'react-router-dom'
import {buildMessage} from "../../utils/toastMiddleware";
import {useToast} from "@chakra-ui/react";
import {confirmEmailApi} from "../../apis/AccountApi";

function ConfirmacaoConta() {
  const toast = useToast()
  const navigate = useNavigate();

  useEffect(() => {
    const doConfirm = async (data) => {
      await confirmEmailApi(data)
    }

    const queryParams = new URLSearchParams(window.location.search)
    const userId = queryParams.get("userId")
    const language = queryParams.get("language")
    const code = queryParams.get("code").replace(/ /g, '+')

    const data = {userId, language, code}

    doConfirm(data).then(() => {
      navigate('/login')
      toast(buildMessage('success', 'Conta confirmada.', "Você já pode fazer login na sua conta."))
    }).catch((error) => {
      let message = ""
      if(error.response.status === 400){
        message = "Conta já foi confirmada."
      } else {
        message = "Entre em contato com o suporte do Correção Online"
      }
      console.log(error)
      toast(buildMessage('error', 'Erro', message))
    })

  }, [])

  return (
    <div className="container-center-horizontal-recuperar-senha">
      <div className="cadastro-step01-recuperar-senha screen">
        <div className="overlap-group3-recuperar-senha">
          <HeaderCadastro/>
        </div>
        <div className={'login-columns'}>
          <IlustracaoLogin/>

          <form className={'login-body'}>
            <div className="title-recuperar-senha">
              <h1 className="title-1-recuperar-senha valign-text-bottom">
                Confirmação de conta
              </h1>
              <p className="entre-e-comece-a-agi-recuperar-senha publicsans-normal-nevada-24px">
                Aguarde enquanto sua conta está sendo confirmada.
              </p>
            </div>

            <Link to={'/login'}>
              <div className="buttons-container-recuperar-senha">
                <div className="mainbutton-1-recuperar-senha">
                  <div className="content-1-recuperar-senha">
                    <div className="main-button-1-recuperar-senha publicsans-bold-white-14px">
                      Ir para tela de login
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ConfirmacaoConta
