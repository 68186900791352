import React, { useEffect, useState } from 'react'
import Page from '../Page'
import Menu from '../../components/Menu'
import Headbar from '../../components/Headbar'
import { getAllPromptsApi } from '../../apis/ownerApi/ownerApi'
import { Modal } from '../../components/modal'
import { Button as ButtonChakra, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { AddIcon } from '../../components/icons/Add'
import { PromptSearch } from './PromptSearch'
import { PromptModalBody } from '../../components/PromptModalBody'

function PromptsPage(props) {
  const [prompts, setPrompts] = useState([])
  const [page] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPrompt, setSelectedPrompt] = useState(null)

  const { isOpen: detailsIsOpen, onClose: detailsOnClose, onOpen: detailsOnOpen } = useDisclosure()

  function handleDetailsOnClose() {
    detailsOnClose()
    setSelectedPrompt(null)
  }

  function handleDetailsOnOpen(prompt) {
    setSelectedPrompt(prompt)
    detailsOnOpen()
  }

  async function fetchPrompts() {
    try {
      setIsLoading(true)
      const result = await getAllPromptsApi()
      setPrompts(result)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!detailsIsOpen) {
      fetchPrompts()
    }
  }, [detailsIsOpen])

  return (
    <>
      <div className="inicio screen">
        <Page authPage={true} allowedUserType={'owner'}>
          <div className="body-no-right">
            <Flex width='100%' justifyContent='space-between' borderRadius='12px' backgroundColor='#F3F5FF' alignItems='center' padding='24px'>
                <Text fontSize='35px' color={'brand.200'}>Todos os Prompts</Text>
                <ButtonChakra variant='primary' leftIcon={<AddIcon />} onClick={detailsOnOpen}>Adicionar</ButtonChakra>
            </Flex>
            <PromptSearch hits={prompts} isLoading={isLoading} detailsAction={handleDetailsOnOpen} nHits={100} page={page} />
          </div>
          <Menu />
          <Headbar />
        </Page>
      </div>
      <Modal
        title={selectedPrompt ? selectedPrompt.title : 'Novo Prompt'}
        isOpen={detailsIsOpen}
        onClose={handleDetailsOnClose}
        body={<PromptModalBody prompt={selectedPrompt ? selectedPrompt.id : null} onClose={handleDetailsOnClose} />}
        size='6xl'
      />
    </>
  )
}

export default PromptsPage
