import React from 'react'

function StatusAlunos(props) {
  const { alunosAtivos } = props

  return (
    <>
      <div className="frame-243-area-de-turmas">
        <img
          className="folder-2851794-1-area-de-turmas"
          src="/img/folder-2851794-1@2x.png"
          alt="folder-2851794"/>
        <div className="alunos-1-area-de-turmas publicsans-bold-nevada-14px">
          Alunos ativos
        </div>
        <div className="number-5-area-de-turmas publicsans-extra-bold-absolute-zero-32px">
          {alunosAtivos}
        </div>
      </div>
      {/* <div className="frame-243-area-de-turmas"> */}
      {/*  <img */}
      {/*    className="folder-2851794-1-area-de-turmas" */}
      {/*    src="/img/folder-2851794-2@2x.png" */}
      {/*    alt="folder-2851794"/> */}
      {/*  <div className="alunos-1-area-de-turmas publicsans-bold-nevada-14px"> */}
      {/*    Alunos inativos */}
      {/*  </div> */}
      {/*  <div className="number-5-area-de-turmas publicsans-extra-bold-absolute-zero-32px"> */}
      {/*    {alunosInativos} */}
      {/*  </div> */}
      {/* </div> */}
    </>
  )
}

export default StatusAlunos
