import React from 'react'
import './Data.css'

function Data(props) {
  const { editSquare2851790, date } = props

  return (
    <div className="data">
      <img className="x-28517" src={editSquare2851790} alt="edit-square-2851790" />
      <div className="date publicsans-normal-nevada-14px">{date}</div>
    </div>
  )
}

export default Data
