import React from 'react'
import './TitleLink.css'

function TitleLink(props) {
  const { titleContent, subTitleName, subTitleContent } = props

  return (
    <div className="title-link">
      <div className="atomsphotopicalbum"></div>
      <div className="frame-2587">
        <div className="title-activity-card publicsans-bold-absolute-zero-16px">{titleContent}</div>
        <div className="tema">
          <div className="place publicsans-bold-nevada-10px">{subTitleName}</div>
          <p className="subtext-card-style publicsans-normal-nevada-14px">
            {subTitleContent}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TitleLink
