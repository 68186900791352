import "./chunk-SUJGGKVT.mjs";
import {
  withDefaultProps
} from "./chunk-PE3QADR6.mjs";
import {
  withDefaultColorScheme
} from "./chunk-7FV6Z5GW.mjs";
import {
  withDefaultSize
} from "./chunk-5IM46G4H.mjs";
import {
  withDefaultVariant
} from "./chunk-5UFXUR4J.mjs";
import {
  extendBaseTheme,
  extendTheme,
  mergeThemeOverride
} from "./chunk-LIR5QAZY.mjs";
export {
  extendBaseTheme,
  extendTheme,
  mergeThemeOverride,
  withDefaultColorScheme,
  withDefaultProps,
  withDefaultSize,
  withDefaultVariant
};
