import React from 'react'
import Voltar from '../Voltar'
import UserProfileHeadbarComponent from '../UserProfileHeadbarComponent'
import './Headbar.css'

function Headbar(props) {
  const { className, returnTo } = props

  return (
        <div className={`content-19 ${className || ''}`}>
            {returnTo && <Voltar returnTo={returnTo}/>}
            {/*<NotificationsComponent/>*/}
            <UserProfileHeadbarComponent/>
        </div>
  )
}

export default Headbar
