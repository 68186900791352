import React from 'react'
import './Voltar.css'
import { useNavigate } from 'react-router-dom'

function Voltar() {
  const navigate = useNavigate()

  return (
    <div className="voltar">
      <img className="ic-arrowleft" onClick={() => navigate(-1)} src="/img/ic-arrowleft@2x.png" alt="ic-arrowleft"/>
      <div className="voltar-1 valign-text-middle publicsans-bold-nevada-14px">Voltar</div>
    </div>
  )
}

export default Voltar
