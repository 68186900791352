import React, { useState } from 'react'
import './RecuperarSenha.css'
import HeaderCadastro from '../../components/HeaderCadastro'
import IlustracaoLogin from '../../components/IlustracaoLogin'
import { Link } from 'react-router-dom'
import { requestPasswordChangeApi } from '../../apis/LoginApi'
import { buildMessage } from '../../utils/toastMiddleware'
import { useToast } from '@chakra-ui/react'

function RecuperarSenha() {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const toast = useToast()

  const handleEmailInputChange = (event) => {
    const inputValue = event.target.value
    setEmail(inputValue)
    setEmailError(false)
  }

  function handleSubmit() {
    if (email && emailRegex.test(email)) {
      requestPasswordChangeApi(email).then((response) => {
        if (response.status === 200) {
          console.log(response)
          toast(buildMessage('success', 'Senha reiniciada com sucesso', 'Uma senha nova foi gerada e enviada para o seu e-mail.'))
        }
      })
    } else {
      setEmailError(!email)
      setEmailError(!emailRegex.test(email))
    }
  }

  return (
        <div className="container-center-horizontal-recuperar-senha">
            <div className="cadastro-step01-recuperar-senha screen">
                <div className="overlap-group3-recuperar-senha">
                    <HeaderCadastro/>
                </div>
                <div className={'login-columns'}>
                    <IlustracaoLogin/>

                    <form className={'login-body'}>
                        <div className="title-recuperar-senha">
                            <h1 className="title-1-recuperar-senha valign-text-bottom">
                                Esqueceu a senha?
                            </h1>
                            <p className="entre-e-comece-a-agi-recuperar-senha publicsans-normal-nevada-24px">
                                Insira um endereço de email associado a sua conta, enviaremos um link de redefinição.
                            </p>
                        </div>

                        <div className="frame-2621-recuperar-senha">
                            <div className="frame-26-recuperar-senha">
                                <div className={`${emailError ? 'error-recuperacao-senha' : ''} e-mail-recuperar-senha publicsans-bold-nevada-16px`}>
                                    E-mail
                                </div>
                                <div className="text-imput-recuperar-senha">
                                    <div className="email-box-recuperar-senha">
                                        <div className="email-inner-recuperar-senha">
                                            <img className="ic-email-recuperar-senha" src={'/img/ic-email.svg'}
                                                 alt="ic-email"/>
                                            <input
                                                className="text-recuperar-senha publicsans-normal-black-14px"
                                                placeholder={'nome@dominio.com'}
                                                type={'text'}
                                                name="email"
                                                onChange={handleEmailInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="buttons-container-recuperar-senha">
                            <div className="mainbutton-1-recuperar-senha" onClick={handleSubmit}>
                                <div className="content-1-recuperar-senha">
                                    <div className="main-button-1-recuperar-senha publicsans-bold-white-14px">
                                        Enviar link de redefinição
                                    </div>
                                </div>
                            </div>
                            <Link to={'/login'}>
                                <div className="voltar-button">
                                    <div className="voltar-button-inner">
                                        <div
                                            className="voltar-button-text-container publicsans-bold-resolution-blue-14px">
                                            {'Voltar'}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
  )
}

export default RecuperarSenha
