import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { useEffect, useState } from 'react'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'draft-js/dist/Draft.css'

export const TextEditor = ({ onChange, essay }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [htmlText, setHtmlText] = useState()

  function onEditorStateChange(state) {
    setEditorState(state)
  }

  useEffect(() => {
    if (editorState) {
      const contentState = editorState.getCurrentContent()
      const raw = convertToRaw(contentState).blocks
        .map((block) => block.text)
        .join('\n')
      onChange(raw)
    }
  }, [editorState])

  useEffect(() => {
    if (editorState) {
      const contentState = editorState.getCurrentContent()
      const rawContentState = convertToRaw(contentState)
      const html = draftToHtml(rawContentState)
      setHtmlText(html)
      console.log(htmlText)
    }
  }, [editorState])

  useEffect(() => {
    if (essay) {
      console.log('criando from html')
      const blocks = htmlToDraft(essay.draft)
      const state = ContentState.createFromBlockArray(
        blocks.contentBlocks,
        blocks.entityMap
      )

      setEditorState(EditorState.createWithContent(state))
    }
  }, [essay])

  const editorStyles = {
    border: '1px solid #E7E6E6',
    borderRadius: '0px 0px 12px 12px',
    backgroundColor: '#fff',
    padding: '5px 24px 5px 24px',
    height: '280px'
  }

  const toolbarStyles = {
    borderRadius: '12px 12px 0px 0px',
    borderTop: '1px solid #E7E6E6',
    borderRight: '1px solid #E7E6E6',
    borderLeft: '1px solid #E7E6E6',
    backgroundColor: '#F3F5FF',
    margin: 0
  }

  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        editorStyle={editorStyles}
        toolbarStyle={toolbarStyles}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['fontSize', 'fontFamily', 'inline', 'textAlign', 'remove', 'history']
        }}
      />
      {/* <pre>{JSON.stringify(rawText)}</pre>
            <pre>{JSON.stringify(htmlText)}</pre> */}
      {/* <textarea
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            /> */}
    </>
  )
}
