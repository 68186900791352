import React from 'react'
import Title2 from '../Title2'
import './TitleCta32.css'
import { Link } from 'react-router-dom'

function TitleCta32(props) {
  const { text } = props
  return (
    <div className="title-cta-5">
      <Title2>{text}</Title2>
      <div className="cta-atividades-1">
        <Link to={'/area-de-turmas'}>
          <div className="text-28 publicsans-normal-azure-radiance-14px">{'Ver todas as turmas'}</div>
          <img className="ic-arrowright-9" src={'/img/ic-arrowright-61@2x.png'} alt="ic-arrowright"/>
        </Link>
      </div>
    </div>
  )
}

export default TitleCta32
