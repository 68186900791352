import React from 'react'
import './Mainbutton.css'

function Mainbutton(props) {
  const { children } = props

  return (
    <div className="mainbutton-14">
      <div className="content-28">
        <div className="main-button-11 publicsans-bold-white-14px">{children}</div>
      </div>
    </div>
  )
}

export default Mainbutton
