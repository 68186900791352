import React, { useEffect } from 'react'
import { logoff, validateToken } from '../apis/LoginApi'
import { useNavigate } from 'react-router-dom'
import useLoggedUserStore from '../store/useLoggedUserStore'

function Page(props) {
  const { cleanLoggedUserData } = useLoggedUserStore()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (props.authPage) {
      const tokenType = localStorage.getItem('user_role')

      if (props.allowedUserType !== '' && !props.allowedUserType.includes(tokenType)) {
        handleLogoff()
      }

      validatingToken()
    }
  }, [])

  const validatingToken = async () => {
    const isTokenValid = await validateToken()
    if (!isTokenValid) {
      handleLogoff()
    }
  }

  const handleLogoff = () => {
    logoff()
    cleanLoggedUserData()
    navigate('/login')
  }

  return (
    <main className={'main-style'}>
      {props.children}
    </main>
  )
}

export default Page
