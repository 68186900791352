import {
  fromEntries
} from "./chunk-YTQ3XZ3T.mjs";
import {
  getLastItem
} from "./chunk-YTAYUX3P.mjs";
import {
  isNumber,
  isObject
} from "./chunk-Y5FGD7DM.mjs";

// src/breakpoint.ts
function analyzeCSSValue(value) {
  const num = parseFloat(value.toString());
  const unit = value.toString().replace(String(num), "");
  return { unitless: !unit, value: num, unit };
}
function px(value) {
  if (value == null)
    return value;
  const { unitless } = analyzeCSSValue(value);
  return unitless || isNumber(value) ? `${value}px` : value;
}
var sortByBreakpointValue = (a, b) => parseInt(a[1], 10) > parseInt(b[1], 10) ? 1 : -1;
var sortBps = (breakpoints) => fromEntries(Object.entries(breakpoints).sort(sortByBreakpointValue));
function normalize(breakpoints) {
  const sorted = sortBps(breakpoints);
  return Object.assign(Object.values(sorted), sorted);
}
function keys(breakpoints) {
  const value = Object.keys(sortBps(breakpoints));
  return new Set(value);
}
function subtract(value) {
  var _a;
  if (!value)
    return value;
  value = (_a = px(value)) != null ? _a : value;
  const factor = value.endsWith("px") ? -0.02 : -0.01;
  return isNumber(value) ? `${value + factor}` : value.replace(/(\d+\.?\d*)/u, (m) => `${parseFloat(m) + factor}`);
}
function toMediaQueryString(min, max) {
  const query = ["@media screen"];
  if (min)
    query.push("and", `(min-width: ${px(min)})`);
  if (max)
    query.push("and", `(max-width: ${px(max)})`);
  return query.join(" ");
}
function analyzeBreakpoints(breakpoints) {
  var _a;
  if (!breakpoints)
    return null;
  breakpoints.base = (_a = breakpoints.base) != null ? _a : "0px";
  const normalized = normalize(breakpoints);
  const queries = Object.entries(breakpoints).sort(sortByBreakpointValue).map(([breakpoint, minW], index, entry) => {
    var _a2;
    let [, maxW] = (_a2 = entry[index + 1]) != null ? _a2 : [];
    maxW = parseFloat(maxW) > 0 ? subtract(maxW) : void 0;
    return {
      _minW: subtract(minW),
      breakpoint,
      minW,
      maxW,
      maxWQuery: toMediaQueryString(null, maxW),
      minWQuery: toMediaQueryString(minW),
      minMaxQuery: toMediaQueryString(minW, maxW)
    };
  });
  const _keys = keys(breakpoints);
  const _keysArr = Array.from(_keys.values());
  return {
    keys: _keys,
    normalized,
    isResponsive(test) {
      const keys2 = Object.keys(test);
      return keys2.length > 0 && keys2.every((key) => _keys.has(key));
    },
    asObject: sortBps(breakpoints),
    asArray: normalize(breakpoints),
    details: queries,
    media: [
      null,
      ...normalized.map((minW) => toMediaQueryString(minW)).slice(1)
    ],
    toArrayValue(test) {
      if (!isObject(test)) {
        throw new Error("toArrayValue: value must be an object");
      }
      const result = _keysArr.map((bp) => {
        var _a2;
        return (_a2 = test[bp]) != null ? _a2 : null;
      });
      while (getLastItem(result) === null) {
        result.pop();
      }
      return result;
    },
    toObjectValue(test) {
      if (!Array.isArray(test)) {
        throw new Error("toObjectValue: value must be an array");
      }
      return test.reduce((acc, value, index) => {
        const key = _keysArr[index];
        if (key != null && value != null)
          acc[key] = value;
        return acc;
      }, {});
    }
  };
}

export {
  px,
  toMediaQueryString,
  analyzeBreakpoints
};
