import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react'
import { useRef } from 'react'

export const Modal = ({ isOpen, onClose, title, body, submitLabel, onSubmit, isLoading, size = 'lg' }) => {
  const cancelRef = useRef()

  return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isCentered
                size={size}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {title}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {body}
                        </AlertDialogBody>

                        {onClose && onSubmit && submitLabel && <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button variant='primary' onClick={onSubmit} isLoading={isLoading} ml={3}>
                                {submitLabel}
                            </Button>
                        </AlertDialogFooter>}
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
  )
}
