import { Icon } from '@chakra-ui/react'

export const CheckIcon = () => {
  return (
        <Icon xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 24 25" fill="none">
            <g clipPath="url(#clip0_1337_2580)">
                <path d="M9.00016 16.6698L4.83016 12.4998L3.41016 13.9098L9.00016 19.4998L21.0002 7.49984L19.5902 6.08984L9.00016 16.6698Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1337_2580">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </Icon>
  )
}
