import { Button, Center, Flex, Stack, Text } from '@chakra-ui/react'

export const NoResults = ({ label, buttonLabel = null }) => {
  return (
        <Center boxShadow={'md'} backgroundColor='#fff' minH={'60vh'} borderRadius={'10px'}>
            <Stack alignItems={'center'} spacing={6}>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    {/* <Icon as={icon} width='90px' height='90px' /> */}
                    <Text>{label}</Text>
                </Flex>
                {buttonLabel && <Button variant='primary'>{buttonLabel}</Button>}
            </Stack>
        </Center>
  )
}
