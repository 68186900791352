import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const listAllTeachers = async (schoolIDParam) => {
  const schoolID = schoolIDParam ? schoolIDParam : localStorage.getItem('user_id')

  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/teacher/school/${schoolID}`)

  return response.data.message
}

export const createMonitorApi = async (data) => {
  const response = await httpRequestHelper.postWithAuth(BACKEND_API + '/v1/teacher/monitor', data)
  console.log(response)
  return response.data.message
}

export const createIndependentTeacherApi = async (data) => {
  const response = await httpRequestHelper.postWithAuth(BACKEND_API + '/v1/teacher/independentTeacher', data)

  return response.data
}

export const deleteIndependentTeacherApi = async (teacherID) => {
  const response = await httpRequestHelper.delWithAuth(BACKEND_API + `/v1/teacher/independentTeacher/${teacherID}/${process.env.OWNER_SECRET_KEY}`)

  return response.data
}

export const updateTeacherApi = async (data, teacherID) => {
  const response = await httpRequestHelper.putWithAuth(BACKEND_API + `/v1/teacher/${teacherID}`, data)

  return response
}

export const getTeacherByIdApi = async (teacherID) => {
  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/teacher/${teacherID}`)

  return response.data.message
}

export const deleteTeacherApi = async (teacherID) => {
  const response = await httpRequestHelper.delWithAuth(BACKEND_API + `/v1/teacher/${teacherID}`)

  return response.data.message
}
