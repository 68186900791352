import './AreaDeTemas.css'
import Headbar from '../../components/Headbar'
import Menu from '../../components/Menu'
import { Link } from 'react-router-dom'
import TemaCard from '../../components/TemaCard'
import SharedCardPagination from '../../components/SharedCardPagination'
import { useEffect, useState } from 'react'
import { listAllThemes } from '../../apis/themeApi/themeApi'
import sanitizedDate from '../../utils/DateSatinizerInput'
import Page from '../Page'

function AreaDeTemas() {
  const itemsPerPage = window.innerWidth > 2390 ? 9 : 10

  const [temas, setTemas] = useState()
  const [temasData, setTemasData] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const data = await listAllThemes()
      setTemasData(data)
    }
    fetchData()
  }, [])

  useEffect(() => {
    setTemas(temasData) // Atualiza temas quando temasData muda
  }, [temasData])

  function handleSearchInputChange(e) {
    const inputText = e.target.value

    setTemas(
      temasData.filter((item) => {
        return item.subject.toLowerCase().includes(inputText.toLowerCase()) ||
            sanitizedDate(item.createdAt).includes(inputText)
      })
    )
  }

  return (
    <div className="area-de-temas screen-area-de-temas">
      <Page authPage={true} allowedUserType={'teacher monitor independentteacher'} />
      <Menu />
      <Headbar />
      <div className={'area-de-temas-body'}>
        <div className="search-filter-area-de-temas">
          <div className="title-cta-area-de-temas">
            <h1 className="rea-de-temas-area-de-temas publicsans-normal-absolute-zero-40px">Área de temas</h1>
            <Link to={'/criar-tema'}>
              <div className="cta-criar-atividade-area-de-temas">
                <div className="button-1-area-de-temas">
                  <div className="mainbutton-1-area-de-temas">
                    <div className="frame-29-area-de-temas">
                      <img className="ic-add-area-de-temas" src="/img/ic-add-6.svg" alt="ic-add" />
                      <div className="criar-tema-area-de-temas valign-text-middle-area-de-temas inter-bold-zircon-14px">
                        Criar tema
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="search-filter-1-area-de-temas">
        <div className="search-filtro-area-de-temas">
          <div className="search-area-de-temas">
            <img className="search-2851823-area-de-temas" src="/img/search-2851823@2x.png" alt="search-2851823" />
            <input
              className="text-search-input-area-atividades publicsans-normal-nevada-16px"
              placeholder={'Buscar temas...'}
              type={'search'}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>
      <div className="atividades-1-area-de-temas">
        {temas ? <SharedCardPagination data={temas} RenderComponent={TemaCard} itemsPerPage={itemsPerPage} /> : null}
      </div>
    </div>
  )
}

export default AreaDeTemas
