import React from 'react'
import './Title2.css'

function Title2(props) {
  const { children } = props

  return (
    <div className="title-7">
      <div className="turmas-5 publicsans-normal-resolution-blue-24px">{children}</div>
    </div>
  )
}

export default Title2
