import axios from 'axios'

const get = async function (url) {
  try {
    const response = await axios({
      method: 'get',
      url
    })
    throwIfNotSucceeded(response)
    return response
  } catch (error) {
    redirectIfNotAuthorized(error)
    throw error
  }
}

const getWithAuth = async function (url) {
  try {
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user_token')}`
      }
    })
    throwIfNotSucceeded(response)
    return response
  } catch (error) {
    redirectIfNotAuthorized(error)
    throw error
  }
}

const post = async function (url, body) {
  try {
    const response = await axios({
      method: 'post',
      url,
      data: body
    })
    throwIfNotSucceeded(response)
    return response
  } catch (error) {
    redirectIfNotAuthorized(error)
    throw error
  }
}

const postWithAuth = async function (url, body) {
  try {
    const response = await axios({
      method: 'post',
      url,
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user_token')}`
      }
    })
    throwIfNotSucceeded(response)
    return response
  } catch (error) {
    redirectIfNotAuthorized(error)
    throw error
  }
}

const putWithAuth = async function (url, body) {
  try {
    const response = await axios({
      method: 'put',
      url,
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user_token')}`
      }
    })
    throwIfNotSucceeded(response)
    return response
  } catch (error) {
    redirectIfNotAuthorized(error)
    return error
  }
}

const delWithAuth = async function (url) {
  try {
    const response = await axios({
      method: 'delete',
      url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user_token')}`
      }
    })
    throwIfNotSucceeded(response)
    return response
  } catch (error) {
    redirectIfNotAuthorized(error)
    throw error
  }
}

function throwIfNotSucceeded(response) {
  if (response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204) {
    throw Error(response.data.message)
  }
}

function redirectIfNotAuthorized(error) {
  if (error.message.includes('401')) {
    console.log('unauthorized')
    window.location.href = '/login'
  }
}

export const httpRequestHelper = {
  get,
  getWithAuth,
  post,
  postWithAuth,
  putWithAuth,
  delWithAuth
}
