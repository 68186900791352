import React, { useState } from 'react'
import {
  Box,
  Button,
  Container,
  HStack,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Spacer,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { institutionGradeTypeEnum } from '../../utils/institutionGradeTypeEnum'
import { deleteInstitutionApi, putInstitutionApi } from '../../apis/institutionApi/institutionApi'
import './InstitutionCard.css'
import { createCompetencyApi, deleteCompetencyApi, putCompetencyApi } from '../../apis/institutionApi/competencyApi'

function InstitutionCard(props) {
  const { itemData, parentProps } = props

  const { fetchAllInstitutions } = parentProps

  const popoverDisclosure = useDisclosure()
  const modalEditDisclosure = useDisclosure()

  const modalDeleteCompetencyDisclosure = useDisclosure()
  const modalEditCompetencyDisclosure = useDisclosure()
  const modalCreateCompetencyDisclosure = useDisclosure()

  const [editCompetency, setEditCompetency] = useState({})

  // COMPETENCY MODALS

  function GetModalCreateCompetency() {
    const [createCompetencyData, setCreateCompetencyData] = useState({
      name: ''
    })

    return (
      <Modal isOpen={modalCreateCompetencyDisclosure.isOpen} size={'2xl'}
             onClose={modalCreateCompetencyDisclosure.onClose}
             isCentered={true}
             closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal'}>Criar
            Competência</ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={'publicsans-bold-nevada-16px'}>Nome da Competência</div>
              <Input className={'input-default-style'}
                     onChange={(e) => setCreateCompetencyData({ ...createCompetencyData, name: e.target.value })}
                     defaultValue={createCompetencyData.name}
              />
            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button backgroundColor={'#f9faff'}
                    borderColor={'#648BF7'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#062989'}
                    height={'48px'}
                    width={'218px'} onClick={modalCreateCompetencyDisclosure.onClose}>
              Cancelar
            </Button>
            <Button backgroundColor={'#0A41D6'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#FFFFFF'}
                    height={'48px'}
                    width={'218px'}
                    onClick={async () => {
                      if (createCompetencyData.name) {
                        await createCompetencyApi(createCompetencyData, itemData.id)
                        fetchAllInstitutions()
                        modalCreateCompetencyDisclosure.onClose()
                      }
                    }}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  function GetModalEditCompetency() {
    const [editCompetencyData, setEditCompetencyData] = useState({
      competencyId: editCompetency.id,
      name: editCompetency.name
    })

    return (
      <Modal isOpen={modalEditCompetencyDisclosure.isOpen} size={'2xl'} onClose={modalEditCompetencyDisclosure.onClose}
             isCentered={true}
             closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal'}>Editar
            Competência</ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={'publicsans-bold-nevada-16px'}>Nome da Competência</div>
              <Input className={'input-default-style'}
                     onChange={(e) => setEditCompetencyData({ ...editCompetencyData, name: e.target.value })}
                     defaultValue={editCompetencyData.name}
              />
            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button backgroundColor={'#f9faff'}
                    borderColor={'#648BF7'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#062989'}
                    height={'48px'}
                    width={'218px'} onClick={modalEditCompetencyDisclosure.onClose}>
              Cancelar
            </Button>
            <Button backgroundColor={'#0A41D6'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#FFFFFF'}
                    height={'48px'}
                    width={'218px'}
                    onClick={async () => {
                      if (editCompetencyData.name) {
                        await putCompetencyApi(editCompetencyData, editCompetency.institutionId)
                        fetchAllInstitutions()
                        modalEditCompetencyDisclosure.onClose()
                      }
                    }}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  function GetModalDeleteCompetency() {
    return (
      <Modal isOpen={modalDeleteCompetencyDisclosure.isOpen} size={'2xl'}
             onClose={modalDeleteCompetencyDisclosure.onClose} isCentered={true}
             closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>

          <ModalHeader fontWeight={'400'}
                       className={'title-modal'}>{`Confirme remoção da competência '${editCompetency.name}'`}</ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button backgroundColor={'#f9faff'}
                    borderColor={'#648BF7'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#062989'}
                    height={'48px'}
                    width={'218px'} onClick={modalDeleteCompetencyDisclosure.onClose}>
              Cancelar
            </Button>
            <Button backgroundColor={'#0A41D6'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#FFFFFF'}
                    height={'48px'}
                    width={'218px'}
                    onClick={async () => {
                      await deleteCompetencyApi(editCompetency.id, editCompetency.institutionId)
                      fetchAllInstitutions()
                      modalDeleteCompetencyDisclosure.onClose()
                    }}>
              Remover Competência
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  // INSTITUTION MODAL

  function GetModalEditInstitution() {
    const [editInstitutionData, setEditInstitutionData] = useState({
      institutionId: itemData.id,
      name: itemData.name,
      institutionGradeType: itemData.institutionGradeType,
      maxGrade: itemData.maxGrade
    })

    const handleMaxGradeInputChange = (e) => {
      const inputValue = e.target.value

      // Check if the input value is a number or empty
      if (/^[0-9]*$/.test(inputValue)) {
        const numValue = parseInt(inputValue, 10)
        if (inputValue === '' || (numValue >= 0 && numValue <= 100000)) {
          setEditInstitutionData({ ...editInstitutionData, maxGrade: parseInt(e.target.value, 10) })
        }
      }
    }

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'lightblue' : null
      }),
      control: (provided) => ({
        ...provided
      })
    }

    return (
      <Modal isOpen={modalEditDisclosure.isOpen} size={'2xl'} onClose={modalEditDisclosure.onClose} isCentered={true}
             closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal'}>Editar
            Instituição</ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={'publicsans-bold-nevada-16px'}>Nome da Instituição</div>
              <Input className={'input-default-style'}
                     onChange={(e) => setEditInstitutionData({ ...editInstitutionData, name: e.target.value })}
                     defaultValue={editInstitutionData.name}
              />
              <div className={'publicsans-bold-nevada-16px'}>Tipo de avaliação</div>
              <Select
                placeholder={'Selecionar a forma de avaliação dessa instituição'}
                onChange={(e) => {
                  setEditInstitutionData({ ...editInstitutionData, institutionGradeType: parseInt(e.target.value, 10) })
                }}
                borderColor={'#d7e3fb'}
                backgroundColor={'white'}
                styles={customStyles}
                defaultValue={editInstitutionData.institutionGradeType}
                w={'100%'}>

                {Object.entries(institutionGradeTypeEnum).map((option, key) => (
                  <option className={'select-grade-type-text'} key={key} value={option[0]}>
                    {option[1]}
                  </option>
                ))}
              </Select>
              <div className={'publicsans-bold-nevada-16px'}>Nota máxima</div>
              <Input className={'input-default-style'}
                     onChange={handleMaxGradeInputChange}
                     defaultValue={editInstitutionData.maxGrade}
              />
            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button backgroundColor={'#f9faff'}
                    borderColor={'#648BF7'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#062989'}
                    height={'48px'}
                    width={'218px'} onClick={modalEditDisclosure.onClose}>
              Cancelar
            </Button>
            <Button backgroundColor={'#0A41D6'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#FFFFFF'}
                    height={'48px'}
                    width={'218px'}
                    onClick={async () => {
                      if (editInstitutionData.maxGrade > 0 && editInstitutionData.name && (editInstitutionData.institutionGradeType === 0 || editInstitutionData.institutionGradeType === 10)) {
                        await putInstitutionApi(editInstitutionData)
                        fetchAllInstitutions()
                        modalEditDisclosure.onClose()
                      }
                    }}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  function HandleCompetencies() {
    if (itemData.competencies.length > 0) {
      return itemData.competencies.map((item, key) => {
        return (
          <div className="competency-row" key={key}>
            <HStack width={'100%'}>
              <div className="competency-row-text publicsans-normal-nevada-14px">
                {item.name}
              </div>

              <Spacer/>

              <Menu placement={'bottom-start'}>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<img src={'/img/more-vert.svg'}/>}
                  variant='outline'
                  marginRight={'16px'}
                />
                <MenuList>
                  <MenuItem onClick={() => {
                    setEditCompetency(item)
                    modalEditCompetencyDisclosure.onOpen()
                  }}>
                    Editar Competência
                  </MenuItem>

                  <MenuItem onClick={() => {
                    setEditCompetency(item)
                    modalDeleteCompetencyDisclosure.onOpen()
                  }}>
                    Remover Competência
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </div>
        )
      })
    } else {
      return (
        <Container height={'50px'}>
        </Container>
      )
    }
  }

  function Institution() {
    return (
      <div className="institution-area-de-institutions">
        <div className="title-link-area-de-institutions">
          <HStack justifyContent={'space-between'} width={'100%'} display={'flex'} direction={'column'}
                  marginLeft={'16px'}>

            <VStack>
              <p className="institution-name-label publicsans-bold-absolute-zero-16px">
                {itemData.name}
              </p>
              <Spacer />
              <HStack className="frame-2606-detalhamento-institution" gap={'32px'}>
                <div>
                  <div className="publicsans-bold-nevada-10px">
                    Forma de avaliacao
                  </div>
                  <div>{institutionGradeTypeEnum[itemData.institutionGradeType]}</div>
                </div>
                <div>
                  <div className="publicsans-bold-nevada-10px">
                    Nota máxima
                  </div>
                  <div> {itemData.maxGrade} </div>
                </div>
              </HStack>
            </VStack>
            <Spacer/>
            <VStack>
              <img
                className="mode-edit-area-de-institution"
                src="/img/mode-edit@2x.png"
                alt="Mode edit"
                onClick={() => {
                  modalEditDisclosure.onOpen()
                }}
              />
              <GetModalEditInstitution/>
              <Spacer/>
              <Box float={'right'}>
                <Popover isOpen={popoverDisclosure.isOpen} onOpen={popoverDisclosure.onOpen}
                         onClose={popoverDisclosure.onClose} placement="bottom" closeOnBlur={false}>
                  <PopoverTrigger>
                    <Image src={'/img/deleteIcon.svg'} cursor={'pointer'}/>
                  </PopoverTrigger>
                  <PopoverContent width={'350px'} borderRadius={'10px'}>
                    <PopoverArrow/>
                    <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'}/>
                    <PopoverHeader>
                      <div className={'text-popover'}>Confirme Remoção da Instituição</div>
                    </PopoverHeader>
                    <PopoverFooter alignSelf={'center'} marginTop={'50px'} width={'100%'}>
                      <HStack width={'100%'}>
                        <Button backgroundColor={'#f9faff'}
                                borderColor={'#648BF7'}
                                border={'1px'}
                                borderRadius={'8px'}
                                color={'#062989'}
                                height={'48px'}
                                width={'100%'} onClick={popoverDisclosure.onClose}>
                          Cancelar
                        </Button>
                        <Button backgroundColor={'#0A41D6'}
                                color={'white'}
                                width={'100%'}
                                height={'48px'}
                                onClick={async () => {
                                  await deleteInstitutionApi(itemData.id)
                                  fetchAllInstitutions()
                                }}
                        > Confirmar Remoção </Button>
                      </HStack>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </Box>
            </VStack>
          </HStack>

        </div>
        <div className="content-1-area-de-institutions">
          <div className="frame-2588-area-de-institutions">
            <div className="information-card-area-de-institutions">
              <div className="competencias-vinculadas-area-de-institutions publicsans-normal-nevada-16px">
                Competências vinculadas:
              </div>

              <HandleCompetencies/>
              <GetModalEditCompetency/>
              <GetModalDeleteCompetency/>
              <GetModalCreateCompetency/>

            </div>
          </div>
          <div className="button-area-de-institutions" onClick={modalCreateCompetencyDisclosure.onOpen}>
            <Mainbutton>
              <HStack justifyContent={'center'}>
                <img className="ic-add-competencia" src="/img/ic-add-6.svg" alt="ic-add"/>
                <div> {'Criar Competência'} </div>
              </HStack>
            </Mainbutton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="institution-card-area-de-institutions">
      <Institution/>
    </div>
  )
}

export default InstitutionCard

function Mainbutton(props) {
  const { children } = props

  return (
    <div className="mainbutton-area-de-institutions">
      <div className="content-2-area-de-institutions">
        <div className="main-button-area-de-institutions publicsans-bold-white-14px">
          {children}
        </div>
      </div>
    </div>
  )
}
