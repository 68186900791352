import React from 'react'
import './Content.css'

function Content(props) {
  const { children } = props

  return (
    <div className="content-1">
      <div className="main-button publicsans-bold-white-14px">{children}</div>
    </div>
  )
}

export default Content
