import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import './SharedTablePagination.css'

export default function SharedTablePagination({ data, itemsPerPage, columns, className }) {
  const [currentPage, setCurrentPage] = useState(0)

  const handlePageClick = ({ selected: selectedPage }) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setCurrentPage(selectedPage)
  }
  const offset = currentPage * itemsPerPage
  const currentPageData = data.slice(offset, offset + itemsPerPage)

  const pageCount = Math.ceil(data.length / itemsPerPage)
  return (
        <div className={'shared-table-pagination'}>
            <div className={'turmas-2-shared-table-pagination ' + className}>
                <div className="frame-2582-shared-table-pagination">
                    <div className="tabela-atividades-shared-table-pagination">
                        {columns.map(({ title, render }) => (
                            <div key={title} className={`tabela-02-1-shared-table-pagination ${title === 'Detalhes' ? 'detalhes-table-style' : ''}`}>
                                <div className="tabela-title-shared-table-pagination">
                                    <div className="turma-shared-table-pagination publicsans-normal-gray-14px">
                                        {title}
                                    </div>
                                </div>
                                <div className="table-paginated-items">
                                    {currentPageData.map((itemData, index) => (
                                        <div key={index} className="column">
                                            {render(itemData)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={'pagination-bar'}>
                <ReactPaginate
                    previousLabel={<img className="ic-arrow-pagination"
                                        src="/img/ic-arrowleft@2x.png" alt="ic-arrowleft"/>}
                    nextLabel={<img className="ic-arrow-pagination" src="/img/ic-arrowright@2x.png"
                                    alt="ic-arrowright"/>}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'current-page-pagination'}
                    pageLinkClassName={'number-pagination'}
                />
            </div>
        </div>
  )
}
