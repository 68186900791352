import React from 'react'
import './Frame258062.css'

function Frame258062(props) {
  const { children } = props

  return (
    <div className="frame-2580-10">
      <div className="x1-ano-a valign-text-middle publicsans-normal-black-12px">{children}</div>
    </div>
  )
}

export default Frame258062
