export const BACKEND_API =
    process.env.REACT_APP_PROD_BACKEND ||
    process.env.REACT_APP_HOMOLOG_BACKEND

export const USER_ROLE_CODE = {
  OWNER: 0,
  ADM: 10,
  INDEPENDENT_TEACHER: 15,
  MONITOR: 17,
  TEACHER: 20,
  STUDENT: 30
}
