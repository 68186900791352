import { create } from 'zustand'

const useLoggedUserStore = create((set) => ({
  loggedUser: {
    user_email: localStorage.getItem('user_email') || '',
    user_role: localStorage.getItem('user_role') || '',
    user_language: localStorage.getItem('user_language') || '',
    user_name: localStorage.getItem('user_name') || '',
    user_id: localStorage.getItem('user_id') || '',
    institutionId: '',
    schoolId: ''
  },
  setLoggedUserData: (data) => set((state) => ({
    loggedUser: { ...state.loggedUser, ...data }
  })),
  cleanLoggedUserData: () => set({ loggedUser: {} })
}))

export default useLoggedUserStore
