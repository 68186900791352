import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const getStudentById = async (studentID) => {
  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/student/${studentID}`)

  return response.data.message
}

export const putStudentById = async (studentID, data) => {
  const response = await httpRequestHelper.putWithAuth(BACKEND_API + `/v1/student/${studentID}`, data)

  return response.data.message
}

export const deleteStudentById = async (studentID, data) => {
  const response = await httpRequestHelper.delWithAuth(BACKEND_API + `/v1/student/${studentID}`)

  return response.data.message
}

export const listAllStudentsFromSchoolById = async () => {
  const schoolID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/student/school/${schoolID}`)

  return response.data.message
}
