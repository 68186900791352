import React, { useEffect, useState } from 'react'
import './CriarAtividadeStep1.css'
import { Box, Flex, Heading, Input, Select, Text, Textarea, VStack } from '@chakra-ui/react'
import Headbar from '../Headbar'
import StepsImage from '../StepsImage'
import FormStepNotSelected from '../FormStepNotSelected'
import BackStepButton from '../BackStepButton'
import ForwardButton from '../ForwardButton'
import { listAllThemes } from '../../apis/themeApi/themeApi'
import useCriarAtividadeStore from '../../store/useCriarAtividadeStore'

function CriarAtividadeStep1(props) {
  const { handleReturn, handlingNextStep } = props
  const { criarAtividadeData, setCriarAtividadeData } = useCriarAtividadeStore()

  const maxCharacteres = 180

  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [nomeAtividadeError, setNomeAtividadeError] = useState(false)
  const [temaError, setTemaError] = useState(false)
  const [descricaoError, setDescricaoError] = useState(false)

  const handleTextChange = (event) => {
    if (event.target.value) {
      setDescricaoError(false)
    }
    const newText = event.target.value
    if (newText.length <= maxCharacteres) {
      setCriarAtividadeData({ description: newText })
    }
  }

  const fetchOptions = () => {
    return new Promise((resolve) => {
      const result = listAllThemes()

      resolve(result)
    })
  }

  useEffect(() => {
    fetchOptions().then((data) => {
      setOptions(
        data.map((tema) => {
          return { value: tema.subject, label: tema.subject, id: tema.id }
        })
      )
      setLoading(false)
    })
  }, [])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightblue' : null
    }),
    control: (provided) => ({
      ...provided
    })
  }

  const handleSubmit = () => {
    if (criarAtividadeData.subject && criarAtividadeData.themeId && criarAtividadeData.description) {
      handlingNextStep()
    } else {
      setNomeAtividadeError(!criarAtividadeData.subject)
      setTemaError(!criarAtividadeData.themeId)
      setDescricaoError(!criarAtividadeData.description)
    }
  }

  return (
    <Flex h={'100%'} w={'100%'}>
      <VStack w={'100%'} h={'100%'}>
        <Headbar returnTo={'/area-de-atividades'} />
        <VStack marginTop={'107px'}>
          <Heading alignSelf={'start'} ml={'35px'} className={'publicsans-extra-extra-bold-absolute-zero-32px'}>
            Criar atividade
          </Heading>
          <VStack mt={'32px'}>
            <StepsImage text={'Sobre a atividade'} number={1} />
            <div className="cards">
              <div className="frame-2546">
                <div className={`${nomeAtividadeError ? 'error-form' : ''} nome-criar-atividade-step-1 publicsans-bold-nevada-16px`}>{'Nome da atividade*'}</div>
                <Input h={'48px'} w={'100%'} backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       onChange={(e) => {
                         if (e.target.value) {
                           setNomeAtividadeError(false)
                         }
                         setCriarAtividadeData({ subject: e.target.value })
                       }}
                       defaultValue={criarAtividadeData.subject}
                />

                <div className={`${temaError ? 'error-form' : ''} nome-criar-atividade-step-1 publicsans-bold-nevada-16px`}>{'Selecionar o tema*'}</div>
                <Select
                  placeholder={loading ? 'Aguarde um momento...' : 'Selecione um tema'}
                  borderColor={'#d7e3fb'}
                  backgroundColor={'white'}
                  styles={customStyles}
                  isDisabled={loading}
                  onChange={(e) => {
                    if (e.target.value) {
                      setTemaError(false)
                      setCriarAtividadeData({ themeId: options.find(element => element.value === e.target.value).id })
                    } else {
                      setCriarAtividadeData({ themeId: '' })
                    }
                  }}
                >
                  {options.map((option, key) => (
                    <option className={'select-tema-text'} key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>

                <div className="atividade-descricao-container">
                  <div className={`${descricaoError ? 'error-form' : ''} descricao-title publicsans-bold-nevada-16px`}>{'Descrição'}</div>

                  <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
                    <Textarea
                      placeholder=""
                      value={criarAtividadeData.description}
                      onChange={handleTextChange}
                      resize="vertical"
                      w={'100%'}
                      h={'100%'}
                      borderColor={'#d7e3fb'}
                    />
                    <Text
                      fontSize="sm"
                      position="absolute"
                      bottom="5px"
                      right="20px"
                      color="#a9a8a8"
                      fontFamily={'Public Sans'}
                    >
                      {criarAtividadeData.description ? criarAtividadeData.description.length : 0}/{maxCharacteres}
                    </Text>
                  </Box>
                </div>

              </div>
            </div>
            <div className="steps">
              <FormStepNotSelected number={2} configuraesDaAtividade={'Configurações da atividade'} />
              <FormStepNotSelected number={3} configuraesDaAtividade={'Enviar'} />
            </div>
          </VStack>

          <div className="buttons">
            <BackStepButton handleReturn={handleReturn} text={'Voltar'} />
            <ForwardButton forwardTo={handleSubmit} className={'button-10'} text={'Continuar'} buttonClassName={''} />
          </div>
        </VStack>
      </VStack>
    </Flex>
  )
}

export default CriarAtividadeStep1
