import React from 'react'
import './Frame2434.css'

function Frame2434(props) {
  const { className, redacoesParaCorrigir, redacoesCorrigidas, redacoesTotal, progressPercentageBlue, progressPercentageGreen } = props

  return (
    <div className={`frame-2434-4 ${className || ''}`}>
      <div className="andamento publicsans-bold-absolute-zero-16px">{'Andamento'}</div>
      <div className="atvd">
        <div className="frame-2434-1">
        <div className="rectangle-10-4" style={{ width: `${progressPercentageBlue}%` }}></div>
        </div>
        <div className="x25-atividades valign-text-middle publicsans-normal-nevada-16px">
          {redacoesParaCorrigir + '/' + redacoesTotal + ' Redações para corrigir'}
        </div>
      </div>
      <div className="atvd">
        <div className="frame-2434-1">
        <div className="rectangle-10-1" style={{ width: `${progressPercentageGreen}%` }}></div>
        </div>
        <div className="x25-atividades valign-text-middle publicsans-normal-nevada-16px">
          {redacoesCorrigidas + '/' + redacoesTotal + ' Redações corrigidas'}
        </div>
      </div>
    </div>
  )
}

export default Frame2434
