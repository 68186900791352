export function HandleDate({ dateSubmitted }) {
  let date = ''
  if (dateSubmitted) {
    date = new Date(dateSubmitted).toLocaleDateString() + ' às ' + new Date(dateSubmitted).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })
  }
  return (<div className={'table-cell-container publicsans-normal-black-12px'}>
    {date !== '' ? <img className="calendar-2851774-1-detalhamento-atividade" src={'/img/calendar-2851774@2x.png'}
                        alt="calendar-2851774"/> : ''}
    {date}</div>)
}
