import React, { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import './DetalhamentoTurma.css'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import SharedTablePagination from '../../components/SharedTablePagination'
import Headbar from '../../components/Headbar'
import {
  deleteGroup,
  getActivityInformation,
  getEssaysInformation,
  getGroupInformation,
  getStudentInformation,
  postStudent,
  putEditGroup
} from '../../apis/groupApi/GroupApi'
import { essayStatus } from '../../utils/essayStatus'
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Textarea,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import AtividadeTxt52 from '../../components/AtividadeTxt52'
import sanitizedDate from '../../utils/DateSatinizerInput'
import Page from '../Page'

function DetalhamentoTurma() {
  const { turmaID } = useParams()
  const location = useLocation()

  const schoolID = location.state ? location.state.schoolID : null

  const navigate = useNavigate()
  const [turmasData, setTurmasData] = useState({})
  const [essaysData, setEssaysData] = useState([])
  const [essays, setEssays] = useState([])
  const [activityData, setActivityData] = useState([])
  const [activity, setActivity] = useState([])
  const [studentData, setStudentData] = useState([])
  const [student, setStudent] = useState([])
  const [alunosAtivos, setAlunosAtivos] = useState([])
  const [redacoesFeitas, setRedacoesFeitas] = useState([])
  const modalDisclosure = useDisclosure()
  const { onOpen } = useDisclosure()
  const [loading, setLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState('alunos')
  const popoverDisclosure = useDisclosure()
  const [isOpenAluno, setIsOpenAluno] = useState(false)
  const [filterTextStudent, setFilterTextStudent] = useState('')
  const [filterTextActivity, setFilterTextActivity] = useState('')
  const [filterTextEssay, setFilterTextEssay] = useState('')

  const onOpenAluno = () => {
    setIsOpenAluno(true)
  }

  const onCloseAluno = () => {
    setIsOpenAluno(false)
  }

  function fetchGroup() {
    Promise.all([getGroupInformation(turmaID, schoolID)])
      .then(([groupInformation]) => {
        setTurmasData(groupInformation)
      })
  }

  async function fetchInitialData() {
    const [studentsInformation, activityInformation, essaysInformation, groupInformation] = await Promise.all([
      getStudentInformation(turmaID, schoolID),
      getActivityInformation(turmaID),
      getEssaysInformation(turmaID),
      getGroupInformation(turmaID, schoolID)
    ])
    return {
      studentsInformation, activityInformation, essaysInformation, groupInformation
    }
  }

  useEffect(() => {
    async function fetchData() {
      fetchInitialData().then(({ studentsInformation, activityInformation, essaysInformation, groupInformation }) => {
        setStudentData(studentsInformation)
        setActivityData(activityInformation)
        setEssaysData(essaysInformation)
        setTurmasData(groupInformation)

        setLoading(false)
      })
    }

    fetchData()
  }, [])

  useEffect(() => {
    handleRender()
  }, [loading])

  function fetchStudents() {
    Promise.all([getStudentInformation(turmaID, schoolID)])
      .then(([studentsInformation]) => {
        setStudentData(studentsInformation)
      })
  }

  // console.log(activityData)

  function handleRender() {
    geradorStatus()
    geradorTema()
    geradorSetas()
    geradorAluno()
    geradorDataDeEnvio()
    geradorAlunosAtivos()
    geradorRedacoesFeitas()
  }

  useEffect(() => {
    setEssays(
      essaysData.filter((item) => {
        const themeMatch = item.theme?.name.toLowerCase().includes(filterTextEssay.toLowerCase())

        const studentMatch = item.student?.name.toLowerCase().includes(filterTextEssay.toLowerCase())

        const statusMatch = essayStatus[item.status].toLowerCase().includes(filterTextEssay.toLowerCase())

        const dateMatch = (item.dateSubmitted && sanitizedDate(item.dateSubmitted).includes(filterTextEssay))

        return themeMatch || studentMatch || statusMatch || dateMatch
      })
    )
  }, [filterTextEssay, essaysData])

  useEffect(() => {
    setStudent(
      studentData.filter((item) => {
        const studentMatch = item.name.toLowerCase().includes(filterTextStudent.toLowerCase())

        return studentMatch
      })
    )
  }, [filterTextStudent, studentData])

  useEffect(() => {
    setActivity(
      activityData.filter((item) => {
        const themeMatch = item.theme.name.toLowerCase().includes(filterTextActivity.toLowerCase())

        const activityMatch = item.subject.toLowerCase().includes(filterTextActivity.toLowerCase())

        const dateMatch = (item.deadline && sanitizedDate(item.deadline).includes(filterTextActivity))

        return themeMatch || activityMatch || dateMatch
      })
    )
  }, [filterTextActivity, activityData])

  function geradorStatusAluno() {
    return <AtividadeTxt3/>
  }

  function AtividadeTxt3() {
    return (
      <div className="atividade-txt-2-area-de-atividades">
        <div className="frame-2581-1-area-de-atividades">
          <div className="ativa-area-de-atividades valign-text-middle-area-de-atividades publicsans-normal-black-12px">
            Ativa
          </div>
        </div>
      </div>
    )
  }

  function geradorStatus(status) {
    let backgroundColor
    if (status === 10) {
      backgroundColor = 'in-progress-essay-status'
    } else if (status === 20) {
      backgroundColor = 'in-grading-essay-status'
    } else if (status === 30) {
      backgroundColor = 'in-review-essay-status'
    } else if (status === 40) {
      backgroundColor = 'finished-essay-status'
    }
    return (
      <div className={'atividade-txt-detalhamento-turma publicsans-normal-black-12px'}>
        <div className={backgroundColor}>{essayStatus[status]}</div>
      </div>
    )
  }

  function geradorRedacoesEntregues(student) {
    let redacoesFeitas = 0
    student.essays.forEach((item) => {
      if (item.status === 40) {
        redacoesFeitas = redacoesFeitas + 1
      }
    })
    return <AtividadeTxt52 entregas={redacoesFeitas}/>
  }

  function geradorDeRedacoesParaCorrigir(activityData) {
    let redacoesParaCorrigir = 0
    if (!Array.isArray(activityData.amountSubmitted) || activityData.amountSubmitted.length === 0) {
      redacoesParaCorrigir = 0
      return <AtividadeTxt52 entregas={redacoesParaCorrigir}/>
    } else {
      redacoesParaCorrigir = activityData.amountSubmitted.length
      return <AtividadeTxt52 entregas={redacoesParaCorrigir}/>
    }
  }

  function geradorTema(tema) {
    return <AtividadeTxt21 tema={tema}/>
  }

  function geradorSetas(essaysId) {
    return <AtividadeTxt62 essaysId={essaysId}/>
  }

  function geradorAluno(student) {
    return <AtividadeTxt22 nomeAluno={student}/>
  }

  function geradorDataDeEnvio(dataDeEnvio) {
    const dataNula = ''
    if (dataDeEnvio === undefined || dataDeEnvio === null) {
      return <AtividadeTxt20 date={dataNula}/>
    } else {
      const dateWithoutFractions = dataDeEnvio.split('.')[0] // Remover as frações de segundos
      const parsedDate = parseISO(dateWithoutFractions)
      const formattedDate = format(parsedDate, "dd/MM/yyyy 'às' HH:mm")
      return <AtividadeTxt20 date={formattedDate}/>
    }
  }

  function geradorAlunosAtivos() {
    if (!Array.isArray(studentData) || studentData.length === 0) {
      setAlunosAtivos(0)
    } else {
      setAlunosAtivos(studentData.length)
    }
  }

  function geradorRedacoesFeitas() {
    let redacoesTotaisFeitas = 0
    essaysData.map((item) => {
      if (!Array.isArray(item.status) && item.status === 40) {
        redacoesTotaisFeitas = redacoesTotaisFeitas + 1
      } else {
        redacoesTotaisFeitas = redacoesTotaisFeitas + 0
      }
    })
    setRedacoesFeitas(redacoesTotaisFeitas)
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function GetModalEditTurma() {
    const maxCharacteres = 180

    const [editTurmaData, setEditTurmaData] = useState({
      groupId: turmasData?.id,
      name: turmasData?.name,
      description: turmasData?.description,
      shift: turmasData?.shift
    })

    return (
      <Modal
        isOpen={modalDisclosure.isOpen}
        size={'2xl'}
        onClose={modalDisclosure.onClose}
        isCentered={true}
        closeOnOverlayClick={false}
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>
            Editar Turma
          </ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={'publicsans-bold-nevada-16px'}>Nome da turma*</div>
              <Input
                className={'input-default-style'}
                onChange={(e) => setEditTurmaData({ ...editTurmaData, name: e.target.value })}
                defaultValue={editTurmaData.name}
              />
              <div className={'publicsans-bold-nevada-16px'}>Mensagem Personalizada</div>
              <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
                <Textarea
                  placeholder=""
                  defaultValue={editTurmaData.description}
                  onChange={(e) => setEditTurmaData({ ...editTurmaData, description: e.target.value })}
                  resize="vertical"
                  w={'100%'}
                  h={'100%'}
                  minHeight={'150px'}
                  borderColor={'#d7e3fb'}
                />
                <Text fontSize="sm" position="absolute" bottom="5px" right="20px" color="#a9a8a8"
                      fontFamily={'Public Sans'}>
                  {editTurmaData.description ? editTurmaData.description.length : 0}/{maxCharacteres}
                </Text>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button
              backgroundColor={'#f9faff'}
              borderColor={'#648BF7'}
              border={'1px'}
              borderRadius={'8px'}
              color={'#062989'}
              height={'48px'}
              width={'218px'}
              onClick={modalDisclosure.onClose}
            >
              Cancelar
            </Button>
            <Button
              backgroundColor={'#0A41D6'}
              border={'1px'}
              borderRadius={'8px'}
              color={'#FFFFFF'}
              height={'48px'}
              width={'218px'}
              onClick={async () => {
                await putEditGroup(editTurmaData, schoolID)
                fetchGroup()
                modalDisclosure.onClose()
              }}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  async function handleAddStudent() {
    const name = document.getElementById('nome').value
    const email = document.getElementById('email').value
    const schoolID = localStorage.getItem('user_id')
    const language = localStorage.getItem('user_language')

    const requestBody = {
      email,
      name,
      groups: [turmaID],
      schoolId: schoolID,
      language
    }

    if (requestBody.email && requestBody.name) {
      try {
        const message = await postStudent(requestBody)
        console.log('Aluno adicionado com sucesso:', message)
      } catch (error) {
        console.error('Erro ao adicionar aluno:', error)
      }

      fetchStudents()
    }

    document.getElementById('nome').value = null
    document.getElementById('email').value = null
  }

  return (
    <div className="detalhamento-turmas screen-detalhamento-turma">
      <Page authPage={true} allowedUserType={'teacher independentteacher monitor'} />
      <Headbar returnTo={'/area-de-turmas'}/>
      <div className="frame-2591-detalhamento-turma">
        <TitleCta2></TitleCta2>
        <div className="frame-2547-detalhamento-turma">
          <div className="email-detalhamento-turma">
            <p id="editableContent" className="bem-vindoa-verif-detalhamento-turma publicsans-normal-nevada-16px">
              {loading ? '' : turmasData?.description}
            </p>
          </div>
        </div>
      </div>
      <div className={'detalhamento-turma-body'}>
        <div className="tema-detalhamento-turma">
          <div className="content-3-detalhamento-turma">
            <div className="frame-2593-detalhamento-turma">
              <div className="frame-2594-detalhamento-turma">
                <div className="frame-2609-detalhamento-turma">
                  <div className="turma-detalhamento-turma publicsans-bold-nevada-10px">TURMA</div>
                  <div className="frame-2610-detalhamento-turma">
                    <div
                      className="x1-ano-a-detalhamento-turma publicsans-normal-absolute-zero-24px">{turmasData?.name}</div>
                    <Frame25803/>
                  </div>
                </div>
                <div className="editar-detalhamento-turma">
                  <div className="edit-container-detalhamento-turma">
                    <div
                      className="editar-1-detalhamento-turma valign-text-middle-detalhamento-turma publicsans-bold-nevada-14px">
                      Editar
                    </div>
                    <img
                      className="mode-edit-detalhamento-atividade"
                      src="/img/mode-edit@2x.png"
                      alt="Mode edit"
                      onClick={modalDisclosure.onOpen}
                    />
                    <GetModalEditTurma/>
                  </div>
                </div>
              </div>
            </div>
            <HStack className="descrio-detalhamento-turma" width={'100%'}>
              <div className="data-detalhamento-turma">
                <div className={'publicsans-bold-nevada-14px'}> Apagar
                  turma?
                </div>
                <Popover
                  isOpen={popoverDisclosure.isOpen}
                  onOpen={popoverDisclosure.onOpen}
                  onClose={popoverDisclosure.onClose}
                  placement="left"
                  closeOnBlur={false}
                >
                  <PopoverTrigger>
                    <Image src={'/img/deleteIcon.svg'}
                           marginRight={'15px'} cursor={'pointer'}/>
                  </PopoverTrigger>
                  <PopoverContent width={'448px'} borderRadius={'10px'}>
                    <PopoverArrow/>
                    <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'}/>
                    <PopoverHeader>
                      <div className={'text-popover'}>Confirme Remoção da turma</div>
                    </PopoverHeader>
                    <PopoverFooter alignSelf={'center'} marginTop={'50px'} width={'100%'}>
                      <HStack width={'100%'}>
                        <Button
                          backgroundColor={'#f9faff'}
                          borderColor={'#648BF7'}
                          border={'1px'}
                          borderRadius={'8px'}
                          color={'#062989'}
                          height={'48px'}
                          width={'100%'}
                          onClick={popoverDisclosure.onClose}
                        >
                          Cancelar
                        </Button>
                        <Button
                          backgroundColor={'#0A41D6'}
                          color={'white'}
                          width={'100%'}
                          height={'48px'}
                          onClick={async () => {
                            await deleteGroup(turmaID)
                            navigate('/area-de-turmas')
                          }}
                        >
                          {' '}
                          Confirmar Remoção{' '}
                        </Button>
                      </HStack>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </div>
            </HStack>
          </div>
        </div>
        <Status alunosAtivos={alunosAtivos} redacoesFeitas={redacoesFeitas}/>
        <div className="frame-2595-detalhamento-turma">
          <div className="switch-detalhamento-turma">
            <div
              className={`frame-2470-detalhamento-turma ${selectedTab === 'alunos' ? 'tab-border' : ''}`}
              onClick={() => changeSelectedTab('alunos')}
            >
              <div
                className={`alunos-detalhamento-turma publicsans-bold-gray-16px ${
                  selectedTab === 'alunos' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'
                }`}
              >
                Alunos
              </div>
            </div>
            <div
              className={`frame-2469-detalhamento-turma ${selectedTab === 'atividades vinculadas' ? 'tab-border' : ''}`}
              onClick={() => changeSelectedTab('atividades vinculadas')}
            >
              <div
                className={`atividades-vinculadas-detalhamento-turma publicsans-bold-gray-16px ${
                  selectedTab === 'atividades vinculadas' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'
                }`}
              >
                Atividades vinculadas
              </div>
            </div>
            <div
              className={`frame-2459-detalhamento-turma ${selectedTab === 'redacoes' ? 'tab-border' : ''}`}
              onClick={() => changeSelectedTab('redacoes')}
            >
              <div
                className={`redaes-detalhamento-turma publicsans-bold-nevada-16px ${
                  selectedTab === 'redacoes' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'
                }`}
              >
                Redações
              </div>
            </div>
          </div>
        </div>
        {selectedTab === 'alunos' && (
          <div className={'redacoes-container-style'}>
            <HStack width={'100%'} alignItems="end">
              <div className="search-filter-detalhamento-turma">
                <div className="search-filtro-detalhamento-turma">
                  <div className="search-detalhamento-turma">
                    <img className="search-2851823-detalhamento-turma" src="/img/search-2851823@2x.png"
                         alt="search-2851823"/>
                    <input
                      className="text-area-de-turmas"
                      placeholder={'Buscar alunos...'}
                      type={'search'}
                      onChange={(e) => setFilterTextStudent(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <Popover
                  isOpen={isOpenAluno}
                  onOpen={onOpenAluno}
                  onClose={onCloseAluno}
                  placement="bottom-end"
                  closeOnBlur={false}
                >
                  <PopoverTrigger>
                    <div className="button-detalhamento-tema" onClick={onOpen}>
                      <div className="mainbutton-detalhamento-tema custom-button" style={{ height: '48px' }}>
                        <div className="content-2-detalhamento-tema">
                          <img
                            className="ic-add-detalhamento-tema"
                            src={'/img/ic-add-1.svg'}
                            alt="button-addStudent"
                            style={{ filter: 'brightness(0) invert(1)' }}
                          />
                          <div
                            className="main-button-detalhamento-tema custom-button-text publicsans-bold-white-14px">{'Adicionar aluno'}</div>
                        </div>
                      </div>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent width={'448px'} borderRadius={'10px'}>
                    <PopoverArrow/>
                    <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'}/>
                    <PopoverHeader>
                      <div className={'text-popover'}>Novo Aluno</div>
                    </PopoverHeader>
                    <PopoverBody marginTop={'18px'}>
                      O aluno receberá um email para confirmar cadastro.
                      <br/>
                      <br/>
                      <Input className='input-default-style'
                             id='nome'
                             style={{
                               width: '100%',
                               backgroundColor: 'white',
                               border: '1px solid #d7e3fb',
                               padding: '5px'
                             }}
                             placeholder={'Digite seu nome'}
                             type="text"
                      />
                      <br/>
                      <br/>
                      <Input className='input-default-style'
                             id='email'
                             style={{
                               width: '100%',
                               backgroundColor: 'white',
                               border: '1px solid #d7e3fb',
                               padding: '5px'
                             }}
                             placeholder={'nome@dominio.com'}
                             type="text"
                      />
                    </PopoverBody>
                    <PopoverFooter alignSelf={'center'}>
                      <Button backgroundColor={'#0A41D6'} color={'white'} width={'400px'} height={'48px'}
                              marginBottom={'15px'} onClick={handleAddStudent}>
                        {' '}
                        Adicionar{' '}
                      </Button>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </div>
            </HStack>
            <SharedTablePagination
              data={student}
              itemsPerPage={5}
              className={'table-margin'}
              columns={[
                {
                  title: 'Aluno',
                  render: (studentData) => <div>{geradorTema(studentData.name)}</div>
                },
                {
                  title: 'Status',
                  render: (studentData) => <div>{geradorStatusAluno()}</div>
                },
                {
                  title: 'Redações entregues',
                  render: (studentData) => <div>{geradorRedacoesEntregues(studentData)}</div>
                },
                {
                  title: 'Detalhes',
                  render: (studentData) => <div>{geradorSetas(studentData.id)}</div>
                }
              ]}
            />
          </div>
        )}
        {selectedTab === 'atividades vinculadas' && (
          <div className={'redacoes-container-style'}>
            <HStack width={'100%'}>
              <div className="search-filter-detalhamento-turma">
                <div className="search-filtro-detalhamento-turma">
                  <div className="search-detalhamento-turma">
                    <img className="search-2851823-detalhamento-turma" src="/img/search-2851823@2x.png"
                         alt="search-2851823"/>
                    <input
                      className="text-area-de-turmas"
                      placeholder={'Buscar atividades...'}
                      type={'search'}
                      onChange={(e) => setFilterTextActivity(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </HStack>
            <SharedTablePagination
              data={activity}
              itemsPerPage={5}
              className={'table-margin'}
              columns={[
                {
                  title: 'Atividade',
                  render: (activityData) => <div>{geradorTema(activityData.subject)}</div>
                },
                {
                  title: 'Tema',
                  render: (activityData) => <div>{geradorTema(activityData.theme.name)}</div>
                },
                {
                  title: 'Andamento',
                  render: (activityData) => <div>{geradorDeRedacoesParaCorrigir(activityData)}</div>
                },
                {
                  title: 'Entrega final',
                  render: (activityData) => {
                    const generatedData = geradorDataDeEnvio(activityData.deadline)
                    return <div>{generatedData}</div>
                  }
                },
                {
                  title: 'Detalhes',
                  render: (activityData) => <div>{geradorSetas(activityData.id)}</div>
                }
              ]}
            />
          </div>
        )}
        {selectedTab === 'redacoes' && (
          <div className={'redacoes-container-style'}>
            <HStack width={'100%'}>
              <div className="search-filter-detalhamento-turma">
                <div className="search-filtro-detalhamento-turma">
                  <div className="search-detalhamento-turma">
                    <img className="search-2851823-detalhamento-turma" src="/img/search-2851823@2x.png"
                         alt="search-2851823"/>
                    <input
                      className="text-area-de-turmas"
                      placeholder={'Buscar redações...'}
                      type={'search'}
                      onChange={(e) => setFilterTextEssay(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </HStack>
            <SharedTablePagination
              data={essays}
              itemsPerPage={5}
              className={'table-margin'}
              columns={[
                {
                  title: 'Data de entrega',
                  render: (essayData) => {
                    const generatedData = geradorDataDeEnvio(essayData.dateSubmitted)
                    return <div>{generatedData}</div>
                  }
                },
                {
                  title: 'Status',
                  render: (essayData) => <div>{geradorStatus(essayData.status)}</div>
                },
                {
                  title: 'Tema',
                  render: (essayData) => <div>{geradorTema(essayData.theme?.name)}</div>
                },
                {
                  title: 'Aluno',
                  render: (essayData) => <div>{geradorAluno(essayData.student.name)}</div>
                },
                {
                  title: 'Detalhes',
                  render: (essayData) => <div>{geradorSetas(essayData.id)}</div>
                }
              ]}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default DetalhamentoTurma

function TitleCta2() {
  return (
    <div className="title-cta-detalhamento-turma">
      <div className="title-detalhamento-turma">
        <h1
          className="title-1-detalhamento-turma publicsans-normal-resolution-blue-24px">{'Mensagem personalizada'}</h1>
      </div>
    </div>
  )
}

function Frame25803() {
  return (
    <div className="frame-2580-detalhamento-turma">
      <div
        className="ativo-detalhamento-turma valign-text-middle-detalhamento-turma publicsans-normal-black-14px">Ativo
      </div>
    </div>
  )
}

function Status(props) {
  const { alunosAtivos, redacoesFeitas } = props
  return (
    <div className="status-detalhamento-turma">
      <div className="frame-243-detalhamento-turma">
        <img className="folder-2851794-detalhamento-turma" src="/img/folder-2851794-3@2x.png" alt="folder-2851794"/>
        <div className="redaes-feitas-no-ms-detalhamento-turma publicsans-bold-nevada-14px">Redações feitas no mês</div>
        <div className="number-1-detalhamento-turma publicsans-extra-bold-absolute-zero-32px">{redacoesFeitas}</div>
      </div>
      <div className="frame-243-detalhamento-turma">
        <img className="folder-2851794-detalhamento-turma" src="/img/folder-2851794-1@2x.png" alt="folder-2851794"/>
        <div className="alunos-ativos-detalhamento-turma publicsans-bold-nevada-14px">Alunos ativos</div>
        <div className="number-1-detalhamento-turma publicsans-extra-bold-absolute-zero-32px">{alunosAtivos}</div>
      </div>
    </div>
  )
}

function AtividadeTxt20(props) {
  const { date } = props

  return (
    <div className="atividade-txt-detalhamento-turma">
      <Frame25805 date={date}/>
    </div>
  )
}

function Frame25805(props) {
  const { date } = props

  return (
    <div className="frame-2580-1-detalhamento-turma">
      {date !== '' ? (
        <img
          className="calendar-2851774-1-detalhamento-atividade"
          src={'/img/calendar-2851774@2x.png'}
          alt="calendar-2851774"
        />
      ) : (
        ''
      )}
      <div
        className="x18112022-s-2030-detalhamento-turma valign-text-middle-detalhamento-turma publicsans-normal-black-12px">
        {date}
      </div>
    </div>
  )
}

function AtividadeTxt21(props) {
  const { tema } = props
  return (
    <div className="atividade-txt-4-detalhamento-turma">
      <div className="content-4-detalhamento-turma">
        <p
          className="gerao-do-sec-21-a-detalhamento-turma valign-text-middle-detalhamento-turma publicsans-normal-nevada-14px">
          {tema}
        </p>
      </div>
    </div>
  )
}

function AtividadeTxt22(props) {
  const { nomeAluno } = props

  return (
    <div className="atividade-txt-5-detalhamento-turma">
      <div className="content-5-detalhamento-turma">
        <div
          className="aluno-name-style valign-text-middle-detalhamento-turma publicsans-normal-black-14px">
          {nomeAluno}
        </div>
      </div>
    </div>
  )
}

function AtividadeTxt62(props) {
  const { essaysId } = props
  return (
    <div className="atividade-txt-6-detalhamento-turma">
      <Link to={`/detalhamento-aluno/${essaysId}`}>
        <img className="ic-arrowright-detalhamento-turma" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright"/>
      </Link>
    </div>
  )
}
