import React from 'react'
import CardTitle from '../TitleLink4'
import './TurmaSemAtividadeIcon.css'
import { useNavigate } from 'react-router-dom'

function TurmaSemAtividadeIcon(props) {
  const { groupName } = props
  const navigate = useNavigate()
  return (
    <div className="turma-card">
      <CardTitle groupName={groupName}/>
      <div className="content-22">
        <div className="content-25">
          <div className="ilustrao-emptystate">
            <div className="group-2-1">
              <div className="overlap-group">
                <img className="papelzinhoEsquerda-ícone"
                     src={'/img/vector-30-1@2x.png'}
                     alt="Componente papelzinho da esquerda do ícone"/>
                <img className="cartão-ícone" src={'/img/vector-26-1@2x.png'}
                     alt="Componente central do ícone"/>
                <img className="boca-ícone" src={'/img/vector-27-1@2x.png'}
                     alt="Componente boca do ícone"/>
                <div className="olhoEsquerda-ícone"></div>
                <div className="olhoDireita-ícone"></div>
                <img className="papelzinhoDireita-ícone"
                     src={'/img/vector-28-1@2x.png'}
                     alt="Componente papelzinho da direita do ícone"/>
                <img className="papelzinhoInferiorEsquerdo-ícone"
                     src={'/img/vector-29-1@2x.png'}
                     alt="Componente papelzinho inferior esquerda do ícone"/>
                <img className="sobrancelhaEsquerda-ícone"
                     src={'/img/vector-31-1@2x.png'}
                     alt="Componente sobrancelha da esquerda do ícone"/>
                <img className="sobrancelhaDireita-ícone"
                     src={'/img/vector-32-1@2x.png'}
                     alt="Componente sobrancelha da direita do ícone"/>
              </div>
            </div>
          </div>
          <p
            className="esta-turma-no-possu publicsans-normal-nevada-16px">{'Esta turma não possui atividades em andamento'}</p>
        </div>
        <div className="frame-258-1" onClick={() => { navigate('/criar-atividade') }}>
          <div className="button-15">
            <div className="mainbutton-15">
              <div className="frame-29-2">
                <img className="ic-add-2" src={'/img/ic-add-11@2x.png'} alt="ic-add"/>
                <div className="criar-atividade-button-pagina-inicial valign-text-middle inter-bold-absolute-zero-14px">{'Criar atividades'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TurmaSemAtividadeIcon
