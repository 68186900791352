import './AreaDeEscolas.css'
import Headbar from '../../components/Headbar'
import Menu from '../../components/Menu'
import { Link } from 'react-router-dom'
import SharedCardPagination from '../../components/SharedCardPagination'
import { useEffect, useState } from 'react'
import Page from '../Page'
import { listAllSchoolsOfLoggedUser } from '../../apis/schoolApi/schoolApi'
import EscolaCard from '../../components/EscolaCard'

function AreaDeEscolas() {
  const itemsPerPage = window.innerWidth > 2390 ? 9 : 10

  const [schools, setSchools] = useState([])
  const [schoolsData, setSchoolsData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    console.log(schoolsData)
    console.log(loading)
    setSchools(schoolsData)
  }, [schoolsData])

  async function fetchAllSchools() {
    const data = await listAllSchoolsOfLoggedUser()
    setSchoolsData(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchAllSchools()
  }, [])

  function handleSearchInputChange(e) {
    const inputText = e.target.value

    setSchools(
      schoolsData.filter((item) => {
        return item.name.toLowerCase().includes(inputText.toLowerCase())
      })
    )
  }

  return (
    <div className="area-de-institutions screen-area-de-institutions">
      <Page authPage={true} allowedUserType={'adm'}>
        <Menu/>
        <Headbar/>
        <div className={'area-de-institutions-body'}>
          <div className="search-filter-area-de-institutions">
            <div className="title-cta-area-de-institutions">
              <h1 className="rea-de-institutions-area-de-institutions publicsans-normal-absolute-zero-40px">Bem vindo, Administrador!</h1>
              <Link to={'/criar-escola'}>
                <div className="cta-criar-atividade-area-de-institutions">
                  <div className="button-1-area-de-institutions">
                    <div className="mainbutton-1-area-de-institutions">
                      <div className="frame-29-area-de-institutions">
                        <img className="ic-add-area-de-institutions" src="/img/ic-add-6.svg" alt="ic-add"/>
                        <div
                          className="criar-institution-area-de-institutions valign-text-middle-area-de-institutions inter-bold-zircon-14px">
                          Adicionar Escola
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="search-filter-1-area-de-institutions">
          <div className="search-filtro-area-de-institutions">
            <div className="search-area-de-institutions">
              <img className="search-2851823-area-de-institutions" src="/img/search-2851823@2x.png"
                   alt="search-2851823"/>
              <input
                className="text-search-input-area-atividades publicsans-normal-nevada-16px"
                placeholder={'Buscar Escolas...'}
                type={'search'}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
        </div>
        <div className="area-de-instituicoes-body">
          {schools ? <SharedCardPagination data={schools} RenderComponent={EscolaCard}
                                                itemsPerPage={itemsPerPage}/> : null}
        </div>
      </Page>
    </div>
  )
}

export default AreaDeEscolas
