import React, { forwardRef } from 'react'
import { Box, Image, Input } from '@chakra-ui/react'

export const DatePickerInput = forwardRef(({ value, onClick, ...props }, ref) => (
    <Box
        onClick={onClick}
        display="flex"
        alignItems="center"
        mt={'18px'}
        backgroundColor={'white'}
        border="1px solid #d7e3fb"
        borderRadius="md"
    >
        <Image src="/img/calendar-2851774-10@2x.png" alt="calendar" boxSize="24px" marginLeft={'14px'} />
        <Input
            ref={ref}
            value={value}
            placeholder="Selecione a data"
            readOnly
            {...props}
            h={'48px'}
            fontFamily={'Public Sans-Regular, Helvetica'}
            color={'#828282'}
            border={'none'}
        />
    </Box>
))
