import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const listAllThemes = async () => {
  const schoolID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/theme/school/${schoolID}`)

  return response.data.message
}

export const createThemeApi = async (data) => {
  const response = await httpRequestHelper.postWithAuth(BACKEND_API + '/v1/theme', data)

  return response.data.message
}

export const getThemeApi = async (themeId) => {
  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/theme/${themeId}`)

  return response.data.message
}

export const putEditTheme = async (temasData) => {
  const response = await httpRequestHelper.putWithAuth(BACKEND_API + '/v1/theme', temasData)

  return response.data.message
}

export const putEditThemeImage = async (temaID, formData) => {
  const response = await httpRequestHelper.putWithAuth(BACKEND_API + `/v1/theme/${temaID}/image`, formData)

  return response.data.message
}

export const deleteTheme = async (temaID) => {
  const response = await httpRequestHelper.delWithAuth(BACKEND_API + `/v1/theme/${temaID}`)

  return response.data.message
}

export const getActivityInformation = async (temaID) => {
  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/activity/theme/${temaID}`)

  return response.data.message
}
