import { Table as ChakraTable, Tbody, Td, Th, Thead, Tr, chakra, Box, Skeleton, StatUpArrow, Icon, StatDownArrow } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'

import { colors } from '../../theme/foundations/colors'

const CHECKBOX_CLASS = 'checkbox-col'

export function Table({ columns, data, isLoading = true, setSelectedHitsFunction = null }) {
  const skeletonData = useMemo(() => {
    const accessors = columns.map(object => object.accessor)
    const samples = columns.map(object => object.sample)
    return Array.from(Array(10), () => {
      const object = {}
      accessors.forEach((accessor, index) => { object[accessor] = samples[index] })
      return object
    })
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
    // selectedFlatRows,
    // state: { selectedRowIds }
  } = useTable(
    {
      columns,
      data: isLoading && !data.length ? skeletonData : data
    },
    useSortBy,
    // useRowSelect,
    hooks => {
      // hooks.visibleColumns.push(columns => [
      //   {
      //     id: 'selection',
      //     width: '40px',
      //     Header: ({ getToggleAllRowsSelectedProps, isAllRowsSelected }) => (
      //       <div>
      //         <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} isChecked={isAllRowsSelected} />
      //       </div>
      //     ),
      //     Cell: ({ row }) => (
      //       <div>
      //         <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} isChecked={row.isSelected} />
      //       </div>
      //     )
      //   },
      //   ...columns
      // ])
    }
  )

  function handleClick(e, row) {
    const acceptedElements = ['div', 'td']
    if (acceptedElements.includes(e.target.localName) && e.target.classList[0] !== CHECKBOX_CLASS) {
      const cell = row.cells.find(cell => cell.column.id === 'action')
      if (cell) cell.value()
    }
  }

  // useEffect(() => {
  //   if (setSelectedHitsFunction) {
  //     const originalValues = selectedFlatRows.map(
  //       d => d.original
  //     )
  //     setSelectedHitsFunction(originalValues)
  //   }
  // }, [selectedRowIds])

  return (
    <Box minHeight={rows.length && '65vh'}>
      <ChakraTable
        backgroundColor='white'
        borderRadius='10px'
        boxShadow='5px 8px 24px #00000017'
        {...getTableProps()}
      >
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} backgroundColor={'#F3F5FF'}>
              {headerGroup.headers.map((column) => (
                column.id !== 'action' && <Th
                  key={column.id}
                  {...column.getHeaderProps({ ...column.getSortByToggleProps(), style: { textAlign: column['textAlign'] } })}
                  isNumeric={column.isNumeric}
                  padding='10px'
                  textAlign='center'
                >
                  <strong>{column.render('Header')}</strong>
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Icon as={StatDownArrow} />
                      ) : (
                        <Icon as={StatUpArrow} />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody minHeight={'60vh'} {...getTableBodyProps()}>
          {rows.slice(0, 12).map((row) => {
            prepareRow(row)
            return (
              <Tr
                key={row.id}
                {...row.getRowProps()}
                backgroundColor={row.isSelected && colors.brand[100]}
                _hover={{ backgroundColor: !row.isSelected && '#e4e9ff' }}
              >
                {row.cells.map((cell) => {
                  return (
                    cell.column.id !== 'action' &&
                    <Td
                      className={cell.column.id === 'selection' ? CHECKBOX_CLASS : null}
                      key={cell.value}
                      paddingX={2}
                      paddingY={7}
                      borderRight='1px solid #dfe2f2'
                      cursor={cell.column.id === 'details' ? 'pointer' : 'default'}
                      onClick={(e) => cell.column.id === 'details' ? handleClick(e, row) : null }
                      {...cell.getCellProps({
                        style: {
                          width: cell.column.width !== 150 ? cell.column.width : 'auto',
                          textAlign: cell.column['textAlign']
                        }
                      })}
                      >
                      <Skeleton isLoaded={!isLoading || data.length}>
                        {typeof cell.value === 'function' ? cell.value() : cell.render('Cell')}
                      </Skeleton>
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </ChakraTable>
      {/* <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
            <pre>
                <code>
                    {JSON.stringify(
                      {
                        selectedRowIds: selectedRowIds,
                        'selectedFlatRows[].original': selectedFlatRows.map(
                          d => d.original
                        )
                      },
                      null,
                      2
                    )}
                </code>
            </pre> */}
    </Box>
  )
}
