import React from 'react'
import './TabelaTitle42.css'

function TabelaTitle42(props) {
  const { children, className } = props

  return (
    <div className={`tabela-title-14 ${className || ''}`}>
      <div className="atividade publicsans-normal-gray-14px">{children}</div>
    </div>
  )
}

export default TabelaTitle42
