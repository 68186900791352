import React, { useState } from 'react'
import Headbar from '../../components/Headbar'
import StepsImage from '../StepsImage'
import './CriarInstituicaoStep1.css'
import BackStepButton from '../BackStepButton'
import ForwardButton from '../ForwardButton'
import FormStepNotSelected from '../FormStepNotSelected'
import { Flex, Heading, Input, Select, VStack } from '@chakra-ui/react'
import { createInstitutionApi } from '../../apis/institutionApi/institutionApi'
import useCriarInstituicaoStore from '../../store/useCriarInstituicaoStore'
import { institutionGradeTypeEnum } from '../../utils/institutionGradeTypeEnum'

function CriarInstituicaoStep1(props) {
  const { handleReturn, handlingNextStep } = props
  const { criarInstituicaoData, setCriarInstituicaoData } = useCriarInstituicaoStore()

  const [nameError, setNameError] = useState(false)
  const [maxGradeError, setMaxGradeError] = useState(false)

  const createInstitution = () => {
    return new Promise((resolve) => {
      const body = {
        ...criarInstituicaoData,
        institutionGradeType: parseInt(criarInstituicaoData.institutionGradeType, 10),
        maxGrade: parseInt(criarInstituicaoData.maxGrade, 10)
      }
      const result = createInstitutionApi(body)
      resolve(result)
    })
  }

  function handleSubmit() {
    if (criarInstituicaoData.name && criarInstituicaoData.maxGrade && criarInstituicaoData.maxGrade > 0) {
      createInstitution().then((result) => {
        handlingNextStep(result)
      })
    } else {
      setNameError(!criarInstituicaoData.name)
      setMaxGradeError(!criarInstituicaoData.maxGrade)
      setMaxGradeError(criarInstituicaoData.maxGrade <= 0)
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightblue' : null
    }),
    control: (provided) => ({
      ...provided
    })
  }

  const handleMaxGradeInputChange = (e) => {
    const inputValue = e.target.value

    // Check if the input value is a number or empty
    if (/^[0-9]*$/.test(inputValue)) {
      const numValue = parseInt(inputValue, 10)
      if (inputValue === '' || (numValue >= 0 && numValue <= 100000)) {
        setMaxGradeError(false)
        setCriarInstituicaoData({ maxGrade: e.target.value })
      }
    }
  }

  return (
    <Flex w={'100%'}>
      <VStack w={'100%'}>
        <Headbar returnTo={'/area-de-instituicoes'}/>
        <VStack marginTop={'107px'}>
          <Heading alignSelf={'start'} ml={'35px'} className={'publicsans-extra-extra-bold-absolute-zero-32px'}
                   color={'#0A41D6'}>
            Criar Instituicao
          </Heading>
          <VStack mt={'32px'}>
            <StepsImage text={'Sobre a instituição'} number={1}/>
            <div className="cards">
              <div className="frame-2546">
                <div
                  className={`${nameError ? 'error-form' : ''} nome-criar-atividade-step-1 publicsans-bold-nevada-16px`}>{'Nome*'}</div>
                <Input h={'48px'} w={'100%'}
                       backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       value={criarInstituicaoData.name}
                       onChange={(e) => {
                         setNameError(false)
                         setCriarInstituicaoData({ name: e.target.value })
                       }}
                />
                <div
                  className={'nome-criar-atividade-step-1 publicsans-bold-nevada-16px'}>{'Forma de avaliação'}</div>
                <Select
                  placeholder={'Selecionar a forma de avaliação dessa instituição'}
                  onChange={(e) => {
                    setCriarInstituicaoData({ institutionGradeType: e.target.value })
                  }}
                  borderColor={'#d7e3fb'}
                  backgroundColor={'white'}
                  styles={customStyles}
                  defaultValue={0}
                  w={'100%'}>
                  {Object.entries(institutionGradeTypeEnum).map((option, key) => (
                    <option className={'select-grade-type-text'} key={key} value={option[0]}>
                      {option[1]}
                    </option>
                  ))}
                </Select>
                <div className={'publicsans-normal-gray-16px'}>
                  {
                    criarInstituicaoData.institutionGradeType === '0'
                      ? 'A nota final da redação será composta pela média das competências.'
                      : 'A nota final da redação será composta pela soma das competências.'
                  }
                </div>
                <div
                  className={`${maxGradeError ? 'error-form' : ''} nome-criar-atividade-step-1 publicsans-bold-nevada-16px`}>{'Nota máxima*'}</div>
                <Input h={'48px'} w={'100%'}
                       backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       value={criarInstituicaoData.maxGrade}
                       onChange={handleMaxGradeInputChange}
                />
                <div className={'publicsans-normal-gray-16px'}>
                  {
                    'É a nota máxima possível das redações associadas a essa instituição, por exemplo a nota máxima da instituição ENEM é 1000.'
                  }
                </div>
              </div>
            </div>
            <div className="steps">
              <FormStepNotSelected number={2} configuraesDaAtividade={'Enviar'}/>
            </div>
          </VStack>
          <div className="buttons">
            <BackStepButton handleReturn={handleReturn} text={'Voltar'}/>
            <ForwardButton forwardTo={handleSubmit} className={'button-10'} text={'Finalizar'}
                           buttonClassName={''}/>
          </div>
        </VStack>
      </VStack>
    </Flex>
  )
}

export default CriarInstituicaoStep1
