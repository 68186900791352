import React from 'react'
import Frame258062 from '../Frame258062'
import './AtividadeTxt132.css'

function AtividadeTxt132(props) {
  const { turma } = props

  return (
    <div className="atividade-txt-17">
      <Frame258062>{turma}</Frame258062>
    </div>
  )
}

export default AtividadeTxt132
