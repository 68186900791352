export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = reject
})

export const base64ToBlob = async base4 => {
  const base64Response = await fetch(`${base4}`)
  return await base64Response.blob()
}
