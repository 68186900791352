import { Center, Stack, Heading, Box } from '@chakra-ui/react'
import ReactDropzone from 'react-dropzone'
import { AddImageIcon } from '../icons/AddImage'
// import { BiImageAdd } from 'react-icons/bi'

export const UploadArea = ({ onDrop }) => {
  return (
        <ReactDropzone accept='image/*' onDropAccepted={onDrop} multiple={false}>
            {({ getRootProps, getInputProps }) => (
                <Center
                    {...getRootProps()}
                    cursor='pointer'
                    flexDirection='column'
                    backgroundColor='#F3F5FF'
                    borderRadius='12px'
                    border='2px solid #E4E9FF'
                    padding='24px 16px 24px 16px'
                    gap='24px'
                >
                    <Stack>
                        <Stack textAlign='center'>
                            <Box backgroundColor='#E4E9FF' borderRadius='68px' p='16px' gap='10px' alignItems='flex-start'>
                                <AddImageIcon />
                            </Box>
                            <Heading fontSize='14px' fontWeight='400'>
                                <span style={{ fontWeight: 700 }}>Clique</span> para escolher<br /> uma imagem
                            </Heading>
                        </Stack>
                    </Stack>
                    <input {...getInputProps()} />
                </Center>
            )}
        </ReactDropzone>
  )
}
