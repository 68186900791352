import React, { useEffect, useState } from 'react'
import './InicioOwner.css'
import Page from '../Page'
import Menu from '../../components/Menu'
import Headbar from '../../components/Headbar'
import { IndependentTeacherSearch } from './independentTeacherSearch'
import { listAllUsers } from '../../apis/ownerApi/ownerApi'
import { USER_ROLE_CODE } from '../../apis/Constants'
import { Modal } from '../../components/modal'
import { Button as ButtonChakra, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { AddIcon } from '../../components/icons/Add'
import { NewIndependentTeacherModalBody } from '../../components/CadastrarProfessorIndependente'
import { IndependentTeacherDetailsModalBody } from '../../components/DetalhamentoProfessorIndependente'

function InicioOwner(props) {
  const [independentTeachers, setIndependentTeachers] = useState([])
  const [page] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTeacher, setSelectedTeacher] = useState(null)

  const { isOpen: detailsIsOpen, onClose: detailsOnClose, onOpen: detailsOnOpen } = useDisclosure()
  const { isOpen: createIsOpen, onClose: createOnClose, onOpen: createOnOpen } = useDisclosure()

  function handleDetailsOnClose() {
    detailsOnClose()
    setSelectedTeacher(null)
  }

  function handleDetailsOnOpen(teacher) {
    setSelectedTeacher(teacher)
    detailsOnOpen()
  }

  async function fetchIndependentTeachers() {
    try {
      setIsLoading(true)
      const result = await listAllUsers(USER_ROLE_CODE.INDEPENDENT_TEACHER, page)
      setIndependentTeachers(result)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!detailsIsOpen && !createIsOpen) {
      fetchIndependentTeachers()
    }
  }, [detailsIsOpen, createIsOpen])

  return (
    <>
      <div className="inicio screen">
        <Page authPage={true} allowedUserType={'owner'}>
          <div className="body-no-right">
            <Flex width='100%' justifyContent='space-between' borderRadius='12px' backgroundColor='#F3F5FF' alignItems='center' padding='24px'>
                <Text fontSize='35px' color={'brand.200'}>Área de Usuários</Text>
                <ButtonChakra variant='primary' leftIcon={<AddIcon />} onClick={createOnOpen}>Adicionar</ButtonChakra>
            </Flex>
            <IndependentTeacherSearch hits={independentTeachers} isLoading={isLoading} detailsAction={handleDetailsOnOpen} nHits={100} page={page} />
          </div>
          <Menu />
          <Headbar />
        </Page>
      </div>
      {selectedTeacher && <Modal
        title={`Professor (a), ${selectedTeacher.name}`}
        isOpen={detailsIsOpen}
        onClose={handleDetailsOnClose}
        body={<IndependentTeacherDetailsModalBody independentTeacherId={selectedTeacher.id} onClose={handleDetailsOnClose} />}
        size='xl'
      />}
      <Modal
        title={'Cadastrar novo professor'}
        isOpen={createIsOpen}
        onClose={createOnClose}
        body={<NewIndependentTeacherModalBody onClose={createOnClose} />}
        size='2xl'
      />
    </>
  )
}

export default InicioOwner
