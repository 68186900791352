import { Box, Center, Stack } from '@chakra-ui/react'
import { Table } from '../../../components/Table'
import { NoResults } from '../../../components/Table/no-result'
import { Pagination } from '../../../components/Pagination'
import { indepententTeacherColumns } from './utils'
import { useMemo } from 'react'
import { ArrowRightIcon } from '../../../components/icons/ArrowRight'

export const IndependentTeacherSearch = ({ hits, nHits, page, isLoading, detailsAction }) => {
  const data = useMemo(() => {
    if (hits) {
      return hits.map((hit) => {
        return {
          ...hit,
          blocked: () => hit.blocked ? 'Sim' : 'Não',
          details: () => (<Center cursor={'pointer'}><ArrowRightIcon /></Center>),
          action: () => detailsAction(hit)
        }
      })
    }
  },
  [hits]
  )

  return (
    <Stack spacing={4} width='100%'>
        <Box>
            <Table data={data} columns={indepententTeacherColumns} isLoading={isLoading} />
            {!data.length && !isLoading &&
                <NoResults
                    label='Nenhum professor cadastrado'
                    buttonLabel='Cadastrar novo professor'
                    buttonPath='/cliente/novo'
                />}
        </Box>
        <Pagination page={page} nPages={Math.ceil(nHits / 10)} onNext={() => console.log('next')} onPrevious={() => console.log('previous')} />
    </Stack>
  )
}
