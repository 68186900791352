import { extendTheme } from '@chakra-ui/react'
import { MultiSelectTheme } from 'chakra-multiselect'
import { Heading } from './components/Heading'
import { Select } from './components/Select'
import { Button } from './components/Button'
import { colors } from './foundations/colors'

export const UITema = extendTheme({
  components: {
    MultiSelect: MultiSelectTheme,
    Select,
    Heading,
    Button
  },
  colors
})
