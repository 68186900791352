import React from 'react'
import './BackStepButton.css'

function BackStepButton(props) {
  const { handleReturn, className, text } = props

  return (
        <div className={`button-10 ${className || ''}`} onClick={handleReturn}>
            <div className="mainbutton-10">
                <div className="content-17">
                    <div className="main-button-8 publicsans-bold-resolution-blue-14px">{text}</div>
                </div>
            </div>
        </div>

  )
}

export default BackStepButton
