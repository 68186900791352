import React from 'react'
import './MenuOutros.css'
import { logoff } from '../../apis/LoginApi'
import { useNavigate } from 'react-router-dom'
import { VStack } from '@chakra-ui/react'

function MenuOutros() {
  const navigate = useNavigate()

  function logout() {
    logoff()
    navigate('/login')
  }

  return (
    <div className="menu-outros">
      {/* <img className="menu-outros-picture" src={'/img/menu-outros@2x.png'} alt="menu-outros"/> */}
      <div className="menu-outros-text">
        <a onClick={logout}>
          <VStack>
            <img className="logout-2851807" src={'/img/logout-2851807@2x.png'} alt="logout-2851807"/>
            <div className={'publicsans-bold-nevada-16px'}>{'Sair'}</div>
          </VStack>
        </a>
      </div>
    </div>
  )
}

export default MenuOutros
