import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import './AreaDeAtividades.css'
import Menu from '../../components/Menu'
import Page from '../Page'
import AtividadeCard from '../../components/AtividadeCard'
import SharedCardPagination from '../../components/SharedCardPagination'
import {listAllAcitivitiesFromSchool} from '../../apis/activitityApi/activityApi'
import Headbar from '../../components/Headbar'
import sanitizedDate from '../../utils/DateSatinizerInput'
import Calendar from '../../components/Calendar'
import {HStack} from "@chakra-ui/react";

function AreaDeAtividades() {
  const itemsPerPage = window.innerWidth > 2390 ? 9 : 10

  const [atividades, setAtividades] = useState([])
  const [atividadesData, setAtividadesData] = useState([])
  const [selectedTab, setSelectedTab] = useState('todas')
  const [filterText, setFilterText] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setAtividadesData(await listAllAcitivitiesFromSchool())
    }
    fetchData()
  }, [])

  useEffect(() => {
    setAtividades(atividadesData) // Atualiza atividades quando atividadesData muda
  }, [atividadesData])

  useEffect(() => {
    if (atividadesData) {
      setAtividades(
        atividadesData.filter((item) => {
          const result = (item.theme.name.toLowerCase().includes(filterText.toLowerCase()) ||
            sanitizedDate(item.createdAt).includes(filterText) ||
            item.subject.toLowerCase().includes(filterText.toLowerCase()) ||
            sanitizedDate(item.deadline).includes(filterText)) && filterBySelectedTab(item)

          return result
        })
      )
    }
  }, [filterText, selectedTab])

  function filterBySelectedTab(item) {
    const currentDate = new Date()
    if (selectedTab === 'todas') {
      return true
    } else if (selectedTab === 'em-andamento') {
      return isInProgress(item)
    } else if (selectedTab === 'finalizadas') {
      return new Date(item.deadline) <= currentDate
    }
    return true
  }

  function returnAmountOfInProgressActivities() {
    return atividades.filter(item => isInProgress(item)).length
  }

  function isInProgress(item) {
    const currentDate = new Date()

    return new Date(item.validFrom) <= currentDate && new Date(item.deadline) >= currentDate
  }

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  return (
    <div className="area-de-atividades screen">
      <Page auth={true} userType={''}>
        <div className={'area-de-atividades-page'}>
          <HStack>
            <div className="area-de-atividades-body body-distance-from-header">
              <div className="search-filter">
                <div className="title-cta-1">
                  <h1 className="rea-de-atividades publicsans-normal-absolute-zero-40px">{'Área de atividades'}</h1>
                  <Link to="/criar-atividade">
                    <div className="cta-criar-atividade">
                      <div className="button">
                        <div className="mainbutton">
                          <div className="frame-29">
                            <img className="ic-add" src={'/img/ic-add-2@2x.png'} alt="ic-add"/>
                            <div className="pay-now valign-text-middle inter-bold-zircon-14px">{'Criar atividade'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="switch">
                <div className={`frame-2459 ${selectedTab === 'todas' ? 'tab-border' : ''}`}
                     onClick={() => changeSelectedTab('todas')}>
                  <div
                    className={`todas ${selectedTab === 'todas' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>{'Todas'}</div>
                </div>
                <div className={`frame-246 ${selectedTab === 'em-andamento' ? 'tab-border' : ''}`}
                     onClick={() => changeSelectedTab('em-andamento')}>
                  <div
                    className={`em-andamento ${selectedTab === 'em-andamento' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>{'Em andamento'}</div>
                </div>
                <div className={'frame-246 ' + (selectedTab === 'finalizadas' ? 'tab-border' : '')}
                     onClick={() => changeSelectedTab('finalizadas')}>
                  <div
                    className={`finalizadas ${selectedTab === 'finalizadas' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>{'Finalizadas'}</div>
                </div>
              </div>
              <div className="search-filter-1">
                <div className="search-filtro">
                  <div className="search">
                    <img className="search-2851823" src={'/img/search-2851823@2x.png'} alt="search-2851823"/>
                    <input
                      className="search-text-area-de-atividade publicsans-normal-nevada-16px"
                      placeholder={'Buscar atividades...'}
                      type={'search'}
                      onChange={handleSearchInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="atividades">
                {atividades ? (
                  <SharedCardPagination data={atividades} RenderComponent={AtividadeCard} itemsPerPage={itemsPerPage}/>
                ) : null}
              </div>
            </div>
            <div className="frame-2591">
              <div className="frame-2590">
                <div className="address valign-text-middle publicsans-normal-absolute-zero-20px">
                  {returnAmountOfInProgressActivities() + ' ' +
                    'Atividade(s) em andamento'}
                </div>
              </div>
              <img className="line-99" src={'/img/line-99@2x.png'} alt="Line 99"/>

              <Calendar atividades={atividades}/>
            </div>
          </HStack>
          <Menu/>
          <Headbar/>
        </div>
      </Page>
    </div>
  )
}

export default AreaDeAtividades
