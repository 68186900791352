import React, {useState} from 'react'
import './CadastroStep3.css'
import IlustracaoLogin from '../IlustracaoLogin'
import {maskCNPJ} from "../../utils/cnpjFormatter";
import {Spinner, Button as ChakraButton} from "@chakra-ui/react";

function CadastroStep3(props) {
  const {handleReturn, handlingNextStep, userData} = props

  const [nomeInstituicao, setNomeInstituicao] = useState(userData.nomeInstituicao ? userData.nomeInstituicao : '')
  const [cnpj, setCnpj] = useState(userData.cnpj ? userData.cnpj : '')
  const [nomeInstituicaoError, setNomeInstituicaoError] = useState(false)
  const [documentNumberError, setDocumentNumberError] = useState(false)

  const handleNomeInstituicaoInputChange = (event) => {
    setNomeInstituicao(event.target.value)
    userData.nomeInstituicao = event.target.value
    setNomeInstituicaoError(false) // Reset the error state
  }

  const handleCpnjInputChange = (event) => {
    const formattedValue = maskCNPJ(event.target.value)
    setCnpj(formattedValue)
    userData.cnpj = formattedValue
    setDocumentNumberError(false)
  }

  const handleSubmit = () => {
    let isValid = true
    const errors = {}

    if (!nomeInstituicao) {
      errors.nomeInstituicao = true
      isValid = false
    }
    if (!cnpj) {
      errors.documentNumber = true
      isValid = false
    }

    if (isValid) {
      userData.isLoading = true
      handlingNextStep(userData)
    } else {
      setNomeInstituicaoError(errors.nomeInstituicao)
      setDocumentNumberError(errors.documentNumber)
    }
  }

  return (
    <div className="container-center-horizontal-cadastro">
      <div className="cadastro-step01-cadastro screen-cadastro">
        <div className={'cadastro-columns'}>

          <IlustracaoLogin/>

          <div className={'cadastro-body-step-3'}>
            <Title/>
            <div className="content-3-cadastro-step-3">
              <img
                className={`line-cadastro-step-3`}
                src="/img/line.png"
                alt="Line"

              />
              <div className="concluido-cadastro-step-3">
                <img
                  className="frame-2482-1-cadastro-step-3"
                  src="/img/frame-2482@2x.png"
                  alt="Frame 2482"
                />
                <Frame2486 seuPerfil="Seu perfil"
                           text={`${userData.userType === 'Professor' ? 'Professor Independente' : 'Gestor Escolar'}`}/>
              </div>
              <div className="concluido-1-cadastro-step-3">
                <img
                  className="frame-2482-1-cadastro-step-3"
                  src="/img/frame-2482-1@2x.png"
                  alt="Frame 2482"
                />
                <Frame2486
                  seuPerfil="Sua conta"
                  text={`${userData.email}`}
                  className="frame-2486-1-cadastro-step-3"
                />
              </div>
              <Step01 criarConta="Informações adicionais"/>

              <div className="frame-2546-cadastro-step-3">
                <div className="email-cadastro-step-3">
                  <div
                    className={`${nomeInstituicaoError ? 'error-cadastro' : ''} nome-do-gestor-cadastro-step-3 publicsans-bold-nevada-16px`}>
                    {'Nome da instituição'}
                  </div>
                  <div className="text-imput-cadastro-step-3">
                    <div className="maintextimput-cadastro-step-3">
                      <div className="buscar-cadastro-step-3">
                        <input
                          className="text-1-cadastro-step-3 publicsans-normal-black-14px"
                          name="nomeInstituicao"
                          placeholder={'Nome da sua instituição educacional'}
                          type="text"
                          onChange={handleNomeInstituicaoInputChange}
                          value={nomeInstituicao}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="email-cadastro-step-3">
                  <div
                    className={`${documentNumberError ? 'error-cadastro' : ''} nome-do-gestor-cadastro-step-3 publicsans-bold-nevada-16px`}>
                    {'CNPJ'}
                  </div>
                  <div className="text-imput-cadastro-step-3">
                    <div className="maintextimput-cadastro-step-3">
                      <div className="buscar-cadastro-step-3">
                        <input
                          className="text-1-cadastro-step-3 publicsans-normal-black-14px"
                          name="cnpj"
                          placeholder={'000-000-000-00'}
                          type="text"
                          onChange={handleCpnjInputChange}
                          value={cnpj}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="steps-cadastro-step-3">
                <Frame2550 number="4" criarConta="Finalizar cadastro"/>
              </div>
            </div>
            <div className="buttons-cadastro-step-3">
              <Button handleReturn={handleReturn}/>

              <div className="mainbutton-2-cadastro-step-3">
                <div className="content-2-cadastro-step-3">
                  <div className="main-button-2-cadastro-step-3 publicsans-bold-white-14px">
                    <ChakraButton
                      onClick={() => handleSubmit(userData)}
                      disabled={userData.isLoading}
                      variant="unstyled"
                      width={'100%'}
                    >
                      {userData.isLoading ? <Spinner size="xs" marginRight="2"/> : null}
                      Finalizar
                    </ChakraButton>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CadastroStep3

function Title() {
  return (
    <div className="title-cadastro-step-3">
      <h1
        className="cadastre-se-no-correo-online-cadastro-step-3 valign-text-bottom-cadastro-step-3 publicsans-bold-white-40px">
        <span>
          <span className="publicsans-bold-absolute-zero-40px">Cadastre-se</span>
          <span className="publicsans-bold-nevada-40px">&nbsp;</span>
          <span className="publicsans-normal-cornflower-blue-40px">no Correção Online</span>
        </span>
      </h1>
    </div>
  )
}

function Frame2486(props) {
  const {seuPerfil, text, className} = props

  return (
    <div className={`frame-2486-cadastro-step-3 ${className || ''}`}>
      <div className="seu-perfil-cadastro-step-3 publicsans-normal-nevada-20px">
        {seuPerfil}
      </div>
      <div className="text-cadastro-step-3 publicsans-normal-nevada-16px">
        {text}
      </div>
    </div>
  )
}

function Step01(props) {
  const {criarConta} = props

  return (
    <div className="step-01-cadastro-step-3">
      <Frame2482/>
      <div className="informaes-da-instituio-cadastro-step-3 publicsans-normal-nevada-24px">
        {criarConta}
      </div>
    </div>
  )
}

function Frame2482() {
  return (
    <div className="frame-2482-cadastro-step-3">
      <div className="number-cadastro-step-3 publicsans-normal-aqua-deep-24px">
        3
      </div>
    </div>
  )
}

function Frame2550(props) {
  const {number, criarConta} = props

  return (
    <div className="frame-2552-cadastro-step-3">
      <div className="frame-2483-cadastro-step-3">
        <div className="number-1-cadastro-step-3 publicsans-normal-shady-lady-20px">
          {number}
        </div>
      </div>
      <div className="finalizar-cadastro-cadastro-step-3 publicsans-normal-shady-lady-20px">
        {criarConta}
      </div>
    </div>
  )
}

function Button(props) {
  const {handleReturn} = props

  return (
    <div onClick={handleReturn} className="button-1-cadastro-step-3">
      <Mainbutton/>
    </div>
  )
}

function Mainbutton() {
  return (
    <div className="mainbutton-1-cadastro-step-3">
      <div className="content-1-cadastro-step-3">
        <div className="main-button-1-cadastro-step-3 publicsans-bold-resolution-blue-14px">
          {'Voltar'}
        </div>
      </div>
    </div>
  )
}

