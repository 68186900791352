import React, { useEffect, useState } from 'react'
import Frame2426 from '../../components/Frame2426'
import TitleCta32 from '../../components/TitleCta32'
import AtividadeTxt132 from '../../components/AtividadeTxt132'
import TabelaTitle42 from '../../components/TabelaTitle42'
import AtividadeTxt322 from '../../components/AtividadeTxt322'
import TabelaTitle22 from '../../components/TabelaTitle22'
import AtividadeDetalhes from '../../components/AtividadeTxt62'
import './InicioAluno.css'
import Page from '../Page'
import Menu from '../../components/Menu'
import Title2 from '../../components/Title2'
import Headbar from '../../components/Headbar'
import { getActivitiesByStudentIdApi } from '../../apis/activitityApi/activityApi'
import { essayStatus } from '../../utils/essayStatus'
import { getStudentById } from '../../apis/studentApi/studentApi'
import Mainbutton from '../../components/Mainbutton'
import { Link, useNavigate } from 'react-router-dom'
import sanitizedDate from '../../utils/DateSatinizerInput'
import { VStack } from '@chakra-ui/react'
import { HandleDate } from '../../utils/HandleDate'

function InicioAluno() {
  const navigate = useNavigate()

  const [tema, setTema] = useState([])
  const [atividade, setAtividade] = useState([])
  const [entrega, setEntrega] = useState([])
  const [aguardandoCorrecao, setAguardandoCorrecao] = useState([])
  const [emCorrecao, setEmCorrecao] = useState([])
  const [corrigida, setCorrigida] = useState([])
  const [setasDetalhe, setSetasDetalhe] = useState([])
  const [essays, setEssays] = useState([])
  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState(true)
  const [validEssays, setValidEssays] = useState([])

  const fetchInitialData = async () => {
    const [studentResult, activityResult] = await Promise.all([
      getStudentById(localStorage.getItem('user_id')),
      getActivitiesByStudentIdApi(localStorage.getItem('user_id'))
    ])

    return { studentResult, activityResult }
  }

  useEffect(() => {
    fetchInitialData().then(({ studentResult, activityResult }) => {
      setEssays(studentResult.essays)
      setActivities(activityResult)
      setValidEssays(studentResult.essays.filter((essay) => essay.dateSubmitted !== null))
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    handleRender()
  }, [loading])

  function handleRender() {
    calculoQuantSetas()
    temaRedacoes()
    atividades()
    HandleDeadline()
    handleEssaysOverall()
  }

  function calculoQuantSetas() {
    const result = activities.slice(0, 4).map((activity, key) => {
      return (<AtividadeDetalhes key={key} activityID={activity.id}/>)
    })

    setSetasDetalhe(result)
  }

  function temaRedacoes() {
    const result = activities.slice(0, 4).map((activity, key) => {
      return (<AtividadeTxt132 turma={activity.theme.name} key={key}/>)
    })

    setTema(result)
  }

  function atividades() {
    const result = activities.slice(0, 4).map((activity, key) => {
      return (
        <AtividadeTxt322 atividade={activity.subject}
                         key={key}/>
      )
    })

    setAtividade(result)
  }

  function HandleDeadline() {
    const result = activities.slice(0, 4).map((activity, key) => {
      return (
        <HandleDate dateSubmitted={activity.deadline} key={key}/>
      )
    })
    setEntrega(result)
  }

  function calculoRedacoesStatus(statusDesejado) {
    let redacoes = 0
    essays.forEach(essay => {
      if (essayStatus[essay.status] === statusDesejado) {
        redacoes++
      }
    })

    return redacoes
  }

  function handleEssaysOverall() {
    setAguardandoCorrecao(<Frame2426
      folderIcon={'/img/folder-icon.png'}
      aguardandoCorreo={'Aguardando correção'}
      number={calculoRedacoesStatus('Aguardando Correção')}/>)

    setEmCorrecao(<Frame2426
      folderIcon={'/img/folder-pen-icon.png'}
      aguardandoCorreo={'Redações em correção'}
      number={calculoRedacoesStatus('Em Revisão')}/>)

    setCorrigida(<Frame2426
      folderIcon={'/img/folder-ok-icon.png'}
      aguardandoCorreo={'Redações corrigidas'}
      number={calculoRedacoesStatus('Finalizada')}/>)
  }

  function EssayCard(props) {
    const { essay } = props

    let backgroundColor
    if (essay.status === 10) {
      backgroundColor = 'in-progress-essay-status'
    } else if (essay.status === 20) {
      backgroundColor = 'in-grading-essay-status'
    } else if (essay.status === 30) {
      backgroundColor = 'in-review-essay-status'
    } else if (essay.status === 40) {
      backgroundColor = 'finished-essay-status'
    }

    return (
      <VStack className={'frame-2434-4'} gap={'16px'}>
        <div className="andamento publicsans-bold-absolute-zero-16px">{'Atividade'}</div>
        <div className="atvd">
          <div className="x25-atividades valign-text-middle publicsans-normal-nevada-16px">
            {essay.activitySubject}
          </div>
        </div>
        <div className="andamento publicsans-bold-absolute-zero-16px">{'Status'}</div>
        <div className="atvd">
          <div className={'x25-atividades valign-text-middle publicsans-normal-nevada-16px ' + backgroundColor}>
            {essayStatus[essay.status]}
          </div>
        </div>
        <div className="andamento publicsans-bold-absolute-zero-16px">{'Data da entrega'}</div>
        <div className="atvd">
          <div className="x25-atividades valign-text-middle publicsans-normal-nevada-16px">
            {essay.dateSubmitted ? sanitizedDate(essay.dateSubmitted) : 'Ainda não foi entregue'}
          </div>
        </div>
      </VStack>
    )
  }

  function HandleMonthlyEssay() {
    if (validEssays.length === 0) {
      return ''
    }

    return validEssays.slice(0, 4).map((essay, key) => {
      const date = new Date(essay.dateSubmitted)
      const today = new Date()
      const diffTime = Math.abs(date - today)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      if (diffDays <= 30) {
        console.log(essay)
        return (
          <div className="turma-card" key={key}>
            <div className="essay-montly-inner-container">
              <EssayCard essay={essay}/>
              <div className="frame-258-1" onClick={() => navigate('/redacao?id=' + essay.essayId)}>
                <div className="button-14">
                  <Mainbutton>{'Ver detalhes'}</Mainbutton>
                </div>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  return (
    <div className="inicio screen">
      <Page authPage={true} allowedUserType={'student'}>
        <div className="body-no-right">
          <header className="header">
            <h1 className="ol-gestor publicsans-normal-absolute-zero-40px">{'Olá, Aluno(a)!'}</h1>
            <div
              className="oque-vamos-corrigir-hoje publicsans-normal-nevada-20px">{'O que vamos corrigir hoje?'}</div>
          </header>
          <div className="status-4">
            {aguardandoCorrecao}
            {emCorrecao}
            {corrigida}
          </div>
          <div className="atividades-em-andamento">
            <TitleCta32 text={'Redações feitas no mês'}/>
            <div className="frame-2585">
              <HandleMonthlyEssay/>
            </div>
          </div>
          <div className="atividades-em-andamento-1">
            <div className="title-cta-4">
              <Title2>{'Atividades em andamento'}</Title2>
              <div className="cta-atividades">
                <Link to="/area-de-atividades">
                  <div className="text-27 publicsans-normal-azure-radiance-14px">{'Ver todas atividades'}</div>
                </Link>
                <img className="setaDireita-VerTodasAtividades" src={'/img/ic-arrowright-61@2x.png'}
                     alt="Seta a direita de ver todas as atividades"/>
              </div>
            </div>
            <div className="tabela-atividades-1">
              <div className="tabela-01-1">
                <TabelaTitle42>{'Atividade'}</TabelaTitle42>
                {atividade}
              </div>
              <div className="tabela-02-6">
                <TabelaTitle42
                  className={'tabela-title-13'}>{'Tema'}</TabelaTitle42>
                {tema}
              </div>
              <div className="tabela-02-7">
                <TabelaTitle42
                  className={'tabela-title-13'}>{'Data final de entrega'}</TabelaTitle42>
                {entrega}
              </div>
              <div className="tabela-02-8">
                <TabelaTitle22>{'Detalhes'}</TabelaTitle22>
                {setasDetalhe}
              </div>
            </div>
          </div>
        </div>
        <Menu/>
        <Headbar/>
      </Page>
    </div>
  )
}

export default InicioAluno
