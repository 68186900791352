export const Button = {
  variants: {
    primary: (props) => ({
      backgroundColor: 'brand.200',
      color: '#fff'
    }),
    vertical: () => ({
      flexDirection: 'column',
      padding: '35px 15px',
      borderRadius: '12px',
      fontWeight: '400',
      fontSize: '11px',
      maxWidth: '79px'
    }),
    'vertical-primary': () => ({
      ...Button.variants.primary(),
      ...Button.variants.vertical(),
      color: 'white'
    }),
    'vertical-outline': () => ({
      ...Button.variants.vertical(),
      border: '2px solid #CFD0D1',
      color: '#000'
    }),
    outline: (props) => ({
      bg: '#F9FAFF',
      borderRadius: '8px',
      border: '1px solid #648BF7',
      color: 'brand.100'
    })
  },
  defaultProps: {}
}
