import './CriarEscola.css'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import useCriarEscolaStore from '../../store/useCriarEscolaStore'
import { createSchoolApi } from '../../apis/schoolApi/schoolApi'
import { createSchoolAddressApi } from '../../apis/schoolApi/sharedApi'
import CriarEscolaStep1 from '../../components/CriarEscolaStep1'
import CriarEscolaStep2 from '../../components/CriarEscolaStep2'
import CriarEscolaStep3 from '../../components/CriarEscolaStep3'
import Page from '../Page'

const CriarEscola = () => {
  const navigate = useNavigate()
  const [newSchoolId, setNewSchoolId] = useState('')
  // Fetch states and actions from zustand store
  const {
    currentStep,
    criarEscolaData,
    setCurrentStep,
    cleanCriarEscolaData
  } = useCriarEscolaStore()

  const createSchool = () => {
    return new Promise((resolve) => {
      const schoolId = createSchoolApi(criarEscolaData.name, criarEscolaData.document)
      resolve(schoolId)
    })
  }

  const createSchoolAddress = (schoolId) => {
    return new Promise((resolve) => {
      const result = createSchoolAddressApi(schoolId, criarEscolaData.address)
      resolve(result)
    })
  }

  const handleNextStep = () => {
    if (currentStep === 2) {
      createSchool().then((schoolId) => {
        createSchoolAddress(schoolId).then(() => {
          cleanCriarEscolaData()
          setCurrentStep(currentStep + 1)
          setNewSchoolId(schoolId)
        })
      }
      )
    } else if (currentStep === 3) {
      navigate('/detalhamento-escola/' + newSchoolId)
      cleanCriarEscolaData()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handlePreviousStep = () => {
    if (currentStep === 3 || currentStep === 1) {
      navigate('/area-de-escolas')
      setCurrentStep(1)
      cleanCriarEscolaData()
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CriarEscolaStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      case 2:
        return <CriarEscolaStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      case 3:
        return <CriarEscolaStep3 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      // Add more cases for additional steps
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'adm'}>
      <Flex alignItems={'center'} justifyContent={'center'} h={'100%'} w={'100%'}>
        {renderStepContent()}
        {}
      </Flex>
    </Page>
  )
}

export default CriarEscola
