import { Highlight, Mark, Text, useHighlight } from '@chakra-ui/react'

export const EssayCorrectionArea = ({ isSelectionMode, allowedHighlight, essayRef, essay, selectedText, setSelectedCommentary, openPopover, onClickUp, isOpen, comments }) => {
  const commentHighlightQueries = comments.map(comment => essay.draft.substring(comment.startPos, comment.endPos))

  const commentChuncks = useHighlight({
    text: essay.draft,
    query: commentHighlightQueries
  })

  function findCommentByQuery(commentQuery) {
    return comments.find(comment => commentQuery === essay.draft.substring(comment.startPos, comment.endPos).trim())
  }

  function onOpenCommentary(commentQuery) {
    const comment = findCommentByQuery(commentQuery)
    setSelectedCommentary(comment)
    openPopover()
  }

  return (
    isSelectionMode
      ? allowedHighlight
        ? <Text ref={essayRef} fontSize='15px' fontWeight='400' color='#828282' whiteSpace='pre-wrap' cursor='default'>
                  <Highlight query={selectedText} styles={{ py: '1', bg: 'orange.100', whiteSpace: 'pre-wrap', cursor: 'default' }}>
                    {essay.draft}
                  </Highlight>
                </Text>

        : <Text ref={essayRef} onClick={onClickUp} fontSize='15px' fontWeight='400' color='#828282' whiteSpace='pre-wrap'>
                  {essay.draft}
                </Text>

      : <Text ref={essayRef} fontSize='15px' fontWeight='400' color='#828282' whiteSpace='pre-wrap'>
                {commentChuncks.map(({ match, text }) => (
                  !match
                    ? text
                    : <Mark key={text} onClick={() => onOpenCommentary(text)} py='1' bg='orange.100' whiteSpace='pre-wrap' cursor='pointer' _hover={{ bg: 'orange.200' }} pointerEvents={isOpen ? 'none' : 'auto'}>
                      {text}
                    </Mark>
                ))}
              </Text>
  )
}
