import './CriarInstituicao.css'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import useCriarInstituicaoStore from '../../store/useCriarInstituicaoStore'
import CriarInstituicaoStep1 from '../../components/CriarInstituicaoStep1'
import CriarInstituicaoStep2 from '../../components/CriarInstituicaoStep2'
import Page from '../Page'

const CriarInstituicao = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [newInstitutionId, setNewInstitutionId] = useState('')
  const navigate = useNavigate()

  const { cleanCriarInstituicaoData } = useCriarInstituicaoStore()

  const handleNextStep = (institutionId) => {
    if (currentStep === 1) {
      setCurrentStep(currentStep + 1)
      setNewInstitutionId(institutionId)
      cleanCriarInstituicaoData()
    } else {
      navigate('/detalhamento-instituicao/' + newInstitutionId)
    }
  }

  const handlePreviousStep = () => {
    setCurrentStep(1)
    cleanCriarInstituicaoData()
    navigate('/area-de-instituicoes')
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CriarInstituicaoStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      case 2:
        return <CriarInstituicaoStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <Flex alignItems={'center'} justifyContent={'center'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CriarInstituicao
