import React from 'react'
import './AtividadeTxt322.css'

function AtividadeTxt322(props) {
  const { atividade } = props

  return (
    <div className="atividade-txt-19">

      <div className="content-29">
        <div className="atividade-iiii-unidade-2-1 valign-text-middle publicsans-normal-nevada-14px">
          {atividade}
        </div>
      </div>
    </div>
  )
}

export default AtividadeTxt322
