import React from 'react'
import './CadastroStep4.css'
import IlustracaoLogin from '../IlustracaoLogin'

function CadastroStep4(props) {
  const { handlingNextStep } = props

  return (
        <div className="container-center-horizontal-cadastro-step-4">
            <div className="cadastro-step04-cadastro-step-4 screen-cadastro">
                <div className={'cadastro-columns'}>

                    <IlustracaoLogin/>

                    <div className={'cadastro-body-step-4'}>
                        <div className="overlap-group3-cadastro-step-4">
                            <div className="title-cadastro-step-4">
                                <h1 className="title-1-cadastro-step-4 valign-text-bottom-cadastro-step-4 publicsans-bold-absolute-zero-40px">
                                    Cadastro realizado!
                                </h1>
                            </div>
                            <p className="finalizacao-cadastro-text publicsans-normal-nevada-20px">
                                Você já pode entrar na sua conta e aproveitar o melhor da correção.
                            </p>
                            <div className="frame-2618-cadastro-step-4">
                                <div className="group-2-cadastro-step-4">

                                    <img
                                        className="vector-4-cadastro-step-4"
                                        src="/img/concluido-icon.svg"
                                        alt="Vector"
                                    />

                                </div>
                            </div>
                        </div>
                        <Buttons
                            button2Props={buttonsData.button2Props}
                            handlingNextStep={handlingNextStep}
                        />
                    </div>
                </div>
            </div>
        </div>
  )
}

export default CadastroStep4

function Buttons(props) {
  const { button2Props, handlingNextStep } = props

  return (
        <div className="buttons-cadastro-step-4">
            <Button2 mainbutton2Props={button2Props.mainbutton2Props} handlingNextStep={handlingNextStep}/>
        </div>
  )
}

function Button2(props) {
  const { mainbutton2Props, handlingNextStep } = props

  return (
        <div onClick={handlingNextStep} className="button-2-cadastro-step-4">
            <Mainbutton2>{mainbutton2Props.children}</Mainbutton2>
        </div>
  )
}

function Mainbutton2(props) {
  const { children } = props

  return (
        <div className="mainbutton-2-cadastro-step-4">
            <div className="content-2-cadastro-step-4">
                <div className="main-button-2-cadastro-step-4 publicsans-bold-white-14px">
                    {children}
                </div>
            </div>
        </div>
  )
}

const mainbuttonData = {
  children: 'Voltar para home'
}

const buttonData = {
  mainbutton6Props: mainbuttonData
}

const mainbutton2Data = {
  children: 'Continuar'
}

const button2Data = {
  mainbutton2Props: mainbutton2Data
}

const buttonsData = {
  buttonProps: buttonData,
  button2Props: button2Data
}
