import {create} from 'zustand'

const useCriarEscolaStore = create((set) => ({
  currentStep: 1,
  criarEscolaData: {
    schoolId: '',
    name: '',
    document: '',
    address: {},
  },

  setCurrentStep: (step) => set({currentStep: step}),
  setCriarEscolaData: (data) => set((state) => ({
    criarEscolaData: {...state.criarEscolaData, ...data}
  })),
  setCriarEnderecoEscolaData: (data) => set((state) => ({
    criarEscolaData: {...state.criarEscolaData, address: {...state.criarEscolaData.address, ...data}}
  })),
  cleanCriarEscolaData: () => set({criarEscolaData: {address: {}}})
}))

export default useCriarEscolaStore
