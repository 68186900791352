import React from 'react'
import './TitleLink4.css'

function CardTitle(props) {
  const { groupName } = props

  return (
    <div className="title-link-1">
      <div className="turma-3">
        <div className="title-card-style publicsans-normal-absolute-zero-20px">{groupName}</div>
      </div>
    </div>
  )
}

export default CardTitle
