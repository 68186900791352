import './CriarTema.css'
import React, { useEffect, useState } from 'react'
import CriarTemaStep1 from '../../components/CriarTemaStep1'
import { useNavigate } from 'react-router-dom'
import CriarTemaStep2 from '../../components/CriarTemaStep2'
import { Flex } from '@chakra-ui/react'
import useCriarTemaStore from '../../store/useCriarTemaStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import Page from '../Page'

const CriarTema = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [newThemeId, setNewThemeId] = useState('')
  const navigate = useNavigate()

  const { setCriarTemaData, cleanCriarTemaData } = useCriarTemaStore()
  const { loggedUser } = useLoggedUserStore()

  useEffect(() => {
    setCriarTemaData({ schoolId: loggedUser.user_id })
  }, [])

  const handleNextStep = (themeId) => {
    if (currentStep === 1) {
      setCurrentStep(currentStep + 1)
      setNewThemeId(themeId)
      cleanCriarTemaData()
    } else {
      navigate('/detalhamento-tema/' + newThemeId)
    }
  }

  const handlePreviousStep = () => {
    setCurrentStep(1)
    cleanCriarTemaData()
    navigate('/area-de-temas')
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CriarTemaStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      case 2:
        return <CriarTemaStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep}/>
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <Flex alignItems={'center'} justifyContent={'center'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CriarTema
