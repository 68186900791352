import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Frame2426 from '../../components/Frame2426'
import TitleCta32 from '../../components/TitleCta32'
import CardTitle from '../../components/TitleLink4'
import Button from '../../components/Button'
import Frame2434 from '../../components/Frame2434'
import AtividadeTxt132 from '../../components/AtividadeTxt132'
import TabelaTitle42 from '../../components/TabelaTitle42'
import AtividadeTxt322 from '../../components/AtividadeTxt322'
import AtividadeTxt52 from '../../components/AtividadeTxt52'
import TabelaTitle22 from '../../components/TabelaTitle22'
import AtividadeDetalhes from '../../components/AtividadeTxt62'
import './InicioProfessor.css'
import Page from '../Page'
import Menu from '../../components/Menu'
import TurmaSemAtividadeIcon from '../../components/TurmaSemAtividadeIcon'
import Title2 from '../../components/Title2'
import Headbar from '../../components/Headbar'
import { listAllGroups } from '../../apis/schoolApi/groupApi'
import { getEssaysByGroupIdApi, getEssaysByTeacherIdApi } from '../../apis/essayApi/essayApi'
import { getActivityByTeacherIdApi } from '../../apis/activitityApi/activityApi'
import { essayStatus } from '../../utils/essayStatus'

function InicioProfessor(props) {
  const [tema, setTema] = useState([])
  const [atividade, setAtividade] = useState([])
  const [entrega, setEntrega] = useState([])
  const [aguardandoCorrecao, setAguardandoCorrecao] = useState([])
  const [emCorrecao, setEmCorrecao] = useState([])
  const [corrigida, setCorrigida] = useState([])
  const [andamento, setAndamento] = useState([])
  const [setasDetalhe, setSetasDetalhe] = useState([])
  const [groups, setGroups] = useState([])
  const [essays, setEssays] = useState([])
  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchInitialData = async () => {
    const [essaysResult, groupsResult, activityResult] = await Promise.all([
      getEssaysByTeacherIdApi(),
      listAllGroups(),
      getActivityByTeacherIdApi()
    ])

    if (groupsResult) {
      const fetchGroupEssayPromises = groupsResult.slice(0, 4).map(group =>
        fetchGroupEssays(group.id).then(groupEssaysResult => {
          group.essays = groupEssaysResult
        })
      )

      await Promise.all(fetchGroupEssayPromises)
    }

    return { essaysResult, groupsResult, activityResult }
  }

  const fetchGroupEssays = (groupID) => {
    return new Promise((resolve) => {
      const result = getEssaysByGroupIdApi(groupID)

      resolve(result)
    })
  }

  useEffect(() => {
    fetchInitialData().then(({ essaysResult, groupsResult, activityResult }) => {
      setGroups(groupsResult)
      setEssays(essaysResult)
      setActivities(activityResult)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    handleRender()
  }, [loading])

  function handleRender() {
    calculoQuantSetas()
    temaRedacoes()
    atividades()
    entregas()
    handleEssaysOverall()
    controleRedacoes()
  }

  function calculoQuantSetas() {
    const result = activities.slice(0, 4).map((activity, key) => {
      return (<AtividadeDetalhes key={key} activityID={activity.id}/>)
    })

    setSetasDetalhe(result)
  }

  function temaRedacoes() {
    const result = activities.slice(0, 4).map((activity, key) => {
      return (<AtividadeTxt132 turma={activity.theme.name} key={key}/>)
    })

    setTema(result)
  }

  function atividades() {
    const result = activities.slice(0, 4).map((activity, key) => {
      return (
        <AtividadeTxt322 atividade={activity.subject}
                         key={key}/>
      )
    })

    setAtividade(result)
  }

  function entregas() {
    const result = activities.slice(0, 4).map((activity, key) => {
      const length = activity.amountSubmitted.length + activity.amountReviewed.length + activity.amountClosed.length

      return (
        <AtividadeTxt52 entregas={length} key={key}/>
      )
    })

    setEntrega(result)
  }

  function calculoRedacoesStatus(statusDesejado) {
    let redacoes = 0
    essays.forEach(essay => {
      if (essayStatus[essay.status] === statusDesejado) {
        redacoes++
      }
    })

    return redacoes
  }

  function calculoRedacoesStatusPorTurma(statusDesejado, group) {
    const filteredEssays = group.essays.filter(essay => essay.status === statusDesejado)

    return filteredEssays.length
  }

  function handleEssaysOverall() {
    setAguardandoCorrecao(<Frame2426
      folderIcon={'/img/folder-icon.png'}
      aguardandoCorreo={'Aguardando correção'}
      number={calculoRedacoesStatus('Aguardando Correção')}/>)

    setEmCorrecao(<Frame2426
      folderIcon={'/img/folder-pen-icon.png'}
      aguardandoCorreo={'Redações em correção'}
      number={calculoRedacoesStatus('Em Revisão')}/>)

    setCorrigida(<Frame2426
      folderIcon={'/img/folder-ok-icon.png'}
      aguardandoCorreo={'Redações corrigidas'}
      number={calculoRedacoesStatus('Finalizada')}/>)
  }

  function calculoRedacoesTotais(group) {
    return group.essays.length
  }

  function calculoProgressBar(redacoes, redacoesTotais) {
    const result = (redacoes / redacoesTotais) * 100
    return (result)
  }

  function controleRedacoes() {
    const result = groups.map((group, key) => {
      if (group.essays.length === 0) {
        return (<TurmaSemAtividadeIcon groupName={group.name} key={key}/>)
      }

      const redacoesStatusParaCorrigir = calculoRedacoesStatusPorTurma(20, group)
      const redacoesStatusCorrigidas = calculoRedacoesStatusPorTurma(30, group) +
        calculoRedacoesStatusPorTurma(40, group)
      const redacoesTotais = calculoRedacoesTotais(group)
      const progressBarBlue = calculoProgressBar(redacoesStatusParaCorrigir, redacoesTotais)
      const progressBarGreen = calculoProgressBar(redacoesStatusCorrigidas, redacoesTotais)
      return (
        <div className="turma-card" key={key}>
          <CardTitle groupName={group.name}/>
          <div className="content-22">
            <Frame2434 progressPercentageBlue={progressBarBlue}
                       progressPercentageGreen={progressBarGreen}
                       redacoesParaCorrigir={redacoesStatusParaCorrigir}
                       redacoesCorrigidas={redacoesStatusCorrigidas}
                       redacoesTotal={redacoesTotais}/>
            <div className="frame-258-1">
              <Button/>
            </div>
          </div>
        </div>
      )
    })

    setAndamento(result)
  }

  return (
    <div className="inicio screen">
      <Page authPage={true} allowedUserType={'teacher monitor independentteacher'}>
        <div className="body-no-right">
          <header className="header">
            <h1 className="ol-gestor publicsans-normal-absolute-zero-40px">{'Olá, Professor!'}</h1>
            <div
              className="oque-vamos-corrigir-hoje publicsans-normal-nevada-20px">{'O que vamos corrigir hoje?'}</div>
          </header>
          <div className="status-4">
            {aguardandoCorrecao}
            {emCorrecao}
            {corrigida}
          </div>
          <div className="atividades-em-andamento">
            <TitleCta32 text={'Turmas'}
            />
            <div className="frame-2585">
              {andamento}
            </div>
          </div>
          <div className="atividades-em-andamento-1">
            <div className="title-cta-4">
              <Title2>{'Atividades em andamento'}</Title2>
              <div className="cta-atividades">
                <Link to="/area-de-atividades">
                  <div className="text-27 publicsans-normal-azure-radiance-14px">{'Ver todas atividades'}</div>
                </Link>
                <img className="setaDireita-VerTodasAtividades" src={'/img/ic-arrowright-61@2x.png'}
                     alt="Seta a direita de ver todas as atividades"/>
              </div>
            </div>
            <div className="tabela-atividades-1">
              <div className="tabela-01-1">
                <TabelaTitle42>{'Atividade'}</TabelaTitle42>
                {atividade}
              </div>
              <div className="tabela-02-6">
                <TabelaTitle42
                  className={'tabela-title-13'}>{'Tema'}</TabelaTitle42>
                {tema}
              </div>
              <div className="tabela-02-7">
                <TabelaTitle42
                  className={'tabela-title-13'}>{'Entregas'}</TabelaTitle42>
                {entrega}
              </div>
              <div className="tabela-02-8">
                <TabelaTitle22>{'Detalhes'}</TabelaTitle22>
                {setasDetalhe}
              </div>
            </div>
          </div>
        </div>
        <Menu/>
        <Headbar/>
      </Page>
    </div>
  )
}

export default InicioProfessor
