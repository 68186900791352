import React from 'react'
import './AtividadeTxt62.css'
import { Link } from 'react-router-dom'

function AtividadeDetalhes(props) {
  const { activityID } = props
  return (
        <Link to={'/detalhamento-atividade/' + activityID}>
            <div className="atividade-txt-9">
                <img className="ic-arrowright-1" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright"/>
            </div>
        </Link>
  )
}

export default AtividadeDetalhes
