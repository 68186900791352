import React, { useState } from 'react'
import Headbar from '../../components/Headbar'
import StepsImage from '../StepsImage'
import './CriarMonitorStep1.css'
import BackStepButton from '../BackStepButton'
import ForwardButton from '../ForwardButton'
import FormStepNotSelected from '../FormStepNotSelected'
import { Flex, Heading, Input, VStack } from '@chakra-ui/react'
import { createMonitorApi } from '../../apis/teacherApi/teacherApi'
import { emailRegex } from '../../utils/emailRegex'
import { phoneNumberFormatter } from '../../utils/phoneNumberFormatter'
import { cpfFormatter } from '../../utils/cpfFormatter'
import useLoggedUserStore from '../../store/useLoggedUserStore'

function CriarMonitorStep1(props) {
  const { handleReturn, handlingNextStep } = props
  const { loggedUser } = useLoggedUserStore()

  const [monitorValue, setMonitorValue] = useState({ name: '', email: '', phoneNumber: '', documentNumber: '', language: loggedUser.user_language })
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  const createMonitor = () => {
    return new Promise((resolve) => {
      const result = createMonitorApi(monitorValue)
      resolve(result)
    })
  }

  function handleSubmit() {
    if (monitorValue.name && monitorValue.email && (emailRegex.test(monitorValue.email))) {
      createMonitor().then((result) => {
        handlingNextStep(result)
      })
    } else {
      setNameError(!monitorValue.name)
      setEmailError(!monitorValue.email)
      setEmailError(!emailRegex.test(monitorValue.email))
    }
  }

  const handleTelefoneInputChange = (event) => {
    const formattedValue = phoneNumberFormatter(event.target.value)
    setMonitorValue({ ...monitorValue, phoneNumber: formattedValue })
  }

  function handleDocumentInputChange(event) {
    const formattedDocument = cpfFormatter(event)
    if (formattedDocument.length <= 14) {
      setMonitorValue({ ...monitorValue, documentNumber: formattedDocument })
    }
  }

  return (
    <Flex w={'100%'}>
      <VStack w={'100%'}>
        <Headbar returnTo={'/area-de-monitores'}/>
        <VStack marginTop={'107px'}>
          <Heading alignSelf={'start'} ml={'35px'} className={'publicsans-extra-extra-bold-absolute-zero-32px'}
                   color={'#0A41D6'}>
            Criar tema
          </Heading>
          <VStack mt={'32px'}>
            <StepsImage text={'Sobre o monitor'} number={1}/>
            <div className="cards">
              <div className="frame-2546">
                <div
                  className={`${nameError ? 'error-form' : ''} nome-criar-atividade-step-1 publicsans-bold-nevada-16px`}>{'Nome*'}</div>
                <Input h={'48px'} w={'100%'}
                       backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       value={monitorValue.name}
                       onChange={(e) => {
                         setNameError(false)
                         setMonitorValue({ ...monitorValue, name: e.target.value })
                       }}
                />
                <div
                  className={`${emailError ? 'error-form' : ''} nome-criar-atividade-step-1 publicsans-bold-nevada-16px`}>{'Email*'}</div>
                <Input h={'48px'} w={'100%'}
                       backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       value={monitorValue.email}
                       onChange={(e) => {
                         setEmailError(false)
                         setMonitorValue({ ...monitorValue, email: e.target.value })
                       }}
                />
                <div
                  className={'nome-criar-atividade-step-1 publicsans-bold-nevada-16px'}>{'Telefone'}</div>
                <Input h={'48px'} w={'100%'}
                       backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       value={monitorValue.phoneNumber}
                       onChange={handleTelefoneInputChange}
                />
                <div
                  className={'nome-criar-atividade-step-1 publicsans-bold-nevada-16px'}>{'CPF'}</div>
                <Input h={'48px'} w={'100%'}
                       backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       value={monitorValue.documentNumber}
                       onChange={handleDocumentInputChange}
                />
              </div>
            </div>
            <div className="steps">
              <FormStepNotSelected number={2} configuraesDaAtividade={'Enviar'}/>
            </div>
          </VStack>
          <div className="buttons">
            <BackStepButton handleReturn={handleReturn} text={'Voltar'}/>
            <ForwardButton forwardTo={handleSubmit} className={'button-10'} text={'Finalizar'}
                           buttonClassName={''}/>
          </div>
        </VStack>
      </VStack>
    </Flex>
  )
}

export default CriarMonitorStep1
