import { Box, Button, Divider, FormControl, FormLabel, Grid, GridItem, HStack, Heading, Input, Select, Stack } from '@chakra-ui/react'
import { useState } from 'react'
import { createIndependentTeacherApi } from '../../apis/teacherApi/teacherApi'

export const NewIndependentTeacherModalBody = ({ onClose }) => {
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [documentNumber, setDocumentNumber] = useState('')
  const [language, setLanguage] = useState('pt-BR')
  const [firstGroupName, setFirstGroupName] = useState('')
  const [groupShift, setGroupShift] = useState(0)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  async function submit() {
    try {
      setIsLoading(true)
      const body = {
        name,
        phoneNumber,
        documentNumber,
        language,
        firstGroupName,
        groupShift,
        email,
        password
      }

      const data = await createIndependentTeacherApi(body)
      if (data.success) {
        onClose()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
        <form onSubmit={submit}>
            <Stack spacing={6}>
                <Stack>
                    <Heading size='sm'>Detalhes</Heading>
                    <Grid templateColumns='repeat(6, 1fr)' gap={5}>
                        <GridItem colSpan={{ sm: 3, lg: 2 }}>
                            <FormControl isRequired>
                                <FormLabel>Nome</FormLabel>
                                <Input value={name} onChange={e => setName(e.target.value)} />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ sm: 3, lg: 2 }}>
                            <FormControl isRequired>
                                <FormLabel>Telefone</FormLabel>
                                <Input type='number' value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ sm: 3, lg: 2 }}>
                            <FormControl isRequired>
                                <FormLabel>CPF</FormLabel>
                                <Input type='number' value={documentNumber} onChange={e => setDocumentNumber(e.target.value)} />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ sm: 3, lg: 2 }}>
                            <FormControl isRequired>
                                <FormLabel>Idioma</FormLabel>
                                <Select value={language} placeholder='Selecione' onChange={e => setLanguage(e.target.value)} >
                                    <option value='pt-BR'>Português (Brasil)</option>
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ sm: 3, lg: 2 }}>
                            <FormControl isRequired>
                                <FormLabel>Primeiro Grupo/Escola</FormLabel>
                                <Input value={firstGroupName} onChange={e => setFirstGroupName(e.target.value)} />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ sm: 3, lg: 2 }}>
                            <FormControl isRequired>
                                <FormLabel>Turno</FormLabel>
                                <Select value={groupShift} placeholder='Selecione' onChange={e => setGroupShift(e.target.value)}>
                                    <option value={0}>Manhã</option>
                                    <option value={1}>Tarde</option>
                                    <option value={3}>Noite</option>
                                </Select>
                            </FormControl>
                        </GridItem>
                    </Grid>
                </Stack>
                <Divider />
                <Stack>
                    <Heading size='sm'>Autenticação</Heading>
                    <HStack width='100%' spacing={6}>
                        <Box width='50%'>
                            <FormControl isRequired>
                                <FormLabel>Email</FormLabel>
                                <Input autoComplete='false' type='email' value={email} onChange={e => setEmail(e.target.value)} />
                            </FormControl>
                        </Box>
                        <Box width='50%'>
                            <FormControl isRequired>
                                <FormLabel>Senha</FormLabel>
                                <Input autoComplete='false' type='password' value={password} onChange={e => setPassword(e.target.value)} />
                            </FormControl>
                        </Box>
                    </HStack>
                </Stack>
                <Stack width='100%' alignItems='end' my={4}>
                    <Button type='submit' variant='primary' onClick={submit} isLoading={isLoading}>Cadastrar</Button>
                </Stack>
            </Stack>
        </form>
  )
}
