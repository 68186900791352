import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const listAllGroups = async (schoolIDParam) => {
  const schoolID = schoolIDParam ? schoolIDParam : localStorage.getItem('user_id')

  const response = await httpRequestHelper.getWithAuth(BACKEND_API + `/v1/school/${schoolID}/group`)

  return response.data.message
}

export const createGroupApi = async (data, schoolIDParam) => {
  const schoolID = schoolIDParam ? schoolIDParam : localStorage.getItem('user_id')
  data.code = 'AAAAAA'

  const response = await httpRequestHelper.postWithAuth(BACKEND_API + `/v1/school/${schoolID}/group`, data)

  return response.data.message
}
