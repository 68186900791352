import {useNavigate} from 'react-router-dom'
import React from 'react'
import './MenuTemas.css'
import useLoggedUserStore from '../../store/useLoggedUserStore'

function MenuTemas() {
  const {loggedUser} = useLoggedUserStore()
  const navigate = useNavigate()

  let menuClass = 'menu-item'
  let menuPicturePath = '/img/icon-temas.png'
  let menuPictureText = 'publicsans-bold-nevada-16px'

  if (document.location.toString().includes('/area-de-temas')) {
    menuClass += ' menu-foco'
    menuPicturePath = '/img/icon-temas-foco.png'
    menuPictureText = 'publicsans-bold-absolute-zero-16px'
  }

  if (loggedUser.user_role === 'adm') {
    return ('')
  }

  return (
    <div className={menuClass} onClick={() => navigate('/area-de-temas')}>
      <img className="menu-temas-picture" src={menuPicturePath} alt="edit-2851791"/>
      <div className={menuPictureText}>{'Temas'}</div>
    </div>
  )
}

export default MenuTemas
