import { create } from 'zustand'

const useCriarTurmaStore = create((set) => ({
  currentStep: 1,
  criarTurmaData: {
    name: '',
    description: '',
    shift: 0
  },

  setCurrentStep: (step) => set({ currentStep: step }),
  setCriarTurmaData: (data) => set((state) => ({
    criarTurmaData: { ...state.criarTurmaData, ...data }
  })),
  cleanCriarTurmaData: () => set({ criarTurmaData: {} })
}))

export default useCriarTurmaStore
