import { Icon } from '@chakra-ui/react'

export const ReplayIcon = () => {
  return (
        <Icon xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 24 25" fill="none">
            <g clipPath="url(#clip0_1337_2574)">
                <path d="M12 5.5V1.5L7 6.5L12 11.5V7.5C15.31 7.5 18 10.19 18 13.5C18 16.81 15.31 19.5 12 19.5C8.69 19.5 6 16.81 6 13.5H4C4 17.92 7.58 21.5 12 21.5C16.42 21.5 20 17.92 20 13.5C20 9.08 16.42 5.5 12 5.5Z" fill="#0A41D6" />
            </g>
            <defs>
                <clipPath id="clip0_1337_2574">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </Icon>
  )
}
