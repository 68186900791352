import React, { useEffect, useState } from 'react'
import BackStepButton from '../BackStepButton'
import ForwardButton from '../ForwardButton'
import Headbar from '../Headbar'
import StepsImage from '../StepsImage'
import FormStepNotSelected from '../FormStepNotSelected'
import './CriarTurmaStep1.css'
import { listAllTeachers } from '../../apis/teacherApi/teacherApi'
import { Box, Flex, Heading, Input, Select, Text, Textarea, VStack } from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'
import useCriarTurmaStore from '../../store/useCriarTurmaStore'
import { shiftMapping } from '../../utils/shiftENUM'

function CriarTurmaStep1(props) {
  const { handleReturn, handlingNextStep } = props

  const maxCharacteres = 180

  const [loading, setLoading] = useState(true)
  const [monitorValue, setMonitorValue] = useState([])
  const [monitorOptions, setMonitorOptions] = useState([])

  const [nomeTurmaError, setNomeTurmaError] = useState(false)
  const [shiftError, setShiftError] = useState(false)

  const { criarTurmaData, setCriarTurmaData } = useCriarTurmaStore()

  const handleTextChange = (event) => {
    const newText = event.target.value
    if (newText.length <= maxCharacteres) {
      setCriarTurmaData({ description: newText })
    }
  }

  const fetchOptions = () => {
    return new Promise((resolve) => {
      Promise.all([listAllTeachers()]).then(([monitorResult]) => {
        resolve({ monitorResult })
      })
    })
  }

  useEffect(() => {
    fetchOptions()
      .then(({ monitorResult }) => {
        setMonitorOptions(
          monitorResult.map((monitor, key) => {
            return { value: monitor.name, label: monitor.name, id: monitor.id }
          })
        )
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
    setCriarTurmaData({ shift: 0 })
  }, [])

  const handleSubmit = () => {
    if (criarTurmaData.name && criarTurmaData.shift !== '') {
      handlingNextStep()
    } else {
      setNomeTurmaError(!criarTurmaData.name)
      setShiftError(criarTurmaData.shift === '')
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightblue' : null
    }),
    control: (provided) => ({
      ...provided
    })
  }

  return (
    <Flex h={'100%'} w={'100%'}>
      <VStack w={'100%'} h={'100%'}>
        <Headbar returnTo={'/area-de-turmas'}/>
        <VStack marginTop={'107px'}>
          <Heading alignSelf={'start'} ml={'35px'} className={'publicsans-extra-extra-bold-absolute-zero-32px'}>
            Criar turma
          </Heading>
          <VStack mt={'32px'}>
            <StepsImage text={'Sobre a turma'} number={1}/>
            <div className="cards">
              <div className="frame-2546">
                <div
                  className={`${nomeTurmaError ? 'error-form' : ''} nome-criar-atividade-step-1 publicsans-bold-nevada-16px`}>{'Nome*'}</div>
                <Input h={'48px'} w={'100%'}
                       backgroundColor={'white'}
                       borderColor={'#d7e3fb'}
                       onChange={(e) => {
                         setNomeTurmaError(false)
                         setCriarTurmaData({ name: e.target.value })
                       }}
                />

                <div className="selecione-corretores-criar-turma publicsans-bold-nevada-16px">Selecione corretores</div>
                <Box w={'100%'} h={'48px'}>
                  <MultiSelect
                    placeholder={loading ? 'Aguarde um momento...' : 'Selecionar monitor'}
                    onChange={setMonitorValue}
                    borderColor={'#d7e3fb'}
                    backgroundColor={'white'}
                    isDisabled={loading}
                    options={monitorOptions}
                    value={monitorValue}
                    w={'100%'}
                  />
                </Box>
                <div className={`${shiftError ? 'error-form' : ''} selecione-corretores-criar-turma publicsans-bold-nevada-16px`}>Selecione o turno da turma</div>
                <Box w={'100%'} h={'48px'}>
                  <Select
                    placeholder={loading ? 'Aguarde um momento...' : 'Selecionar Turno da turma'}
                    onChange={(e) => {
                      setShiftError(false)
                      setCriarTurmaData({ shift: e.target.value })
                    }}
                    borderColor={'#d7e3fb'}
                    backgroundColor={'white'}
                    styles={customStyles}
                    isDisabled={loading}
                    defaultValue={criarTurmaData.shift}
                    w={'100%'}>
                    {Object.entries(shiftMapping).map((option, key) => (
                      <option className={'select-tema-text'} key={key} value={option[0]}>
                        {option[1]}
                      </option>
                    ))}
                  </Select>
                </Box>

                <div className="atividade-descricao-container">
                  <div className="descricao-title publicsans-bold-nevada-16px">{'Mensagem personalizada'}</div>

                  <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
                    <Textarea
                      placeholder=""
                      value={criarTurmaData.description}
                      onChange={handleTextChange}
                      resize="vertical"
                      w={'100%'}
                      h={'100%'}
                      borderColor={'#d7e3fb'}
                    />
                    <Text
                      fontSize="sm"
                      position="absolute"
                      bottom="5px"
                      right="20px"
                      color="#a9a8a8"
                      fontFamily={'Public Sans'}
                    >
                      {criarTurmaData.description ? criarTurmaData.description.length : 0}/{maxCharacteres}
                    </Text>
                  </Box>
                </div>
              </div>
            </div>
            <div className="steps">
              <FormStepNotSelected number={2} configuraesDaAtividade={'Enviar'}/>
            </div>
          </VStack>
          <div className="buttons-criar-turma">
            <BackStepButton handleReturn={handleReturn} text={'Voltar'}/>
            <ForwardButton forwardTo={handleSubmit} className={'button-10'} text={'Continuar'} buttonClassName={''}/>
          </div>
        </VStack>
      </VStack>
    </Flex>
  )
}

export default CriarTurmaStep1
