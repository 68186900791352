export const handleFileChange = (event, temaId) => {
  return new Promise((resolve, reject) => {
    const file = event.target.files[0]

    if (!file) {
      return
    }

    const extractFileDetails = (file) => {
      const fileParts = file.name.split('.')
      const extension = fileParts.pop()
      const title = fileParts.join('.')
      const type = ['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase()) ? 2 : 3

      return { title, extension, type }
    }

    const { title, extension, type } = extractFileDetails(file)

    const reader = new FileReader()

    reader.onloadend = () => {
      const base64URL = reader.result
      const base64Data = base64URL.split(',')[1]

      const payload = {
        title,
        type,
        value: base64Data,
        extension,
        themeId: temaId
      }

      resolve(payload)
    }

    reader.onerror = () => {
      reject(new Error('Error reading the file'))
    }

    reader.readAsDataURL(file)
  })
}
