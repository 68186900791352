import React, { useState } from 'react'
import LogoSVG from '../LogoSVG'
import MenuInicio from '../MenuInicio'
import MenuOutros from '../MenuOutros'
import './Menu.css'
import MenuMonitores from '../MenuMonitores'
import MenuRedacoes from '../MenuRedacoes'
import MenuTurmas from '../MenuTurmas'
import MenuTemas from '../MenuTemas'
import MenuInstitution from '../MenuInstitution'
import { VStack } from '@chakra-ui/react'
import MenuEscolas from '../MenuEscolas'

function Menu() {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
        <div className={'menu-container'}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            <LogoSVG isHovered={isHovered}/>
            <div className={'menu-reduzido-4'}>
                <VStack width={'100%'} className="navbar-4" justifyContent={'space-between'}>
                    <MenuInicio isHovered={isHovered}/>
                    <MenuEscolas isHovered={isHovered}/>
                    <MenuRedacoes isHovered={isHovered}/>
                    <MenuTemas isHovered={isHovered}/>
                    <MenuInstitution isHovered={isHovered}/>
                    <MenuTurmas isHovered={isHovered}/>
                    <MenuMonitores isHovered={isHovered}/>
                </VStack>
                <MenuOutros/>
            </div>
        </div>
  )
}

export default Menu
