import React from 'react'
import Content from '../Content'
import './Mainbutton22.css'

function Mainbutton22(props) {
  const { className, contentProps } = props

  return (
    <div className={`mainbutton-2 ${className || ''}`}>
      <Content>{contentProps.children}</Content>
    </div>
  )
}

export default Mainbutton22
