import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {DoLogin} from '../../apis/LoginApi'
import './Login.css'
import HeaderCadastro from '../../components/HeaderCadastro'
import IlustracaoLogin from '../../components/IlustracaoLogin'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import {buildMessage} from "../../utils/toastMiddleware";
import {Button, Spinner, useToast} from "@chakra-ui/react";


function App() {
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const {setLoggedUserData} = useLoggedUserStore()
  const navigate = useNavigate();
  const toast = useToast()

  const [loading, setLoading] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    const {userName, password} = document.forms[0];
    const response = await DoLogin({userName: userName.value, password: password.value}, setLoggedUserData);
    setLoading(false)
    if(response.data.message.statusCode){
      const status = response.data.message.statusCode
      if(status === 401) {
        toast(buildMessage('error', 'Acesso não autorizado.', "Confirme o seu e-mail."))
      } else if (status === 423) {
        toast(buildMessage('error', 'Acesso negado.', "Espere 5 minutos antes de tentar novamente."))
      }
    } else {
      loginSuccessful()
    }

  }

  const loginSuccessful = () => {
    const role = localStorage.getItem('user_role');

    switch (role) {
      case 'independentteacher':
      case 'monitor':
      case 'teacher':
        navigate('/inicio-professor');
        break;
      case 'student':
        navigate('/inicio-aluno')
        break;
      case 'owner':
        navigate('/inicio-owner')
        break;
      case 'adm':
       navigate('/area-de-escolas')
        break;
      default:
        break;

    }
  }

  return (
    <div className="container-center-horizontal-login">
      <div className="cadastro-step01-login screen">
        <div className="overlap-group3-login">
          <HeaderCadastro/>
        </div>
        <div className={'login-columns'}>
          <IlustracaoLogin/>
          <form className={'login-body'} onSubmit={handleSubmit}>
            <div className="title-login">
              <h1 className="title-1-login valign-text-bottom">
                Login
              </h1>
              <p className="entre-e-comece-a-agi-login publicsans-normal-nevada-24px">
                Entre e comece a agilizar as suas correções.
              </p>
            </div>

            <div className="frame-2621-login">
              <div className="frame-26-login">
                <div className="e-mail-login publicsans-bold-nevada-16px">
                  E-mail
                </div>
                <TextImput maintextimputProps={textImputData.maintextimputProps}/>
              </div>
              <div className="frame-26-login">
                <div className="senha-login publicsans-bold-nevada-16px">
                  Senha
                </div>

                <div className="text-imput-1-login">
                  <div className="password-input-login">
                    <div className="password-inner-box-login">
                      <img className="password-images-login" src={'/img/ic-senha.svg'}

                           alt="ic-senha"/>
                      <input
                        className="text-password-login publicsans-normal-black-14px"
                        name="password"
                        placeholder="Insira até 12 caracteres"
                        type={isPasswordVisible ? 'text' : 'password'}
                      />
                      <img onClick={togglePasswordVisibility}
                           className="password-images-login" src={'/img/ic-visibility.svg'}
                           alt="ic-visibility"/>
                    </div>
                  </div>
                </div>

                <div className="container-esqueceu-senha valign-text-middle">
                  <Link className="text-2-login" to={'/recuperar-senha'}>

                    Esqueceu sua senha?

                  </Link>
                </div>
              </div>
            </div>

            <div className="button-1-login">
              <div className="mainbutton-1-login">
                <div className="content-1-login publicsans-bold-white-14px">
                  <Button
                    type="submit"
                    disabled={loading}
                    variant="unstyled"
                    width={'100%'}
                  >
                    {loading ? <Spinner size="xs" marginRight="2" /> : null}
                    Entrar
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default App

function TextImput(props) {
  const {maintextimputProps} = props

  return (
    <div className="text-imput-login">
      <Maintextimput
        icEmail={maintextimputProps.icEmail}
        text={maintextimputProps.text}/>
    </div>
  )
}

function Maintextimput(props) {
  const {icEmail} = props

  return (
    <div className="email-box-login">
      <div className="email-inner-login">
        <img className="ic-email-login" src={icEmail} alt="ic-email"/>
        <input
          className="text-login publicsans-normal-black-14px"
          placeholder={'nome@dominio.com'}
          type={'text'}
          name="userName"
        />
      </div>
    </div>
  )
}

const maintextimputData = {
  icEmail: '/img/ic-email.svg',
  text: 'nome@dominio.com'
}

const textImputData = {
  maintextimputProps: maintextimputData
}
