export const gradeColors = {
  0: 'red',
  40: '#f5e982',
  80: '#d7db95',
  120: '#92e8ce',
  160: '#63dbb7',
  200: '#02E39F'
}

export const ESSAY_STATUS = {
  10: 'Rascunho',
  20: 'Aguardando correção',
  30: 'Finalizada'
}

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))
