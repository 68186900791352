import React, { useEffect, useState } from 'react'
import './DetalhamentoAtividade.css'
import Headbar from '../../components/Headbar'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteActivityApi, getActivityByIdApi, putActivityApi } from '../../apis/activitityApi/activityApi'
import { essayStatus } from '../../utils/essayStatus'
import sanitizedDate from '../../utils/DateSatinizerInput'
import SharedTablePagination from '../../components/SharedTablePagination'
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Textarea,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { listSupportMaterialApi } from '../../apis/themeApi/supportMaterialApi'
import SupportMaterialList from '../../components/SupportMaterialList'
import { DatePickerInput } from '../../components/Datepicker'
import { pt } from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import Page from '../Page'
import { getThemeApi } from '../../apis/themeApi/themeApi'

function DetalhamentoAtividade() {
  const navigate = useNavigate()
  const { atividadeID } = useParams()
  const [activityData, setActivityData] = useState({})
  const [supportMaterialList, setSupportMaterialList] = useState([])
  const [theme, setTheme] = useState({})
  const [loading, setLoading] = useState(true)
  const [filterText, setFilterText] = useState('')
  const [essays, setEssays] = useState([])
  const [selectedTab, setSelectedTab] = useState('detalhes')
  const modalDisclosure = useDisclosure()
  const popoverDisclosure = useDisclosure()

  const fetchOptions = () => {
    return new Promise((resolve) => {
      Promise.all([getActivityByIdApi(atividadeID)])
        .then(([activityResult]) => {
          Promise.all([listSupportMaterialApi(activityResult.theme.id), getThemeApi(activityResult.theme.id)])
            .then(([supportMaterialResult, themeResult]) => {
              resolve({ activityResult, supportMaterialResult, themeResult })
            })
        })
    })
  }

  useEffect(() => {
    fetchOptions().then(({ activityResult, supportMaterialResult, themeResult }) => {
      console.log(themeResult)
      setActivityData(activityResult)
      setSupportMaterialList(supportMaterialResult)
      setTheme(themeResult)
      setLoading(false)
    })
  }, [])

  function fetchActivity() {
    Promise.all([getActivityByIdApi(atividadeID)])
      .then(([activityResult]) => {
        setActivityData(activityResult)
      })
  }

  useEffect(() => {
    if (activityData) {
      setEssays(
        activityData.essays ? activityData.essays.filter((item) => {
          const result = (item.student.name.toLowerCase().includes(filterText.toLowerCase()) ||
            (item.dateSubmitted && sanitizedDate(item.dateSubmitted).includes(filterText)) ||
            item.group.name.toLowerCase().includes(filterText.toLowerCase()) ||
            essayStatus[item.status].toLowerCase().includes(filterText.toLowerCase()))

          return result
        }) : []
      )
    }
  }, [filterText, activityData])

  function HandleGroups() {
    const itemsPerPage = 5
    const [currentPage, setCurrentPage] = useState(1)

    if (!activityData.groups) {
      return ''
    }

    const totalPages = Math.ceil(activityData.groups.length / itemsPerPage)

    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1)
      }
    }

    const handleNext = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1)
      }
    }

    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const currentItems = activityData.groups.slice(startIndex, endIndex)

    return (
      <div className="frame-2492-detalhamento-atividade">
        {loading ? '' : currentItems.map((item, key) => {
          return (<GroupMiniCard groupName={item.name} key={key}/>)
        })}
        <div className="card-turmas-detalhamento-atividade-2">
          <HStack justifyContent={'center'} height={'100%'}>
            <img className={'arrow-pagination'} src={'/img/arrowLeftPagination.svg'} onClick={handlePrevious}/>
            <img className={'arrow-pagination'} src={'/img/arrowRightPagination.svg'} onClick={handleNext}/>
          </HStack>
        </div>
      </div>
    )
  }

  function GroupMiniCard(props) {
    const { groupName } = props

    return (
      <div className="card-turmas-1-detalhamento-atividade">
        <div className="content-detalhamento-atividade">
          <div
            className="group-text-detalhamento-atividade valign-text-middle-detalhamento-atividade publicsans-normal-dove-gray-14px">
            {groupName}
          </div>
        </div>
      </div>
    )
  }

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  function HandleStatusInput({ status }) {
    let backgroundColor
    if (status === 10) {
      backgroundColor = 'in-progress-essay-status'
    } else if (status === 20) {
      backgroundColor = 'in-grading-essay-status'
    } else if (status === 30) {
      backgroundColor = 'in-review-essay-status'
    } else if (status === 40) {
      backgroundColor = 'finished-essay-status'
    }
    return (
      <div className={'atividade-txt-detalhamento-atividade publicsans-normal-black-12px'}>
        <div className={backgroundColor}>
          {essayStatus[status]}
        </div>
      </div>)
  }

  function HandleSubmittedDate({ dateSubmitted }) {
    let date = ''
    if (dateSubmitted) {
      date = new Date(dateSubmitted).toLocaleDateString() + ' às ' + new Date(dateSubmitted).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      })
    }
    return (<div className={'table-cell-container publicsans-normal-black-12px'}>
      {date !== '' ? <img className="calendar-2851774-1-detalhamento-atividade" src={'/img/calendar-2851774@2x.png'}
                          alt="calendar-2851774"/> : ''}
      {date}</div>)
  }

  function HandleGroup({ groupName }) {
    return (
      <div className={'table-cell-container publicsans-normal-black-12px'}>
        <div className={'group-background'}> {groupName} </div>
      </div>
    )
  }

  function HandleDetails({ activityId }) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>{
        <Link to={'/detalhamento-atividade/' + activityId}>
          <div className="atividade-txt-4-area-de-turmas">
            <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'}
                 alt="ic-arrowright"/>
          </div>
        </Link>}
      </div>
    )
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function GetModalEditActivity() {
    const [validFrom, setValidFrom] = useState(new Date())
    const validFromPlusOne = new Date(validFrom)
    validFromPlusOne.setDate(validFrom.getDate() + 1)
    const [deadlineError, setDeadlineError] = useState(false)
    const [activityNameError, setActivityNameError] = useState(false)

    const currentDate = new Date()
    const endOfYear = new Date(new Date().getFullYear(), 11, 31)

    const maxCharacteres = 180

    const [editActivityData, setEditActivityData] = useState({
      id: activityData.id,
      subject: activityData.subject,
      description: activityData.description,
      deadline: activityData.deadline,
      validFrom: activityData.validFrom
    })

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
      const year = date.getFullYear()

      return `${year}-${month}-${day}T00:00:00.000Z`
    }

    return (
      <Modal isOpen={modalDisclosure.isOpen} size={'2xl'} onClose={modalDisclosure.onClose} isCentered={true}
             closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>Editar
            Atividade</ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'}/>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={`${activityNameError ? 'error-form' : ''} publicsans-bold-nevada-16px`}>Nome da
                atividade*
              </div>
              <Input className={'input-default-style'}
                     onChange={(e) => {
                       setActivityNameError(false)
                       setEditActivityData({ ...editActivityData, subject: e.target.value })
                     }}
                     defaultValue={editActivityData.subject}
              />
              <HStack width={'100%'}>
                <VStack width={'100%'} alignItems={'flex-start'}>
                  <div className={'publicsans-bold-nevada-16px'}>Inicio da atividade</div>
                  <DatePicker
                    selected={new Date(editActivityData.validFrom)}
                    onChange={(date) => {
                      if (date) {
                        setEditActivityData({ ...editActivityData, validFrom: formatDate(date) })
                        setValidFrom(date)
                      } else {
                        setValidFrom('')
                      }
                    }}
                    minDate={currentDate}
                    maxDate={endOfYear}
                    dateFormat="dd/MM/yyyy"
                    customInput={<DatePickerInput/>}
                    locale={pt}
                  />
                </VStack>
                <VStack width={'100%'} alignItems={'flex-start'}>
                  <div className={`${deadlineError ? 'error-form' : ''} publicsans-bold-nevada-16px`}>Entrega da
                    atividade
                  </div>
                  <DatePicker
                    selected={new Date(editActivityData.deadline)}
                    onChange={(date) => {
                      if (date) {
                        setDeadlineError(false)
                        setEditActivityData({ ...editActivityData, deadline: formatDate(date) })
                      }
                    }}
                    minDate={validFromPlusOne}
                    maxDate={endOfYear}
                    dateFormat="dd/MM/yyyy"
                    customInput={<DatePickerInput/>}
                    locale={pt}
                  />
                </VStack>
              </HStack>
              <div className={'publicsans-bold-nevada-16px'}>Descrição</div>
              <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
                <Textarea
                  placeholder=""
                  defaultValue={editActivityData.description}
                  onChange={(e) => setEditActivityData({ ...editActivityData, description: e.target.value })}
                  resize="vertical"
                  w={'100%'}
                  h={'100%'}
                  minHeight={'150px'}
                  borderColor={'#d7e3fb'}
                />
                <Text
                  fontSize="sm"
                  position="absolute"
                  bottom="5px"
                  right="20px"
                  color="#a9a8a8"
                  fontFamily={'Public Sans'}
                >
                  {editActivityData.description ? editActivityData.description.length : 0}/{maxCharacteres}
                </Text>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'}>
            <Button backgroundColor={'#f9faff'}
                    borderColor={'#648BF7'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#062989'}
                    height={'48px'}
                    width={'218px'} onClick={modalDisclosure.onClose}>
              Cancelar
            </Button>
            <Button backgroundColor={'#0A41D6'}
                    border={'1px'}
                    borderRadius={'8px'}
                    color={'#FFFFFF'}
                    height={'48px'}
                    width={'218px'}
                    onClick={async () => {
                      if (editActivityData.deadline <= editActivityData.validFrom || activityNameError) {
                        setDeadlineError(editActivityData.deadline <= editActivityData.validFrom)
                        setActivityNameError(!editActivityData.subject)
                      } else {
                        await putActivityApi(editActivityData)
                        fetchActivity()
                        modalDisclosure.onClose()
                      }
                    }}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <div className="detalhamento-atividade screen">
      <Page authPage={true} allowedUserType={''}>
        <Headbar returnTo={'/area-de-atividades'}/>
        <HStack width={'100%'} justifyContent={'space-between'} marginTop={'91px'}>
          <div className={'detalhamento-atividade-body'}>
            <div className="atividade-detalhamento-atividade">
              { theme?.base64Image ? <Image className={'activity-image-style'} src={theme?.base64Image}/> : '' }
              <div className="frame-2593-detalhamento-atividade">
                <div className="frame-2594-detalhamento-atividade">
                  <VStack width={'100%'} alignItems={'flex-start'}>
                    <div className="tema-title-style publicsans-bold-nevada-14px">Atividade</div>
                    <div
                      className="atividade-iii-unidade-2-detalhamento-atividade publicsans-normal-absolute-zero-24px">
                      {loading ? '' : activityData.subject}
                    </div>
                  </VStack>
                  <div className="editar-detalhamento-atividade">
                    <div className="edit-container-detalhamento-atividade">
                      <div
                        className="editar-1-detalhamento-atividade valign-text-middle publicsans-bold-nevada-14px">
                        Editar
                      </div>
                      <img
                        className="mode-edit-detalhamento-atividade"
                        src="/img/mode-edit@2x.png"
                        alt="Mode edit"
                        onClick={modalDisclosure.onOpen}
                      />
                      <GetModalEditActivity/>
                    </div>
                  </div>
                </div>
                <div className="frame-2592-detalhamento-atividade">
                  <div className="tema-detalhamento-atividade">
                    <div className="place-detalhamento-atividade publicsans-bold-nevada-10px">
                      TEMA
                    </div>
                    <p className="theme-name-detalhamento-atividade publicsans-normal-nevada-14px"
                       onClick={() => navigate('/detalhamento-tema/' + activityData.theme.id)}>
                      {loading ? '' : activityData.theme.name}
                    </p>
                  </div>
                  <HStack className="frame-2606-detalhamento-atividade">
                    <div className="entrega-detalhamento-atividade">
                      <div className="criao-detalhamento-atividade publicsans-bold-nevada-10px">
                        CRIAÇÃO
                      </div>
                      <Data date={loading ? '' : activityData.createdAt}/>
                    </div>
                    <div className="entrega-detalhamento-atividade">
                      <div className="criao-detalhamento-atividade publicsans-bold-nevada-10px">
                        INICIO
                      </div>
                      <Data date={loading ? '' : activityData.validFrom}/>
                    </div>
                    <div className="entrega-1-detalhamento-atividade">
                      <div className="entrega-2-detalhamento-atividade publicsans-bold-nevada-10px">
                        DATA FINAL
                      </div>
                      <Data date={loading ? '' : activityData.deadline}/>
                    </div>
                    <div className={'publicsans-bold-nevada-14px'}> Apagar atividade?</div>
                    <div>
                      <Popover isOpen={popoverDisclosure.isOpen} onOpen={popoverDisclosure.onOpen}
                               onClose={popoverDisclosure.onClose} placement="bottom-end" closeOnBlur={false}>
                        <PopoverTrigger>
                          <Image src={'/img/deleteIcon.svg'} marginRight={'15px'} cursor={'pointer'}/>
                        </PopoverTrigger>
                        <PopoverContent width={'448px'} borderRadius={'10px'}>
                          <PopoverArrow/>
                          <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'}/>
                          <PopoverHeader>
                            <div className={'text-popover'}>Confirme Remoção da atividade</div>
                          </PopoverHeader>
                          <PopoverFooter alignSelf={'center'} marginTop={'50px'} width={'100%'}>
                            <HStack width={'100%'}>
                              <Button backgroundColor={'#f9faff'}
                                      borderColor={'#648BF7'}
                                      border={'1px'}
                                      borderRadius={'8px'}
                                      color={'#062989'}
                                      height={'48px'}
                                      width={'100%'} onClick={popoverDisclosure.onClose}>
                                Cancelar
                              </Button>
                              <Button backgroundColor={'#0A41D6'}
                                      color={'white'}
                                      width={'100%'}
                                      height={'48px'}
                                      onClick={async () => {
                                        await deleteActivityApi(activityData.id)
                                        navigate('/area-de-atividades')
                                      }}
                              > Confirmar Remoção </Button>
                            </HStack>
                          </PopoverFooter>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </HStack>
                </div>
              </div>
            </div>
            <div className="frame-2595-detalhamento-atividade">
              <div className="switch-detalhamento-atividade">
                <div className={`frame-2469-detalhamento-atividade ${selectedTab === 'detalhes' ? 'tab-border' : ''}`}
                     onClick={() => changeSelectedTab('detalhes')}>
                  <div
                    className={`material-de-apoio-detalhamento-atividade ${selectedTab === 'detalhes' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                    Detalhes da Atividade
                  </div>
                </div>
                <div className={`frame-2459-detalhamento-atividade ${selectedTab === 'redacoes' ? 'tab-border' : ''}`}
                     onClick={() => changeSelectedTab('redacoes')}>
                  <div
                    className={`redacoes-detalhamento-atividade ${selectedTab === 'redacoes' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                    Redações
                  </div>
                </div>
              </div>
            </div>
            {selectedTab === 'detalhes' && (
              <VStack marginLeft={'32px'} width={'100%'}>
                <div className={'title-detalhe-atividade'}> Descrição da atividade</div>
                <div
                  className={'detalhes-da-atividade publicsans-normal-nevada-16px'}> {activityData.description ? activityData.description : ''}</div>
                <div className={'title-detalhe-atividade'}> Anexos</div>
                {
                  <VStack width={'100%'} marginBottom={'32px'}>
                    <SupportMaterialList supportMaterialList={supportMaterialList}
                                         deleteType={'detalhamentoAtividade'}/>
                  </VStack>
                }
              </VStack>
            )}
            {selectedTab === 'redacoes' && (
              <div className={'redacoes-container-style'}>
                <div className="search-filter-detalhamento-atividade">
                  <div className="search-filtro-detalhamento-atividade">
                    <div className="search-detalhamento-atividade">
                      <img
                        className="search-2851823-detalhamento-atividade"
                        src="/img/search-2851823@2x.png"
                        alt="search-2851823"
                      />
                      <input
                        placeholder={'Buscar redações...'}
                        type={'search'}
                        className="text-detalhamento-atividade publicsans-normal-nevada-16px"
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </div>
                </div>
                <SharedTablePagination
                  className={'table-adjustment-style'}
                  data={essays}
                  itemsPerPage={5}
                  columns={[
                    {
                      title: 'Status',
                      render: (itemData) => <HandleStatusInput status={itemData.status}/>
                    },
                    {
                      title: 'Data de Entrega',
                      render: (itemData) => <HandleSubmittedDate dateSubmitted={itemData.dateSubmitted}/>
                    },
                    {
                      title: 'Turma',
                      render: (itemData) => <HandleGroup groupName={itemData.group.name}/>
                    },
                    {
                      title: 'Aluno',
                      render: (itemData) => <div
                        className={'table-cell-container publicsans-normal-nevada-14px'}>{itemData.student.name}</div>
                    },
                    {
                      title: 'Detalhes',
                      render: (itemData) => <HandleDetails activityId={itemData.id}/>
                    }
                  ]}
                />
              </div>
            )}
          </div>
          <div className="frame-2591-detalhamento-atividade">
            <div className="title-cta-detalhamento-atividade">
              <div className="title-detalhamento-atividade">
                <h1 className="title-1-detalhamento-atividade publicsans-normal-resolution-blue-24px">
                  Turmas vinculadas
                </h1>
              </div>
            </div>
            <HandleGroups/>
          </div>
        </HStack>
      </Page>
    </div>
  )
}

export default DetalhamentoAtividade

function Data(props) {
  const { date } = props

  const formattedDate = new Date(date).toLocaleDateString()

  return (
    <div className="data-detalhamento-atividade">
      <img className="calendar-2851774-detalhamento-atividade" src={'/img/calendar-2851774-5@2x.png'}
           alt="calendar-2851774"/>
      <div className="date-detalhamento-atividade publicsans-normal-nevada-14px">
        {date === '' ? '' : formattedDate}
      </div>
    </div>
  )
}
