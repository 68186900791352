import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const createAvailableEssay = async (body) => {
  const response = await httpRequestHelper.postWithAuth(BACKEND_API + '/v1/essay', body)

  return response.data.message
}

export const fetchEssay = async (id) => {
  const response = await httpRequestHelper.getWithAuth(BACKEND_API + '/v1/essay/' + id)

  return response.data.message
}

export const editExitingEssay = async (id, newContent) => {
  const body = {
    essayId: id,
    draft: newContent
  }
  const response = await httpRequestHelper.putWithAuth(BACKEND_API + '/v1/essay', body)

  return response.data.message
}

export const submitEssayForCorrection = async (id) => {
  const response = await httpRequestHelper.putWithAuth(BACKEND_API + '/v1/essay/' + id + '/submit')

  return response.data.message
}

export const fetchComments = async (id) => {
  const response = await httpRequestHelper.getWithAuth(BACKEND_API + '/v1/essay/' + id + '/comments')

  return response.data.message
}

export const updateGradesApi = async (essayId, body) => {
  const response = await httpRequestHelper.putWithAuth(BACKEND_API + '/v1/essay/' + essayId + '/update-grades', body)

  return response.data.message
}

export const postCommentaryApi = async (essayId, body) => {
  const response = await httpRequestHelper.postWithAuth(BACKEND_API + '/v1/essay/' + essayId + '/comments', body)

  return response.data.message
}

export const deleteCommentaryApi = async (essayId, commentId) => {
  const response = await httpRequestHelper.delWithAuth(BACKEND_API + '/v1/essay/' + essayId + '/comments/' + commentId)

  return response.data.message
}

export const finishReviewApi = async (essayId) => {
  const response = await httpRequestHelper.putWithAuth(BACKEND_API + '/v1/essay/' + essayId + '/finish-review')

  return response.data.message
}

export const getEssaysByTeacherIdApi = async () => {
  const teacherID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.getWithAuth(BACKEND_API + '/v1/essay/teacher/' + teacherID)

  return response.data.message
}

export const getEssaysByStudentIdApi = async (studentID) => {
  const response = await httpRequestHelper.getWithAuth(BACKEND_API + '/v1/essay/student/' + studentID)

  return response.data.message
}

export const getEssaysByGroupIdApi = async (groupID) => {
  const response = await httpRequestHelper.getWithAuth(BACKEND_API + '/v1/essay/group/' + groupID)

  return response.data.message
}
