export const promptColumns = [
  {
    Header: 'Titulo',
    accessor: 'title',
    sample: 'sadasdasd'
  },
  {
    Header: 'Conteudo',
    accessor: 'content',
    sample: 'sadsadasd'
  },
  {
    Header: 'Conteudo de Sistema',
    accessor: 'systemContent',
    sample: 'sadsadasd'
  },
  {
    Header: 'Modelo',
    accessor: 'model',
    sample: 'sadsada'
  },
  {
    Header: 'Detalhes',
    accessor: 'details',
    sample: 'asd'
  },
  {
    Header: 'Action',
    accessor: 'action'
  }
]
