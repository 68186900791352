import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const createCompetencyApi = async (data, institutionId) => {
  const schoolID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.postWithAuth(BACKEND_API + `/v1/school/${schoolID}/institution/${institutionId}/competency`, data)

  return response.data.message
}

export const putCompetencyApi = async (data, institutionId) => {
  const schoolID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.putWithAuth(BACKEND_API + `/v1/school/${schoolID}/institution/${institutionId}/competency`, data)

  return response.data.message
}

export const deleteCompetencyApi = async (competencyId, institutionId) => {
  const schoolID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.delWithAuth(BACKEND_API + `/v1/school/${schoolID}/institution/${institutionId}/competency/${competencyId}`)

  return response.data.message
}
