export const Select = {
  parts: ['field'],
  baseStyle: {
    field: {
      backgroundImage: `url(${'/img/ic-arrowdown-4@2x.png'})`,
      backgroundSize: '24px 24px',
      backgroundPosition: 'right 16px center',
      backgroundRepeat: 'no-repeat',
      appearance: 'none'
    }
  }
}
