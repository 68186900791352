import React, { useEffect, useState } from 'react'
import './CriarAtividadeStep2.css'
import BackStepButton from '../BackStepButton'
import ForwardButton from '../ForwardButton'
import Headbar from '../Headbar'
import { Box, Flex, Heading, Image, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react'
import FormStepNotSelected from '../FormStepNotSelected'
import StepsImage from '../StepsImage'
import { MultiSelect } from 'chakra-multiselect'
import { listAllGroups } from '../../apis/schoolApi/groupApi'
import { listAllTeachers } from '../../apis/teacherApi/teacherApi'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DatePickerInput } from '../Datepicker'
import { pt } from 'date-fns/locale'
import useCriarAtividadeStore from '../../store/useCriarAtividadeStore'

function CriarAtividadeStep2(props) {
  const { handleReturn, handlingNextStep } = props
  const [turmaOptions, setTurmaOptions] = useState([])
  const [monitorOptions, setMonitorOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [turmaValue, setTurmaValue] = useState([])
  const [monitorValue, setMonitorValue] = useState([])
  const [validFrom, setValidFrom] = useState(new Date())
  const [deadlineDate, setDeadlineDate] = useState(new Date())
  const [modoDeEnvioValue, setModoDeEnvioValue] = React.useState('1')
  const { criarAtividadeData, setCriarAtividadeData } = useCriarAtividadeStore()

  const [turmasError, setTurmasError] = useState(false)
  const [inicioError, setInicioError] = useState(false)
  const [deadlineError, setDeadlineError] = useState(false)

  const fetchOptions = () => {
    return new Promise((resolve) => {
      Promise.all([listAllGroups(), listAllTeachers()])
        .then(([turmaResult, monitorResult]) => {
          resolve({ turmaResult, monitorResult })
        })
    })
  }

  useEffect(() => {
    fetchOptions().then(({ turmaResult, monitorResult }) => {
      setTurmaOptions(turmaResult.map((turma, key) => {
        return { value: turma.name, label: turma.name, id: turma.id }
      }))

      setMonitorOptions(monitorResult.map((monitor, key) => {
        return { value: monitor.name, label: monitor.name, id: monitor.id }
      }))

      setLoading(false)
    })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const currentDate = new Date()
  const endOfYear = new Date(new Date().getFullYear(), 11, 31)

  const handleModoDeEnvioChange = (e) => {
    setModoDeEnvioValue(e)
  }

  useEffect(() => {
    if (turmaValue) {
      setCriarAtividadeData({ groups: turmaValue.map(turma => turmaOptions.find(item => turma.value === item.value).id) })
    }
  }, [turmaValue])

  const handleSubmit = () => {
    if ((criarAtividadeData.groups.length > 0 && criarAtividadeData.validFrom && criarAtividadeData.deadline) || (deadlineDate > validFrom)) {
      handlingNextStep()
    } else {
      setTurmasError(criarAtividadeData.groups.length === 0)
      setInicioError(!validFrom)
      setDeadlineError(!deadlineDate)
      setDeadlineError(deadlineDate <= validFrom)
    }
  }

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
    const year = date.getFullYear()

    return `${year}-${month}-${day}T00:00:00.000Z`
  }

  return (
    <Flex h={'100%'} w={'100%'}>
      <VStack w={'100%'} h={'100%'}>
        <Headbar returnTo={'/area-de-atividades'}/>
        <VStack marginTop={'107px'}>
          <Heading alignSelf={'start'} ml={'35px'}
                   className={'publicsans-extra-extra-bold-absolute-zero-32px'}>Criar atividade</Heading>
          <VStack mt={'32px'}>
            <Concluido/>
            <div className="cards-step-2-container">
              <StepsImage text={'Configurações da atividade'} number={2}/>
              <div className="cards-step-2">
                <div className={`${turmasError ? 'error-form' : ''} modo-de-envio-step-2 publicsans-bold-nevada-16px`}>
                  Turmas*
                </div>
                <Box w={'100%'} h={'48px'}>
                  <MultiSelect
                    placeholder={loading ? 'Aguarde um momento...' : 'Selecionar turma'}
                    onChange={(e) => {
                      if (e) {
                        setTurmasError(false)
                      }
                      setTurmaValue(e)
                    }}
                    borderColor={'#d7e3fb'}
                    backgroundColor={'white'}
                    isDisabled={loading}
                    options={turmaOptions}
                    value={turmaValue}
                    w={'100%'}
                  />
                </Box>

                <div className="modo-de-envio-step-2 publicsans-bold-nevada-16px">
                  Selecione monitores
                </div>
                <Box w={'100%'} h={'48px'}>
                  <MultiSelect
                    placeholder={loading ? 'Aguarde um momento...' : 'Selecionar monitor'}
                    onChange={setMonitorValue}
                    borderColor={'#d7e3fb'}
                    backgroundColor={'white'}
                    isDisabled={loading}
                    options={monitorOptions}
                    value={monitorValue}
                    w={'100%'}
                  />
                </Box>
                <div className="email-step-2">
                  <div className="frame-2596-step-2">
                    <Box>
                      <div
                        className={`${inicioError ? 'error-form' : ''} x-da-atividade-step-2 publicsans-bold-nevada-16px`}>
                        {'Início da atividade'}
                      </div>
                      <DatePicker
                        selected={validFrom}
                        onChange={(date) => {
                          if (date) {
                            setInicioError(false)
                            setCriarAtividadeData({ validFrom: formatDate(date) })
                            setValidFrom(date)
                          } else {
                            setValidFrom('')
                          }
                        }}
                        minDate={currentDate}
                        maxDate={endOfYear}
                        dateFormat="dd/MM/yyyy"
                        customInput={<DatePickerInput/>}
                        locale={pt}
                      />
                    </Box>
                    <Box>
                      <div
                        className={`${deadlineError ? 'error-form' : ''} x-da-atividade-step-2 publicsans-bold-nevada-16px`}>
                        {'Entrega da atividade'}
                      </div>
                      <DatePicker
                        selected={deadlineDate}
                        onChange={(date) => {
                          if (date) {
                            setDeadlineError(false)
                            setCriarAtividadeData({ deadline: formatDate(date) })
                            setDeadlineDate(date)
                          } else {
                            setDeadlineDate('')
                          }
                        }}
                        minDate={validFrom}
                        maxDate={endOfYear}
                        dateFormat="dd/MM/yyyy"
                        customInput={<DatePickerInput/>}
                        locale={pt}
                      />
                    </Box>
                  </div>
                </div>

                <Box className="email-1-step-2">
                  <Text className="modo-de-envio-step-2 publicsans-bold-nevada-16px">Modo de envio</Text>
                  <RadioGroup
                    className="frame-2599-step-2"
                    value={modoDeEnvioValue}
                    onChange={handleModoDeEnvioChange}
                    name="myRadioGroup"
                  >
                    <Box className="frame-2602-step-2"
                         backgroundColor={modoDeEnvioValue === '1' ? '#E4E9FF' : '#F3F5FF'}
                         borderColor={'var(--hawkes-blue)'}>
                      <Box className="frame-2600-step-2">
                        <Image className="x-28517-step-2"
                               src={modoDeEnvioValue === '1' ? '/img/imagem-selecionado.svg' : '/img/camera-2851779@2x.png'}
                               alt="camera-2851779"/>
                        <Text className="text-2-step-2 publicsans-normal-nevada-14px">Envio por imagem ou texto</Text>
                      </Box>
                      <Radio className={'radio-button-step-2'} value="1" colorScheme="blue" size="lg" color={'#0A41D6'}
                             border={'2px'}/>

                    </Box>
                    <Box className="frame-2602-step-2"
                         backgroundColor={modoDeEnvioValue === '2' ? '#E4E9FF' : '#F3F5FF'}
                         borderColor={'var(--hawkes-blue)'}>
                      <Box className="frame-2600-1-step-2">
                        <Image className="x-28517-step-2"
                               src={modoDeEnvioValue === '2' ? '/img/document-2851788@2x.png' : '/img/texto-nao-selecionado.svg'}
                               alt="document-2851788"/>
                        <Text className="text-3-step-2 publicsans-normal-nevada-14px">Envio apenas por texto</Text>
                      </Box>
                      <Radio className={'radio-button-step-2'} value="2" colorScheme="blue" size="lg" color={'#0A41D6'}
                             border={'2px'}/>

                    </Box>
                  </RadioGroup>
                </Box>
              </div>
            </div>
            <div className={'steps-step-2'}>
              <FormStepNotSelected number="3" configuraesDaAtividade="Enviar"/>
            </div>
          </VStack>
          <div className="buttons">
            <BackStepButton handleReturn={handleReturn} text={'Voltar'}/>
            <ForwardButton
              forwardTo={handleSubmit}
              className={'button-10'}
              text={'Enviar'}
              buttonClassName={''}
            />
          </div>

        </VStack>
      </VStack>
    </Flex>
  )
}

export default CriarAtividadeStep2

function Concluido() {
  return (
    <div className="concluido-step-2">
      <img className="frame-2482-step-2" src="/img/frame-2482@2x.png" alt="Frame 2482"/>
      <div className="frame-2486-step-2">
        <div className="text-step-2 publicsans-normal-gray-16px">
          Sobre a atividade
        </div>
        <div className="atividade-unidade-2-step-2 publicsans-normal-nevada-20px">
          Atividade unidade 2
        </div>
      </div>
    </div>
  )
}
