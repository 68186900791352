import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Image } from '@chakra-ui/react'

function TemaCard(props) {
  const { itemData } = props

  const [atividadesVinculadasECriacao, setAtividadesVinculadasECriacao] =
    useState([])

  useEffect(() => {
    const fetchData = async () => {
      // assuming listAlltheme returns a promise
      // const response = await listAlltheme();
      // assuming convertResponse is a function that processes the response
      // inicioProfessorData = convertResponse(response);

      handleRender()
    }

    fetchData()
  }, [])

  function handleRender() {
    gerarAtividadesVinculadasECriacao()
  }

  function gerarAtividadesVinculadasECriacao() {
    const atividadesVinculadas = itemData.activities.length
    const date = new Date(itemData.createdAt)
    const formattedDate = date.toLocaleDateString()
    const tema = itemData.subject
    const result = (
      <Tema012
        atividadesVinculadas={atividadesVinculadas}
        dataCriacao={formattedDate}
        gerarTema={tema}
        temaId={itemData.id}
        base64Image={itemData.base64Image}
      />
    )
    setAtividadesVinculadasECriacao(result)
  }

  return (
    <div className="atividades-area-de-temas">
      {atividadesVinculadasECriacao}
    </div>
  )
}

export default TemaCard

function Tema012(props) {
  const { atividadesVinculadas, dataCriacao, gerarTema, temaId, base64Image } = props

  return (
    <div className="tema-area-de-temas">
      <div className="title-link-area-de-temas">
        {base64Image
          ? (
            <Image className={'tema-image-style'} src={base64Image}/>
            )
          : (
            <div className="atomsphotopicalbum-area-de-temas"></div>
            )}
        <div className="frame-2587-area-de-temas">
          <div className="frame-2580-area-de-temas">
            <div className="tema-ativo-area-de-temas valign-text-middle-area-de-temas publicsans-normal-black-12px">
              Tema ativo
            </div>
          </div>
          <div className="tema-1-area-de-temas">
            <p className="desmatamento-florest-area-de-temas publicsans-bold-absolute-zero-16px">
              {gerarTema}
            </p>
          </div>
        </div>
      </div>
      <div className="content-1-area-de-temas">
        <div className="frame-2588-area-de-temas">
          <div className="criao-atividade-area-de-temas">
            <div className="atividades-vinculadas-area-de-temas publicsans-normal-nevada-16px">
              Atividades vinculadas
            </div>
            <div className="data-area-de-temas">
              <img
                className="x-2851-area-de-temas"
                src={'/img/paper-2851817-2@2x.png'}
                alt="paper-2851817"
              />
              <div className="number-1-area-de-temas publicsans-normal-nevada-14px">
                {atividadesVinculadas}
              </div>
            </div>
          </div>
          <div className="criao-atividade-1-area-de-temas">
            <div className="criada-em-area-de-temas publicsans-normal-nevada-16px">
              Criada em:
            </div>
            <div className="data-area-de-temas">
              <img
                className="x-2851-area-de-temas"
                src={'/img/edit-square-2851790@2x.png'}
                alt="paper-2851817"
              />
              <div className="number-1-area-de-temas publicsans-normal-nevada-14px">
                {dataCriacao}
              </div>
            </div>
          </div>
        </div>
        <div className="button-area-de-temas">
          <Link to={`/detalhamento-tema/${temaId}`}>
            <Mainbutton>{'Ver detalhes'}</Mainbutton>
          </Link>
        </div>
      </div>
    </div>
  )
}

function Mainbutton(props) {
  const { children } = props

  return (
    <div className="mainbutton-area-de-temas">
      <div className="content-2-area-de-temas">
        <div className="main-button-area-de-temas publicsans-bold-white-14px">
          {children}
        </div>
      </div>
    </div>
  )
}
